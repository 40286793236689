import { Scatter } from "vue-chartjs";
//const { reactiveProp } = mixins;

export default {
  extends: Scatter,
  //mixins: [reactiveProp],
  props: ["makedata","missdata"],
  watch: {
    makedata: function() {
      this.$data._chart.destroy();
      this.createGraph();
    },
    missdata:function(){
      this.$data._chart.destroy();
      this.createGraph();
    }
  },
  mounted() {
    
    setInterval(this.createGraph(), 3000);
  },
  methods: {
    update() {
      this.$data._chart.update()
    },
    createGraph(){
      this.scatteroptions = { 
        responsive: true,  
        maintainAspectRatio: false,
        legend: {display: false},
        animation: {
          duration: 0, // general animation time
      },
        scales: 
        {
         xAxes: [{
                  ticks: {
                    display: true,
                    beginAtZero: false,
                           stepSize:1,
                           min:1,
                            max:5,
                   },
                  gridLines: {display:false}
               }],
         yAxes: [{
                  ticks: {display: false,
                   beginAtZero: false,
                           stepSize:1,
                           min:1,
                            max:10,},
                  gridLines: {display:false }   
                }]
        },
        tooltips: 
        {
           enabled: true,
           mode: 'single',
           displayColors: false,
           callbacks: {
             label: function(tooltipItems,data) { 
               return data['datasets'][tooltipItems['datasetIndex']]["data"][[tooltipItems["index"]]]["title"];
             }
           }
        },
     };
     
   this.datacollection = {
                           datasets:[
                                     {
                                     pointBackgroundColor: "rgba(12, 71, 157, 0.8)",
                                     pointRadius: 5,
                                     borderWidth: 1,
                                     data: []
                                     }, 
                                     {
                                     pointRadius: 4,
                                     pointBorderColor: '#0C479D',
                                     borderWidth: 2,
                                     data: []
                                     }
                                   ]
                        }
                         
                        // for (let i = 1; i <= this.makedata.length - 1; i++) 
                        // {
                        //     this.datacollection.datasets[0].backgroundColor[i] = this.makedata[i].background;
                        //     this.datacollection.datasets[0].pointBorderColor[i]     = this.makedata[i].background;
                        // }
                        // for (let i = 1; i <= this.missdata.length - 1; i++) 
                        // {
                        //     this.datacollection.datasets[1].backgroundColor[i]   = "transparent";
                        //     this.datacollection.datasets[1].pointBorderColor[i]     = this.missdata[i].background;
                        // }
      this.datacollection.datasets[0].data = this.makedata;                  
      this.datacollection.datasets[1].data = this.missdata;
      this.renderChart(this.datacollection, this.scatteroptions);
      
    }
  },
};
