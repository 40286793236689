<template>
    <div id="livescorepage">
      <v-container class="fill-height" fluid>
        <div v-if="sportstab.length == 0">
            {{showmessage}}
        </div>
        <div class="overall-layout-box" v-else>
          <v-tabs v-model="sportid" color="themebtn">
            <v-tab v-for="(value,key) in sportstab" @change="changesports(key)" :key="value.name">
              <div>{{ value.name }} <img class="sideicon livescore" :src="value.web_icon"></div>
              <div class="lookdifferent" v-if="value.smallname == 'ncaa-basketball'">Basketball</div>
              <div class="lookdifferent" v-if="value.smallname == 'ncaa-football'">Football</div>
            </v-tab>
          </v-tabs>
          <div class=" mt-5 allgame-box">
            <slider-frame class="fourbox" v-if="selectsportid != 12" :slides-per-view="3" :breakpoints="{sm: { slidesPerView: 1 }, md: { slidesPerView: 2 }, lg: { slidesPerView: 3 }, xl: { slidesPerView: 3 } }" :infinite-scroll="false">
              <div class="slider d-inline-flex" slot-scope="{ next, prev, scrollToSlide, activeIndex, canScrollNext, canScrollPrev }">
                <button class="mr-2 my-auto button" v-if="canScrollPrev" :disabled="!canScrollPrev" @click="prev">
                  <span class="material-icons">arrow_left</span>
                </button>
              <slider-slides>
                <slider-slide v-for="(invalue,keys) in sportsdata[selectsportid]" :key="keys"  class="align-items-center" @click.native="changematch(keys)" @touchend.native="changematch(keys)">
               <div class="gameblock" :class="{'active-slide' : activeIndex === keys,'px-1': activeIndex !== keys}"  :title="getvsteamname(invalue.teamafullname,invalue.teambfullname)" >
                 <div class="d-flex settimer" >
                  <div class="gamestatus">{{ invalue.gamestatus }}</div>
                 <div class="timercount" v-if="hidecountdown && keys == selectedmatchid && invalue.gamestatus != 'Completed'">{{ formatedCountdown }}</div>
                 </div>
                 <div v-if="selectsportid != 23">
                    <div class="single-team-info">
                       <div class="team-info-box" :class="invalue.teamacolor">
                         <span><img :src="invalue.teamalogo"></span><span>{{invalue.team_a_name}}</span>
                       </div>
                       <div class="team-value"  :class="invalue.teamacolor">
                           <span class="teamscore">{{invalue.teamascore}} </span>
                           <span :class="invalue.teamadiffcolor" v-if="invalue.showteamprices == 1">
                              <span>${{invalue.teamAactualprice}} &nbsp;( </span>
                                <span v-if="invalue.teamadiffcolor == 'greencolor'">+</span>
                                <span v-else-if="invalue.teamadiffcolor == 'orangecolor'">+</span>
                                <span v-else>-</span>
                                <span>${{invalue.teamadiff}}</span>
                              <span> )</span>
                           </span>
                       </div>
                    </div>
                    <div class="single-team-info">
                      
                       <div class="team-info-box" :class="invalue.teambcolor">
                         <span><img :src="invalue.teamblogo"></span>
                         <span>{{invalue.team_b_name}}</span>
                       </div>
                       <div class="team-value"  :class="invalue.teambcolor">
                          <span class="teamscore">{{invalue.teambscore}}</span>
                          <span :class="invalue.teambdiffcolor" v-if="invalue.showteamprices == 1">
                              <span>${{invalue.teamBactualprice}} &nbsp;( </span>
                                <span v-if="invalue.teambdiffcolor == 'greencolor'">+</span>
                                <span v-else-if="invalue.teambdiffcolor == 'orangecolor'">+</span>
                                <span v-else>-</span>
                                <span>${{invalue.teambdiff}}</span>
                              <span> )</span>
                           </span>
                       </div>
                    </div>
                 </div>  
                 <div v-else>
                   <div class="single-team-info">
                       <div class="team-info-box">
                         <div class="mb-5 tourname">{{invalue.name}}</div>
                       </div>
                       
                    </div>
                 </div>
               </div> 
             </slider-slide>
            </slider-slides>
            <button class="button ml-2 my-auto" v-if="canScrollNext" :disabled="!canScrollNext" @click="next">
             <span class="material-icons">arrow_right</span>
            </button>
          </div>
        </slider-frame>
       </div>
      
      <div class="mt-5">
        <v-tabs v-model="basketballtab" @change="boxscore()" color="themebtn" fixed-tabs v-if="sportname == 'Basketball'">
        <v-tab>Play By Play</v-tab>
        <v-tab>Box Score</v-tab>
       </v-tabs>
      </div> 
      <div v-if="showcommentry && basketballtab == 0" class="commentry">
        <div>
          <v-tabs class="commtabs" v-model="commtab" fixed-tabs background-color="white" >
           <v-tab> 1st </v-tab>
           <v-tab> 2nd </v-tab>
           <v-tab> 3rd </v-tab>
           <v-tab> 4th </v-tab>
          </v-tabs>
          <div class="commentry-content">
            <div class="basketb-comm-head underline">
              <div class="time bolder">TIME</div>
              <div class="playbyplay bolder">PLAY</div>
              <div class="gamescore bolder">
                <div><img class="scorelogo" :src="awayteamlogo"/> </div>
                <div>{{ teamsdata.away }}</div>
              </div>
              <div class="gamescore bolder">
                 <div><img class="scorelogo" :src="hometeamlogo"/></div>
                 <div>{{ teamsdata.home }}</div>
              </div>
            </div>
           
            <div class="scrollable" id="commdiv">
             <div v-for="(val,key) in commentrydata[commtab]" :key="key">
              <div class="basketb-comm-head">
               <div class="time">{{ val["time"] }}</div>
                <div class="playbyplay">
                  <span class="mr-2" v-if="val['logo'] != ''"><img class="commlogo" :src="val['logo']" /></span> 
                  <span :class="{'boldit': val['boldteam']}">{{ val["commentry"] }}</span>
                </div>
                <div class="gamescore" :class="{'boldit': val['boldteam'] == 'away' }">{{ val["away_score"] }}</div>
                <div class="gamescore" :class="{'boldit': val['boldteam'] == 'home'}">{{ val["home_score"] }}</div>
              </div>
             </div>
            </div>
           </div>
        </div>   
        <div class="pitchmap">
          <div class="pitchimg">
            <scatter chart-id="pitchmapchart" ref="pitchmapchart" v-if="showpitchmap" :makedata="makedata" :missdata="missdata"></scatter>
            <img :src="courtimg" />
            <div class="innerhomelogo"><img :src="groundlogo"  /></div>
            <div class="hidescatter"></div>
          </div>   
          <div class="filteration">
             <div class="filter-item mt-2">
              <v-select @change="filtering" :items="quarters" v-model="filterQuarter" label="ALL QUARTERS" hide-details rounded dense outlined></v-select>
             </div>
             <div class="filter-item d-flex mt-3">
               <div><img class="scorelogo" :src="awayteamlogo"/> </div>
               <div class="teamname">{{ teamsdata.away }}</div>  
             </div>
             <div class="filter-item">
              <v-select @change="filtering" :items="awayteamplayers" v-model="awayplayerfilter" item-text="name" item-value="id" label="SELECT PLAYER" hide-details rounded dense outlined></v-select>
             </div>
             <div class="filter-item d-flex diffsize">
                <div class="d-flex filterinner">
                  <v-checkbox @change="filtering" v-model="awaymade" v-bind:false-value="false"  v-bind:true-value="true" color="blue" value="blue" hide-details></v-checkbox>
                  <div class="pointmade"><span class="colorpointer"></span> <span>Made</span></div>
                </div>
                <div class="d-flex filterinner">
                  <v-checkbox @change="filtering" v-model="awaymiss" v-bind:false-value="false"  v-bind:true-value="true" color="blue" value="blue" hide-details></v-checkbox>
                  <div class="pointmissed"><span class="colorpointer"></span> <span>Missed</span></div>
                </div>
             </div>
             <div class="filter-item d-flex  mt-3">
               <div><img class="scorelogo" :src="hometeamlogo"/> </div>
               <div class="teamname">{{ teamsdata.home }}</div>  
             </div>
             <div class="filter-item">
              <v-select @change="filtering" v-model="homeplayerfilter" :items="hometeamplayers"  item-text="name" item-value="id" label="SELECT PLAYER" hide-details rounded dense outlined></v-select>
             </div>
             <div class="filter-item d-flex diffsize">
                <div class="d-flex filterinner">
                  <v-checkbox @change="filtering" v-model="homemade" v-bind:false-value="false"  v-bind:true-value="true" color="blue" value="blue" hide-details></v-checkbox>
                  <div class="pointmade"><span class="colorpointer"></span> <span>Made</span></div>
                </div>
                <div class="d-flex filterinner">
                  <v-checkbox @change="filtering" v-model="homemiss" v-bind:false-value="false"  v-bind:true-value="true" color="blue" value="blue" hide-details></v-checkbox>
                  <div class="pointmissed"><span class="colorpointer"></span> <span>Missed</span></div>
                </div>
             </div>  
          </div>
       </div>
     </div>
     <div v-if="basketballtab == 1" class="boxscorediv">
        <div class="boxes mt-5">
           <div class="teamintro">
             <img class="logs" :src="basketballdata.hometeamlogo" />
             <span>{{ basketballdata.hometeamname }}</span>
           </div>
        </div>  
        <div class="teamdata">
          <div class="heading">
            <div class="name"></div>
            <div v-for="(val,key) in basketballdata.heading" :key="key">{{ val }}</div>
          </div>
          <div class="playerscore">
            <div v-for="(val,key) in basketballdata.homeplayer" :key="key" :class="{'even': key % 2 === 0, 'odd': key % 2 !== 0 }">
               <div class="name">
                <router-link class="playerlink" :to="{name: 'player', params:{ id: val.id }}">{{ val.name }} </router-link> 
               </div>
               <div v-for="(val,key) in val.stat" :key="key">{{ val }}</div>
            </div>
          </div>
        </div>

        <div class="boxes mt-5">
           <div class="teamintro">
             <img class="logs" :src="basketballdata.awayteamlogo" />
             <span>{{ basketballdata.awayteamname }}</span>
           </div>
        </div>  
        <div class="teamdata">
          <div class="heading">
            <div class="name"></div>
            <div v-for="(val,key) in basketballdata.heading" :key="key">{{ val }}</div>
          </div>
          <div class="playerscore">
            <div v-for="(val,key) in basketballdata.awayplayer" :key="key" :class="{'even': key % 2 === 0, 'odd': key % 2 !== 0 }">
               <div class="name">
                <router-link class="playerlink" :to="{name: 'player', params:{ id: val.id }}">{{ val.name }} </router-link> 
               </div> 
               <div v-for="(val,key) in val.stat" :key="key">{{ val }}</div>
            </div>
          </div>
        </div>
     </div>
      
     <div class="mt-5" v-if="selectsportid != 12"> 
            <div v-if="selectsportid != 23">
              <v-tabs class="tabber mt-10" centered color="themebtn" v-model="teamtabs" v-if="sportsdata[selectsportid][selectedmatchid].teamaplayers.length > 0">
            <v-tab>{{sportsdata[selectsportid][selectedmatchid].teamafullname}}</v-tab>
            <v-tab>{{sportsdata[selectsportid][selectedmatchid].teambfullname}}</v-tab>   
          </v-tabs>  
            </div>
            <div v-if="teamtabs == 0" >
              <div v-if="sportsdata[selectsportid][selectedmatchid].teamaplayers.length > 0">
                <playerlisting :staticgraph="staticgraph" :itemsWithSno="sportsdata[selectsportid][selectedmatchid].teamaplayers" />
            </div>  
            </div>  
            <div v-else>
              <div v-if="sportsdata[selectsportid][selectedmatchid].teambplayers.length > 0">
                <playerlisting :staticgraph="staticgraph" :itemsWithSno="sportsdata[selectsportid][selectedmatchid].teambplayers" />
              </div>  
            </div>  
       </div>
       <div class="mt-10" v-else>
            <div v-if="sportsdata[selectsportid][selectedmatchid].players.length > 0">
                  <playerlisting :staticgraph="staticgraph" :itemsWithSno="sportsdata[selectsportid][selectedmatchid].players" />
            </div>     
       </div>  
      </div>         
     </v-container>
      <v-overlay color="white" :value="overlay">
        <v-progress-circular indeterminate color="themebtn" size="80"></v-progress-circular>
     </v-overlay>
    </div>
  </template>    
  <script>
   
  import Playerlisting from '../components/Playerlisting'
  import {SliderFrame, SliderSlides, SliderSlide} from 'vue-dynamic-slider';
  import * as moment from 'moment'
  import 'moment-duration-format' 
  import Scatter from '@/components/ScatterChart.js'
  
  export default {
      name: 'Livescore',
      components:{ Playerlisting,SliderSlide, SliderSlides, SliderFrame, Scatter },
      data() {
        return {
            timer:'',
            commentrytimer:'',
            sportname:'',
            showmessage:'',
            sportstab:[],
            sportsdata:[],
            sportid:0,
            selectsportid:0,
            selectedmatchid:0,
            teamtabs:0,
            overlay:false,
            showslider:false,
            refresh: 0,
            serveimage:require("@/assets/ball-serve.jpg"),
            courtimg:require("@/assets/bg-court.svg"),
            staticgraph:true,
            showcommentry:false,
            getlivecommnetry:false,
            commentrydata:[],
            teamsdata:[],
            commtab:0 ,
            commentrylastloop:1,
            commentrylasttime:0,
            countdown: 1, // 5min
            missdata: [],
            makedata: [],
            missdatamain: [],
            makedatamain: [],
            scatteroptions:'',
            showpitchmap:false,
            hidecountdown:false,
            groundlogo:'',
            singleEntryLoop:0,
            thirdTimeout:'', 
            secondTimeout:'',
            firstTimeout:'',
            basketballtab:0,
            basketballdata:[],
            hometeamlogo:'',
            awayteamlogo:'',
            quarters:[],
            hometeamplayers:[],
            awayteamplayers:[],
            homemade:true,
            homemiss:true,
            awaymade:true,
            awaymiss:true,
            filterQuarter:'',
            homeplayerfilter:'',
            awayplayerfilter:''
        };
      },
      created() {
          this.fetchMatchesList();
          this.overlay = true;
          this.timer          = setInterval(this.fetchMatchesList, 10000);
          
      },
      computed: {
      formatedCountdown() {
        return moment.duration(this.countdown, 'seconds').format('m:ss');
      },
    },
      methods:{
         scoredisplaying(score)
         {
           if(typeof score != "undefined")
           {
            var splitval = score.split(".");
    
           if(splitval.length > 0)
           {
             if(splitval.length > 1)
             {
               score = splitval[0]+"<sup>"+splitval[1]+"</sup>"; 
             } 
             else
             {
               score = splitval[0];
             }
           } 
          }
           
           return score;
         }, 
         countryflag(name)
         {
           return 'https://countryflagsapi.com/png/'+name;
         },
         opengamesite(game){
            window.open("https://www.espn.com/"+game+"/", "_blank");
         },
         getvsteamname(teama,teamb){
           return teama+" Vs "+teamb;
         },
         changematch(key){
            this.selectedmatchid = key;
            this.teamtabs = '';
            this.hidecountdown = false;
            this.sportname     = this.sportstab[this.selectsportid].name;
            this.basketballtab = 0;
            if(this.sportstab[this.selectsportid].smallname == "fantasy")
            {
              this.hidecountdown = true;
            }
            this.onloadCommentry();
            
         },
         changesports(key){
            this.selectsportid = key;
            this.selectedmatchid = 0;
            this.basketballtab = 0;
            this.teamtabs = '';
            this.showcommentry = false;
            this.hidecountdown = false;
            this.onloadCommentry();
         }, 
         async fetchMatchesList () 
         {
            this.$store.dispatch("getLiveMatches",{data:"fantasy"}).then((response) => 
            {
               if(response.message == 'success')
               {
                  this.sportstab  = response.data.sports;
                  this.sportsdata = response.data.matches;
                  if(this.selectsportid == 0)
                  {
                      this.selectsportid = response.data.firstsports;
                      this.sportname     = this.sportstab[this.selectsportid].name;
                      
                      if(this.sportname == "Basketball")
                      {
                         this.onloadCommentry();
                      }
                  }
                    this.refresh++;
               } 
               else
               {
                   this.showmessage = "No Live Matches";
  
               }
               this.overlay = false
            });  
          },
         boxscore(){
          if(this.basketballtab == 1)
          {
            var matchid = this.sportsdata[this.selectsportid][this.selectedmatchid].matchid;
            this.$store.dispatch("getMatchBoxScore",{matchid:matchid}).then((response) => {
                  this.basketballdata = response.data;
            });
          }
         },
         getBasketballFilter(){
            var matchid = this.sportsdata[this.selectsportid][this.selectedmatchid].matchid;
            this.$store.dispatch("getBasketballFilter",{matchid:matchid}).then((response) => {
                  //this.basketballfilterData = response.data;
                  this.quarters = response.data.quarters;
                  this.hometeamplayers = response.data.homeplayers;
                  this.awayteamplayers = response.data.awayplayers;
            });   
         },
         onloadCommentry()
         {
          clearTimeout(this.firstTimeout);  
          clearTimeout(this.secondTimeout);  
          clearTimeout(this.thirdTimeout);
            this.singleEntryLoop = 1;
            this.missdata = [];
            this.makedata = [];
            this.showpitchmap = false;
            this.hidecountdown = false;
            if(this.sportstab[this.selectsportid].smallname == "fantasy")
            {
              this.getBasketballFilter();
              this.showcommentry = true;
              this.hidecountdown = true;
              var matchid = this.sportsdata[this.selectsportid][this.selectedmatchid].matchid;
              this.$store.dispatch("getMatchCommentry",{matchid:matchid}).then((response) =>
              {
                this.staticgraph = true;
                 if(response.data.commentry.length > 0)
                 {
                   this.commentrydata     = response.data.commentry;
                   this.teamsdata         = response.data.teams
                   this.commentrylastloop = response.data.lastloop;
                   this.commentrylasttime = response.data.lastime;
                   this.countdown         = response.data.timer;
                   this.groundlogo        = response.data.groundlogo;
                   this.hometeamlogo      = response.data.homelogo;
                   this.awayteamlogo      = response.data.awaylogo;
                   
                   if(typeof response.data.tooltipdata.make != "undefined")
                   {
                      let indatas = response.data.tooltipdata.make;
                      for(let j=0; j < indatas.length; j++)
                      {
                       this.makedata.push({
                                          x:indatas[j].X,
                                          y:indatas[j].Y,
                                          title:indatas[j].title+" X -"+indatas[j].X+" Y -"+indatas[j].Y,
                                          quarter:indatas[j].quarter,
                                          playerid:indatas[j].playerid,
                                          playerteam:indatas[j].playerteam,
                                          custom:{pointBackgroundColor: indatas[j].background},
                                         });  
                      }
                      this.makedatamain = this.makedata;
                   } 
                   if(typeof response.data.tooltipdata.misses != "undefined")
                   {
                      let indatas = response.data.tooltipdata.misses;
                      for(let j=0; j < indatas.length; j++)
                      {
                        this.missdata.push({
                                           x:indatas[j].X,
                                           y:indatas[j].Y,
                                           title:indatas[j].title+" X -"+indatas[j].X+" Y -"+indatas[j].Y,
                                           quarter:indatas[j].quarter,
                                           playerid:indatas[j].playerid,
                                           playerteam:indatas[j].playerteam,
                                           custom:{pointBackgroundColor: indatas[j].background},
                                          });  
                      }
                      this.missdatamain = this.missdata;
                   } 
                   this.showpitchmap = true;
                   if(response.data.getcommentrylive == 1)
                   {
                    //setTimeout(() => this.getlivecommnetry = true, 1000);
                   
                    var t = this;
                    this.firstTimeout = setTimeout(function()
                     {
                      clearTimeout(this.firstTimeout);  
                      t.getlivecommnetry = true;
                      t.getcommentry();
                     },1100)
                   }
                 } 
              });
            } 
         },
          getcommentry(){
            if(typeof this.sportstab[this.selectsportid] != "undefined")
            {
              if(this.sportstab[this.selectsportid].smallname == "fantasy" && this.getlivecommnetry)
              {
                
                if(this.countdown > 0)
                     {
                      var th = this;
                     this.secondTimeout = setTimeout(function(){
                      clearTimeout(this.secondTimeout);
                        th.countdown -= 1;
                     },1100)
                     }   
                 let matchid  = this.sportsdata[this.selectsportid][this.selectedmatchid].matchid;
                 let lastloop = this.commentrylastloop;
                 var lasttime = this.commentrylasttime;
                 this.$store.dispatch("getMatchCommentryLive",{matchid:matchid,lastloop:lastloop,lasttime:lasttime}).then((response) =>
                 {
                   this.stopcountdown = response.data.timerstop;
                   if(response.data.blank == 0)
                   {
                     var pushinto = parseInt(response.data.lastloop)-1;
                    
                     if(typeof this.commentrydata[pushinto] == "undefined")
                     {
                      this.commentrydata[pushinto] = response.data.commentry;
                     }
                     else
                     {
                      var lastentry = this.commentrydata[pushinto];
                      if(typeof response.data.commentry.length == "undefined")
                      {
                        let innerdata = response.data.commentry;
                        lastentry.push({time: innerdata.time,away_score:innerdata.away_score,commentry:innerdata.commentry,home_score:innerdata.home_score,logo:innerdata.logo,boldteam:innerdata.boldteam});  
                      } 
                      else
                      {
                        for(var i = 0; i < response.data.commentry.length; i++)
                        {
                          let innerdata = response.data.commentry[i];
                          lastentry.push({time: innerdata.time,away_score:innerdata.away_score,commentry:innerdata.commentry,home_score:innerdata.home_score,logo:innerdata.logo,boldteam:innerdata.boldteam});
                        }
                      }
                     }
                   } 
                 
                     if(response.data.timer != '')
                     {
                       this.countdown = response.data.timer;
                     } 
                     this.commentrylastloop = response.data.lastloop;
                     this.commentrylasttime = response.data.lasttime; 
                     
                     if(typeof response.data.make != "undefined")
                    {
                       let indatas = response.data.make;
                       for(let j=0; j < indatas.length; j++)
                       {
                         this.makedata.push({
                                     x:indatas[j].X,
                                     y:indatas[j].Y,
                                     title:indatas[j].title,
                                     quarter:indatas[j].quarter,
                                     playerid:indatas[j].playerid,
                                     playerteam:indatas[j].playerteam,
                                     custom:{pointBackgroundColor: indatas[j].background},
                                    });  
                       }
                       this.makedatamain = this.makedata;
                   } 
                   if(typeof response.data.misses != "undefined")
                   {
                       let indatas = response.data.misses;
                       for(let j=0; j < indatas.length; j++)
                       {
                         this.missdata.push({
                                             x:indatas[j].X,
                                             y:indatas[j].Y,
                                             title: indatas[j].title,
                                             quarter:indatas[j].quarter,
                                             playerid:indatas[j].playerid,
                                             custom:{pointBackgroundColor: indatas[j].background},
                                            });  
                       }
                       this.missdatamain = this.missdata;
                   } 
                   if(response.data.getcommentrylive == 0)
                   {
                     this.getlivecommnetry = false;
                     clearTimeout(this.thirdTimeout);
                   }
                   else
                   {
                     
                    var t = this;
                     this.thirdTimeout = setTimeout(function(){
                      clearTimeout(this.thirdTimeout);
                      t.getcommentry();
                     
                     },1100)
                     
                   }
                  })
              }
            } 
         },
         filtering()
       {
           var searchvalue      = this.filterQuarter;
           var homeplayerfilter = this.homeplayerfilter
           var awayplayerfilter = this.awayplayerfilter
           var homemade         = this.homemade;
           var homemiss         = this.homemiss; 
           var awaymade         = this.awaymade;
           var awaymiss         = this.awaymiss;
           var homeindex        = '';
           var awayindex        = '';
           var awayteam         = '';
           var hometeam         = '';
           this.missdata        = this.missdatamain;
           this.makedata        = this.makedatamain;
           
           if(homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')
           {
             homeindex = this.hometeamplayers.findIndex(p => p.id == this.homeplayerfilter);
             hometeam = this.hometeamplayers[homeindex].team
           } 
           if(awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS')
           {
             awayindex = this.awayteamplayers.findIndex(p => p.id == this.awayplayerfilter);
             awayteam = this.awayteamplayers[awayindex].team
           } 
           console.log("searchvalue == "+searchvalue+" homeplayerfilter == "+homeplayerfilter+" awayplayerfilter == "+awayplayerfilter+" homemiss == "+homemiss+" =  awaymiss == "+awaymiss+" = homemade == "+homemade+" = awaymade == "+awaymade);
          
            this.missdata = this.missdata.filter(function(product) 
            { 
               if((searchvalue != '' && searchvalue != 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter != '' || awayplayerfilter != 'ALL PLAYERS') && homemiss && awaymiss && homemade && awaymade)
               {  console.log("miss 1");
                 if(product.playerteam == awayteam) 
                 { 
                  if(product.playerid == awayplayerfilter) 
                  { 
                    return product.quarter == searchvalue; 
                  } 
                 }
                 else{  return  product.quarter == searchvalue;  }
               }
               else if(searchvalue == 'ALL QUARTERS' && homeplayerfilter == '' && awayplayerfilter != 'ALL PLAYERS' && homemiss && awaymiss && homemade && awaymade)
               {  console.log("miss 2");
                 if(product.playerteam == awayteam) { if(product.playerid == awayplayerfilter) { return product; }  }
                 else{ return  product; }
               }
               else if(searchvalue == '' && homeplayerfilter == '' && awayplayerfilter != 'ALL PLAYERS' && homemiss && awaymiss && homemade && awaymade)
               {  console.log("1 "+homemiss);
                 if(product.playerteam == awayteam) { if(product.playerid == awayplayerfilter) { return product; }  }
                 else{ return  product;  }
               }
               
              
               else if((searchvalue != '' && searchvalue != 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == '' || awayplayerfilter == 'ALL PLAYERS') && homemiss && awaymiss && homemade && awaymade)
               { console.log("4");
                return  product.quarter == searchvalue;  
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == '' || awayplayerfilter == 'ALL PLAYERS') && homemiss && awaymiss && homemade && awaymade)
               { console.log("5");
                  return product
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && homeplayerfilter != 'ALL PLAYERS' && (awayplayerfilter != 'ALL PLAYERS' && awayplayerfilter != '') && homemiss && awaymiss && homemade && awaymade)
               { console.log("6");
                  if(product.playerteam == awayteam || product.playerteam == hometeam)
                  {
                     if(product.playerid == homeplayerfilter || product.playerid == awayplayerfilter) { return product; }
                  }
               }
               else if(searchvalue != 'ALL QUARTERS' && homeplayerfilter != 'ALL PLAYERS' && (awayplayerfilter != 'ALL PLAYERS' && awayplayerfilter != '') && homemiss && awaymiss && homemade && awaymade)
               {  console.log("7");
                if((product.playerteam == awayteam || product.playerteam == hometeam) && product.quarter == searchvalue)
                  {
                     if(product.playerid == homeplayerfilter || product.playerid == awayplayerfilter) { return product; }
                  }
               } 
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && homeplayerfilter != 'ALL PLAYERS' && (awayplayerfilter == '' || awayplayerfilter == 'ALL PLAYERS') && homemiss && awaymiss && homemade && awaymade)
               { console.log("8");
                  if(product.playerteam == hometeam)
                  {
                     if(product.playerid == homeplayerfilter) 
                     { 
                      return product; 
                    }
                  }
                  else{
                     return product
                  }
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') &&  awayplayerfilter != 'ALL PLAYERS' && homemiss && awaymiss && homemade && awaymade)
               { console.log("9");
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return product; 
                    }
                  }
                  else{
                     return product
                  }
               }
               else if((searchvalue != '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter != '' || homeplayerfilter != 'ALL PLAYERS') &&  (awayplayerfilter == '' || awayplayerfilter == 'ALL PLAYERS') && homemiss && awaymiss && homemade && awaymade)
               { console.log("10");
                  if(product.playerteam == hometeam)
                  {
                     if(product.playerid == homeplayerfilter) 
                     { 
                      return product.quarter == searchvalue; 
                    }
                  }
                  else{
                     return product.quarter == searchvalue; 
                  }
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && !homemiss && awaymiss && homemade && awaymade)
               {  console.log("011");
                   if(product.playerteam != "home")
                   {
                     return product;
                   }
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && homemiss && awaymiss && !homemade && awaymade)
               {  console.log("012");
                   return product
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && homemiss && !awaymiss && homemade && awaymade)
               {  console.log("013");
                   if(product.playerteam != "away")
                   {
                     return product;
                   }
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && homemiss && awaymiss && homemade && !awaymade)
               {  console.log("014");
                   return product
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && homemiss && !awaymiss && homemade && !awaymade)
               {  console.log("015");
                   if(product.playerteam != "away")
                   {
                     return product;
                   }
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && !homemiss && awaymiss && !homemade && awaymade)
               {  console.log("016");
                   if(product.playerteam != "home")
                   {
                     return product;
                   }
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && homemiss && awaymiss && !homemade && !awaymade)
               {  console.log("017");    
                 return product;
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && homemiss && !awaymiss && !homemade && !awaymade)
               {  console.log("018");    
                  if(product.playerteam == "home")
                  {
                    return product
                  }
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && !homemiss && !awaymiss && !homemade && !awaymade)
               {  console.log("019");    
                    return ''
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && !homemiss && awaymiss && !homemade && !awaymade)
               {  console.log("020");
                   if(product.playerteam != "home")
                   {
                     return product;
                   }
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && !homemiss && !awaymiss && homemade && !awaymade)
               {  console.log("021");
                     return '';
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && homemiss && !awaymiss && !homemade && awaymade)
               {  console.log("022");
               if(product.playerteam != "away")
                   {
                     return product;
                   }  
               } 
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && !homemiss && !awaymiss && homemade && awaymade)
               {   console.log("023")
                  return '';
               } 
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && !homemiss && awaymiss && homemade && !awaymade)
               {   console.log("024")
                 if(product.playerteam != "home")
                   {
                     return product;
                   }  
               } 
               else if((searchvalue != '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && homemiss && awaymiss && homemade && !awaymade)
               {   console.log("025")
                  return product.quarter == searchvalue;
               } 
               else if((searchvalue != '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && homemiss && awaymiss && !homemade && awaymade)
               {   console.log("026")
                  return product.quarter == searchvalue;
               } 
               else if((searchvalue != '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && !homemiss && awaymiss && homemade && awaymade)
               {   
                console.log("027")
                if(product.playerteam != "home")
                   {
                    return product.quarter == searchvalue;
                   }  
               } 
               else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && awaymiss && homemade && !awaymade)
               {
                 console.log("above 028");
                 if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return product; 
                    }
                  }
                  if(product.playerteam == "home")
                  {
                     return  product; 
                  }
               }
               else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter == ''|| homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && homemiss && awaymiss && homemade && !awaymade)
               {   
                  console.log("028")
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return product.quarter == searchvalue; 
                    }
                  }
                  else{
                    return product.quarter == searchvalue; 
                  }  
               } 
               else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter == '' || awayplayerfilter == 'ALL PLAYERS') && homemiss && !awaymiss && homemade && !awaymade)
               {
                console.log("line 837");
                if(product.playerteam == "away")
                  {
                      return ''; 
                  }
                  if(product.playerteam == "home") 
                  {
                    return product.quarter == searchvalue; 
                  }  
               }
               else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter == ''|| homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && homemade && !awaymade)
               {
                  console.log("above 029");
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return ''; 
                    }
                  }
                  if(product.playerteam == "home")
                  {
                     return product;
                  }
               }
               else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter == ''|| homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && homemade && !awaymade)
               {   
                  console.log("029")
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return ''; 
                    }
                  }
                  else{
                    return product.quarter == searchvalue; 
                  }  
               } 
               else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && homemiss && awaymiss && !homemade && awaymade)
               {   
                  console.log("030")
                  if(product.playerteam == hometeam)
                  {
                     if(product.playerid == homeplayerfilter) 
                     { 
                      return product.quarter == searchvalue; 
                    }
                  }
                  else{
                    return product.quarter == searchvalue; 
                  }  
               } 
               else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && !homemiss && awaymiss && !homemade && awaymade)
               {   
                  console.log("031")
                  if(product.playerteam == hometeam)
                  {
                     if(product.playerid == homeplayerfilter) 
                     { 
                      return ''; 
                    }
                  }
                  else{
                    return product.quarter == searchvalue; 
                  }  
               } 
               else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && !homemiss && awaymiss && homemade && awaymade)
               {   
                  console.log("032")
                  if(product.playerteam == hometeam)
                  {
                     if(product.playerid == homeplayerfilter) 
                     { 
                      return ''; 
                    }
                  }
                  else{
                    return product.quarter == searchvalue; 
                  }  
               } 
               else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && awaymiss && homemade && !awaymade)
               {  
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                       return product; 
                     }
                  }
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product; 
                      }
                    }
               } 
               else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && homemiss && awaymiss && homemade && !awaymade)
               {  
                  console.log("033") 
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                     return product.quarter == searchvalue; 
                     }
                  }
                  else
                  {
                    if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product.quarter == searchvalue; 
                      }
                    }
                  }  
               } 
               else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && homemade && !awaymade)
               {  
                if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                       return ''; 
                     }
                  }
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product; 
                      }
                    }
               } 
               else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && homemade && !awaymade)
               {  
                  console.log("034") 
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                     return ''; 
                     }
                  }
                  
                    if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product.quarter == searchvalue; 
                      }
                    }
                   
                } 
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && !homemade && !awaymade)
                {
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                     return ''; 
                     }
                  }
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product; 
                      }
                    }
                }
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' || homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && !homemade && !awaymade)
               {
                    if(product.playerteam == "home")
                    {
                       return product;
                    }   
               }
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && !homemade && !awaymade)
               {  
                  console.log("035") 
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                     return ''; 
                     }
                  }
                  else
                  {
                    if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product.quarter == searchvalue; 
                      }
                    }
                  }  
                } 
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter == '' && homeplayerfilter != 'ALL PLAYERS') && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && homemade && awaymade)
                {
                   console.log("line 966");
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                       return ''; 
                     }
                  }
                  if(product.playerteam == "home")
                  {
                    return product.quarter == searchvalue;
                  }
                }
                else if((searchvalue != 'ALL QUARTERS' && searchvalue != '') &&  (homeplayerfilter == '' || homeplayerfilter != 'ALL PLAYERS') && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && homemade && awaymade)
                {
                  console.log("new secsss");
                  if(product.playerteam == awayteam)
                  {
                   
                    return '';
                  }
                  if(product.playerteam == hometeam)
                  {
                    
                    if(product.playerid == homeplayerfilter) 
                     { 
                       return product.quarter == searchvalue; 
                     }
                  }
                }
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && homemade && awaymade)
                {
                  if(product.playerteam == awayteam)
                  {
                     return ''; 
                  }  
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product; 
                      }
                    }
                }
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && homemade && awaymade)
               {  
                  console.log("036") 
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                     return ''; 
                     }
                  }
                  else
                  {
                    if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product.quarter == searchvalue; 
                      }
                    }
                  }  
                } 
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && homemade && awaymade)
               {  
                  console.log("new 036") 
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                     return ''; 
                     }
                  }
                  else
                  {
                    if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product.quarter == searchvalue; 
                      }
                    }
                  }  
                } 
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''  && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && !homemade && awaymade)
               {  
                if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                     return ''; 
                     }
                  }
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product; 
                      }
                    }
               } 
               else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter == ''|| homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && !homemade && awaymade)
               {
                if(product.playerteam == awayteam)
                  {
                    return '';
                  }  
                  if(product.playerteam == 'home')
                    {
                       return product;
                    }   
               }
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && !homemade && awaymade)
               {  
                  console.log("037") 
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                     return ''; 
                     }
                  }
                  else
                  {
                    if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product.quarter == searchvalue; 
                      }
                    }
                  }  
                } 
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss && !awaymiss && homemade && awaymade)
               {  
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                       return ''; 
                     }
                  }
                  else
                  {
                    if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return ''; 
                      }
                    }
                  }  
                } 
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss && !awaymiss && homemade && awaymade)
                {
                   return '';
                }
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && !homemiss && !awaymiss && homemade && awaymade)
               {  
                  console.log("038") 
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                     return ''; 
                     }
                  }
                  else
                  {
                    if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return ''; 
                      }
                    }
                  }  
                } 
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && awaymiss && !homemade && !awaymade)
                {
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                       return product; 
                     }
                  }  
                  if(product.playerteam == "home")
                  {  
                     return product;
                  }  
                }
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && awaymiss && !homemade && !awaymade)
                {
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return  product; 
                     }
                  }
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product; 
                      }
                    }
                }
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && homemiss && awaymiss && !homemade && !awaymade)
               {  
                  console.log("039") 
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return  product.quarter == searchvalue; 
                     }
                  }
                  else
                  {
                    if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product.quarter == searchvalue; 
                      }
                    }
                  }  
                }
                else if(searchvalue == '' &&  (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && homemiss && awaymiss && !homemade && awaymade)
                {
                  console.log("1063");
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return product; 
                     }
                  }
                  if(product.playerteam == "home")
                  {
                        return product; 
                  }   
                }
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && homemiss && awaymiss && !homemade && awaymade)
                {
                  console.log("1077");
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return product.quarter == searchvalue; 
                     }
                  }
                  else
                  {
                    if(product.quarter == searchvalue)
                    {
                        return product; 
                    }
                  }  
                }
                else if(searchvalue == '' && (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS') && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && homemiss && awaymiss && !homemade && awaymade)
                {
                  console.log("1094");
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return product; 
                     }
                  }
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return product; 
                      }
                    }
                }
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && awaymiss && !homemade && awaymade)
               {  
                  console.log("040");
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return product.quarter == searchvalue; 
                     }
                  }
                  
                    if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return product.quarter == searchvalue; 
                      }
                    }
                    
                }  
                else if(searchvalue == 'ALL QUARTERS' &&  (homeplayerfilter == ''|| homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && !homemade && awaymade)
               {  
                  console.log("line 1133");
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return product; 
                     }
                  }
                  else
                  {
                    if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return ''; 
                      }
                    }
                  }  
                }  
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' || homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss && !awaymiss && !homemade && awaymade)
               {  
                   return '';
                }  
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss && !awaymiss && !homemade && awaymade)
               {  
                  console.log("new 041") 
                  return '';  
                }  
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && !homemiss && !awaymiss && !homemade && awaymade)
               {  
                  console.log("041") 
                  return '';  
                }  
                else if(searchvalue == '' &&  homeplayerfilter == ''  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && homemade && awaymade)
                {
                  console.log("line 1159");
                  if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return product; 
                        }
                      }
                      if(product.playerteam == "home")
                    {
                        return ''; 
                    }   
                }
                else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && homemade && awaymade)
                {
                  console.log("new above 042")
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return ''; 
                      }
                    }
                    if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return product; 
                        }
                      } 
                }
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter == ''|| homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && homemade && awaymade)
                {
                  if(product.playerteam == "home")
                    {
                        return product; 
                    }
                    if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return ''; 
                        }
                      }  
                }
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && homemade && awaymade)
               {  
                  console.log("042") 
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return ''; 
                      }
                    }
                   else{
                    if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return product.quarter == searchvalue; 
                        }
                      }
                   } 
                }  
                else if(searchvalue == '' && homeplayerfilter == '' && awayplayerfilter != '' && !homemiss && awaymiss && !homemade && awaymade )
                {
                  console.log("line 1212");
                    if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return ''; 
                      }
                    }
                   else{
                    if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return product; 
                        }
                      }
                   } 
                } 
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && !homemade && awaymade)
                {
                  console.log("line 1231");
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return ''; 
                       }
                    }   
                    if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return product; 
                        }
                      }
                }
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter == ''|| homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && !homemade && awaymade)
                {
                  if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return product; 
                        }
                      }
                    
                      if(product.playerteam == "home")
                    {
                       return '';
                    }
                }
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && !homemade && awaymade)
               {  
                  console.log("043") 
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return ''; 
                      }
                    }
                   else{
                    if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return product.quarter == searchvalue; 
                        }
                      }
                   } 
                }  
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && homemade && !awaymade)
               {  
                if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return product; 
                        }
                      }
               }
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && homemade && !awaymade)
               {  
                  console.log("044") 
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return ''; 
                      }
                    }
                   else{
                    if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return product.quarter == searchvalue; 
                        }
                      }
                   } 
                }
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''  &&  awayplayerfilter != 'ALL PLAYERS') && !homemiss && !awaymiss && homemade && !awaymade)
                {
                  return '';
                }  
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter == ''|| homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss && !awaymiss && homemade && !awaymade)
               { 
                  return ''; 
               }
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && !homemiss && !awaymiss && homemade && !awaymade)
               {  console.log("045");
                  return ''; 
               }  
               else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' || homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && !homemade && !awaymade)
               {  console.log("above 0046");
               if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return product; 
                        }
                      }
               } 
               else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && !homemade && !awaymade)
               {  console.log("046");
               if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return product.quarter == searchvalue; 
                        }
                      }
               }  
               else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && homemade && awaymade)
               {
                console.log("asaadadasd");
                    if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return product.quarter == searchvalue; 
                        }
                      }
                      if(product.playerteam == "home")
                      {
                        return '';
                      }    
               }
               else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != '' &&  homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && homemade && awaymade)
               {  
                  console.log("wwww");
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return '';
                     }
                  }
                  else
                  {
                    if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product.quarter == searchvalue; 
                      }
                    }
                  }  
                } 
                else if((searchvalue == "" || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == 'ALL PLAYERS' || homeplayerfilter == '') && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && homemade && awaymade)
                {
                  if(product.playerteam == awayteam)
                  {
                       return '';
                  }
                  if(product.playerteam == "home")
                    {
                       return  product; 
                    }
                }
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && homemade && awaymade)
               { 
                console.log("lat cond");
                if(product.playerteam == awayteam)
                  {
                       return '';
                  }
                  if(product.playerteam == hometeam)
                    {
                        if(product.quarter == searchvalue)
                        {
                          return  product.quarter == searchvalue; 
                        } 
                    }
              } 
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter == 'ALL PLAYERS' || homeplayerfilter == '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss &&  awaymiss && homemade && !awaymade)
              {
                console.log("new lat cond");
                if(product.playerteam == awayteam)
                  {
                    if(product.playerid == awayplayerfilter) 
                     {
                       return  product;  
                     } 
                  }
                  if(product.playerteam == "home")
                      {
                        return product;
                      }      
              }
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter == 'ALL PLAYERS' || homeplayerfilter == '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss &&  !awaymiss && homemade && !awaymade)
              {
                console.log("searchvalue blank home blank away value homemiss !awaymiss homemade !awaymade");
                if(product.playerteam == awayteam)
                  {
                     return '';
                  }  
                  if(product.playerteam == "home")
                      {
                        return product;
                      }      
              }  
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter != 'ALL PLAYERS' && homeplayerfilter != '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss &&   awaymiss && !homemade && awaymade)
              {
                console.log("again late");
                if(product.playerteam == awayteam)
                  {
                    if(product.playerid == awayplayerfilter) 
                     {
                       return  product;  
                     } 
                  }
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product; 
                      }
                    }   
              }  
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter != 'ALL PLAYERS' && homeplayerfilter != '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss &&   awaymiss && homemade && !awaymade)
              {
                 console.log("here comes new");
                 if(product.playerteam == awayteam)
                  {
                    if(product.playerid == awayplayerfilter) 
                     {
                       return product;  
                     } 
                  }
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product; 
                      }
                    }   
              }  
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter != 'ALL PLAYERS' && homeplayerfilter != '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss &&  !awaymiss && homemade && !awaymade)
              {
                 console.log("let make new one");
                 if(product.playerteam == awayteam)
                  {
                       return '';  
                   }
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product; 
                      }
                    }   
              }  
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter != 'ALL PLAYERS' && homeplayerfilter != '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss &&  !awaymiss && !homemade && !awaymade)
              {
                 console.log("this one again new miss");
                 if(product.playerteam == awayteam)
                  {
                       return '';  
                   }
                  if(product.playerteam == hometeam)
                    {
                      if(product.playerid == homeplayerfilter) 
                       { 
                        return  product; 
                      }
                    }   
              }  
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter != 'ALL PLAYERS' && homeplayerfilter != '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss &&  !awaymiss && !homemade && awaymade)
              {
                 console.log("let create for miss");
                 if(product.playerteam == awayteam)
                  {
                       return '';  
                   }
                  if(product.playerteam == hometeam)
                    {
                      if(product.playerid == homeplayerfilter) 
                       { 
                        return  product; 
                      }
                    }   
              }  
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter != 'ALL PLAYERS' && homeplayerfilter != '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss &&  !awaymiss && homemade && awaymade)
              {
                 console.log("if you smell for miss");
                 if(product.playerteam == awayteam)
                  {
                    if(product.playerid == awayplayerfilter) 
                       { 
                        return  ''; 
                      }  
                   }
                   if(product.playerteam == hometeam)
                    {
                   if(product.playerid == homeplayerfilter) 
                       { 
                        return  product; 
                      }
                    }   
              } 
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter != 'ALL PLAYERS' && homeplayerfilter != '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss &&  !awaymiss && homemade && awaymade)
              {
                 console.log("if you smell for miss");
                 if(product.playerteam == awayteam)
                  {
                    if(product.playerid == awayplayerfilter) 
                       { 
                        return  ''; 
                      }  
                   }
                   if(product.playerteam == hometeam)
                    {
                   if(product.playerid == homeplayerfilter) 
                       { 
                        return  ''; 
                      }
                    }   
              } 
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter != 'ALL PLAYERS' && homeplayerfilter != '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss &&  !awaymiss && !homemade && awaymade)
              {
                 console.log("if you smell for  new miss");
                 if(product.playerteam == awayteam)
                  {
                    if(product.playerid == awayplayerfilter) 
                       { 
                        return  ''; 
                      }  
                   }
                   if(product.playerteam == hometeam)
                    {
                   if(product.playerid == homeplayerfilter) 
                       { 
                        return  ''; 
                      }
                    }   
              } 
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter != 'ALL PLAYERS' && homeplayerfilter != '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss &&  !awaymiss && homemade && !awaymade)
              {
                 console.log("here comes game for miss");
                 if(product.playerteam == awayteam)
                  {
                    if(product.playerid == awayplayerfilter) 
                       { 
                        return  ''; 
                      }  
                   }
                   if(product.playerteam == hometeam)
                    {
                   if(product.playerid == homeplayerfilter) 
                       { 
                        return  ''; 
                      }
                    }   
              } 
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter != 'ALL PLAYERS' && homeplayerfilter != '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss &&  awaymiss && homemade && !awaymade)
              {
                 console.log("feed the meal");
                 if(product.playerteam == awayteam)
                  {
                    if(product.playerid == awayplayerfilter) 
                       { 
                        return  product; 
                      }  
                   }
                   if(product.playerteam == hometeam)
                    {
                   if(product.playerid == homeplayerfilter) 
                       { 
                        return  ''; 
                      }
                    }   
              } 
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter != 'ALL PLAYERS' && homeplayerfilter != '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss &&  awaymiss && !homemade && !awaymade)
              {
                console.log("1805");
                if(product.playerteam == awayteam)
                  {
                    if(product.playerid == awayplayerfilter) 
                       { 
                        return  product; 
                      }  
                   }
                   if(product.playerteam == hometeam)
                    {
                        return  ''; 
                    }   
                   
              }
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter != 'ALL PLAYERS' && homeplayerfilter != '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss &&  awaymiss && !homemade && !awaymade)
              {
                console.log("1820");
                if(product.playerteam == awayteam)
                  {
                    if(product.playerid == awayplayerfilter) 
                       { 
                        return  product; 
                      }  
                   }
                   if(product.playerteam == hometeam)
                    {
                   if(product.playerid == homeplayerfilter) 
                       { 
                        return  product; 
                      }
                    }   
              }  
              else if((searchvalue == "ALL QUARTERS" || searchvalue == "") && (homeplayerfilter == 'ALL PLAYERS' || homeplayerfilter == '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss &&  awaymiss && !homemade && awaymade)
              {
                console.log("1837");
                if(product.playerteam == awayteam)
                  {
                    if(product.playerid == awayplayerfilter) 
                    { 
                       return  product; 
                    }  
                  }
                   if(product.playerteam == hometeam)
                    {
                     return  ''; 
                    }   
              }
            });

            this.makedata = this.makedata.filter(function(product) 
            { 
              if((searchvalue != '' && searchvalue != 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter != '' || awayplayerfilter != 'ALL PLAYERS') && homemiss && awaymiss && homemade && awaymade)
               {  console.log("make 1");
                 if(product.playerteam == awayteam) 
                 { 
                  if(product.playerid == awayplayerfilter) 
                  { 
                    return product.quarter == searchvalue; 
                  } 
                 }
                 else{  return  product.quarter == searchvalue;  }
               }
               else if(searchvalue == 'ALL QUARTERS' && homeplayerfilter == '' && awayplayerfilter != 'ALL PLAYERS' && homemade && awaymade && homemiss && awaymiss)
               { console.log("make 2"); 
                 if(product.playerteam == awayteam) { if(product.playerid == awayplayerfilter) { return product; }  }
                 else{ return  product; }
               }
              else if(searchvalue == '' && homeplayerfilter == '' && awayplayerfilter != 'ALL PLAYERS' && homemade && awaymade && homemiss && awaymiss)
               {  console.log("11");
                 if(product.playerteam == awayteam) { if(product.playerid == awayplayerfilter){ return product; }  }
                 else{ return  product; }  
               }
               
               
               else if((searchvalue != '' && searchvalue != 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == '' || awayplayerfilter == 'ALL PLAYERS') && homemiss && awaymiss && homemade && awaymade)
               { console.log("44");
                return  product.quarter == searchvalue;  
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == '' || awayplayerfilter == 'ALL PLAYERS') && homemade && awaymade && homemiss && awaymiss)
               { console.log("55");
                  return product
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && homeplayerfilter != 'ALL PLAYERS' && (awayplayerfilter != 'ALL PLAYERS' && awayplayerfilter != '') && homemade && awaymade && homemiss && awaymiss)
               { console.log("66");
                  if(product.playerteam == awayteam || product.playerteam == hometeam)
                  {
                     if(product.playerid == homeplayerfilter || product.playerid == awayplayerfilter) { return product; }
                  }
               }
               else if(searchvalue != 'ALL QUARTERS' && homeplayerfilter != 'ALL PLAYERS' && (awayplayerfilter != 'ALL PLAYERS' && awayplayerfilter != '') && homemade && awaymade && homemiss && awaymiss)
               {  console.log("77");
                if((product.playerteam == awayteam || product.playerteam == hometeam) && product.quarter == searchvalue)
                  {
                     if(product.playerid == homeplayerfilter || product.playerid == awayplayerfilter) { return product; }
                  }
               } 
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && homeplayerfilter != 'ALL PLAYERS' && (awayplayerfilter == '' || awayplayerfilter == 'ALL PLAYERS') && homemade && awaymade && homemiss && awaymiss)
               { console.log("88");
                  if(product.playerteam == hometeam)
                  {
                     if(product.playerid == homeplayerfilter) 
                     { 
                      return product; 
                    }
                  }
                  else{
                     return product
                  }
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') &&  awayplayerfilter != 'ALL PLAYERS' && homemade && awaymade && homemiss && awaymiss)
               { console.log("9");
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return product; 
                    }
                  }
                  else{
                     return product
                  }
               }
               else if((searchvalue != '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter != '' || homeplayerfilter != 'ALL PLAYERS') &&  (awayplayerfilter == '' || awayplayerfilter == 'ALL PLAYERS') && homemade && awaymade && homemiss && awaymiss)
               { console.log("10");
                  if(product.playerteam == hometeam)
                  {
                     if(product.playerid == homeplayerfilter) 
                     { 
                      return product.quarter == searchvalue; 
                    }
                  }
                  else{
                     return product.quarter == searchvalue; 
                  }
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && !homemiss && awaymiss && homemade && awaymade)
               {
                  console.log("0111");
                     return product;
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && homemiss && awaymiss && !homemade && awaymade)
               {  console.log("0112");
                   if(product.playerteam != "home")
                   {
                     return product;
                   }
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && homemiss && !awaymiss && homemade && awaymade)
               {  console.log("0113");
                     return product;
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && homemiss && awaymiss && homemade && !awaymade)
               {  console.log("0114");
                  if(product.playerteam != "away")
                   {
                     return product;
                   }
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && homemiss && !awaymiss && homemade && !awaymade)
               {  console.log("015");
                   if(product.playerteam != "away")
                   {
                     return product;
                   }
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && !homemiss && awaymiss && !homemade && awaymade)
               {  console.log("016");
                   if(product.playerteam != "home")
                   {
                     return product;
                   }
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && homemiss && awaymiss && !homemade && !awaymade)
               {    console.log("0117"); 
                return '';
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && !homemiss && !awaymiss && !homemade && awaymade)
               {   console.log("0118");
               if(product.playerteam != "home")
                   {
                     return product;
                   }
               } 
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && !homemiss && !awaymiss && !homemade && !awaymade)
               {  console.log("0019");    
                    return ''
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && !homemiss && awaymiss && !homemade && !awaymade)
               {  console.log("0020");
                   return ''
               }
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && !homemiss && !awaymiss && homemade && !awaymade)
               {  console.log("0021");
               if(product.playerteam != "away")
                   {
                     return product;
                   }
                }
                else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && homemiss && !awaymiss && !homemade && awaymade)
               {  console.log("022");
               if(product.playerteam != "home")
                   {
                     return product;
                   }  
               } 
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && !homemiss && !awaymiss && homemade && awaymade)
               {   console.log("0023")
               return product;
               } 
               else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && !homemiss && awaymiss && homemade && !awaymade)
               {   console.log("024")
               if(product.playerteam != "away")
                   {
                     return product;
                   }  
               } 
               else if((searchvalue != '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && homemiss && awaymiss && homemade && !awaymade)
               {   console.log("0025")
               if(product.playerteam != "away")
                   {
                  return product.quarter == searchvalue;
                   }
                   else{
                    return '';
                   }
               } 
               else if((searchvalue != '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && homemiss && awaymiss && !homemade && awaymade)
               {   console.log("026")
               if(product.playerteam != "home")
                   {
                  return product.quarter == searchvalue;
                   }
                   else{
                    return '';
                   }
               } 
               else if((searchvalue != '' || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && !homemiss && awaymiss && homemade && awaymade)
               {   
                console.log("027")
                 return product.quarter == searchvalue;
               } 
               else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && awaymiss && homemade && !awaymade)
               {
                 console.log("above 028 make");
                 if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return ''; 
                    }
                  }
                  if(product.playerteam == "home")
                  {
                     return  product; 
                  }
               }
               else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter == ''|| homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && homemiss && awaymiss && homemade && !awaymade)
               {   
                  console.log("028")
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return ''; 
                    }
                  }
                  else{
                    return product.quarter == searchvalue; 
                  }  
               } 
               
               else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter == '' || awayplayerfilter == 'ALL PLAYERS') && homemiss && !awaymiss && homemade && !awaymade)
               {
                console.log("new");
                if(product.playerteam == "away")
                  {
                      return ''; 
                  }
                 if(product.playerteam == "home")
                 {
                    return product.quarter == searchvalue; 
                 }  
               }
               else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && homemade && !awaymade)
               {
                  if(product.playerteam == "home")
                  {
                     return product;
                  }  
               }
               else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter == ''|| homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && homemade && !awaymade)
               {   
                  console.log("0029")
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return ''; 
                    }
                  }
                  else{
                    return product.quarter == searchvalue; 
                  }  
               } 
               else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && homemiss && awaymiss && !homemade && awaymade)
               {   
                  console.log("0030")
                  if(product.playerteam == hometeam)
                  {
                     if(product.playerid == homeplayerfilter) 
                     { 
                      return ''; 
                     }
                  }
                  else{
                    return product.quarter == searchvalue; 
                  }  
               } 
               else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && !homemiss && awaymiss && !homemade && awaymade)
               {   
                  console.log("0031")
                  if(product.playerteam == hometeam)
                  {
                     if(product.playerid == homeplayerfilter) 
                     { 
                      return ''; 
                    }
                  }
                  else{
                    return product.quarter == searchvalue; 
                  }  
               } 
               else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter == ''|| awayplayerfilter == 'ALL PLAYERS') && !homemiss && awaymiss && homemade && awaymade)
               {   
                  console.log("0032")
                  if(product.playerteam == hometeam)
                  {
                     if(product.playerid == homeplayerfilter) 
                     { 
                      return ''; 
                    }
                  }
                  else{
                    return product.quarter == searchvalue; 
                  }  
               } 
               else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && awaymiss && homemade && !awaymade)
               {  
                console.log("2143");
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                       return ''; 
                     }
                  }
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product; 
                      }
                    }
               } 
               else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && homemiss && awaymiss && homemade && !awaymade)
               {  
                  console.log("0033") 
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                     return ''; 
                     }
                  }
                  else
                  {
                    if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product.quarter == searchvalue; 
                      }
                    }
                  }  
               } 
               else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && homemade && !awaymade)
               {  
                console.log("2181");
                if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                       return ''; 
                     }
                  }
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product; 
                      }
                    }
               } 
               else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && homemade && !awaymade)
               {  
                  console.log("0034") 
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                     return ''; 
                     }
                  }
                  
                    if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product.quarter == searchvalue; 
                      }
                    }
                   
               } 
               else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && !homemade && !awaymade)
               {
                 return '';
               } 
               else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' || homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && !homemade && !awaymade)
               {
                 return '';
               }
               else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && !homemade && !awaymade)
               {  
                  console.log("0035") 
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                     return ''; 
                     }
                  }
                  else
                  {
                    if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  ''; 
                      }
                    }
                  }  
                } 
                else if((searchvalue != 'ALL QUARTERS' && searchvalue != '') &&  (homeplayerfilter == '' && homeplayerfilter != 'ALL PLAYERS') && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && homemade && awaymade)
                {
                  console.log("new secsss");
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                       return product.quarter == searchvalue; 
                     }
                  }
                  else
                  {
                    return '';
                  }
                }
                 else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && homemade && awaymade)
                {
                  console.log("2258");
                  if(product.playerteam == awayteam)
                  {
                    if(product.playerid == awayplayerfilter) 
                     { 
                       return product; 
                     }
                  }  
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product; 
                      }
                    }
                }
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != '' &&  homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && homemade && awaymade)
               {  
                  console.log("0036");
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return  product.quarter == searchvalue;
                     }
                  }
                  else
                  {
                    if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product.quarter == searchvalue; 
                      }
                    }
                  }  
                } 
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''  && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && !homemade && awaymade)
               {  
                console.log("2296");
                if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                     return product; 
                     }
                  }
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return ''; 
                      }
                    }
               } 
               else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter == ''|| homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && !homemade && awaymade)
               {
                console.log("2313");
                if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                     return product; 
                     }
                  }
                  if(product.playerteam == 'home')
                    {
                       return '';
                    }   
               }
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && !homemade && awaymade)
               {  
                  console.log("0037") 
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                     return product.quarter == searchvalue; 
                     }
                  }
                  else
                  {
                    if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  ''; 
                      }
                    }
                  }  
                } 
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss && !awaymiss && homemade && awaymade)
               {  
                console.log("2348");
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                       return product; 
                     }
                  }
                  else
                  {
                    if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return product; 
                      }
                    }
                  }  
                } 
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss && !awaymiss && homemade && awaymade)
                {
                  console.log("2368")
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                     return product; 
                     }
                  }
                  if(product.playerteam == "home")
                    {
                        return product; 
                    }
                }
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && !homemiss && !awaymiss && homemade && awaymade)
               {  
                  console.log("0038") 
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                     return product.quarter == searchvalue; 
                     }
                  }
                  else
                  {
                    if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return product.quarter == searchvalue; 
                      }
                    }
                  }  
                } 
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && awaymiss && !homemade && !awaymade)
                {
                     return '';
                }
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && awaymiss && !homemade && !awaymade)
                {
                   return '';
                }
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && homemiss && awaymiss && !homemade && !awaymade)
               {  
                  console.log("0039") 
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return  ''; 
                     }
                  }
                  else
                  {
                    if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  ''; 
                      }
                    }
                  }  
                } 
                else if(searchvalue == '' &&  (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && homemiss && awaymiss && !homemade && awaymade)
                {
                  console.log("line 1755");
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return product; 
                     }
                  }
                  if(product.playerteam == "home")
                  {
                        return ''; 
                  }   
                }
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && homemiss && awaymiss && !homemade && awaymade)
                {
                  console.log("new third");
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return product.quarter == searchvalue; 
                     }
                  }
                  else
                  {
                    if(product.quarter == searchvalue)
                    {
                        return ''; 
                    }
                  }  
                }
                else if(searchvalue == '' && (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS') && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && homemiss && awaymiss && !homemade && awaymade)
                {
                  console.log("asadasdadas");
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return product; 
                     }
                  }
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return ''; 
                      }
                    }
                }
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && awaymiss && !homemade && awaymade)
               {  
                  console.log("0040") 
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return product.quarter == searchvalue; 
                     }
                  }
                  
                    if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  ''; 
                      }
                    }
                   
                } 
                else if(searchvalue == 'ALL QUARTERS' &&  (homeplayerfilter == ''|| homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && !homemade && awaymade)
               {  
                console.log("new fourth");
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return product; 
                     }
                  }
                  else
                  {
                    if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return ''; 
                      }
                    }
                  }  
                }  
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss && !awaymiss && !homemade && awaymade)
               {  
                  console.log("new 0041") 
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return product; 
                     }
                  } 
                  else
                  {
                  return '';  
                  }
                }  
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' || homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss && !awaymiss && !homemade && awaymade)
               {  
                console.log("2531")
                if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return product; 
                     }
                  }
                  else
                  {
                  return '';  
                  }
                }  
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && !homemiss && !awaymiss && !homemade && awaymade)
               {  
                  console.log("0041") 
                  if(product.playerteam == awayteam)
                  {
                     if(product.playerid == awayplayerfilter) 
                     { 
                      return product.quarter == searchvalue; 
                     }
                  }
                  else
                  {
                  return '';  
                  }
                } 
                else if(searchvalue == '' &&  homeplayerfilter == ''  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && homemade && awaymade)
                {
                  console.log("searchvalue ==  homeplayerfilter ==  awayplayerfilter == 25433 homemiss == false =  awaymiss == true = homemade == true = awaymade == true");
                  if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return product; 
                        }
                      }
                      if(product.playerteam == "home")
                    {
                        return product; 
                    }   
                } 
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter == ''|| homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && homemade && awaymade)
                {
                  console.log("asaaa");
                  if(product.playerteam == "home")
                    {
                        return product; 
                    }
                    if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return product; 
                        }
                      }  
                }
                else if((searchvalue != '' || searchvalue != 'ALL QUARTERS') &&  (homeplayerfilter == ''|| homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && homemade && awaymade)
                {
                  console.log("sss");
                  if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return product.quarter == searchvalue; 
                        }
                      }
                      if(product.playerteam == "home")
                    {
                      
                        return product.quarter == searchvalue; 
                      
                    }    
                }
                else if((searchvalue == '' || searchvalue == 'ALL QUARTERS') &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && homemade && awaymade)
                {
                  console.log("new above 0042")
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return product; 
                      }
                    }
                    if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return product; 
                        }
                      } 
                }
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && homemade && awaymade)
               {  
                  console.log("0042") 
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return product.quarter == searchvalue; 
                      }
                    }
                   else{
                    if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return product.quarter == searchvalue; 
                        }
                      }
                   } 
                }  
                else if(searchvalue == '' && homeplayerfilter == '' && awayplayerfilter != '' && !homemiss && awaymiss && !homemade && awaymade )
                {
                  console.log("new fifth");
                    if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return ''; 
                      }
                    }
                   else{
                    if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return product; 
                        }
                      }
                   } 
                } 
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter == ''|| homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && !homemade && awaymade)
                {
                  console.log("new ablvoe 0043")
                  if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return product; 
                        }
                      }
                    
                      if(product.playerteam == "home")
                    {
                       return '';
                    }
                }
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && !homemade && awaymade)
                {
                  console.log("new above 0043");
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return ''; 
                       }
                    }   
                    if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return product; 
                        }
                      }
                }
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && !homemade && awaymade)
               {  
                  console.log("0043") 
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return ''; 
                      }
                    }
                   else{
                    if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return product.quarter == searchvalue; 
                        }
                      }
                   } 
                }
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && homemade && !awaymade)
               {  
                console.log("2719")
                if(product.playerteam == "home")
                      {
                          return product; 
                      }
               }  
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && homemade && !awaymade)
               {  
                  console.log("0044") 
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return product.quarter == searchvalue; 
                      }
                    }
                   else{
                    if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return ''; 
                        }
                      }
                   } 
                }
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' && homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''  &&  awayplayerfilter != 'ALL PLAYERS') && !homemiss && !awaymiss && homemade && !awaymade)
                {
                  console.log("2758");
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return product; 
                      }
                    }
                }
                else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter == ''|| homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss && !awaymiss && homemade && !awaymade)
               { 
                console.log("2768");
                if(product.playerteam == "home")
                      {
                         
                        return product; 
                      
                    } 
               }
                else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && !homemiss && !awaymiss && homemade && !awaymade)
               {  console.log("0045");
               if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return product.quarter == searchvalue; 
                      }
                    }
               } 
               else if((searchvalue == 'ALL QUARTERS' || searchvalue == '') &&  (homeplayerfilter != '' || homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && !homemade && !awaymade)
               {  console.log("above 0046");
                  return '';
               } 
               else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter != ''|| homeplayerfilter != 'ALL PLAYERS')  && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && !homemade && !awaymade)
               {  console.log("0046");
                  return '';
               } 
               else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter == '' || homeplayerfilter == 'ALL PLAYERS') && (awayplayerfilter != ''|| awayplayerfilter != 'ALL PLAYERS') && !homemiss && awaymiss && homemade && awaymade)
               {
                console.log("new sixth");
                if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return product.quarter == searchvalue; 
                        }
                      }
                      if(product.playerteam == "home")
                      {
                        return '';
                      }    
               }  
                 else if((searchvalue == "" || searchvalue == 'ALL QUARTERS') && (homeplayerfilter == 'ALL PLAYERS' || homeplayerfilter == '') && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && homemade && awaymade)
                {
                  console.log("2807");
                  if(product.playerteam == awayteam)
                      {
                        if(product.playerid == awayplayerfilter) 
                        { 
                          return product; 
                        }
                      }
                  if(product.playerteam == "home")
                    {
                       return  product; 
                    }
                }
               else if(searchvalue != 'ALL QUARTERS' &&  (homeplayerfilter == 'ALL PLAYERS')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss && !awaymiss && homemade && awaymade)
               { 
                console.log("made lat cond");
                if(product.playerteam == awayteam)
                  {
                    if(product.playerid == awayplayerfilter) 
                        { 
                          return product.quarter == searchvalue; 
                        }
                  }
                  if(product.playerteam == hometeam)
                    {
                         return  product.quarter == searchvalue; 
                    }
              } 
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter == 'ALL PLAYERS' || homeplayerfilter == '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss &&  awaymiss && homemade && !awaymade)
              {
                console.log("new lat cond made");
                if(product.playerteam == awayteam)
                  {
                    if(product.playerid == awayplayerfilter) 
                     {
                       return '';  
                     } 
                  }
                  if(product.playerteam == "home")
                      {
                        return product;
                      }      
              }
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter == 'ALL PLAYERS' || homeplayerfilter == '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss &&  !awaymiss && homemade && !awaymade)
              {
                console.log("for made - searchvalue blank home blank away value homemiss !awaymiss homemade !awaymade");
                if(product.playerteam == awayteam)
                  {
                     return '';
                  }  
                  if(product.playerteam == "home")
                      {
                        return product;
                      }      
              } 
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter != 'ALL PLAYERS' && homeplayerfilter != '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss &&   awaymiss && !homemade && awaymade)
              {
                console.log("again late made");
                if(product.playerteam == awayteam)
                  {
                    if(product.playerid == awayplayerfilter) 
                     {
                       return  product;  
                     } 
                  }
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  ''; 
                      }
                    }   
              }   
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter != 'ALL PLAYERS' && homeplayerfilter != '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss &&   awaymiss && homemade && !awaymade)
              {
                console.log("here comes new made")
                if(product.playerteam == awayteam)
                  {
                    if(product.playerid == awayplayerfilter) 
                     {
                       return '';  
                     } 
                  }
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product; 
                      }
                    }   
              }  
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter != 'ALL PLAYERS' && homeplayerfilter != '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss &&  !awaymiss && homemade && !awaymade)
              {
                 console.log("let make new one again");
                 if(product.playerteam == awayteam)
                  {
                       return '';  
                   }
                  if(product.playerteam == hometeam)
                    {
                       if(product.playerid == homeplayerfilter) 
                       { 
                        return  product; 
                      }
                    }   
              }  
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter != 'ALL PLAYERS' && homeplayerfilter != '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss &&  !awaymiss && !homemade && !awaymade)
              {
                 console.log("this one again new made");
                 if(product.playerteam == awayteam)
                  {
                       return '';  
                   }
                  if(product.playerteam == hometeam)
                    {
                       
                        return  ''; 
                      
                    }   
              }  
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter != 'ALL PLAYERS' && homeplayerfilter != '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss &&  !awaymiss && !homemade && awaymade)
              {
                 console.log("let create for make");
                 if(product.playerteam == awayteam)
                  {
                    if(product.playerid == awayplayerfilter) 
                       { 
                        return  product; 
                      }  
                   }
                  if(product.playerteam == hometeam)
                    {
                      return  ''; 
                    }   
              }  
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter != 'ALL PLAYERS' && homeplayerfilter != '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss &&  !awaymiss && homemade && awaymade)
              {
                 console.log("if you smell for make");
                 if(product.playerteam == awayteam)
                  {
                    if(product.playerid == awayplayerfilter) 
                       { 
                        return  product; 
                      }  
                   }
                   if(product.playerteam == hometeam)
                    {
                   if(product.playerid == homeplayerfilter) 
                       { 
                        return  product; 
                      }
                    }   
              } 
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter != 'ALL PLAYERS' && homeplayerfilter != '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss &&  !awaymiss && homemade && awaymade)
              {
                 console.log("if you smell for  new");
                 if(product.playerteam == awayteam)
                  {
                    if(product.playerid == awayplayerfilter) 
                       { 
                        return  product; 
                      }  
                   }
                   if(product.playerteam == hometeam)
                    {
                   if(product.playerid == homeplayerfilter) 
                       { 
                        return  product; 
                      }
                    }   
              } 
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter != 'ALL PLAYERS' && homeplayerfilter != '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss &&  !awaymiss && !homemade && awaymade)
              {
                 console.log("if you smell for  new");
                 if(product.playerteam == awayteam)
                  {
                    if(product.playerid == awayplayerfilter) 
                       { 
                        return  product; 
                      }  
                   }
                   if(product.playerteam == hometeam)
                    {
                   if(product.playerid == homeplayerfilter) 
                       { 
                        return  ''; 
                      }
                    }   
              } 
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter != 'ALL PLAYERS' && homeplayerfilter != '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss &&  !awaymiss && homemade && !awaymade)
              {
                 console.log("here comes game for make");
                 if(product.playerteam == awayteam)
                  {
                    if(product.playerid == awayplayerfilter) 
                       { 
                        return  ''; 
                      }  
                   }
                   if(product.playerteam == hometeam)
                    {
                   if(product.playerid == homeplayerfilter) 
                       { 
                        return  product; 
                      }
                    }   
              } 
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter != 'ALL PLAYERS' && homeplayerfilter != '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss &&  awaymiss && homemade && !awaymade)
              {
                 console.log("feed the make");
                 if(product.playerteam == awayteam)
                  {
                    if(product.playerid == awayplayerfilter) 
                       { 
                        return  ''; 
                      }  
                   }
                   if(product.playerteam == hometeam)
                    {
                   if(product.playerid == homeplayerfilter) 
                       { 
                        return  product; 
                      }
                    }   
              } 
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter != 'ALL PLAYERS' && homeplayerfilter != '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && !homemiss &&  awaymiss && !homemade && !awaymade)
              {
                 console.log("feed the make new make cond");
                        return  ''; 
                   
              } 
              else if(searchvalue == "ALL QUARTERS" && (homeplayerfilter != 'ALL PLAYERS' && homeplayerfilter != '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss &&  awaymiss && !homemade && !awaymade)
              {
                console.log("heyyyy")
                return '';
              }  
              else if((searchvalue == "ALL QUARTERS" || searchvalue == "") && (homeplayerfilter == 'ALL PLAYERS' || homeplayerfilter == '')  && (awayplayerfilter != '' && awayplayerfilter != 'ALL PLAYERS') && homemiss &&  awaymiss && !homemade && awaymade)
              {
                if(product.playerteam == awayteam)
                  {
                    if(product.playerid == awayplayerfilter) 
                    { 
                       return  product; 
                    }  
                  }
                   if(product.playerteam == hometeam)
                    {
                     return  ''; 
                    }   
              }
            });
            
        },
          cancelAutoUpdate () {
              clearInterval(this.timer);
              clearInterval(this.commentrytimer);
          }  
      },
      beforeDestroy () {
        this.cancelAutoUpdate();
      }
  }
  </script>
  <style scoped>
  .basketb-comm-head{display: flex;align-items: center; padding: 3px; border-bottom: 1px solid #f1f1f1;}
  .basketb-comm-head.underline{width: 98%; }
  .underline {
      border-bottom: 1px solid #ccc;
  }
  .time {
      flex: 0 0 8%;
      text-align: center;
  }
  .playbyplay {
      flex: 0 0 78%;
      display: flex;
      align-items: center;
  }
  img.scorelogo {
    width: 16px;
}
.filter-item.d-flex > div{  margin-left: 5px;}
.filter-item img.scorelogo { width: 20px; height: 20px;}
  .gamescore {
      flex: 0 0 6%;
      text-align: center;
  }
  .bolder{font-weight: bold;}
  
  .commentry{width: 82%; margin: auto; margin-top: 15px; font-size: 13px;}
  .boxscorediv{width: 82%; margin: auto; margin-top: 15px; }
  .scrollable {
      overflow-y: scroll;
      height: 180px;
  }
  .matchblocks {border: 1px solid #ccc; padding: 5px;cursor: pointer; width: auto; display: inline-block;}
  .teamsname {font-size: 14px;}
  .teamscomment {font-size: 13px;}
  .single-team-info {display: flex;flex-wrap: wrap; align-items: center;justify-content: space-between;}
  .team-info-box{display: flex;flex-wrap: wrap; align-items: center;}
  .single-team-info .team-info-box img{  max-width: 90%; padding: 2px 0px;max-height: 30px;width:auto;margin:auto;}
  /*.single-team-info .team-info-box > span:first-child{margin-right: 10px;}*/
  /*.allgame-box{display: flex;align-items: center;}*/
  /*.allgame-box  .gameblock{margin-right: 15px;    box-shadow: 0 0 2px 3px #ddd;
      padding: 10px 15px; flex: 0 0 23%;}*/
  .overall-layout-box{width:100%;}
  .gameblock{cursor: pointer;}
  .single-team-info .team-info-box > span:first-child { margin-right: 10px; width: 50px;height:30px;text-align: center; overflow: hidden;}
  .comments{font-size: 10px;margin-top: 3px;}
  .allgame-box{position: relative;}
  .allgame-box .slider .gameblock{-webkit-box-shadow: 0 0 2px 3px #ddd;box-shadow: 0 0 2px 3px #ddd;  padding: 6px 3px; -webkit-box-flex: 0; -ms-flex: 0 0 23%;margin: 2px auto; max-width: 95%;}
  .allgame-box .slider{width: 100%;display: block !important;}
  .allgame-box .tennis.slider .gameblock{min-height: 135px;}
  .allgame-box .slider .gameblock{min-width:235px;}
  .allgame-box .fourbox .gameblock{min-width:230px;}
  .allgame-box .slider > button.my-auto.button.mr-2{position: absolute;left:0px;z-index:9999;background: white;padding-left: 10px;height: 100%;top:0px;}
  .allgame-box .slider > button.my-auto.ml-2{position: absolute;right:0px;z-index:9999;background: white;padding-left: 10px;height: 100%;top:0px;}
  .allgame-box .slider .dynamic-slider{width:85%;margin: auto;}
  .dynamic-slider .dynamic-slider-slide{vertical-align: middle;}
  .team-value{font-size: 14px;}
  .team-info-box span{font-size: 13px;}
  .gray{color:gray}
  .gamestatus {font-size: 13px;padding-left: 5px;}
  /* tennis*/
  .tennis-row {display: flex;justify-content: initial;align-items: center;}
  .player-name {flex: 0 0 45%;font-size: 13px;display: flex;align-items: center;}
  .player-score {display: flex;justify-content: space-between; align-items: center;flex: 0 0 55%;}
  .score-set{font-size: 13px; flex: 0 0 16.6%;}
  .versusname{font-size: 12px; margin-top: 5px; margin-bottom: 5px; text-align: center;}
  .flag-flex{margin-right: 2px; width: 24px; height: 15px; text-align: center; overflow: hidden;}
  .flag-flex img{max-width: 90%;padding: 2px 0px;max-height: 30px;width: auto;margin: auto;}
  .score-set span.bold{font-weight: bold; font-size: 13px;}
  span.ballserve {margin-left: 4px;}
  .ballserve {width: 15px;height: inherit;margin-left: 5px;}
  .score-set.smallfont {font-size: 11px;text-align: center;color: gray;}
  .tourname {font-size: 13px;}
  .lookdifferent {position: absolute;bottom: 0px;text-align: center;}
  .dynamic-slider-slides .greencolor,.dynamic-slider-slides .redcolor,.dynamic-slider-slides .orangecolor{ display: inline-block;width: 74px;}
  .dynamic-slider-slides .greencolor span{color:green;font-size: 10px;}
  .dynamic-slider-slides .redcolor span{color:red;font-size: 10px;}
  .dynamic-slider-slides .orangecolor span{color:orange;font-size: 10px;}
  span.teamscore {margin-right: 5px;text-align: center;width: 80px;display: inline-block;}
  .sideicon.livescore {width: 14px;margin-right: 5px;margin-left: 2px;}
  img.commlogo {
    width: 20px;
      height: 20px;
  }
  .timercount {
      background: black;
      color: #e12a2a;
      font-weight: bold;
      text-align: center; 
      border-radius: 5px;
      flex: 0 0 25%;
      margin-left: auto;
  }
  .boldit{font-weight: bold;}
  .teamintro {   display: flex;}
  .teamintro img {width: 20px;height: 20px;}
  .teamintro span {font-weight: 500;padding-left: 4px;}
  .boxscorediv{box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; padding: 10px; border-radius: 8px;}
  .teamdata { font-size: 12px;}
  .teamdata .heading {  display: flex; margin-top: 10px; font-weight:bold}
  .teamdata .name { flex: 0 0 20%;}
  .heading div{flex: 0 0 11.5%;text-transform: uppercase;border-top: 1px solid #dcdddf;border-bottom: 1px solid #dcdddf;text-align: center;}
  .playerscore > div{display: flex;}
  .playerscore div{flex: 0 0 11.5%; text-align: center; padding: 3px;}
  .playerscore .name { flex: 0 0 20%; text-align: left;}
  .playerscore .even{background: rgba(0,0,0,.04);}
  a.playerlink { color: black;  font-weight: bold;  font-size: 12px;}
 .filterinner { margin-top: 10px; align-items: center; margin-right: 15px; flex: 0 0 30%;}
 .diffsize{width: 80%; margin: auto;}
 .pointmade, .pointmissed{ display: flex; align-items: center;}
 .pointmade .colorpointer{ width: 12px; height: 12px; border-radius: 50%; background-color: #2e669f;     margin-right: 5px;}
 .pointmissed .colorpointer{width: 12px; height: 12px; border-radius: 50%; border: 2px solid #2e669f;     margin-right: 5px;}
 .filter-item  div.teamname{font-weight: bold;}
  </style>
  <style>
  .pitchmap{position:relative; width: 60%;  margin-top: 15px; display: flex;}
  .pitchimg{flex: 0 0 98%;}
  .filteration{flex: 0 0 68%; margin-left: 10px;}
.pitchmap canvas{    z-index: 999999; position: relative; width: 500px !important; height: 300px !important; }
.pitchmap .pitchimg img{position: absolute; top: 2px;z-index: 1;     width: 517px;}
.innerhomelogo{position: absolute;top: 30%;left: 40%;}
.innerhomelogo img { width: 100px !important;}
.hidescatter{height: 15px;top: 277px;position: absolute;width: 100%;background: white;z-index: 999999;}
.commtabs .v-slide-group__content{border:1px solid red;}
.commtabs .v-tab{border-right: 1px solid red;}
.commtabs .v-tab:last-child{border-right: none;}
.gamescore.bolder { display: flex;}
.gamescore.bolder > div {margin-right: 3px;}
.filter-item  .v-input--selection-controls{margin-top: 0; padding-top: 0;}

</style>