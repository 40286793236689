<template>
 <v-container class="fill-height" fluid>
  <v-row align="center" justify="center">
   <v-col cols="12" sm="6" md="6" >
    <v-card class="customround">
     <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="8" class="text-center pt-5 pb-5">
       <v-form action="#" @submit.prevent="submit">
        <v-card-text class="removespace">
         
          <v-text-field 
            ref="password"
            type="password"
            v-model="pass"
            v-validate="'required'"
            :error-messages="errors.collect('pass')"
            label="New Password"
            data-vv-name="pass"
            required
           outlined />
          <v-text-field 
            v-model="pass2"
            type="password"
            v-validate="'required|confirmed:password'"
            :error-messages="errors.collect('pass2')"
            label="Confirm Password"
            data-vv-name="pass"
            required
           outlined />
        </v-card-text>
        <v-card-actions class="justify-center">
              <v-row dense>
                 <v-col col="12" md="12" sm="12">  
                   <v-btn type="submit" class="button__full x-large" color="primary">Change Password</v-btn>
                 </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
            </v-col> 
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>  
  </v-container>
</template>
<script>
import Vue from 'vue';
import VeeValidate from 'vee-validate';

Vue.use(VeeValidate);
 export default{
   name:"personal",
    $_veeValidate: {
    validator: 'new'
  },
   data(){
     return {
        text: "",
        pass: "",
        pass2: "",
        snackbar: false,
        timeout: 2000,
       
     };   
   },  
   computed:{
      
   },
   
   methods: {
        submit () {
          this.$validator.validateAll().then(() => {
             this.$store.dispatch("changePassword",{password:this.pass}).then((result) => {
                this.snackbar = true;
                if(result.data.status == true)
                {
                  this.text = result.data.message;
                }
                else
                {
                  this.text = result.data.message;
                }
             });
          })
    },
   } 
 }
</script>
<style scoped>
.row {margin-left: 0px;}
.customround.v-card:not(.v-sheet--tile):not(.v-card--shaped){border-radius:20px;}
.button__full{width:98%;height:45px !important;}
.removespace{padding-bottom:0;}
</style>