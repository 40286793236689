<template>
    <div class="maindiv">
      <div class="teamladder">
         <div class="topheader">
           <div class="rank">#</div>
           <div class="teamname">Team</div>
           <div class="played">P</div>
           <div class="win">W</div>
           <div class="lose">L</div>
           <div class="score">F</div>
           <div class="againstscore">A</div>
           <div class="difference">Diff</div>
           <div class="points">Pts</div>
         </div>
         <div v-for="(val,key) in ladderData" :key="key" class="teamblock hvr-grow">
            <div class="rank">{{ key+1 }}</div>
            <div class="teamname">
                <div class="fantasy-avatar"><img class="teamlogo" :src="val.logo" /></div>
                <span>{{ val.name }} </span>
            </div>
            <div class="played">{{ val.P }}</div>
           <div class="win">{{ val.W }}</div>
           <div class="lose">{{ val.L }}</div>
           <div class="score">{{ val.F }}</div>
           <div class="againstscore">{{ val.A }}</div>
           <div class="difference">{{ val.D }}</div>
           <div class="points">{{ val.Pts }}</div>
         </div>
      </div>  
      <v-overlay color="white" :value="overlay">
        <v-progress-circular indeterminate color="themebtn" size="80"></v-progress-circular>
      </v-overlay> 
    </div>
</template>
<script>
export default{
 data(){
  return{
         overlay:false,
         sportid:0,
         leagueid:0,
         ladderData:[]
  };
 },
 created(){
   this.sportid  = this.$route.params.sport;
   this.leagueid = this.$route.params.leagueid;
   this.overlay = true;
   this.getLadder(); 
 },
 methods:{
    getLadder(){
        this.$store.dispatch("getFantasyLadder",{sport:this.sportid,leagueid:this.leagueid}).then((response) => {
            this.ladderData = response.data;
            this.overlay = false;
        })
    } 
 }
}
</script>
<style scoped>
 .teamladder > div {display: flex; align-items: center; padding: 5px;     height: 65px;}
 .teamladder {width: 98%;margin: auto;margin-top: 10px;border-radius: 10px;padding: 10px 10px 10px;box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; font-size: 13px;    }
 .rank { flex: 0 0 3%; text-align: center;margin-right: 3px;}
 .teamname {flex: 0 0 44%;margin-right: 3px;display: flex;align-items: center;}
 .played, .win, .lose, .score, .againstscore, .difference, .points {flex: 0 0 7%;margin-right: 3px; text-align: center;}
 .topheader{border: 1px solid #ccc; font-weight: bold; height: 30px !important;}
 img.teamlogo {  /*width: 25px;  height: 25px; margin-right:6px*/
  width: 48px;
    height: auto;
    margin-right: 6px;}
 .teamblock{border-right: 1px solid #ccc; border-left: 1px solid #ccc; border-bottom: 1px solid #ccc;}
 .fantasy-avatar {
    min-width: 48px;
    overflow: hidden;
    border-radius: 50%;
    
    margin-right: 5px;
}
</style>