<template>
  <div id="invitefriend">
     <v-row align="center" justify="center">
       <v-col cols="12" sm="8" md="8" >
         <v-card class="customround">
           <v-row align="center" justify="center">
             <v-col cols="12" sm="10" md="10" class="text-center ">
                <h1>Invite friends and earn</h1>
                <div class="bannertop">
                  <img :src="wishimage" class="topbanner">  
                </div>
                <div class="landing-page-invite-driend">
					<div class="share-box-cus">
						<div class="share-box-left">
							<input  v-on:focus="$event.target.select()"  type="text" name="shareinvitelink" ref="myinput"  :value="getusername">
						</div>
						<div class="share-box-right">
							<div class="share-button theme-button d-flex"> 
                 <span>Share</span>
                 <v-menu transition="slide-x-transition" bottom right  class="sharebuttonlinks">
                   <template v-slot:activator="{ on, attrs }">
                     <span class="material-icons cursor"  v-bind="attrs" v-on="on">arrow_drop_down</span>  
                   </template>
                  <v-list>
                   <v-list-item>
                      <v-list-item-title>
                        <a  @click="copy" ref="mylink" class="d-flex"><img :src="linkicon" class="shareicon" /> <span class="social-labels">Copy Link</span></a>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <ShareNetwork
                         network="facebook"
                        :url="getusername"
                        title="Sportstrade"
                        description=""
                        quote=""
                        hashtags="sportstrade,refer,invite" class="d-flex">
                        <img :src="fbicon" class="shareicon" /> <span class="social-labels">Post to Facebook</span>
                       </ShareNetwork>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <ShareNetwork
                         network="whatsapp"
                        :url="getusername"
                        title="Sportstrade"
                        description=""
                        quote=""
                        hashtags="sportstrade,refer,invite" class="d-flex">
                        <img :src="whatsappicon" class="shareicon" /> <span class="social-labels">Send with Whatsapp</span>
                       </ShareNetwork>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <ShareNetwork
                         network="twitter"
                        :url="getusername"
                        title="Sportstrade"
                        description=""
                        quote=""
                        hashtags="sportstrade,refer,invite" class="d-flex">
                        <img :src="twittericon" class="shareicon" /> <span class="social-labels">Tweet</span>
                       </ShareNetwork>
                      </v-list-item-title>
                    </v-list-item>
                    
                  </v-list>
                </v-menu> 
              </div>
						</div>
					</div>
                    
					<div class="invite-via-email" @click="openinvite">Invite via email</div>
					
				</div>
             </v-col>
           </v-row>
         </v-card>
       </v-col>
    </v-row>    
    <v-dialog transition="dialog-bottom-transition" max-width="600" v-model="dialog">
      <v-card>
         <v-toolbar color="primary" dark>Invite A Friend</v-toolbar>
         <v-card-text>
          <div class="text-h2 mt-5">
            <div class="red--text text-center">{{error}}</div>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="5" md="5" class="text-center ">
                <div><v-text-field label="Your friends Name" hide-details outlined v-model="name"></v-text-field></div>
              </v-col>
              <v-col cols="12" sm="5" md="5" class="text-center ">
                <div><v-text-field label="Your friends email address" hide-details outlined v-model="email"></v-text-field></div> 
              </v-col>
              <v-col cols="12" sm="2" md="2" class="text-center ">
                <v-btn color="themebtn" @click="addpeople">Add</v-btn>
              </v-col>
            </v-row>  
          </div>
          
          <div class="text-h2 mt-3" v-if="peoples.length > 0">
            <div v-for="(value,key) in peoples" :key="key">
              <v-row class="removepadd">
                <v-col class="smalltxtnew" cols="12" sm="5" md="5">{{value.name}}</v-col>
                <v-col class="smalltxtnew" cols="12" sm="5" md="5">{{value.email}}</v-col>
                <v-col cols="12" sm="2" md="2">
                  <span class="material-icons cursor" @click="deletethis(key)">delete</span>
                </v-col>
              </v-row>    
            </div>
          </div> 
        </v-card-text>
        <v-card-actions class="justify-center">
           <v-btn color="primary" :disabled="disabled" @click="sendinvite">Send Invite</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>     
  </div>
</template>  
<script>
export default {
  name: "topup",
  data() {
    return {
       error:'',
       dialog:false, 
       disabled:false,
       peoples:[],
       name:'',
       email:'',
       wishimage:require("@/assets/referal.png"),   
       fbicon:require("@/assets/fb.png"),    
       whatsappicon:require("@/assets/whatsapp.png"),    
       twittericon:require("@/assets/twitter.png"),    
       fbmsgicon:require("@/assets/fb-msg.png"),  
       linkicon:require("@/assets/link.png"),   
    };
  },
   
  computed: {
      getusername(){
          var  myusername = 'https://app.sportstrade.online/register?invite='+this.$store.getters.getUserdetails.username;
          return myusername;
      }
  },
  methods:{
      openinvite(){
          this.dialog = true;
          this.error = '';
          this.peoples = [];
      },
      sendinvite(){
        if(this.peoples.length > 0)
        {
          this.$store.dispatch("inviteFriend",{"data":this.peoples}).then((response) => {
           
            this.error = response.data.data;
            if(response.data.status == "success")
            {
               this.peoples = [];
               var v = this;
               setTimeout(function () {
                v.dialog = false;
               }, 3000);
            }
          });
        } 
        else
        {
          this.error = "Please enter information";
        } 
      },
      addpeople(){
          if(this.email != '')
          {
            this.peoples.push({"name":this.name,"email":this.email}); 
            this.name = '';
            this.email = '';
            this.error = '';
          }
          else
          {
            this.error = "Please enter information";
          }
      },
      deletethis(key){
         this.peoples.splice(key, 1);
      },
      copy() {
      this.$refs.myinput.focus();
      document.execCommand('copy');
    }
  } 
};
</script>
<style>
.topbanner{width:100%;}
.nopadd{padding:5px}
.cursor{cursor:pointer;}
.iconclass{margin-top:5px}
.landing-page-invite-driend {width: 75%;margin: 15px auto;}
.share-box-cus {display: flex;flex-wrap: wrap;border: 1px solid #53b6f9;}
.share-box-cus > div {padding: 10px 15px;}
.share-box-left input {color: #736f6f;border: none;line-height: 22px;font-size: 18px;width: 100%;}
.share-box-cus > div {padding: 10px 15px;}
.share-box-left {flex: 0 0 75%;}
.share-box-right {flex: 0 0 25%;background: #53b6f9;text-align: center;}
.share-box-right .share-button.theme-button {color: white;font-weight: 600;font-size: 18px;line-height: 22px;}
.invite-via-email {margin-top: 15px;background: #53b6f9;text-align: center;padding: 10px 0px;color: white;cursor: pointer;}
.row.removepadd div {padding-top: 0;padding-bottom: 0;}
.menuable__content__active .d-flex{align-items:center;}
.menuable__content__active .d-flex  span.social-labels{margin-left:15px; color:#000; font-size:14px;}
.menuable__content__active .d-flex img{width:25px;}
.smalltxtnew{font-size: 14px;}
</style>