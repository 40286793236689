<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="6" md="6" >
        <v-card class="customround">
          <v-row align="center" justify="center">
            <v-col cols="12" sm="9" md="9" class="text-center pb-5">
              <div class="loginlogo"><img :src="logo" ></div>
              <v-toolbar light flat  class="d-flex justify-space-around">
            <v-toolbar-title class="logheading">Login</v-toolbar-title>
          </v-toolbar>
          <v-form action="#" @submit.prevent="login">
            <v-card-text class="removespace">
              <span v-if="error"><strong class="red--text">{{errorText}}</strong></span>
              <v-text-field
              label="Email"
              name="username"
              outlined
              :rules="emailRules"
              type="text"
              id="username"
              v-model="username"/>
              
              <v-text-field
              id="password"
              label="Password"
              name="password"
              :rules="passwordRule"
              outlined
              @click:append="show1 = !show1"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              v-model="password"/>

            <!--
                 <v-text-field
              label="DamnN ID"
              name="damnnid"
              outlined
              :rules="damnnIdRule"
              type="text"
              id="damnnid"
              v-model="damnnid"/>
            -->
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-row dense>
                 <v-col col="12" md="12" sm="12">  
                   <v-btn type="submit" class="button__full x-large" color="primary">Login</v-btn>
                   <div class="mt-5">
                    Don't have an account? <router-link :to="{ name: 'register'}" class="bluecolor">Create Account</router-link>

                   </div>
                    <router-link class="bluecolor" :to="{name: 'reset-password', params:{  }}" >
          Forgot Password
          </router-link>
                 </v-col>
              </v-row>
              
            </v-card-actions>
          </v-form>
            </v-col> 
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "login",
  data() {
    return {
      username: '',
      password: '',
      damnnid:'',
      error: false,
      show1: false,
      errorText: '',
      emailRules: [
        v => !!v || "Please enter your email address",
        v =>
          /.+@.+\..+/.test(v) ||
          "This doesn't appear to be a valid email address"
      ],
      passwordRule: [
        v => v.length >= 6 || "Password must be at least 6 characters"
      ],
      damnnIdRule:[
        v => !!v || "Please enter DamnnId",
      ],
      logo: require("@/assets/logo.png")
    };
  },
  methods: {
    login() 
    {
       if(this.username != '' && this.password != '')
       {
         this.$store.dispatch("retrieveToken", {
          username: this.username,
          password: this.password,
         // damnnId:this.damnnid
        })
        .then(() => 
        {
          this.$store
            .dispatch("retrieveUserDetails")
            .then((response) => {
              if(typeof response == 'undefined')
              {
                this.error = true;
                this.errorText = 'No user exist';
              }
              else
              {
                this.$router.push({ name: "home" });
              }
            })
        })
        .catch(error => {
          this.error = true;
          this.errorText = error.response.data.message;
        });
       }
       else
       {
          this.error = true;
          this.errorText = 'Please enter login details';
       }
    }
  }
};
</script>

<style scoped>
.row {margin-left: 0px;}
.customround.v-card:not(.v-sheet--tile):not(.v-card--shaped){border-radius:20px;}
.button__full{width:98%;height:45px !important;}
.removespace{padding-bottom:0;}
.logheading{font-family:Lexend;font-size:24px;font-weight:600;}
</style>