import Home from '../views/Home';
import TopUp from '../views/TopUp';
import Login from '../components/auth/Login';
import Logout from '../components/auth/Logout';
import Register from '../views/Register';
import Damnnpx from '../views/Damnnpx';
import Hotornot from '../views/Hotornot';
import Teams from '../views/Teams';
import Team from '../views/Team';
import Portfolio from '../views/Portfolio';
import Player from '../views/Player';
import Buynsell from '../views/Buynsell';
import PersonalInformation from '../views/Personalinformation';
import ForgotPassword from '../views/ForgotPassword';
import ResetPasswordForm from '../views/ResetPasswordForm';
import Transactionhistory from '../views/Transactionhistory';
import Changepassword from '../views/Changepassword';
import Invitefriend from '../views/Invitefriend';
import Bonus from '../views/Bonus';
import Dividend from '../views/Dividend';
import Fantasylive from '../views/Fantasylive';
import Livescore from '../views/Livescore';
import Fantasyladder from '../views/Fantasyladder';
import Fantasyfixtures from '../views/Fantasyfixtures';

const routes = [
    { 
        path: '/reset-password', 
        name: 'reset-password', 
        component: ForgotPassword, 
        meta: { 
          auth:false 
        } 
    },
    { 
        path: '/reset-password/:token', 
        name: 'reset-password-form', 
        component: ResetPasswordForm, 
        meta: { 
          auth:false 
        } 
      },
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            title : 'Home',
            requiresAuth: true,
        }
    },
    {
        path: '/live-score',
        name: 'livescore',
        component: Livescore,
        meta: {
            title : 'World Sports Live',
            requiresAuth: true,
        }
    },
    {
        path: '/fantasy-live-score',
        name: 'fantasylivescore',
        component: Fantasylive,
        meta: {
            title : 'Real Fantasy Live',
            requiresAuth: true,
        }
    },
    {
        path: '/ladder/:sport/:leagueid',
        name: 'fantasyladder',
        component: Fantasyladder,
        meta: {
            title : 'Ladder',
            requiresAuth: true,
        }
    },
    {
        path: '/fixtures/:sport/:leagueid',
        name: 'fantasyfixtures',
        component: Fantasyfixtures,
        meta: {
            title : 'Fixtures',
            requiresAuth: true,
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            requiresVisitor: true,
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            requiresVisitor: true,
        }
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout
    }, 
    {
        path: '/invite-friend',
        name: 'invitefriend',
        component: Invitefriend,
        meta: {
            title:'Invite Friend',
            requiresAuth: true,
        }
    },
    {
        path: '/dividend',
        name: 'dividend',
        component: Dividend,
        meta: {
            title:'Dividend',
            requiresAuth: true,
        }
    },
    {
        path: '/hot-or-not',
        name: 'hotornot',
        component: Hotornot,
        meta: {
            title:'Hot or Not',
            requiresAuth: true,
        }
    },
    {
        path: '/hattrick',
        name: 'bonus',
        component: Bonus,
        meta: {
            title:'Hat Trick',
            requiresAuth: true,
        }
    }, 
    {
        path: '/damnnpx/:sport?/:league?',
        name: 'damnnpx',
        component: Damnnpx,
        meta: {
            title:'World Sport UX',
            requiresAuth: false,
        }
    },
    {
        path: '/teams/:sport/:country?/:fantasyid?',
        name: 'teams',
        component: Teams,
        meta: {
            title:'World Sport UX',
            requiresAuth: true,
        }
    },
    {
        path: '/team/:id',
        name: 'team',
        component: Team,
        meta: {
            title:'Team Profile',
            requiresAuth: true,
        }
    },
    {
        path: '/player/:id',
        name: 'player',
        component: Player,
        meta: {
            title:'Player Profile',
            requiresAuth: true,
        }
    },
    {
        path: '/portfolio',
        name: 'portfolio',
        component: Portfolio,
        meta: {
            title:'Portfolio',
            requiresAuth: true,
        }
    },
    {
        path: '/buy-n-sell',
        name: 'buynsell',
        component: Buynsell,
        meta: {
            title:'Marketplace',
            requiresAuth: true,
        }
    }, 
    {
        path: '/personal-information',
        name: 'personal',
        component: PersonalInformation,
        meta: {
            title:'Personal Information',
            requiresAuth: true,
        }
    },
    {
        path: '/transaction-history',
        name: 'transactionhistory',
        component: Transactionhistory,
        meta: {
            title:'Transaction History',
            requiresAuth: true,
        }
    },
    {
        path: '/change-password',
        name: 'changepassword',
        component: Changepassword,
        meta: {
            title:'Change Password',
            requiresAuth: true,
        }
    },
    {
        path: '/topup',
        name: 'topup',
        component: TopUp,
        meta: {
            requiresAuth: true,
        }
    },
   
]

export default routes;
