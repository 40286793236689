<template>
  <div id="dividend">
       <playertable :headers="divheaders" :itemsWithSno="itemsWithSno"></playertable>
  </div>
</template>    
<script>
import Playertable from '../components/Playertable.vue';
export default {
  name: "Portfolio",
  components:{ Playertable },
  data() {
    return {
       error: false,
      errorText:'',
      showgamelist:true,
      desserts:[],
      searching:'',
      searchteam:'',
      dialog:false,
      dialogteam:false,
      inputunit: 0,
      total:0,
      readonly:false, 
      selectedPlayer:null,
      sellprice:0,
      tabing:0,
      maindata:[],
      customfilter:'',
      isMobile: false,
    };
  },   
  mounted() {
    
    this.getDividendList();
    this.getDividendBalance();
  },
  computed: {
      
    divheaders(){
        let  headers = [
              { text:"#", value: "sno", width:"8%"},
              { text:"Name",align: "name",value: "name",sortable: true, width:"33%"},
              { text:"", value: "nationality",sortable: true, width:"20%"},
              { text:"", value: "",sortable: false, width:"5%"},
              { text:"DX Units Sold", value:"availability",sortable: true, width:"15%"},
              { text:"Current",value:"price",sortable:true, width:"18%"},
              { text:"",value:"#", sortable: false, width:"12%"}
         ];

         return headers;
    },
    
    itemsWithSno() 
    {
          if(this.searching)
           {
              return this.desserts.filter((item)=>{
                 return this.searching.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v));
              })   
          }
          else
          {
            if(this.desserts.players != '')
            {
              if(this.customfilter == '') 
              {
                return this.desserts.map((d, index) => ({ ...d, sno: index + 1,keyval:index }))
              }
              else
              {
                return this.desserts.filter((item)=>{
                 return this.customfilter.toLowerCase().split(' ').every(v => item.type.toLowerCase().includes(v))
                })   
              } 
            }
            else
            {
               return '';
            }
          }
       },
        
  },
  methods: {
    getDividendBalance(){
      this.$store.dispatch("getdividendBalance");
    }, 
    onResize() {
          if (window.innerWidth < 769)
            this.isMobile = true;
          else
            this.isMobile = false;
    },
     
    getDividendList(){
      this.$store.dispatch("getDividendList").then((response) => 
      {
          this.desserts = response.players;
      }); 
    },
     
     sellplayer(index){
         this.error = false;
         this.errorText = '';
         this.inputunit = '';
         this.sellprice = '';
         this.total     = 0;
         //this.selectedPlayer = this.itemsWithSno[index];
         this.selectedPlayer = index;  
         this.dialog = true; 
     },
     sellteam(index)
     {
         this.error = false;
         this.errorText = '';
         this.inputunit = '';
         this.sellprice = '';
         this.total     = 0;
         //this.selectedPlayer = this.itemsWithSno[index];
         this.selectedPlayer = index;
         this.dialogteam = true; 
     }
  }
};
</script>