<template>
 <div id="playerlist">
   
  <v-data-table :headers="headers" :mobile-breakpoint="0" :items="desserts"   hide-default-footer  disable-pagination class="playertable">
  <template v-slot:item="{ item }"  >
    <tr>
      <td class="text-left">
        <div class="mt-3 mb-2">
          <router-link  class="anchorcust" :to="{name: 'player', params:{ id: item.id }}">
            <span class="flag-flex" v-if="sport == 'golf'"><img class="countryflag" :src="countryflag(item.extrastats.nationality)"></span> 
            <v-avatar><img :src="item.thumbnail_image"   :alt="item.name"></v-avatar>  
            <span class="playername">{{ item.name }}</span>
          </router-link>
        </div>
      </td>
     <td class="text-center" v-if="sport == 'Tennis'">
       <span class="flag-flex" v-if="item.nationality != 'world'"><img class="countryflag" :src="countryflag(item.nationality)"></span>
       <span class="flag-flex" v-if="item.nationality == 'world'"><img class="countryflag" :src="countryflag(item.nationality)"></span>
     </td>
     <td class="text-center" v-if="sport == 'golf'">
      {{ item.extrastats.hole }}
     </td> 
     <td class="text-center" v-if="sport == 'golf'">
      {{ item.extrastats.today_score }}
     </td> 
      <td class="comfont text-center graphimg">
        <div v-if="staticgraph"> 
          <span v-if="item.twnty_four_Hrs.flag != '' ">
          <img v-if="item.twnty_four_Hrs.flag == 'positive'" :src="getimagestatic('green')">
          <img v-else-if="item.twnty_four_Hrs.flag == 'negative'" :src="getimagestatic('red')">
          <img v-else :src="getimagestatic('orange')">
        </span>
        </div>
        <div v-else>
          <span v-if="item.twnty_four_Hrs.flag != '' ">
          <img v-if="item.twnty_four_Hrs.flag == 'positive'" :src="getimage('green')">
          <img v-else-if="item.twnty_four_Hrs.flag == 'negative'" :src="getimage('red')">
          <img v-else :src="getimage('orange')">
        </span>
        </div>
      </td>
      <td class="comfont text-left">{{ numberWithCommas(item.availability) }}</td>
      <td class="comfont text-left">
       <span class="contentdata displaycurr">
        <span class="displaycurr" v-html="currency(item.price)"></span>
        <span v-if="item.twnty_four_Hrs.flag == 'orange'" class="orangevalue">
            <span>( </span><div  class="displaycurrency" v-html="currencysymbol()"></div> <span v-html="item.twnty_four_Hrs.percentage_difference"></span> <span> )</span>
        </span>   
        <span v-if="item.twnty_four_Hrs.flag == 'positive'" class="greenvalue"> 
            <span>(+ </span><div  class="displaycurrency" v-html="currencysymbol()"></div> <span v-html="item.twnty_four_Hrs.percentage_difference"></span><span> )</span>
        </span>
        <span class="redvalue" v-if="item.twnty_four_Hrs.flag == 'negative'"> 
            <span>(-</span><div  class="displaycurrency" v-html="currencysymbol()"></div> <span v-html="item.twnty_four_Hrs.percentage_difference"></span><span> )</span>
        </span>
       </span>  
      </td>
      <td class="comfont text-left" >
        <div v-if="item.midpoint > 0">
        <div class="doughnutchart" >
           <div class="midpoint orangecolor">{{item.midpoint}}</div>
           <Doughnut :key="item.id" ref="skills_chart" :width="106" :height="83" :chartDataval="item.chartData" :midPointcolor="item.midpointcolor" :optionsval="item.points"></Doughnut>
           <div class="midpointcolor" :class="item.midpointcolor">{{item.points}}</div>
        </div>
        <v-row class="pointsdisplay">
          <v-col class="removepads redcolor left text-left" cols="6" sm="6" md="6">0</v-col>
          <v-col class="removepads greencolor right text-right" cols="6" sm="6" md="6">{{item.pointsneed}}</v-col>
        </v-row>
        </div> 
        <div v-else>
           <div class="text-center" :class="item.midpointcolor">{{item.points}}</div>
        </div> 
      </td>
      <td class="comfont">
        <template>
          <div v-if="item.player_status == 'Active'">
            <v-btn color="green" @click="openingamepop" class="actions">In Game</v-btn>
          </div>
          <div v-else>
            <div v-if="suspendedShow(item.price)">
            <v-btn color="themebtn" @click="buyplayer(item.keyval)" class="actions">Buy Now</v-btn>
          </div>  
          <v-btn v-else color="primary text-center sus"  @click="buyplayer(item.keyval)" class="actions">Suspended</v-btn>
          </div>
        </template>
       </td>
     </tr>
    </template>   
  </v-data-table> 
  <buy-player-modal :dialog.sync="showplayerdialog" :selectedPlayer="selectedPlayer" :language="language" />
 </div>    
</template>
<script>
import Doughnut from './Doughnut.vue'

export default{
   components: {
      Doughnut
    },
   data(){
     return{
         showplayerdialog:false,
         language:'',
         selectedPlayer:null,
         options:'',
         chartData:'',
         showchart:false,
     } 
   }, 
   props:{
       sport:{
         type:String
       },
       itemsWithSno:{
           type: Array
       },
       staticgraph:{type: Boolean,default:false}
   },
   computed: {
     headers(){
      let headers = [];
       if(this.sport == 'Tennis')
       {
        headers = [
              { text:"Name",align: "name",value: "name",sortable: true, width:"30%"},
              { text:"",value:"",sortable:false, width:"4%"},
              { text:"", value: "",sortable: false, width:"8%"},
              { text:"Units To Buy", value:"availability",sortable: true, width:"18%"},
              { text:"Price",value:"price",sortable:true, width:"14%"},
              { text:"Points", value: "sno", width:"13%"},
              { text:"",value:"#", sortable: false, width:"13%"}
         ]
       } 
       else if(this.sport == 'golf')
       {
        headers = [
              { text:"Name",align: "name",value: "name",sortable: true, width:"30%"},
              { text:"Hole",value:"",sortable:false, width:"4%"},
              { text:"Score",value:"",sortable:false, width:"4%"},
              { text:"", value: "",sortable: false, width:"8%"},
              { text:"Units To Buy", value:"availability",sortable: true, width:"14%"},
              { text:"Price",value:"price",sortable:true, width:"14%"},
              { text:"Points", value: "sno", width:"13%"},
              { text:"",value:"#", sortable: false, width:"13%"}
         ]
       } 
       else
       {
        headers = [
              { text:"Name",align: "name",value: "name",sortable: true, width:"30%"},
              { text:"", value: "",sortable: false, width:"8%"},
              { text:"Units To Buy", value:"availability",sortable: true, width:"18%"},
              { text:"Price",value:"price",sortable:true, width:"14%"},
              { text:"Points", value: "sno", width:"13%"},
              { text:"",value:"#", sortable: false, width:"13%"}
         ]
       }
         
         return headers
       },
         desserts() {
          return this.itemsWithSno.map((d, index) => ({ ...d, sno: index + 1,keyval:index }))
       },
   },
   
   methods: {
    openingamepop(){
         this.$root.$refs.Master.openInGame();
    }, 
    countryflag(name)
       {
         if(name == 'world')
         {
           return require("@/assets/world.png"); 
         }
         if(name != '')
         {
           return 'https://flagsapi.com/'+name+'/shiny/32.png';
         } 
       }, 
   changeType() {
      this.page = 1;
       this.$nextTick(() => {
        this.$refs.infiniteLoading.attemptLoad();
      });
      this.infiniteId += 1;
    },
    getimagestatic(type){
       var image = '';
       var imgnumber = 1;
       if(type == 'green'){
           image = "green"+imgnumber+".png";
       }
       else if(type == 'orange'){
           image = "orange.png";
       }
       else{
           image = "red"+imgnumber+".png";
       }
       var fullpath = require(`../assets/`+image);

      return fullpath;   
    },
    getimage(type){
       var image = '';
       var imgnumber = Math.floor(Math.random() * 6) + 1;
       if(type == 'green'){
           image = "green"+imgnumber+".png";
       }
       else if(type == 'orange'){
           image = "orange.png";
       }
       else{
           image = "red"+imgnumber+".png";
       }
       var fullpath = require(`../assets/`+image);

      return fullpath;   
    },
    suspendedShow(price){
         var suspended = true;
         var getsetting = this.$store.getters.getcurrencySetting;
         var checkprice = 0;
         if(getsetting.dammn_coin == 1)
         {
            checkprice = price*getsetting.dammn_coin_multiple;      

            if(getsetting.dc_deduction_value > 0)  
            {
              if(checkprice < getsetting.dc_deduction_value)
              {
                 suspended = false;
              }
            }  
         }
         else
         {
            checkprice = price;      
            if(checkprice < getsetting.dc_deduction_value)
             {
                 suspended = false;
             }
         } 

         return suspended;
       },
     buyplayer(index){ 
         this.error          = '';
         this.errorText      = '';
         this.inputunit      = '';
         this.total          = 0;  
         var price           = this.itemsWithSno[index].price;
         var getsetting      = this.$store.getters.getcurrencySetting;
         this.selectedPlayer = this.itemsWithSno[index];
         var checkprice = 0; 
         if(getsetting.dammn_coin == 1)
         {
            checkprice = price*getsetting.dammn_coin_multiple;      

            if(getsetting.dc_deduction_value > 0)  
            {
              if(checkprice < getsetting.dc_deduction_value)
              {
                this.selectedPlayer = '';
                this.language = 'This Player is suspended due to their amount is less then DC 50';
              }
            }  
         }
         else
         { 
             checkprice = price;
             if(checkprice < getsetting.dc_deduction_value)
              {
                this.selectedPlayer = '';
                this.language = 'This Player is suspended due to their amount is less then $0.05';
              }
         }
        
         this.showplayerdialog = true; 
     },  
   }   
}
</script>
<style scoped>
.removepads {padding: 0;padding-left: 13px;padding-right: 13px;font-size: 11px;color: black;}
.row.pointsdisplay {position: relative;}
.removepads.left.text-left {margin-top: -20px; padding-left: 5px; position: absolute;}
.removepads.right.text-right {margin-top: -20px;padding-right: 0px;position: absolute;right: -5px;}
.midpoint {position: absolute;text-align: center;width: 100%;top: -2px;font-size: 11px;color: black;}
.doughnutchart {position: relative;}
.playertable td{padding: 8px 16px !important;}
.redcolor{color:red}
.orangecolor{color: orange;}
.greencolor{color:green}
@media(max-width:767px){
  .removepads.left.text-left{margin-top: -19px;right: -7px;}
  .removepads.right.text-right{margin-top: -19px;right: -7px;}
}  
.midpointcolor{
    top: 48px;
    text-align: center;
    position: absolute;
    width: 100%;
}
.browncolor{color:#a52a2a}
.greencolor{color:green}
.redcolor{color:red}
</style>