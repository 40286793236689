<template>
  <v-app>
    <Master />
  </v-app>
</template>

<script>
import Master from '../src/components/layouts/Master.vue'
export default {
  name: "App",
  components: {
    Master
  },
  data() {
    return {
      //
    };
  }
};
</script>
