<template>
  <div id="hotornot">
    <gamelist v-if="showgamelist"></gamelist>
    <div v-else>
     <v-tabs class="tabber" color="themebtn" @change="tabchange" v-model="tabing">
       <v-tab>{{tabone}}</v-tab>
       <v-tab><div style="margin-right:4px" class="displaycurrency" v-html="currencysymbol()"></div>&nbsp; &nbsp; Gainers</v-tab>
       <v-tab><div style="margin-right:4px" class="displaycurrency" v-html="currencysymbol()"></div> Losers</v-tab>
     </v-tabs>
     <div class="filterdiv" v-if="game != ''">
      <div class="filtersub">
          <v-select class="teamselection mx-4" @change="filterdata" :items="teams" item-text="name" item-value="id" label="Select Team" v-model="team" hide-details></v-select>
      </div> 
      <div class="filtersub">
        <v-text-field  v-model="search"  label="Search Player" class="mx-4 searchplayer"></v-text-field>
      </div>
      <div class="filtersub filtercontrol">
          <div class="filtercontrolsub">Price</div>
          <div class="filtercontrolsub">
            <v-radio-group v-model="price" class="mylegeneds" row @change="filterdata">  
            <v-radio label="High" value="high"></v-radio>
            <v-radio label="Low" value="low"></v-radio>
            </v-radio-group>
          </div>
          <div class="filtercontrolsub">
            <v-btn color="primary" @click="resetfilter" class="actions filter">Reset</v-btn>
          </div>
      </div>
    </div>  
    <div v-if="tabing == 0">
      <playertable :headers="headers" :itemsWithSno="itemsWithSno" :game="game" :fire="true"></playertable>
    </div>
    <div v-if="tabing == 1">
      <playertable :headers="headers" :itemsWithSno="itemsWithSno" :game="game"></playertable> 
    </div>
    <div v-if="tabing == 2">
      <playertable :headers="headers" :itemsWithSno="itemsWithSno" :game="game"></playertable>
    </div>
    <v-overlay color="white" :value="overlay">
      <v-progress-circular indeterminate color="themebtn" size="80"></v-progress-circular>
    </v-overlay>
  </div>
 </div>
</template>
<script>
import Gamelist from '../components/Gamelist.vue';
import Playertable from '../components/Playertable.vue';

export default {
  name: "Hotornot",
  components:{ Gamelist,Playertable },
  data() {
    return {
      mobileBreakpoint: 600,
      price:'',
      error: false,
      errorText:'',
      tabing:0,
      overlay: false,
      showgamelist:false,
      desserts:[],
      search:'', 
      inputunit: 0,
      total:0,
      readonly:false,
      page:1,
      team:'',
      teams: [],
      game:'',
      league:'',
      gameList:[],
      hotornot:false,
      tabone:'',
    };
  },
  mounted() {
      this.overlay = true;
      this.hotornot = true;
      this.tabone = 'Top Units Sold';
    
    var param = this.$route.params.sport;
    if(typeof param != "undefined") {
       this.game = param;
    }
    if(typeof this.$route.params.league != "undefined"){
       this.league = this.$route.params.league; 
    } 
    //this.gamesListing();
    this.getList();
  },
  computed: {
     headers(){
       var headers = '';
       if(this.tabing == 0)
        {
           headers = [
              { text:"#", value: "sno", width:"8%"},
              { text:"Name",align: "name",value: "name",sortable: true, width:"33%"},
              { text:"", value: "nationality",sortable: true, width:"20%"},
              { text:"", value: "",sortable: false, width:"5%"},
              { text:"UX Units Sold", value:"availability",sortable: true, width:"15%"},
              { text:"Current",value:"price",sortable:true, width:"18%"},
              { text:"",value:"#", sortable: false, width:"12%"}
         ];
        }
        else
        { 
            headers = [
              { text:"#", value: "sno", width:"5%"},
              { text:"Name",align: "name",value: "name",sortable: true, width:"35%"},
              { text:"", value: "nationality",sortable: true, width:"22%"},
              { text:"", value: "",sortable: false, width:"8%"},
              { text:"DX Units To Buy", value:"availability",sortable: true, width:"8%"},
              { text:"Current",value:"price",sortable:true, width:"10%"},
              { text:"Days Last Game",value:"last_played",sortable:true,width:"11%"},
              { text:"Buy",value:"#", sortable: false, width:"12%"}
         ]
        }
         return headers;
       },
       itemsWithSno() {
          return this.desserts.map((d, index) => ({ ...d, sno: index + 1,keyval:index }))
       },
  },
  watch: {
    $route() {
      this.page = 0;
      this.tabing = 0;
      this.overlay = true;
      this.desserts = [];
      this.league = '';
      this.team = '';  
        
      if(this.$route.params.sport){
        this.game = this.$route.params.sport;
      }
      else{
        this.game = '';
      }
      if(typeof this.$route.params.league != "undefined"){
       this.league = this.$route.params.league; 
      } 
      else{
        this.league = '';
      }
      this.getList();
   },
  },    
  methods: {
    gamesListing(){
        this.$store.dispatch("getGameList").then(() => {
          //this.gameList = this.$store.getters.getGameList;
      })
    },
    tabchange(){
       this.page = 1;
       this.game = '';
       this.team = '';
       this.search = ''; 
       this.overlay = true;
       this.desserts = [];
      if(this.$route.params.sport){
        this.game = this.$route.params.sport;
      }
       if(this.tabing == 0){
         this.getList();
       }
       else if(this.tabing == 1){
         this.gainerList();
       }
       else{
         this.loserList();
       }
    },
    resetfilter(){
       this.game = '';
       this.price = '';
       this.page = 1;
       this.overlay = true; 
       this.desserts = [];
       this.getList();
    },
    specialfilterdata(){
      this.team = '';
      this.search = '';
      this.filterdata();
    },
    filterdata(){
       this.page = 1;
       this.desserts = [];
       this.overlay = true;
       if(this.tabing == 0){
         this.getList();
       }
       else if(this.tabing == 1){
         this.gainerList();
       }
       else{
         this.loserList();
       }
    },
    getTeamListing(){
       var sports = ""
       var league = "";
       if(this.game != '') 
       { 
         sports = this.game.toLowerCase();
       } 
       if(this.league != "")  
       {
         league = this.league;
       } 
       this.teams = [{id:0,name:"All Team"}];
       this.$store.dispatch("getTeamlisting",{game:sports,league:league}).then((response) => 
          { 
             //this.teams.push(response.teams);  
             for(var i = 0; i < response.teams.length; i++)
             {
               this.teams.push({ id:response.teams[i].id, name:response.teams[i].name });
               //console.log(this.teams);
             }
          }); 
    },
    getList(){ //$state
       var sports = "";
       var league = "";
       var parameter = "page="+this.page;
       if(this.game != '')
       {
         sports = this.game.toLowerCase(); 
         parameter = parameter+"&sport="+sports;
       } 
       if(this.league != '')
       {
         league = this.league;
         parameter = parameter+"&league="+league; 
       }
       if(this.team != '')
       {
          parameter = parameter+"&team="+this.team;
       }
       if(this.search != '')
       {
          parameter = parameter+"&q="+this.search;
       } 
       if(this.price != '')
       {
          parameter = parameter+"&sort_by="+this.price;
       } 
       
         this.$store.dispatch("getHotorNotList",{paramters:parameter}).then((response) => {
              this.overlay = false; 
              if(response.players.length > 0)
              {
                this.page += 1;
                this.desserts = response.players;
                this.desserts = this.desserts.filter(function(item){ return item.price != "0.00"})
                //$state.loaded();
              }
          }); 
     },
    loserList(){ //$state
          this.$store.dispatch("getDashboardPlayer",{"limit":50,"type":"loser"}).then((response) => 
          {
              this.overlay = false;
              if(response.players.length > 0)
              {
                this.page += 1;
                this.desserts = response.players;
                //$state.loaded();
              }
              else
              {
              // $state.complete();
              }
          });
    },
    gainerList(){ 
          this.$store.dispatch("getDashboardPlayer",{"limit":50,"type":"gainer"}).then((response) => 
          {
              this.overlay = false;
              if(response.players.length > 0)
              {
                this.page += 1;
                this.desserts = response.players;
              }
              else
              {
              // $state.complete();
              }
          });
       
    },
    
  }
};
</script>
<style>
.playername{font-family:Lexend;font-weight:500; size:12px; margin-left:8px;}
.comfont{font-family:lexend; font-size:14px;}
.themebtn{color:#fff;text-transform:capitalize;}
.rounded-xl{border-radius: 50px !important;}
.popupbody{padding-top:15px;padding-bottom:10px;}
.popupbody .heading{font-family:Lexend;font-size:18px; font-weight:500;color:#010101;line-height: 35px;}
.material-icons.closeicon{width: 35px;height: 35px;display: block;line-height: 33px;background-color: #FAFAFA;text-align: center;border-radius: 50%;cursor: pointer;}
.playerphoto {width: 62px;height: 62px;display: block;margin: auto;}
.playerphoto img{width: 100%;border-radius: 5px;}
.playername{text-align:center;width:100%;color:#010101;font-size:14px;font-weight:500;line-height:17.5px;}
.themebox {width: 100%;height: 70px;background: rgb(72,159,255);background: linear-gradient(232deg, rgba(72,159,255,1) 0%, rgba(12,107,211,1) 100%);display: block;border-radius: 10px;padding-top: 15px;color:#fff;font-family:Lexend;}
.unitvalue{color:#fff;opacity:0.7;font-size:12px;font-weight:400;}
.no-padding{padding-right:0;padding-left:0;}
.totalunit,.unitprice{font-size:16px; font-weight:500; line-height:18px;}
.labels{font-family:Lexend;font-size:14px;font-weight:500;color:#010101}
.input {border: 1px solid #E9E9E9;border-radius: 8px;margin-top: 10px;padding:10px;display:flex;}
.custom {width: 50%;}
.material-icons.controls {display: inline-block;height: 25px;width: 25px;background: #E9E9E9;border-radius: 50%;margin-left: 10px;cursor: pointer;}
.total {margin-top: 20px;font-size: 18px;color: #247CDD;font-weight: 500;}
.action button{width:90%;color:rgba(255, 255, 255, 0.87);}
.v-application .themebtn{color:#fff !important}
.vdialognew{-webkit-box-shadow:none !important;box-shadow:none !important;}
.anchorcust{color:transparent!important;position:relative !important;}
/*.fire-player{color:red;top:5px;left:30px; position: absolute;}*/
.fire-player {color: red;position: absolute;display: inline-block; right: 4px;font-size: 17px;}
td.relative{position:  relative !important;}
.smallfonts{font-size: 12px;} 
.tabber .v-tabs-bar.v-item-group > * {border-bottom: 1px solid #E5E5E5;}
.filterdiv {display: flex;justify-content: space-between; align-items: center;margin: 0 10px;}
.filtersub {flex: 0 0 30%;}
.filtersub.filtercontrol {display: flex;justify-content: space-around;align-items: center;flex: 0 0 40%;}
.teamselection.v-text-field{padding-top:1px;margin-top:0;}
.actions.filter{padding: 0 12px;margin-right:5px;}
.searchplayer{margin-top:12px;}
.graphimg img{ width: 100%;}
.themebox .displaycurr{display: flex;justify-content: center;align-items: center;}
.themebox .displaycurr .cursym img{width:18px;}
.primary.sus{text-transform:capitalize;}
 span.bold{color: black;font-size: 10px;margin-left: 3px;}
.cursym{line-height: 24px;}
@media(max-width:768px)
{
  .fire-player{position: inherit;}
  .filterdiv{justify-content: center;flex-wrap: wrap;margin-top:20px;}
  .filtersub{flex:0 0 50%;}
  .filtersub.filtercontrol{flex: 0 0 50%;}
  .anchorcust{display:block; width: 100%;text-align: center;}
  .anchorcust .v-avatar{margin: auto;text-align: center;}
  .anchorcust .v-avatar img{margin: auto;display: block;}
  .playername{display: block;text-align: center;margin-left:0;}
}
@media(max-width:640px){
 .filterdiv{display:block}
}
@media(max-width:450px){
 .filtersub{width: 90%;margin: auto;}
 .searchplayer.mx-4,.teamselection.mx-4 {margin-right: 0px !important; margin-left: 0px !important;}  
 .searchplayer {    margin-top: 0;}
 .teamselection.mx-4{padding-top: 12px;
margin-top: 4px;}
}
@media(max-width:380px){
 .filtersub.filtercontrol{
    justify-content: space-between;
    flex-wrap: wrap;align-items: center;
}
.filtercontrolsub:nth-child(3){flex: 0 0 100%;text-align: center;}
.v-input--selection-controls{margin-top:0 !important;padding-top:0 !important;}
.actions.filter{margin-bottom:10px;}
}
@media(max-width:360px){
  .filtercontrolsub:nth-child(3) { flex: 0 0 100%; }
}
</style>