<template>
  <div id="transactionhistory">
    <div>
     <div class="historycontent">
       <div class="historycontent-sub">
         <div class="tophead">Today</div>
         <div class="history-result">
           <div class="balancediv">
             <div class="leftpart">
              <div class="leftfirst"><span class="material-icons">account_balance</span></div>
              <div class="leftsecond">Deposit Money</div>
             </div>
             <div class="rightpart">
              <!--${{historydata.todaydeposite}}-->
              <span class="displaycurr" v-html="currency(historydata.todaydeposite)"></span>
              </div>
           </div>
           <div class="balancediv">
             <div class="leftpart">
               <div class="leftfirst"><span class="material-icons">account_balance</span></div>
               <div class="leftsecond">Withdraw</div>
             </div>
             <div class="rightpart">
               <!--${{historydata.todayWithdrwal}}-->
               <span class="displaycurr" v-html="currency(historydata.todayWithdrwal)"></span>
             </div>
           </div>     
        </div>
      </div>
      <div class="historycontent-sub">
         <div class="tophead d-flex">
            <span class="md-6">Last Week</span>
            <span class="md-6 text-right">
                <v-select
                  :items="days"
                  v-model="defaultday"
                  hide-details
                  hide-selected
                  @change="getHistory"
                  class="cusselect"></v-select>
            </span>
         </div>
         <div class="history-result">
           <div class="balancediv">
             <div class="leftpart">
               <div class="leftfirst"><span class="material-icons">account_balance</span></div>
               <div class="leftsecond">Deposit Money</div>
             </div>
             <div class="rightpart">
               <!--${{historydata.lastsevendeposite}}-->
               <span class="displaycurr" v-html="currency(historydata.lastsevendeposite)"></span>
             </div>
           </div>
           <div class="balancediv">
             <div class="leftpart">
               <div class="leftfirst"><span class="material-icons">account_balance</span></div>
               <div class="leftsecond">Withdraw</div>
             </div>
             <div class="rightpart">
               <!--${{historydata.lastsevenWithdrwal}}-->
               <span class="displaycurr" v-html="currency(historydata.lastsevenWithdrwal)"></span>
             </div>
           </div>
         </div>
       </div>
     </div>
     <v-tabs class="tabber" color="themebtn" @change="tabchange" v-model="tabing">
       <v-tab>Transaction</v-tab>
       <v-tab>Dividend</v-tab>
       <v-tab v-if="email == 'Rochome@sportstrade.online'">History</v-tab>
     </v-tabs>
     <div v-if="tabing == 0">
       <v-data-table :headers="headers" :items="itemsWithSno" class="makefullwidth" :search="search"  hide-default-footer  disable-pagination fixed-header
      :height="500">
        <template v-slot:item="{ item }"  >
        <tr :class="item.color">
          <!--<td>{{ item.sno }}</td>
          <td class="text-left">
            <div class="mt-3 mb-2" v-if="item.image != ''">
              <router-link class="anchorcust" :to="{name: 'player', params:{ id: item.player_id }}" v-if="item.pttype == 'Player'"> 
               <v-avatar>
                <img :src="item.image"   :alt="item.player_name">
               </v-avatar>
             </router-link>  
             <router-link class="anchorcust" :to="{name: 'team', params:{ id: item.player_id }}" v-else> 
               <v-avatar>
                <img :src="item.image" :alt="item.player_name">
               </v-avatar>  
             </router-link>  
            </div>
          </td>-->
          <td><span class="playername">{{ item.name }}</span></td>
          <td class="comfont text-center">{{item.buytype}}</td>
          <td class="comfont text-center">{{item.buydate}}</td>
          <td class="comfont">
             <span v-if="item.buyprice" class="displaycurr" v-html="currency(item.buyprice)"></span>
          </td>
          <td class="comfont text-center">{{item.buyunit}}</td>
          <td class="comfont text-center">
             <span v-if="item.buytotal" class="displaycurr" v-html="currency(item.buytotal)"></span>
          </td>
          <td class="comfont">
             <span v-if="item.currentprice" class="displaycurr" v-html="currency(item.currentprice)"></span>
          </td>
          <td class="comfont">
             <span v-if="item.currentprice" class="displaycurr"
               :class="{'greenfont': item.buycolor == 'green','redfont': item.buycolor == 'red'}"
              v-html="currency(item.currentmarketvalue)"></span>
          </td>
          <td class="comfont">
             <span v-if="item.currentprice" class="displaycurr" 
               :class="{'greenfont': item.buycolor == 'green','redfont': item.buycolor == 'red'}" v-html="currency(item.updown)">
             </span>
          </td>
          <td>{{item.solddate}}</td>
          <td class="comfont">
             <span v-if="item.soldprice" class="displaycurr" v-html="currency(item.soldprice)"></span>
          </td>
          <td>
            <span v-if="item.buyselldifference" class="displaycurr" 
               :class="{'greenfont': item.profitlosecolor == 'green','redfont': item.profitlosecolor == 'red'}" v-html="currency(item.buyselldifference)">
             </span>
          </td>
          <td>{{item.soldunit}}</td>
          <td class="comfont">
             <span v-if="item.soldtotal" class="displaycurr" v-html="currency(item.soldtotal)"></span>
          </td>
          <td class="comfont">
             <span v-if="item.transactionfees" class="displaycurr" v-html="currency(item.transactionfees)"></span>
          </td>
          <td class="comfont">
             <span v-if="item.profitlose" class="displaycurr" 
               :class="{'greenfont': item.profitlosecolor == 'green','redfont': item.profitlosecolor == 'red'}" v-html="currency(item.profitlose)">
             </span>
          </td>
          <td class="comfont">
             <span v-if="item.deposite" class="displaycurr" v-html="currency(item.deposite)"></span>
          </td>
          <td class="comfont">
             <span v-if="item.currentbalance" class="displaycurr" v-html="currency(item.currentbalance)"></span>
          </td>
        </tr>
      </template>   
    </v-data-table>
    <infinite-loading v-if="tabing == 0" @infinite="transactiondata"></infinite-loading>
    </div>
    
    <div v-if="tabing == 1">
      <v-row>
        <v-col cols="12" sm="12" md="12" class="text-right pr-5 pl-5">
          <v-select
            item-text="value"
            item-value="id"
            :items="months" 
            @change="dividendval"
            v-model="selectedmonth">
          </v-select>
        </v-col> 
      </v-row>
      <v-data-table :headers="divheaders" :items="itemsWithSno" :search="search"  hide-default-footer  disable-pagination>
        <template v-slot:item="{ item }"  >
         <tr>
          <td>{{ item.sno }}</td>
          <td class="text-left">
            <div class="mt-3 mb-2">
              <router-link class="anchorcust" :to="{name: 'player', params:{ id: item.player_id }}" > 
                <v-avatar><img :src="item.playerimg" :alt="item.player_name"></v-avatar>  
                <span class="playername">{{ item.player_name }}</span>
              </router-link>  
            </div>
          </td>
          <td class="comfont text-center">${{ item.amount }}</td>
         </tr>
       </template> 
        <template v-slot:body.append>
        <tr>
          <td class="text-right" colspan="2">Total Amount</td>
          <td  class="text-center">${{ totalamount }}</td>
        </tr>
      </template> 
      </v-data-table>
      
    </div>  
    <div v-if="tabing == 2">
       <div v-if="historylist">
           <v-data-table :headers="headers" :items="itemsWithSno" hide-default-header hide-default-footer class="elevation-1">
              <template v-slot:item="{ item }"  >
              <tr>
                <td>{{ item.sno }}</td>
                <td>{{ item.display }}</td>
                <td>
                  <div class="displaycurr">
                  <span class="">Buy {{ item.units }} Unit<span v-if="item.units > 1">s</span></span>  
                  <span class="displaycurr" v-html="currency(item.sellprice)"></span> &nbsp; 
                    &nbsp; = &nbsp;<span class="displaycurr" v-html="currency(item.amount)"></span>
                  </div>  
                </td>
              </tr>
            </template>   
            </v-data-table>
        </div>  
     </div>
   <v-overlay color="white" :value="overlay">
      <v-progress-circular  indeterminate color="themebtn" size="80"></v-progress-circular>
    </v-overlay>
    </div>
  </div>
</template>

<script>
export default {
  name: "transactionhistory",
  data() {
    return {
      error: false,
      errorText:'',
      page:1,
      tabing:0,
      overlay: false,
      desserts:[],
      search:'', 
      readonly:false,
      historydata:[],
      historylist:[],
      days:[7,15,30,60,90,365],
      defaultday:7,
      totalamount:0,
      months:[],
      selectedmonth:''
    };
  },
  mounted() {
     this.getHistory();   
     //this.investmentlist();
     //this.transactiondata();
  },
  computed: {
     headers(){
        let headers = [
              //{ text:"#", value: "sno" },
              //{ text:"Image",value: "image",sortable: true},
              { text:"Name",value:"name"},
              { text:"Type",value:"date", sortable: false},
              { text:"Buy Date",value:"dividend_paid", sortable: false},
              { text:"Buy Price",value:"current_price", sortable: false},
              { text:"Buy Units",value:""},
              { text:"Total",value:""},
              { text:"Current Price",value:""},
              { text:"Up / Down",value:""},
              { text:"Current Market Value",value:""},
              { text:"Sold Date",value:""},
              { text:"Sold Price",value:""},
              { text:"Buy - Sell Difference",value:""},
              { text:"Sold Units",value:""},
              { text:"Total",value:""},
              { text:"Transaction Fee",value:""},
              { text:"Profit / Loss",value:""},
              { text:"Deposit",value:""},
              { text:"Current Balance",value:""},
         ]
         return headers
      },
      email() {
      return this.$store.getters.email;
    },
    divheaders(){
         let headers = [
              { text:"#", value: "sno", width:"5%" },
              { text:"Player",value: "player_name",sortable: true,width:"80%"},
              { text:"Dividend Price", value:"amount",width:"15%"},
         ]
         return headers
    },
      sellheaders(){
        let headers = [
              { text:"#", value: "sno" },
              { text:"Player",value: "player_name",sortable: true},
              { text:"Purchased Unit(s)",value:"units"},
              { text:"Purchase Date",value:"date", sortable: false},
              { text:"Purchase price",value:"dividend_paid", sortable: false},
              
              { text:"Sold price",value:"current_price", sortable: false},
              { text:"Transaction Fee",value:"transaction_fee", sortable: false}
         ]
         return headers
      },
       itemsWithSno() {
           return this.desserts.map((d, index) => ({ ...d, sno: index + 1,keyval:index }))
       },
  },
  
  methods: {
    getHistory(){
        this.$store.dispatch("getTransactionHistoryTop",{days:this.defaultday}).then((response) => {
           this.historydata = response.data;
        });
    },
    tabchange(){
       this.page = 1;
       this.overlay = true;
       this.desserts = [];
        
       if(this.tabing == 0){
         //this.transactiondata();
       }
       else if(this.tabing == 1){
         this.dividend(); 
       }
       else{
         this.getallhistory(); 
       }
    },
    transactiondata($state){
       this.$store.dispatch("getallTransctiondata",{page:this.page}).then((result) => {
        
          if(result.data.data.length > 0)
          {
           this.desserts.push(...result.data.data);
           this.page += 1;
           $state.loaded();
          } 
          else
          {
            $state.complete();
          }
           this.overlay = false;
        }); 
    },
    getallhistory(){
        this.$store.dispatch("getallTransction").then((result) => {
           this.desserts = result.data;
           this.overlay = false;
        })
    },
    dividendval(){
        this.overlay = true;
        this.dividend();
    },
    dividend(){
        var sendmonth = '';
        if(this.selectedmonth != '')
        {
           var key = this.selectedmonth-1;
           sendmonth = this.months[key].value;
        } 
        this.$store.dispatch("getDividendhistory",{"selectedmonth":sendmonth}).then((result) =>{
             if(result.data.userdata)
             {
              this.desserts      = result.data.userdata;
             this.totalamount   = result.data.payableamount;
             this.months        = result.data.monthsarray;
             this.selectedmonth = result.data.selectmonth;
             }
             this.overlay = false;  
        }) 
    },
    investmentlist(){
        this.$store.dispatch("getInvestmentList").then((result) => {
           var list = result.data;
           this.desserts = list;
           this.overlay = false;
        });
    },
    selllist(){
        this.$store.dispatch("getsellList").then((result) => {
           var list = result.data;
          if(list.length > 0){           
            for(var i=0; i<list.length;i++){
              var per = 100-this.$store.getters.getcurrencySetting.paypal_fee;
              list[i].transaction_fee = 0;
              list[i].transaction_fee = list[i].current_price*list[i].units*(100/per - 1);
            }
          }
           this.desserts = list; 
           this.overlay = false;
        });
    }
  }
};
</script>
<style scopped>
.historycontent {padding: 10px 10px;}
.tophead {font-size: 18px;font-family: Lexend;font-weight: 500;}
.balancediv {box-shadow: 0 0 3px 0px #ccc;display: flex;justify-content: space-between;padding: 5px 15px;align-items: center;border-radius: 10px;margin-top: 10px;}
.leftpart {display: flex;justify-content: space-between;}
.leftfirst {	background: #ccc;border-radius: 50%;	padding: 7px;width: 35px;height: 35px;	margin-right: 15px;}
.leftsecond{line-height:35px;}
.leftfirst span{color: #247cda;font-size: 22px;margin-right: 20px;}
.historycontent-sub {flex: 0 49%;}
.historycontent{display:flex;justify-content:space-between;}
.cusselect {padding-top: 0;margin-top: 0;}
span.md-6 {
    flex: 0 0 50%;
}
/*.makefullwidth table{width: max-content !important;} */
.makefullwidth table th, .makefullwidth table td, .makefullwidth table td span{font-size: 11px !important;}
.makefullwidth table th{vertical-align: top;}
.displaycurr.greenfont{color: green;}
.displaycurr.redfont{color: red;}
</style>