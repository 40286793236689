<template>
  <div id="livescorepage">
    <v-container class="fill-height" fluid>
      <div v-if="sportstab.length == 0">
          {{showmessage}}
      </div>
      <div class="overall-layout-box" v-else>
        <v-tabs v-model="sportid" color="themebtn">
          <v-tab v-for="(value,key) in sportstab" @change="changesports(key)" :key="value.name">
            <div>{{ value.name }} <img class="sideicon livescore" :src="value.web_icon"></div>
            <div class="lookdifferent" v-if="value.smallname == 'ncaa-basketball'">Basketball</div>
            <div class="lookdifferent" v-if="value.smallname == 'ncaa-football'">Football</div>
          </v-tab>
        </v-tabs>
        <div class=" mt-5 allgame-box">
          <slider-frame class="fourbox" v-if="selectsportid != 12" :slides-per-view="3" :breakpoints="{sm: { slidesPerView: 1 }, md: { slidesPerView: 2 }, lg: { slidesPerView: 3 }, xl: { slidesPerView: 3 } }" :infinite-scroll="false">
            <div class="slider d-inline-flex" slot-scope="{ next, prev, scrollToSlide, activeIndex, canScrollNext, canScrollPrev }">
              <button class="mr-2 my-auto button" v-if="canScrollPrev" :disabled="!canScrollPrev" @click="prev">
                <span class="material-icons">arrow_left</span>
              </button>
            <slider-slides>
              <slider-slide v-for="(invalue,keys) in sportsdata[selectsportid]" :key="keys"  class="align-items-center" @click.native="changematch(keys)" @touchend.native="changematch(keys)">
             <div class="gameblock" :class="{'active-slide' : activeIndex === keys,'px-1': activeIndex !== keys}"  :title="getvsteamname(invalue.teamafullname,invalue.teambfullname)" >
               <div class="d-flex settimer" >
                <div class="gamestatus">{{ invalue.gamestatus }}</div>
               <div class="timercount" v-if="hidecountdown && keys == selectedmatchid">{{ formatedCountdown }}</div>
               </div>
               <div v-if="selectsportid != 23">
                  <div class="single-team-info">
                     <div class="team-info-box" :class="invalue.teamacolor">
                       <span><img :src="invalue.teamalogo"></span><span>{{invalue.team_a_name}}</span>
                     </div>
                     <div class="team-value"  :class="invalue.teamacolor">
                         <span class="teamscore">{{invalue.teamascore}} </span>
                         <span :class="invalue.teamadiffcolor" v-if="invalue.showteamprices == 1">
                            <span>${{invalue.teamAactualprice}} &nbsp;( </span>
                              <span v-if="invalue.teamadiffcolor == 'greencolor'">+</span>
                              <span v-else-if="invalue.teamadiffcolor == 'orangecolor'">+</span>
                              <span v-else>-</span>
                              <span>${{invalue.teamadiff}}</span>
                            <span> )</span>
                         </span>
                     </div>
                  </div>
                  <div class="single-team-info">
                    
                     <div class="team-info-box" :class="invalue.teambcolor">
                       <span><img :src="invalue.teamblogo"></span>
                       <span>{{invalue.team_b_name}}</span>
                     </div>
                     <div class="team-value"  :class="invalue.teambcolor">
                        <span class="teamscore">{{invalue.teambscore}}</span>
                        <span :class="invalue.teambdiffcolor" v-if="invalue.showteamprices == 1">
                            <span>${{invalue.teamBactualprice}} &nbsp;( </span>
                              <span v-if="invalue.teambdiffcolor == 'greencolor'">+</span>
                              <span v-else-if="invalue.teambdiffcolor == 'orangecolor'">+</span>
                              <span v-else>-</span>
                              <span>${{invalue.teambdiff}}</span>
                            <span> )</span>
                         </span>
                     </div>
                  </div>
               </div>  
               <div v-else>
                 <div class="single-team-info">
                     <div class="team-info-box" style="display: block; text-align: center; width: 100%; margin-top: 10px;">
                       <div  v-if="selectsportid == 23"><img class="leaguelogo" :src="invalue.leaguelogo"></div>
                       <div style="font-weight: bold;" class="tourname">{{invalue.name}}</div>
                     </div>
                     
                  </div>
               </div>
             </div> 
           </slider-slide>
          </slider-slides>
          <button class="button ml-2 my-auto" v-if="canScrollNext" :disabled="!canScrollNext" @click="next">
           <span class="material-icons">arrow_right</span>
          </button>
        </div>
      </slider-frame>
      <slider-frame v-else :slides-per-view="3" :breakpoints="{sm: { slidesPerView: 1 }, md: { slidesPerView: 2 }, lg: { slidesPerView: 3 }, xl: { slidesPerView: 3 } }" :infinite-scroll="false">
        <div class="slider d-inline-flex tennis" slot-scope="{ next, prev, scrollToSlide, activeIndex, canScrollNext, canScrollPrev }">
           <button class="mr-2 my-auto button" v-if="canScrollPrev" :disabled="!canScrollPrev" @click="prev">
             <span class="material-icons">arrow_left</span>
           </button>
           <slider-slides>
             <slider-slide v-for="(invalue,keys) in sportsdata[selectsportid]" :key="keys"  class="align-items-center" @click.native="changematch(keys)" @touchend.native="changematch(keys)">
              <div class="gameblock"  :title="getvsteamname(invalue.teamafullname,invalue.teambfullname)" >
                
                 <div class="gamestatus">{{ invalue.gamestatus }}</div>
                   <div class="tennis" >
                     <div class="tennies-box">
                       <div class="versusname">{{invalue.match_comment}}</div> 
                        <div class="tennis-row">       
                          <div class="player-name"></div>
                          <div class="player-score">
                            <div class="score-set smallfont"><span v-if="invalue.tennissetplayed >= 1">S1</span></div>
                            <div class="score-set smallfont"><span v-if="invalue.tennissetplayed >= 2">S2</span></div>
                            <div class="score-set smallfont"><span v-if="invalue.tennissetplayed >= 3">S3</span></div>
                            <div class="score-set smallfont"><span v-if="invalue.tennissetplayed >= 4">S4</span></div>
                            <div class="score-set smallfont"><span v-if="invalue.tennissetplayed >= 5">S5</span></div>
                            <div class="score-set smallfont">
                            <span v-if="invalue.gamestatus !='Finished'">P</span>
                         </div>
                         <!-- <div class="score-set smallfont">SW</div>-->
                        </div> 
                       </div>   
                       <div class="tennis-row">                      
                         <div class="player-name">
                            <span class="flag-flex"><img class="countryflag" :src="countryflag(invalue.teamalogo)"></span>
                            <span :class="invalue.teamacolor">{{invalue.team_a_name}}</span>
                            <img :src="serveimage" class="ballserve"  v-if="invalue.ballserveA == 1">
                          </div>
                          <div class="player-score">
                            <div class="score-set text-center" v-for="(teamastats,tkey) in invalue.teamascore.stats" :key="tkey">
                              <span :class="teamastats.color" v-html="scoredisplaying(teamastats.val)"></span>
                            </div>
                            <div class="score-set text-center">{{invalue.teamascore.score}}</div>
                            <!--<div class="score-set text-center">{{invalue.teamascore.set_won}}</div>-->
                          </div>
                        </div>
                        <div class="tennis-row">
                          <div class="player-name">
                              <span class="flag-flex"><img class="countryflag" :src="countryflag(invalue.teamblogo)"></span>
                              <span :class="invalue.teambcolor">{{invalue.team_b_name}}</span>
                              <img :src="serveimage" class="ballserve" v-if="invalue.ballserveB == 1" />
                          </div>
                          <div class="player-score">
                            <div class="score-set text-center" v-for="(teambstats,tkey) in invalue.teambscore.stats" :key="tkey">
                              <span :class="teambstats.color" v-html="scoredisplaying(teambstats.val)"></span>
                            </div>
                            <div class="score-set text-center">{{invalue.teambscore.score}}</div>
                            <!--<div class="score-set text-center">{{invalue.teambscore.set_won}}</div>-->
                          </div>
                        </div>
                      </div>
                    </div>  
                 </div> 
           </slider-slide>
          </slider-slides>
          <button class="button ml-2 my-auto" v-if="canScrollNext" :disabled="!canScrollNext" @click="next">
           <span class="material-icons">arrow_right</span>
          </button>
        </div>
      </slider-frame>
     </div>
      
   <!--
      <div v-if="sportsdata[selectsportid][selectedmatchid].extrastats != ''" class="teetime">
       <div  v-for="(statsval,statkey) in  sportsdata[selectsportid][selectedmatchid].extrastats" :key="statkey">
          <div>{{ statkey}}</div>
          <div v-for="(instatval,instatkey) in statsval" :key="instatkey">
            <div><img class="countryflag" :src="countryflag(instatval.nationality)"></div>
            <div style="margin-left: 5px;">{{ instatval.playername }}</div>
          </div>
       </div>
     </div>
   -->
     <div class="mt-5" v-if="selectsportid != 12"> 
          <div v-if="selectsportid != 23">
            <v-tabs class="tabber mt-10" centered color="themebtn" v-model="teamtabs" v-if="sportsdata[selectsportid][selectedmatchid].teamaplayers.length > 0">
          <v-tab>{{sportsdata[selectsportid][selectedmatchid].teamafullname}}</v-tab>
          <v-tab>{{sportsdata[selectsportid][selectedmatchid].teambfullname}}</v-tab>   
        </v-tabs>  
          </div>
          <div v-if="teamtabs == 0" >
            <div v-if="sportsdata[selectsportid][selectedmatchid].teamaplayers.length > 0">
              <playerlisting :sport="sportsdata[selectsportid][selectedmatchid].sport" :staticgraph="staticgraph" :itemsWithSno="sportsdata[selectsportid][selectedmatchid].teamaplayers" />
          </div>  
          </div>  
          <div v-else>
            <div v-if="sportsdata[selectsportid][selectedmatchid].teambplayers.length > 0">
              <playerlisting :sport="sportsdata[selectsportid][selectedmatchid].sport" :staticgraph="staticgraph" :itemsWithSno="sportsdata[selectsportid][selectedmatchid].teambplayers" />
            </div>  
          </div>  
     </div>
     <div class="mt-10" v-else>
          <div v-if="sportsdata[selectsportid][selectedmatchid].players.length > 0">
                <playerlisting :sport="sportsdata[selectsportid][selectedmatchid].sport" :staticgraph="staticgraph" :itemsWithSno="sportsdata[selectsportid][selectedmatchid].players" />
          </div>     
     </div>  
    </div>         
   </v-container>
    <v-overlay color="white" :value="overlay">
      <v-progress-circular indeterminate color="themebtn" size="80"></v-progress-circular>
   </v-overlay>
  </div>
</template>    
<script>
 
import Playerlisting from '../components/Playerlisting'
import {SliderFrame, SliderSlides, SliderSlide} from 'vue-dynamic-slider';
import * as moment from 'moment'

export default {
    name: 'Livescore',
    components:{ Playerlisting,SliderSlide, SliderSlides, SliderFrame },
    data() {
      return {
          timer:'',
          commentrytimer:'',
          showmessage:'',
          sportstab:[],
          sportsdata:[],
          sportid:0,
          selectsportid:0,
          selectedmatchid:0,
          teamtabs:0,
          overlay:false,
          showslider:false,
          refresh: 0,
          serveimage:require("@/assets/ball-serve.jpg"),
          courtimg:require("@/assets/bg-court.svg"),
          staticgraph:true,
          showcommentry:false,
          getlivecommnetry:false,
          commentrydata:[],
          teamsdata:[],
          commtab:0 ,
          commentrylastloop:1,
          commentrylasttime:0,
          countdown: 1, // 5min
          missdata: [],
          makedata: [],
          scatteroptions:'',
          showpitchmap:false,
          hidecountdown:false,
          groundlogo:'',
          singleEntryLoop:0,
          thirdTimeout:'', 
          secondTimeout:'',
          firstTimeout:'',
      };
    },
    created() {
        this.fetchMatchesList();
        this.overlay = true;
        this.timer          = setInterval(this.fetchMatchesList, 20000);
        this.staticgraph = true;
        
    },
    computed: {
    formatedCountdown() {
      return moment.duration(this.countdown, 'seconds').format('m:ss');
    },
  },
    methods:{
      scoredisplaying(score)
      {
         if(typeof score != "undefined")
         {
          var splitval = score.split(".");
  
         if(splitval.length > 0)
         {
           if(splitval.length > 1)
           {
             score = splitval[0]+"<sup>"+splitval[1]+"</sup>"; 
           } 
           else
           {
             score = splitval[0];
           }
         } 
        }
         
         return score;
      }, 
      countryflag(name)
       {
         if(name == '')
         {
           return require("@/assets/world.png"); 
         }
         else
         {
           return 'https://flagsapi.com/'+name+'/shiny/64.png';
         } 
       },
       opengamesite(game){
          window.open("https://www.espn.com/"+game+"/", "_blank");
       },
       getvsteamname(teama,teamb){
         return teama+" Vs "+teamb;
       },
       changematch(key){
          this.selectedmatchid = key;
          this.teamtabs = '';
          this.hidecountdown = false;
         
          
       },
       changesports(key){
          this.selectsportid = key;
          this.selectedmatchid = 0;
          this.teamtabs = '';
          this.showcommentry = false;
          this.hidecountdown = false;
         
       }, 
       async fetchMatchesList () 
       {
          this.$store.dispatch("getLiveMatches").then((response) => 
          {
             if(response.message == 'success')
             {
                this.sportstab  = response.data.sports;
                this.sportsdata = response.data.matches;
                if(this.selectsportid == 0)
                {
                    this.selectsportid = response.data.firstsports;
                  
                }
                  this.refresh++;
             } 
             else
             {
                 this.showmessage = "No Live Matches";

             }
             this.overlay = false
          });  
        },
       
        cancelAutoUpdate () {
            clearInterval(this.timer);
            clearInterval(this.commentrytimer);
        }  
    },
    beforeDestroy () {
      this.cancelAutoUpdate();
    }
}
</script>
<style scoped>
.basketb-comm-head{display: flex;align-items: center; padding: 3px; border-bottom: 1px solid #f1f1f1;}
.basketb-comm-head.underline{width: 98%; }
.underline {
    border-bottom: 1px solid #ccc;
}
.time {
    flex: 0 0 8%;
    text-align: center;
}
.playbyplay {
    flex: 0 0 80%;
    display: flex;
    align-items: center;
}
.gamescore {
    flex: 0 0 5%;
    text-align: center;
}
.bolder{font-weight: bold;}

.commentry{width: 82%; margin: auto; margin-top: 15px; font-size: 13px;}
.scrollable {
    overflow-y: scroll;
    height: 180px;
}
.matchblocks {border: 1px solid #ccc; padding: 5px;cursor: pointer; width: auto; display: inline-block;}
.teamsname {font-size: 14px;}
.teamscomment {font-size: 13px;}
.single-team-info {display: flex;flex-wrap: wrap; align-items: center;justify-content: space-between;}
.team-info-box{display: flex;flex-wrap: wrap; align-items: center;}
.single-team-info .team-info-box img{  max-width: 90%; padding: 2px 0px;max-height: 30px;width:auto;margin:auto;}
/*.single-team-info .team-info-box > span:first-child{margin-right: 10px;}*/
/*.allgame-box{display: flex;align-items: center;}*/
/*.allgame-box  .gameblock{margin-right: 15px;    box-shadow: 0 0 2px 3px #ddd;
    padding: 10px 15px; flex: 0 0 23%;}*/
.overall-layout-box{width:100%;}
.gameblock{cursor: pointer;}
.single-team-info .team-info-box > span:first-child { margin-right: 10px; width: 50px;height:30px;text-align: center; overflow: hidden;}
.comments{font-size: 10px;margin-top: 3px;}
.allgame-box{position: relative;}
.allgame-box .slider .gameblock{-webkit-box-shadow: 0 0 2px 3px #ddd;box-shadow: 0 0 2px 3px #ddd;  padding: 6px 3px; -webkit-box-flex: 0; -ms-flex: 0 0 23%;margin: 2px auto; max-width: 95%;}
.allgame-box .slider{width: 100%;display: block !important;}
.allgame-box .tennis.slider .gameblock{min-height: 135px;}
.allgame-box .slider .gameblock{min-width:235px;}
.allgame-box .fourbox .gameblock{min-width:230px;}
.allgame-box .slider > button.my-auto.button.mr-2{position: absolute;left:0px;z-index:9999;background: white;padding-left: 10px;height: 100%;top:0px;}
.allgame-box .slider > button.my-auto.ml-2{position: absolute;right:0px;z-index:9999;background: white;padding-left: 10px;height: 100%;top:0px;}
.allgame-box .slider .dynamic-slider{width:85%;margin: auto;}
.dynamic-slider .dynamic-slider-slide{vertical-align: middle;}
.team-value{font-size: 14px;}
.team-info-box span{font-size: 13px;}
.gray{color:gray}
.gamestatus {font-size: 13px;padding-left: 5px;}
/* tennis*/
.tennis-row {display: flex;justify-content: initial;align-items: center;}
.player-name {flex: 0 0 45%;font-size: 13px;display: flex;align-items: center;}
.player-score {display: flex;justify-content: space-between; align-items: center;flex: 0 0 55%;}
.score-set{font-size: 13px; flex: 0 0 16.6%;}
.versusname{font-size: 12px; margin-top: 5px; margin-bottom: 5px; text-align: center;}
.flag-flex{margin-right: 2px; width: 25px; height: 25px; text-align: center; overflow: hidden;}
.flag-flex img{max-width: 90%;padding: 2px 0px;max-height: 30px;width: auto;margin: auto;}
.score-set span.bold{font-weight: bold; font-size: 13px;}
span.ballserve {margin-left: 4px;}
.ballserve {width: 15px;height: inherit;margin-left: 5px;}
.score-set.smallfont {font-size: 11px;text-align: center;color: gray;}
.tourname {font-size: 13px;}
.lookdifferent {position: absolute;bottom: 0px;text-align: center;}
.dynamic-slider-slides .greencolor,.dynamic-slider-slides .redcolor,.dynamic-slider-slides .orangecolor{ display: inline-block;width: 74px;}
.dynamic-slider-slides .greencolor span{color:green;font-size: 10px;}
.dynamic-slider-slides .redcolor span{color:red;font-size: 10px;}
.dynamic-slider-slides .orangecolor span{color:orange;font-size: 10px;}
span.teamscore {margin-right: 5px;text-align: center;width: 80px;display: inline-block;}
.sideicon.livescore {width: 14px;margin-right: 5px;margin-left: 2px;}
img.commlogo {
  width: 20px;
    height: 20px;
}
.timercount {
    background: black;
    color: #e12a2a;
    font-weight: bold;
    text-align: center; 
    border-radius: 5px;
    flex: 0 0 25%;
    margin-left: auto;
}
.boldit{font-weight: bold;}
.teetime{display: flex; flex-wrap: wrap; margin-top: 10px;}
.teetime .countryflag{width: 20px;}
.teetime > div{flex: 0 0 18%;border: 1px solid #ccc;
    padding: 5px;
    margin: 5px;
    font-size: 13px;}
.teetime > div > div{display: flex;}
.single-team-info .team-info-box img.leaguelogo{max-width: 90%;
    padding: 2px 0px;
    max-height: 45px;
    width: auto;
    margin: auto;}
/*.pitchmap{   height: 350px; width: 450px; margin:auto; background: url('https://secure.espncdn.com/redesign/assets/img/nba/bg-court.svg') no-repeat;}*/
</style>
<style >

@media(max-width:767px){
   .allgame-box .slider .gameblock{
     max-width: 90%;
   }
   .removepads.left.text-left {
      position: absolute;
        left: 0!important;
  }
  .removepads.right.text-right {
    position: absolute;
    right: -8px !important;
  }
}
</style>