<template>
  <div id="home">
    <v-container class="fill-height" fluid>
        <div class="col-md-8 col-sm-8 col-xs-12 greetingclass">
           <div class="uppernew">
             <div class="wishuser" v-bind:style="{ 'background-image': 'url(' + wishimage + ')' }">
             <div class="wishdata">
                <div class="wishtitle">
                  {{greeting}}, {{ name }}
                </div>
               
             </div>
           </div>  
           </div>
        </div>  
        <div class="col-md-4 col-sm-4 col-xs-12 bankbtns">
           <div class="transaction" v-bind:style="{ 'background-image': 'url(' + transimage + ')' }">
              <div class="transdata">
                 <div class="firstline">
                    <span class="contentdata"><span class="displaycurr" v-html="currency(getbalance)"></span></span>
                    <div class="tagline">DamnN Sport Bank Balance</div>
                 </div>
                 <div class="actionbtn">
                    <v-btn color="primary text-center" class="actions transparent" @click="opendewithdrawmodal">Withdraw</v-btn>
                    <v-btn color="themebtn text-center" @click="opendepositemodal" class="actions">Deposit</v-btn>
                 </div>
              </div>
           </div>
        </div>  
        <v-container class="homecontainer">
         <div class="d-flex overallbox">
           <div class="calcus-box">
               <div class="value-set"><animated-number :value="todayunits" :formatValue="formatToUnit" :duration="2500"  /></div>
               <div class="labelset">Sports Units Sold: (Last 24hrs)  </div>
          </div>
           <div class="calcus-box">
               
               <div class="value-set"><animated-number :value="todayprice" :formatValue="formatToPrice" :duration="2500"  /></div>
               <div class="labelset"> Market Trading: (Last 24hrs)</div> 
          </div>
          <div class="calcus-box">
               <div class="value-set"><animated-number :value="weeklyunits" :formatValue="formatToUnit" :duration="2500" /></div>
               
               <div class="labelset">Sports Units Sold: (Last 52 weeks)</div>
          </div>
          <div class="calcus-box">
               <div class="value-set"><animated-number :value="weeklyprice" :formatValue="formatToPrice" :duration="2500"  /></div>
               <div class="labelset">Market Trading: (Last 52 weeks)</div>
          </div>
           <div class="calcus-box">
               <div class="value-set"><animated-number :value="dividendpay" :formatValue="formatToPrice" :duration="2500"  /></div>
               <div class="labelset">Monthly Dividend</div>
          </div>
         </div>
         <h3 class="chooseheading">Today's top gainers</h3>
         <carousel v-if="loadedowl" class="slider_row" :autoplay="true" :dots="false" :nav="false" :margin="10" 
           :responsive="{200:{items:1,nav:false},600:{items:1,nav:false},650:{items:2,nav:true},768:{items:2,nav:true},1024:{items:3,nav:true}}">
           <!--<template slot="prev"><span class="prev">prev</span></template>-->
           <div v-for="(value,index) in playerlist" @click="gotoplayer(value.id,value.type)" :key="index" class="slider_items d-flex align-items-center" > <!--v-bind:style="{ 'background-image': 'url(' + playerBackimg + ')' }"-->
             <div class="left_col">
               <div class="img_wrapper d-flex align-items-center justify-content-center" v-bind:style="heroImage">
                 <a class="bg_pink"><img :src="value.thumbnail_image" alt="Trader One"></a> 
                 <span class="bg_pink d-inline-flex align-items-center justify-content-center"></span>
               </div>
             </div>
             <div class="right_col flex-grow-1">
               <h5><a>{{value.name}}</a></h5>
               <p>{{value.nationality}}</p>
               <div class="price_line d-flex align-items-center">
                 <div class="price"><p ><span class="displaycurr" v-html="currency(value.price)"></span></p></div>
                 <div class="rate">
                      <p class="d-flex"><span>+ &nbsp;</span><span class="displaycurr" v-html="currency(value.twnty_four_Hrs.percentage_difference)"></span> <i class="fas fa-caret-up"></i></p>
                 </div>
               </div>
             </div>
            </div>
            <!--<template slot="next"><span class="next">next</span></template>-->
          </carousel>
         
           <h3 class="chooseheading mt-5">My Portfolio</h3>
            <div class="totalvaldiv">
       
       <div class="sameline">
           <div class="totaltophead">Todays Market Value</div>
           <div class="totaltopbody">
             <span class="displaycurr justifycontent" v-html="currency(maindata.total_market_value)"></span>
           </div> 
           <div class="totaltophead diffmarket">Yesterdays Market Value</div>
           <div class="totaltopbody diffmarket">
             <span class="displaycurr justifycontent" v-html="currency(maindata.yestotalmarketvalue)"></span>
           </div> 
       </div>
       <div class="sameline">
         <div class="totaltophead">Total Cost</div>
         <div class="totaltopbody ">
            <span class="displaycurr justifycontent" v-html="currency(maindata.total_cost)"></span>
         </div> 
       </div>
        <div class="sameline">
         <div class="totaltophead">Todays Profit/Loss</div>
         <div class="totaltopbody ">
            <span class="displaycurr justifycontent">
              <span v-if="maindata.profitlostcolor == 'green'" class="material-icons green--text">arrow_upward</span>
              <span v-if="maindata.profitlostcolor == 'red'" class="material-icons red--text">arrow_downward</span>
              <span class="d-flex" v-html="currency(maindata.profitlose)"></span>
            </span>
         </div> 
         <div class="totaltophead diffmarket">Yesterdays Profit/Loss</div>
         <div class="totaltopbody diffmarket">
            <span class="displaycurr justifycontent diffmarket">
             
              <span class="d-flex" v-html="currency(maindata.yesterdayprofitlose)"></span>
            </span>
         </div> 
       </div>
       <div class="sameline single-data">
         <div class="totaltophead">Withdrawl</div>
         <div class="totaltopbody ">
            <span class="displaycurr justifycontent" v-html="currency(getbalance)"></span>
         </div> 
       </div>
       <div class="sameline single-data cursorhand"  @click="filterdata('player')">
         <div class="totaltophead">Players</div>
         <div class="totaltopbody">{{maindata.total_players}}</div>
       </div>
       <div class="sameline single-data cursorhand" @click="filterdata('team')">
         <div class="totaltophead">Teams</div>
         <div class="totaltopbody" >{{maindata.total_teams}}</div>
       </div>
    </div>
           <div class="d-flex pricechart mt-5 mb-5">
             <div class=""><!--<span class="displaycurr" v-html="currency(totalvalue)"></span>--></div>
             <div class="selector text-right">
               <v-select
                 class="ma-0 pa-0 selectbox"
                 v-model="pointchartdays"
                 :items="daysitem"
                 item-text="name"
                 item-value="abbr"
                 @change="changedata('pointschart')"
                 outlined
                 hide-details></v-select>
            </div>
           </div>  
           <div class="chartdiv mt-5">
             <div class="legendata">
                <div class="row d-inline-flex text-right">
                  <div class="col-sm-12 col-md-12 pt-1 pb-5  d-inline-flex">
                    <div class="pxblock" style="display:none">
                      <div class="colorbox"></div><div class="legendname">Player</div> 
                    </div>
                    <div class="tmblock team" style="display:none">
                      <div class="colorbox"></div><div class="legendname">Team</div> 
                    </div>
                  </div>
                </div>
              </div>
             <canvas id="pointschart"  ref="pointschart" style="display:none"></canvas>
             <line-chart chart-id="pointschart" :width="480" :height="240"  v-if="loaded" :chart-data="pointschartdata"  :options="pointsoption"></line-chart>
          </div>
        </v-container>
        <!--<gamelist></gamelist>-->
    </v-container>
  </div>
</template>
<script>
//import Gamelist from '../components/Gamelist.vue';
import carousel from 'vue-owl-carousel'
import Chart from 'chart.js'
import LineChart from './LineChart.js'
import AnimatedNumber from "animated-number-vue";

export default {
  name: "Home",
  components:{ /*Gamelist,*/carousel,LineChart,AnimatedNumber},
  data() {
    return {
      gameList:[],
      wishimage:require("@/assets/dashboard1.png"),
      transimage:require("@/assets/dashboard2.png"),
      playerBackimg: require('@/assets/line.png'),
      playerlist:[],
      loaded:false,
      loadedowl:false,
      totalvalue:0,
      type:"line",
      pointchartdays:30,
      pointschartdata:{},
      maindata:[],
      dividendpay:0,
      daysitem:[
          { name: '1 Day', abbr: '1' },
          { name: '7 Days', abbr: '7' },
          { name: '30 Days', abbr: '30' },
          { name: '90 Days', abbr: '90' },
          { name: '180 Days', abbr: '180' },
          { name: '365 Days', abbr: '365' },
          
      ],
      todayprice:0,
      todayunits:0,
      weeklyprice:0,
      weeklyunits:0,
    }; 
  },  
  computed:{
    heroImage() 
    {
      var bgImg = require('@/assets/circle_one.png');
      return {
         "background-image": 'url('+bgImg+')'
      };
    },
    greeting(){
      var greet = "Good Morning"; 
      var ndate = new Date();
      var hr = ndate.getHours();
      
      if (hr < 12)
        greet = 'Good Morning';
      else if (hr >= 12 && hr <= 17)
        greet = 'Good Afternoon';
      else if (hr >= 17 && hr <= 20)
        greet = 'Good Evening';
      else
        greet = 'Good Night';  

       return greet; 
    },
    name() {
      return this.$store.getters.name.split(" ")[0];
    },
    getbalance() {
      return this.$store.getters.balance;
    },
  },
  
  mounted(){
     this.pointchartdays = { name: '7 Days', abbr: '7' };
      this.gradientss = this.$refs.pointschart.getContext("2d").createLinearGradient(0, 0, 0, 450);  
      this.gradientss2 = this.$refs.pointschart.getContext("2d").createLinearGradient(0, 0, 0, 450);

      this.gradientss.addColorStop(0, "rgba(255, 0,0, 0)");
      this.gradientss.addColorStop(0.5, "rgba(255, 0, 0, 0)");
      this.gradientss.addColorStop(1, "rgba(255, 0, 0, 0)");

      this.gradientss2.addColorStop(0, "rgba(36, 124, 221, 0.8)");
      this.gradientss2.addColorStop(0.5, "rgba(36, 124, 221, 0.5)");
      this.gradientss2.addColorStop(1, "rgba(36, 124, 221, 0)");

      this.dashboardPlayer();
      this.dashboarddata();
      this.pointschart();
      this.getList();
  },
  methods:{
    formatToPrice(value) {
      return `$${value.toLocaleString("en-US")}`;
    },
    formatToUnit(value) {
      return `${value.toLocaleString("en-US")}`;
    },
    dashboarddata(){
       this.$store.dispatch("dashboardData").then((response) => {
          this.todayprice  = response.data.data.todayprice;
          this.todayunits  = response.data.data.todayunits;
          this.weeklyprice = response.data.data.weekprice;
          this.weeklyunits = response.data.data.weekunits;
          this.dividendpay = response.data.data.dividendpay;
       }); 
    },
    getList(){
       this.$store.dispatch("getPortfolio").then((response) => {
          this.desserts = response.data.players;
          this.maindata = response.data;
       });
     },
    changedata(chartname) 
    {
        this.pointChartData  = '';
        if(chartname == 'pointschart')
          this.pointschart();
    },
    async pointschart(){
     
       var pointchartdays = "";
       
       if(typeof this.pointchartdays.abbr == "undefined")
          pointchartdays = this.pointchartdays;
       else
          pointchartdays = this.pointchartdays.abbr;
          
       /**/
       this.loaded = false;
       this.pointsoption = {
                            responsive: true,
                            bezierCurve: false,
                            lineTension: 10,
                            legend: { display: false},
                          
                            scales: {
                                      xAxes: [{
                                                gridLines: {
                                                  display: false,
                                                  
                                                },
                                              }],
                                      yAxes: [{
                                                gridLines: {
                                                      borderDash: [8, 8],
                                                      color: "rgba(1, 1, 1, 0.1)",
                                                      zeroLineColor: '#fff'
                                                  },
                                                    
                                                    ticks: {
                                                      beginAtZero: true,
                                                      padding: 25,
                                                      callback: function(value) {
                                                          return  value;
                                                      }
                                                    },
                                               }]
                                     },
                       };
       /**/ 
      await this.$store.dispatch("dashboardpointsChart",{"days":pointchartdays}).then((response) => 
       {
         this.totalvalue = response.data.total_value;
        
         this.pointschartdata = {
               labels: response.data.x,
                  datasets: [
                             
                              {
                                label: "PX",
                                data: response.data.y,
                                backgroundColor: this.gradientss2,
                                borderColor: "#247CDD",
                                borderWidth: 0.8,
                                borderCapStyle:'Yes',
                                radius: 2,
                                
                              }
                            ]
         
      };
       this.loaded = true;
      const ctx = document.getElementById('pointschart');
      new Chart(ctx, this.pointschartdata);
       });
         
    },
    gotoplayer(id,type){
       if(type == "player")
         this.$router.push('player/'+id);
       else
         this.$router.push('team/'+id);  
    },
    dashboardPlayer(){
      this.$store.dispatch("getDashboardPlayer",{"limit":10,"type":"gainer"}).then((response) => 
      {
           if(response.players.length > 0)
           {
             this.playerlist = response.players;
             this.loadedowl = true;
           } 
      });
    },
    opendepositemodal(){
      this.$root.$refs.Master.opendeposit();
    },
    opendewithdrawmodal(){
      this.$root.$refs.Master.openwithdraw();
    }
  } 
};
</script>
<style scoped>
.slider_row .slider_items {
    cursor:pointer;
    padding: 15px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: left bottom;
    width: 98%;
    margin: auto;
}
.container.fill-height > .row {
  max-width: initial;
}
.wishuser {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 75%;
    height: 130px;
}
.wishdata {
    width: 53%;
    margin-left: 3%;
    padding-top: 5.5%;
    color:#fff;
}
.wishcontent{
font-family: Lexend;
font-weight: 500;
font-size: 12px;
line-height: 18px;
font-style: normal;
padding-top: 10px;}

.wishtitle{font-family:Lexend;font-weight:600;font-size:24px;}
.transaction{
   background-repeat: no-repeat;
background-position: center;
background-size: 100%;
height: 130px;
background-size: 100% 100%;
border-radius: 29px;
}

.transdata {
    width: 92%;
margin-left: 5%;
padding-top: 2%;
}
.contentdata {
    display: inline-block;
    vertical-align: super;
    font-size: 22px;
    color: white;
    margin-top:5px
}
.tagline {
    font-size: 13px;
    color: white;
    font-family: Lexend;
    font-weight: 500;
}
.actionbtn .actions{
    color: #fff;
height: 45px;
font-family: Lexend;
font-size: 14px;
font-weight: 500;
-webkit-box-shadow: none;
box-shadow: none;
text-transform: capitalize;
margin-right: 10px;
width: 130px;
}
.actionbtn {
    margin-top: 5px;
    
}
.actions.transparent {
    background: rgba(255, 255, 255, 0.15) !important;
 
}
.uppernew{background:linear-gradient(87.42deg, #247CDD 4.23%, #9F24FF 98.92%); border-radius:30px;}
.slider_row .slider_items .right_col {
    margin-left: 10px;
}
.slider_row .slider_items .left_col .img_wrapper {
    width: 140px;
    height: 140px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    position: relative;
}
.align-items-center {
    align-items: center !important;
}
.justify-content-center {
	justify-content: center !important;
}
.slider_row .slider_items .left_col .img_wrapper a {
    text-decoration: none;
    display: inline-block;
    width: 90px;
    height: 90px;
    border: 2px solid #ffffff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
}
.slider_row .slider_items .right_col h5 a {
    text-decoration: none;
    display: inline-block;
    font-family: "Rubik", sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 1.5;
    color: #000;
    margin-top: 5px;
}
.slider_row .slider_items .right_col p {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.667;
    color: #000;
    margin: 7px 0px;
}
 .slider_items .right_col .price_line .price p {
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.889;
    color: #000;
}
.slider_row .slider_items .left_col .img_wrapper .bg_purple {
	background-color: #c85ef4;
}
.slider_row .slider_items .left_col .img_wrapper span {
	display: inline-block;
	position: absolute;
	bottom: 13%;
	left: 50%;
	-webkit-transform: translate(-50%, -13%);
	-ms-transform: translate(-50%, -13%);
	transform: translate(-50%, -13%);
	min-width: 50px;
	font-family: "Open Sans", sans-serif;
	font-size: 12px;
	font-weight: 700;
	line-height: 1.667;
	color: #000;
  background-color: #2a295b;
	text-align: center;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	-ms-border-radius: 20px;
	border-radius: 20px;
}
.slider_row .slider_items .left_col .img_wrapper .bg_pink {
    background-color: #fff;
}
.slider_row .slider_items .left_col .img_wrapper a img {
    max-width: 100%;
    display: inline-block;
    position: relative;
    bottom: -5px;
}
.slider_row .slider_items .right_col .price_line .rate p {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.714;
    color: #1fa02a;
    margin-left: 40px;
}
.pricechart {
    justify-content: space-between;
    align-items: center;
}
.pricechart .selectbox{width:40%; display:inline-block;}
@media(max-width:1299px){
  .container.homecontainer{max-width: 100%;}
}
@media(max-width:1023px){
  .actionbtn .actions{height: inherit; width: auto; padding: 10px;}
}
@media(max-width:767px){
  
  .wishuser{background-position:bottom;}
  .wishdata{padding-top: 20px;}
  .wishtitle{line-height: 26px;}
  .slider_row .slider_items{width:100%;}
  .wishdata{width: 56%;}
  .overallbox{flex-wrap: wrap; justify-content: center;}
  .calcus-box{flex: 0 0 47%;}
}

@media(max-width:750px){
  .greetingclass {flex: 0 0 55%;}
  .bankbtns {   flex: 0 0 45%;    max-width: 45%;}
  .wishdata[data-v-fae5bece] {  width: 90%;}
}
@media(max-width:649px){
  .slider_row .slider_items{width: 100%;margin: auto;width: auto;max-width: 400px;}
}
@media(max-width:600px){
  .greetingclass{flex: 0 0 100%;}
  .bankbtns{ flex: 0 0 100%; max-width: 100%;}
}
</style>
<style>
.slider_row .owl-nav{display:none !important;}
.slider_row .cursym img{width:20px !important;}
.tmblock.team .colorbox{background:#00FF00}
.calcus-box > div.labelset { font-size: 11px;text-align: center;color:#fff;}
.value-set, .value-set span { font-size: 31px; text-align: center; margin-top: 10px; color: #FFF;}
.calcus-box {  margin-bottom: 15px;  background: linear-gradient(87.42deg, #247CDD 4.23%, #9F24FF 98.92%);flex: 0 0 19.2%;margin-right: 10px;align-items:center ;border-radius:15px;padding: 10px 10px;}
</style>