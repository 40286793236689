<template>
  <div id="damnnpx">
    <div>
     <div>
       
      <v-data-table :headers="headers" :mobile-breakpoint="0" :items="itemsWithSno"   hide-default-footer  disable-pagination fixed-header
      :height="windowheight" >
      <template v-slot:header.line="{ header }">
    {{ header.text }} 
          <span style="display: flex;">
             <span>$</span>
             <span style="display: flex;">
              <span class="material-icons topheads green--text">arrow_upward</span>/
              <span class="material-icons topheads red--text">arrow_downward</span>
             </span>
          </span> 
    
  </template>
       <template v-slot:item="{ item }"  >
         <tr>
          <td :class="item.team_status"><span>{{ item.sno }}</span></td>
          <td class="text-left">
            <div class="mt-3 mb-2">
              <router-link class="anchorcust" :to="{name: 'team', params:{ id: item.team_id }}" >
               <v-avatar class="cusavatar"><img :src="item.team_logo"   :alt="item.team_name"></v-avatar>  
               <span class="playername">{{ item.team_name }}</span>
              </router-link>
            </div>
          </td>
           <td v-if="showthiscoumn" class="text-left teamvslogo pl-0">
            <div class="d-flex samelinediv">
              <div v-if="item.line" style="padding-right: 16px;">Vs</div>
              <div class="mt-3 mb-2" v-if="item.line">
                <router-link class="anchorcust" :to="{name: 'team', params:{ id: item.teamvsid }}" >
                 <v-avatar><img :title="item.teamvsname" :src="item.teamvslogo" :alt="item.teamvsname"></v-avatar>  
                </router-link>
              </div>  
            </div>
          </td>
          <td v-if="showthiscoumn" class="nopadd text-center">
             <span v-html="item.prediction" :class="{'green--text' : item.ext_line == 'W' && item.prediction != 'Draw',
             'red--text'  : item.ext_line == 'L' && item.prediction != 'Draw',
             'orange--text'    : item.prediction == 'Draw'}"></span>
          </td>
          <td v-if="showthiscoumn" class="different text nopadd text-center">
            <span :class="item.ext_color" v-if="item.line">
             <span v-if="item.ext_line == 'W'">+</span><span v-else>-</span> {{item.line}}
            </span>
          </td>
           <td class="comfont text-center graphimg new text-center">
            <span v-if="item.color != '' ">
               <img v-if="item.color == 'green'" :src="getimage('green')">
               <img v-else-if="item.color == 'red'" :src="getimage('red')">
               <img v-else :src="getimage('orange')">
             </span>
          </td>
          <td class="comfont text-center">{{ numberWithCommas(item.availability) }}</td>
          <td class="comfont text-center">
            <span class="contentdata displaycurr">
              <span class="displaycurr" v-html="currency(item.price)"></span>
              <span >
                <span class="greenvalue" v-if="item.color == 'green'"> 
                  <span>(<span>+$</span></span>  <span v-html="item.difference"></span><span> )</span>
                </span>
                <span class="redvalue" v-if="item.color == 'red'"> 
                  <span>(<span>-$</span></span><span v-html="item.difference"></span><span> )</span>
                </span>
                <span class="orangevalue" v-if="item.color == 'orange'"> 
                  <span>(</span> <span v-html="item.difference"></span><span> )</span>
                </span>
              </span>
            </span>
         </td>  
          <td class="comfont">
             <template>
              <div v-if="item.team_status == 'Active'">
                 <v-btn color="green" @click="openingamepop" class="actions">In Game</v-btn>
              </div>
              <div v-else> 
               <div v-if="suspendedShow(item.price)">
                  <v-btn color="themebtn" @click="buyteam(item.keyval)" class="actions">Buy Now</v-btn>
              </div>  
               <v-btn v-else color="primary text-center sus"  @click="buyteam(item.keyval)" class="actions">Suspended</v-btn>
               <!--<v-btn  color="themebtn" @click="buyteam(item.keyval)" class="actions">Buy Now</v-btn>-->
          </div> 
             </template>
          </td>    
        </tr>
      </template>   
     </v-data-table>
     
   </div>
   <v-overlay color="white" :value="overlay">
      <v-progress-circular indeterminate color="themebtn" size="80"></v-progress-circular>
   </v-overlay>
   <v-dialog transition="dialog-bottom-transition" max-width="400" v-model="dialog" content-class="vdialognew">
      <v-card class="rounded-xl">
        <v-card-text v-if="selectedPlayer">
          <div class="popupbody">
            <div class="row">
              <div class="heading col-md-10 col-xs-10">Buy Units</div>
              <div class="closebtn col-md-2 col-xs-2">
              <span class="material-icons closeicon"  @click="dialog = false">close</span>
            </div>
          </div>
          <div class="row"><div class="playerphoto"><img :src="selectedPlayer.team_logo"></div></div>
          <div class="row mt-5"><div class="playername">{{ selectedPlayer.name }}</div></div>   
          <div class="row mt-3">
            <div class="col-md-6 col-xs-6 text-center">
              <div class="themebox">
                <div class="unitprice"><span class="displaycurr" v-html="currency(selectedPlayer.price)"></span></div>
                <div class="unitvalue">Price Per Unit</div>
              </div>
            </div>
            <div class="col-md-6 col-xs-6 text-center">
              <div class="themebox">
                <div class="totalunit">{{ selectedPlayer.availability}}</div>
                <div class="unitvalue">Units Available to Buy</div>
              </div>
            </div>
          </div>   
        </div>
        <div class="row ">
            <div class="col-md-6 col-xs-6">
              <div class="labels">Input Units</div>
              <div class="input">
                  <input type="text" v-model="inputunit" @keyup="checkresult" class="custom" />
                  <span class="material-icons controls" @click="quantity('minus')">remove</span>
                  <span class="material-icons controls" @click="quantity('plus')">add</span>
              </div>
            </div>
            <div class="col-md-6 col-xs-6">
              <div class="labels">Total</div>
              <div class="total"><span class="displaycurr" v-html="currency(total)"></span></div>
            </div>
       </div>
       <div v-if="error" class="red--text smallfonts">{{ errorText }}</div>
      </v-card-text>
      <v-card-text v-else>
        <div class="bold pt-8 pb-8">{{language}}</div>
      </v-card-text>
      <v-card-actions class="justify-center action pb-5" v-if="selectedPlayer">
        <v-btn x-large class="primary" :disabled=readonly @click="confirmbuy">Confirm</v-btn>
      </v-card-actions>
    </v-card>
   </v-dialog>
  </div>
 </div>
</template>
<script>
export default 
{
  name: "Damnnpx",
  data() {
    return {
      mobileBreakpoint: 600,
      error: false,
      errorText:'',
      overlay: false,
      desserts:[],
      search:'', 
      dialog:false,
      readonly:false,
      country:'',
      page:1,
      teams: [],
      gameList:[],
      language:'',
      hotornot:false,
      selectedPlayer:null,
      tabone:'',
      inputunit: 0,
      total:0,
      headers:[],
      showthiscoumn:false,
      allowgamearr:["Football","Basketball","NFL","AFL","NRL","Hockey","Cricket","Baseball","Rugby Union"],
      windowheight:0
    };
  },
  computed: {
       itemsWithSno() {
          return this.desserts.map((d, index) => ({ ...d, sno: index + 1,keyval:index }))
       },
  },
  watch: {
    $route() {
      this.overlay = true;
      this.desserts = [];
      this.league = '';
      this.team = '';  
      
      var param = this.$route.params.sport;
      if(typeof param != "undefined") {
        this.game = param;
      }
      if(typeof this.$route.params.country != "undefined"){
        this.country = this.$route.params.country; 
      } 

      if(this.allowgamearr.includes(this.game))
        this.showthiscoumn = true;      
      else
        this.showthiscoumn =  false;

      this.getList();
   },
  },   
  mounted() {
    this.overlay = true;
    this.windowheight = document.documentElement.clientHeight-80;
    var param = this.$route.params.sport;
    if(typeof param != "undefined") {
       this.game = param;
    }
    if(typeof this.$route.params.country != "undefined"){
       this.country = this.$route.params.country; 
    } 
    this.getList();
    
    if(this.allowgamearr.includes(this.game))
      this.showthiscoumn = true;      
    else
      this.showthiscoumn =  false;
  },
  created() {
  window.addEventListener("resize", this.myEventHandler);
},
destroyed() {
  window.removeEventListener("resize", this.myEventHandler);
},
  methods: {
    myEventHandler() {
      this.windowheight = document.documentElement.clientHeight - 80;
    },
     openingamepop(){
        this.$root.$refs.Master.openInGame();
    }, 
    numberWithCommas(x){
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }, 
     getimage(type){
       var image = '';
       var imgnumber = Math.floor(Math.random() * 6) + 1;
       if(type == 'green'){
           image = "green"+imgnumber+".png";
       }
       else if(type == 'orange'){
           image = "orange.png";
       }
       else{
           image = "red"+imgnumber+".png";
       }
       var fullpath = require(`../assets/`+image);

      return fullpath;   
    },
    getList(){ //$state
       var sports = "";
       var league = "";
       var fantasyid = "";
       var parameter = "page="+this.page;
       if(this.game != '')
       {
         sports = this.game.toLowerCase(); 
         parameter = parameter+"&sport="+sports;
       } 
       if(typeof this.$route.params.country != "undefined")
       {
         league = this.country;
         parameter = parameter+"&country="+league; 
       }
       if(typeof this.$route.params.fantasyid != "undefined")
       {
         fantasyid = this.$route.params.fantasyid;
         parameter = parameter+"&fantasyid="+fantasyid; 
       }
        
         this.$store.dispatch("getSportTeam",{paramters:parameter}).then((response) => {
              this.overlay = false; 
              if(response.data.length > 0)
              {
                this.page += 1;
                this.desserts = response.data;
                
                if(this.allowgamearr.includes(this.game))
                {
                  this.headers = [
                        { text:"#", value: "sno", width:"5%"},
                        { text:"Name",align: "name",value: "team_name",sortable: true, width:"28%"},
                        { text:"Next Match",value:"",sortable:false, width:"4%" },
                       // { text:"",value:"",sortable:false, width:"3%" },
                        { text:"Genie Prediction",value:"",sortable:false, width:"8%"},
                        { text:"Gennie",value:"line",sortable:false, width:"5%" },
                        { text:"",value:"",sortable:false, width:"16%"},
                        { text:"Units To Buy", value:"availability",sortable: true, width:"10%"},
                        { text:"Current",value:"price",sortable:true, width:"10%"},
                        { text:"Buy",value:"#", sortable: false, width:"15%"}
                      ];
                } 
                else
                {
                  this.headers = [
                        { text:"#", value: "sno", width:"5%"},
                        { text:"Name",align: "name",value: "team_name",sortable: true, width:"35%"},
                        { text:"",value:"",sortable:false, width:"12%"},
                        { text:"Units To Buy", value:"availability",sortable: true, width:"15%"},
                        { text:"Current",value:"price",sortable:true, width:"18%"},
                        { text:"Buy",value:"#", sortable: false, width:"15%"}
                    ];
                }
                //this.desserts = this.desserts.filter(function(item){ return item.price != "0.00"})
                //$state.loaded();
              }
          }); 
      
     },
     quantity(action){
       if(action == 'plus') {
         if(this.inputunit == '')
         {
           this.inputunit = 0;
         }
          this.inputunit = parseInt(this.inputunit)+1;
          this.error =false;
       }
       else{
          if(this.inputunit > 0)
          {
             this.inputunit = parseInt(this.inputunit)-1;
          }
          else{
            this.inputunit = 0;
          }
       }
       var total = this.inputunit*this.selectedPlayer.price;
       this.total = total.toFixed(2);
    },
    checkresult(){
       
        if(this.inputunit > 0 ||this.inputunit <= this.selectedPlayer.availability)
       {
          var total    = 0;
          total   = this.selectedPlayer.price*this.inputunit;
          this.total = total.toFixed(2);
       }
    },
    suspendedShow(price){
         var suspended = true;
         var getsetting = this.$store.getters.getcurrencySetting;
         var checkprice = 0;
         if(getsetting.dammn_coin == 1)
         {
            checkprice = price*getsetting.dammn_coin_multiple;      

            if(getsetting.dc_deduction_value > 0)  
            {
              if(checkprice < getsetting.dc_deduction_value)
              {
                 suspended = false;
              }
            }  
         }
         else
         {
            checkprice = price;      
            if(checkprice < getsetting.dc_deduction_value)
             {
                 suspended = false;
             }
         } 

         return suspended;
       },
      buyteam(index){ 
         this.error          = '';
         this.errorText      = '';
         this.inputunit      = '';
         this.total          = 0;  
         var price           = this.itemsWithSno[index].price;
         var getsetting      = this.$store.getters.getcurrencySetting;
         this.selectedPlayer = this.itemsWithSno[index];
         var checkprice      = 0; 
         if(getsetting.dammn_coin == 1)
         {
            checkprice = price*getsetting.dammn_coin_multiple;      

            if(getsetting.dc_deduction_value > 0)  
            {
              if(checkprice < getsetting.dc_deduction_value)
              {
                this.selectedPlayer = '';
                this.language = 'This Team is suspended due to their amount is less then DC 50';
              }
            }  
         }
         else
         {
            checkprice = price;
             if(checkprice < getsetting.dc_deduction_value)
              {
                this.selectedPlayer = '';
                this.language = 'This Team is suspended due to their amount is less then $0.5';
              }
         }
        
         this.dialog = true; 
     },    
     confirmbuy(){
      if(this.inputunit == 0)
      {
         this.error     = true; 
         this.errorText = "Please select unit";
      }
      else{
        this.error    = false;
        this.readonly = true;
        var teamid    = this.selectedPlayer.team_id;
        var unit      = this.inputunit;
        this.$store.dispatch("buyTeam",{"teamid":teamid,"unit":unit}).then((response) => 
        {
           this.readonly = false;
           if(response.data.status == "success")
           {
             this.readonly = false;
             this.$store.dispatch("retrieveBalance");
             
             this.$router.push("/portfolio");
           }
           else 
           {
             this.error     = true; 
             this.errorText  = response.data.message;
           }
        });
      }
    },
  },
  
};
</script>
<style>
.graphimg.new img{ width: 55%;}
.different.text .green{background:none !important;color:green}
.different.text .red{background:none !important;color:red}
.teamvslogo .anchorcust .v-avatar{height:40px !important;width:40px !important;}
.nopadd{padding: 0 !important;}
.smallword{font-size: 11px;}
</style>
<style scoped>
.samelinediv{align-items: center;}
.topheads{font-size: 16px;}
</style>
 