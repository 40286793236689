<template>
  <div id="rewards">
      <v-alert text type="success" :value="showSuccess" dismissible>Your payment was successful. Your balance is now ${{ (balance/100).toFixed(2) }}</v-alert>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <h1>Top Up</h1>
      </v-row>
    </v-container>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12">
          <v-card color="blue" dark>
            <v-card-text>
              <v-row align="center">
                <v-col cols="5">
                  <v-card-title class="headline text-start">Balance</v-card-title>
                  <v-card-subtitle class="text-start">Your current balance</v-card-subtitle>
                </v-col>
                <v-col class="display-2 text-end" cols="7">${{ (balance/100).toFixed(2) }}</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12">
          <v-card dark>
            <v-card-text>
              <v-row align="center">
                <v-col cols="6">
                  <v-card-title class="headline text-start mb-2">Top Up</v-card-title>
                  <v-card-subtitle class="text-start">Select a top up amount and payment method</v-card-subtitle>
                </v-col>
                <v-col class="display-2 text-center" cols="6">
                  <v-form @submit.prevent="validate" ref="form" lazy-validation>
                    <v-select
                      :items="paymentAmounts"
                      v-model="paymentAmount"
                      label="Amount"
                      class="mb-2"
                    ></v-select>
                    <card
                      ref="cardElem"
                      class="stripe-card mb-2"
                      :class="{ complete }"
                      :stripe="apiKey"
                      :options="stripeOptions"
                      @change="complete = $event.complete"
                      dark
                    ></card>
                    <div class="red--text text--darken-1 text-caption">{{stripeErr}}</div>
                    <v-btn
                      color="primary"
                      dark
                      type="submit"
                      class="mt-4"
                      :disabled="topupDisabled"
                    >Top Up</v-btn>
                  </v-form>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="modal" max-width="290">
      <v-card>
        <v-card-title class="headline">Your Purchase</v-card-title>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="modal = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    -->
  </div>
</template>

<script>
import {
  Card,
  createToken,
  createPaymentMethod
    } from "vue-stripe-elements-plus"; 
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

axios.defaults.baseURL = process.env.VUE_APP_BASEAPI;

export default {
  name: "topup",
  components: { Card },
  data() {
    return {
      showSuccess: false,
      topupDisabled: false,
      email: "",
      cardElem: null,
      stripeErr: "",
      stripeOptions: {
        hidePostalCode: true,
        style: {
          base: {
            color: "#fff",
            fontSize: "16px",
            fontFamily: '"Roboto", sans-serif'
          }
        }
      },
      complete: false,
      error: false,
      modal: false,
      paymentAmounts: [],
      paymentAmount: "",
      balance: 0,
      apiKey: process.env.VUE_APP_STRIPE_API_KEY
    };
  },
  mounted() {
    this.getUserEmail();
    this.getPaymentAmounts();
    this.getBalance();
  },
  computed: {},
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        if (this.paymentAmount === "") {
          this.stripeErr = "Please select a top up amount";
          return false;
        }
        this.stripeErr = "";
        this.topupDisabled = true;
        this.pay();
      }
    },
    pay() {
      createToken().then(data => {
        if ("error" in data) {
          // handle any errors
          this.stripeErr = data.error.message;
          this.topupDisabled = false;
        }
        if ("token" in data) {
          // we've received a token, use the card id to create a payment intent
          createPaymentMethod("card", {
            card: data.token.card.id,
            billing_details: {
              email: this.email
              // you could add more here according to thr stripe docs
            }
          }).then(data => {
            if ("error" in data) {
              // handle any create payment intent errors
              this.stripeErr = data.error.message;
              this.topupDisabled = false;
            }
            if ("paymentMethod" in data) {
              Vue.axios
                .post("bank/stripe/charge", {
                  payment_id: data.paymentMethod.id,
                  amount: this.paymentAmount
                })
                .then(() => {
                  this.showSuccess = true;
                  this.getBalance();
                  window.setInterval(() => {
                    this.showSuccess = false;
                  }, 10000);
                  this.stripeErr = "";
                  this.topupDisabled = false;
                })
                .catch(error => {
                  // console.log(error.response);
                  this.stripeErr =
                    "Unable to process payment. " + error.response.data.message;
                  this.topupDisabled = false;
                });
            }
          });
        }
      });
    }, 
    getUserEmail() {
      this.$store.dispatch("retrieveUserDetails").then(() => {
        this.email = this.$store.getters.email;
      });
    },
    getPaymentAmounts() {
      this.$store.dispatch("retrievePaymentAmounts").then(() => {
        this.paymentAmounts = this.$store.getters.paymentAmounts;
      });
    },
    getTimezone() {
      this.timezone = this.$moment.tz.guess();
    },
    getBalance() {
      this.$store.dispatch("retrieveBalance").then(() => {
        this.balance = this.$store.getters.balance;
      });
    },
    amountChanged() {
      if (this.paymentAmount === 0) {
        this.error = true;
        return;
      } else {
        this.error = false;
      }
    }
  }
};
</script>

<style scoped>
.stripe-card {
  /* width: 300px; */
  border-bottom: 1px solid grey;
}

.InputElement.Input {
  color: white !important;
}
</style>