<template>
  <div id="portfolio">
     <div>
      <div class="totalvaldiv">
        <div class="sameline">
          <div class="totaltophead">Todays Market Value</div>
          <div class="totaltopbody">
            <span class="displaycurr justifycontent" v-html="currency(maindata.total_market_value)"></span>
          </div> 
          <div class="totaltophead diffmarket">Yesterdays Market Value</div>
          <div class="totaltopbody diffmarket">
            <span class="displaycurr justifycontent" v-html="currency(maindata.yestotalmarketvalue)"></span>
          </div> 
        </div>
        <div class="sameline">
          <div class="totaltophead">Total Cost</div>
          <div class="totaltopbody"><span class="displaycurr justifycontent" v-html="currency(maindata.total_cost)"></span></div> 
        </div>
        <div class="sameline">
          <div class="totaltophead">Todays Profit/Loss</div>
          <div class="totaltopbody ">
            <span class="displaycurr justifycontent">
             <span v-if="maindata.profitlostcolor == 'green'" class="material-icons green--text">arrow_upward</span>
             <span v-if="maindata.profitlostcolor == 'red'" class="material-icons red--text">arrow_downward</span>
             <span class="d-flex" v-html="currency(maindata.profitlose)"></span>
            </span>
          </div> 
          <div class="totaltophead diffmarket">Yesterdays Profit/Loss</div>
          <div class="totaltopbody diffmarket">
           <span class="displaycurr justifycontent diffmarket">
            <span v-if="maindata.yesprofitlostcolor == 'green'" class="material-icons green--text">arrow_upward</span>
            <span v-if="maindata.yesprofitlostcolor == 'red'" class="material-icons red--text">arrow_downward</span>
            <span class="d-flex" v-html="currency(maindata.yesterdayprofitlose)"></span>
           </span>
          </div> 
        </div>
        <div class="sameline single-data">
          <div class="totaltophead">Withdraw Amount</div>
          <div class="totaltopbody"><span class="displaycurr justifycontent" v-html="currency(getbalance)"></span></div> 
        </div>
        <div class="sameline single-data cursorhand"  @click="filterdata('player')">
          <div class="totaltophead">Players</div>
          <div class="totaltopbody">{{maindata.total_players}}</div>
        </div>
        <div class="sameline single-data cursorhand" @click="filterdata('team')">
          <div class="totaltophead">Teams</div>
          <div class="totaltopbody" >{{maindata.total_teams}}</div>
        </div>
       </div>
       <div>
         <v-text-field v-model="searching" label="Search Player/Team" class="mx-4"></v-text-field>
         <v-layout v-resize="onResize" column class="formobile"> 
          <v-data-table class="newtable" :headers="headers" :items="itemsWithSno"  hide-default-footer  disable-pagination>
          <template v-slot:top></template>
          <template v-slot:item="{ item }">
           <tr v-if="!isMobile">
            <td>{{ item.sno }}</td>
            <td>
              <div class="mt-3 mb-2" :class="{'makediffcircle': item.purchase_type == 'Dividend','makediffcircle': item.purchase_type == 'Damnnhunt',}">
               <router-link class="anchorcust" :to="{name: 'player', params:{ id: item.id }}" v-if="item.type == 'player'"> 
                <v-avatar>
                  <img class="circlcls" :src="item.thumbnail_image"   :alt="item.name">
                </v-avatar>  <span class="playername">{{ item.name }}</span>
               </router-link>  
               <router-link class="anchorcust" :to="{name: 'team', params:{ id: item.id }}" v-else> 
                <v-avatar>
                  <img class="circlcls" :src="item.thumbnail_image"   :alt="item.name">
                </v-avatar>  <span class="playername">{{ item.name }}</span>
               </router-link>  
              </div>
           </td>
           <td><div class="flex" v-if="item.nationality"><span class="country">{{ item.nationality }}</span></div></td>
           <td class="comfont graphimgother">
              <img v-if="item.stats.twnty_four_Hrs.flag == 'positive'" :src="getimage('green')">
              <img v-else-if="item.stats.twnty_four_Hrs.flag == 'orange'" :src="getimage('orange')">
              <img v-else :src="getimage('red')">
           </td>
           <td class="comfont ">
              <div class="displaycurr othercase">
                  <div class="bold">{{ getPurchaseType(item.purchase_type) }} </div>
                  <div class="unitshow">{{ item.purchased_units }} units </div>
                  <div class="inner3">@</div>
                  <div class="displaycurr inner1" v-html="currency(item.buy_price)"></div>
                  <div class="equals"> = </div>
                  <span class="displaycurr inner2" v-html="currency(item.buy_price*item.purchased_units)"></span>
              </div>
              <div class="displaycurr othercase">
                <div class="bold">WS UX</div>
                <div class="unitshow">{{ item.purchased_units }} units </div>
                <div class="inner3">@</div>
                <div class="displaycurr inner1" v-html="currency(item.price)"></div>
                <div class="equals"> = </div>
                <span class="displaycurr inner2" v-html="currency(item.price*item.purchased_units)"></span>
              </div>
              <div class="otherimg displaycurr">
                <div class="displaycurr myothercurr" v-html="calulatedvalue(item.price,item.buy_price)"></div>
                <div class="displaycurr myothercurr" v-html="calulatepercent(item.price,item.buy_price)"></div>
              </div>
          </td>
          <td class="comfont lastchild">
            <template> 
               <v-btn v-if="item.type == 'player'" color="primary text-center" @click="sellplayer(item)" class="actions">Sell</v-btn>
               <v-btn v-else color="primary text-center" @click="sellteam(item)" class="actions">Sell</v-btn>
            </template>
          </td>
         </tr>
         <tr v-else>
           <td>
             <div class="d-flex listing-result">
                 <div class="listing-result-sub-1 portfolio-left">
                   <div class="playerdiv">
                     <div class="player-image" 
                     :class="{'makediffcircle': item.purchase_type == 'Dividend','makediffcircle': item.purchase_type == 'Damnnhunt',}">
                       <router-link class="anchorcust" :to="{name: 'player', params:{ id: item.id }}" v-if="item.type == 'player'"> 
                          <img class="photo" :src="item.thumbnail_image" :alt="item.name">
                       </router-link>
                       <router-link class="anchorcust" :to="{name: 'team', params:{ id: item.id }}" v-else>    
                           <img class="photo" :src="item.thumbnail_image" :alt="item.name">
                       </router-link>  
                     </div>
                     <div class="player-name">
                       <router-link class="anchorcust" :to="{name: 'player', params:{ id: item.id }}" v-if="item.type == 'player'"> 
                         <span class="playername">{{ item.name }}</span>
                       </router-link>  
                       <router-link class="anchorcust" :to="{name: 'team', params:{ id: item.id }}" v-else> 
                         <span class="playername">{{ item.name }}</span>
                       </router-link>
                     </div>
                     <div class="player-graph">
                        <img class="grp" v-if="item.stats.twnty_four_Hrs.flag == 'positive'" :src="getimage('green')">
                        <img class="grp" v-else-if="item.stats.twnty_four_Hrs.flag == 'orange'" :src="getimage('orange')">
                        <img class="grp" v-else :src="getimage('red')">
                     </div>
                   </div>
                 </div>
                 <div class="listing-result-sub-1 portfolio-right">
                   <div class="right-sub-port purchased-sec">
                     <div class="bold">{{ getPurchaseType(item.purchase_type) }}</div>
                      <div class="displaycurr othercase">
                          <div class="unitshow">{{ item.purchased_units }} units @</div>
                          <div class="displaycurr inner1" v-html="currency(item.buy_price)"></div>
                          <div class="equals"> = </div>
                          <span class="displaycurr inner2" v-html="currency(item.buy_price*item.purchased_units)"></span>
                      </div>
                   </div>
                   <div class="right-sub-port damnnn-px-sec">
                      <div class="bold">DamnN PX</div>
                      <div class="displaycurr othercase">
                        <div class="unitshow">{{ item.purchased_units }} units @</div>
                        <div class="displaycurr inner1" v-html="currency(item.price)"></div>
                        <div class="equals"> = </div>
                        <span class="displaycurr inner2" v-html="currency(item.price*item.purchased_units)"></span>
                      </div>
                   </div> 
                   <div class="right-sub-port damnnn-px-sec">
                      <div class="otherimg displaycurr">
                        <div class="displaycurr myothercurr" v-html="calulatedvalue(item.price,item.buy_price)"></div>
                        <div class="displaycurr myothercurr" v-html="calulatepercent(item.price,item.buy_price)"></div>
                      </div>
                   </div>
                   <div class="sellportdiv">
                      <template> 
                        <v-btn v-if="item.type == 'player'" color="primary text-center" @click="sellplayer(item)" class="actions">Sell</v-btn>
                        <v-btn v-else color="primary text-center" @click="sellteam(item)" class="actions">Sell</v-btn>
                      </template>
                    </div>
                 </div>
             </div>
           </td>
         </tr>  
        </template>   
       </v-data-table>
     </v-layout>  
      </div>
    </div>  
     <v-dialog transition="dialog-bottom-transition" max-width="400"  v-model="dialog" content-class="vdialognew">
      <v-card class="rounded-xl">
       <v-card-text v-if="selectedPlayer">
        <div class="popupbody">
         <v-row>
           <v-col cols="10" md="10" sm="10" class="heading">Sell Units</v-col>
           <v-col cols="2" md="2" sm="2" class="closebtn">
             <span class="material-icons closeicon"  @click="dialog = false">close</span>
           </v-col>
         </v-row>
         <div class="row"><div class="playerphoto"><img :src="selectedPlayer.thumbnail_image"></div></div>
         <div class="row mt-5"><div class="playername">{{ selectedPlayer.name }}</div></div>   
         <v-row class=" mt-4">
          <v-col cols="6" md="6" sm="6" class="text-center">
           <div class="themebox">
            <div class="unitprice">
              <v-text-field 
                placeholder="Enter price per unit" 
                v-model="sellprice" 
                @change="checkresult" 
                @keyup="checkresult" 
                hide-details
                dark
                color="white"
                class="customwhite"></v-text-field>     
            </div>
           </div>
          </v-col>
          <v-col cols="6" md="6" sm="6" class="text-center">
            <div class="themebox">
              <div class="totalunit">{{ selectedPlayer.buy_units}}</div>
              <div class="unitvalue">Units Available to Sell</div>
            </div>
          </v-col>
         </v-row>   
        </div>
        <v-row>
          <v-col cols="6" sm="6" md="6">
            <div class="labels">Input Units</div>
            <div class="input">
                <input type="text" v-model="inputunit" class="custom"  @keyup="checkresult"/>
                <span class="material-icons controls" @click="quantity('minus')">remove</span>
                <span class="material-icons controls" @click="quantity('plus')">add</span>
            </div>
          </v-col>
          <v-col cols="6" sm="6" md="6">
              <div class="labels">Total</div>
              <div class="total displaycurr">
                <span v-html="currencysymbol()"></span> <span>{{ total }}</span> 
              </div>
              <div v-if="total > 0" class="taxclass">( Include's Transaction fee )</div>
          </v-col>
        </v-row>
        <div v-if="error" class="red--text smallfonts mt-1 mb-1 text-center">{{ errorText }}</div>
      </v-card-text>
      <v-card-actions class="justify-center action pb-5">
        <v-btn x-large class="primary" :disabled=readonly @click="confirmsell">Confirm</v-btn>
      </v-card-actions>
    </v-card>
   </v-dialog>
   <v-dialog transition="dialog-bottom-transition" max-width="400" v-model="dialogteam" content-class="vdialognew">
     <v-card class="rounded-xl">
       <v-card-text v-if="selectedPlayer">
         <div class="popupbody">
          <v-row>
           <v-col cols="10" md="10" sm="10" class="heading">Sell Units</v-col>
           <v-col cols="2" md="2" sm="2" class="closebtn">
             <span class="material-icons closeicon"  @click="dialogteam = false">close</span>
           </v-col>
         </v-row>
         <div class="row"><div class="playerphoto"><img :src="selectedPlayer.thumbnail_image"></div></div>
         <div class="row mt-5"><div class="playername">{{ selectedPlayer.name }}</div></div>   

         <v-row class="mt-4">
          <v-col cols="6" sm="6" md="6" class="text-center">
           <div class="themebox">
            <div class="unitprice">
              <v-text-field placeholder="Enter price per unit" color="white" v-model="sellprice" @change="checkresult" @keyup="checkresult" class="customwhite"></v-text-field>     
            </div>
           </div>
          </v-col>
          <v-col cols="6" sm="6" md="6" class="text-center">
            <div class="themebox">
              <div class="totalunit">{{ selectedPlayer.buy_units}}</div>
              <div class="unitvalue">Units Available to Sell</div>
            </div>
          </v-col>
         </v-row>   

        </div>
        <v-row>
            <v-col cols="6" sm="6" md="6">
              <div class="labels">Input Units</div>
              <div class="input">
                  <input type="text" v-model="inputunit" class="custom"  @keyup="checkresult"/>
                  <span class="material-icons controls" @click="quantity('minus')">remove</span>
                  <span class="material-icons controls" @click="quantity('plus')">add</span>
              </div>
            </v-col>
            <v-col cols="6" sm="6" md="6">
              <div class="labels">Total</div>
              <div class="total displaycurr">
                <span v-html="currencysymbol()"></span> <span>{{ total }}</span> 
              </div>
              <div v-if="total > 0" class="taxclass">( Include's Transaction fee )</div>
            </v-col>
        </v-row>
        <div v-if="error" class="red--text smallfonts mt-1 mb-1 text-center">{{ errorText }}</div>
      </v-card-text>
      <v-card-actions class="justify-center action pb-5">
        <v-btn x-large class="primary" :disabled="readonly" @click="confirmsellteam">Confirm</v-btn>
      </v-card-actions>
    </v-card>
   </v-dialog>
  </div>
</template>

<script>

export default {
  name: "Portfolio",
  data() {
    return {
       error: false,
      errorText:'',
      showgamelist:true,
      desserts:[],
      searching:'',
      searchteam:'',
      dialog:false,
      dialogteam:false,
      inputunit: 0,
      total:0,
      readonly:false, 
      selectedPlayer:null,
      sellprice:0,
      tabing:0,
      maindata:[],
      customfilter:'',
      isMobile: false,
    };
  },   
  mounted() {
    
    this.getList();
  },
  computed: {
     headers(){
        let headers = [
              /*
              { text:"#", value: "sno",  width: "2%"},
              { text:"Name",align: "name",value: "name",sortable: true,  width: "25%"},
              { text:"", value:"",  width: "15%"},
              { text:"", value:"",  width: "10%"},
              { text:"Current",value:"#",  width: "12%"},
              { text:"Purchased",value:"#",  width: "12%"},
              { text:"", value:"",  width: "5%"}, 
              { text:"Charge",value:"#",  width: "15%"},
              */
              { text:"",width: "3%",sortable:false},
              { text:"",width: "25%",sortable:false},
              { text:"",width: "8%",sortable:false},
              { text:"",width: "5%",sortable:false},
              { text:"",width: "48%",sortable:false},
              { text:"",width: "7%",sortable:false},
         ]
 
         return headers
    },
    divheaders(){
        let  headers = [
              { text:"#", value: "sno", width:"8%"},
              { text:"Name",align: "name",value: "name",sortable: true, width:"33%"},
              { text:"", value: "nationality",sortable: true, width:"20%"},
              { text:"", value: "",sortable: false, width:"5%"},
              { text:"DX Units Sold", value:"availability",sortable: true, width:"15%"},
              { text:"Current",value:"price",sortable:true, width:"18%"},
              { text:"",value:"#", sortable: false, width:"12%"}
         ];

         return headers;
    },
    getbalance() {
      return this.$store.getters.balance;
    },
    itemsWithSno() 
    {
          if(this.searching)
           {
              return this.desserts.filter((item)=>{
                 return this.searching.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v));
              })   
          }
          else
          {
            if(this.desserts.players != '')
            {
              if(this.customfilter == '') 
              {
                return this.desserts.map((d, index) => ({ ...d, sno: index + 1,keyval:index }))
              }
              else
              {
                return this.desserts.filter((item)=>{
                 return this.customfilter.toLowerCase().split(' ').every(v => item.type.toLowerCase().includes(v))
                })   
              } 
            }
            else
            {
               return '';
            }
          }
       },
        
  },
  methods: {
    getPurchaseType(type){
        var returnval = "Purchased ";
        if(type == "Buy") 
          returnval = "Purchased";
        else if(type == "Damnnhunt") 
          returnval = "Hunt";  
       
       return returnval;
    },
    tabchange(){
      if(this.tabing == 0)
      {
        this.getList();
      }
      else
      {
        this.getDividendList();
      }
    },  
    onResize() {
          if (window.innerWidth < 769)
            this.isMobile = true;
          else
            this.isMobile = false;
    },
    filterdata(types)
    {
      if(this.customfilter == '')   
          {
            this.customfilter = types;  
          }   
          else
          {
            if(this.customfilter == types)
              this.customfilter = '';  
            else  
              this.customfilter = types;  
          }      
    },
    getDividendList(){
      this.$store.dispatch("getDividendList").then((response) => 
      {
          this.desserts = response.players;
      }); 
    },
    getList(){
       this.$store.dispatch("getPortfolio").then((response) => {
          this.desserts = response.data.players;
          this.maindata = response.data;
       });
     },
    getimage(type){
       var image = '';
       var imgnumber = Math.floor(Math.random() * 6) + 1;
       if(type == 'green'){
           image = "green"+imgnumber+".png";
       }
       else if(type == 'orange'){
           image = "orange.png";
       }
       else{
           image = "red"+imgnumber+".png";
       }
       var fullpath = require(`../assets/`+image);

      return fullpath;   
    },
    calulatedvalue(secondprice,firstprice){
      var currencyvalue  = localStorage.getItem('coinvalue') || 1;
      var coin = localStorage.getItem('coin') || 0;
       var symbol   = "$";
       if(coin == 1)  
             {
                symbol = "<img src='"+require("@/assets/transaction.png")+"'>";
             } 
       else
       {
          secondprice = secondprice/100;
          firstprice  = firstprice/100; 
       } 
      var total = (secondprice*currencyvalue) - (firstprice*currencyvalue);
      if(coin == 1)
      {
        total = Math.round(total);
      }
      else
      {
         total = total.toFixed(2);
      }
      var arrow = '<span class="material-icons green--text">arrow_upward</span>';

      if(Math.sign(total) == -1)
      {
         arrow = '<span class="material-icons red--text">arrow_downward</span>';
      }
      if(total == 0)
      {
        arrow = '';
      }
      //symbol = '';
      var displaycal = arrow+"<div>"+symbol+"</div><div>"+total+"</div>";    

      return displaycal;
    },
    calulatepercent(secondprice,firstprice){
       var currencyvalue = localStorage.getItem('coinvalue') || 1;
       var coin   = localStorage.getItem('coin');

       if(coin == 0)
       {
          currencyvalue = 1;
       }
       var total  = (secondprice*currencyvalue) - (firstprice*currencyvalue);
       
       var symbol = "$";
       var percent    = (total/firstprice)*100;
       var percentage = 0;
       if(coin == 1)  
             {
                symbol = "<img src='"+require("@/assets/transaction.png")+"'>";
                percentage = Math.round(percent);
             } 
       else
       {
            percentage = percent.toFixed(2);
       }
       
       
       
       var arrow = '<span class="material-icons green--text">arrow_upward</span>';
        if(Math.sign(total) == -1)
      {
         arrow = '<span class="material-icons red--text">arrow_downward</span>';
      }
      if(total == 0)
      {
        arrow = '';
      }
      symbol = '';
      var displayper = arrow+"<div>"+symbol+"</div><div>"+percentage+"%</div>";    
       return displayper;
    },
    confirmsellteam()
    {
      //here need to start
      var noerror = 1; 
      if(this.inputunit == 0)
      {
         this.error     = true; 
         this.errorText = "Please select unit";
         noerror = 0;
      }
      
      if(this.sellprice == '' || this.sellprice <= 0)
      {
         this.error     = true; 
         this.errorText = "Please enter sell unit price";
         noerror = 0;
      }
     
      if(noerror == 1)
      {
        this.error =false;
        this.readonly = true;
        
        var user_team_id = this.selectedPlayer.user_player_id;
        var unit     = this.inputunit;
        var coin     = localStorage.getItem('coin') || 0;
        var currencyvalue = localStorage.getItem('coinvalue') || 1;
        //var price    = this.total/currencyvalue;
        
         var price    = 0;
        if(coin == 1)
         {
           price    = this.sellprice/currencyvalue;
           price    = Math.round(price);
         } 
        else
         {
           price    = parseFloat(this.sellprice).toFixed(2);
         } 
        this.$store.dispatch("sellTeam",{"user_team_id":user_team_id,"unit":unit,"price":price}).then((response) => 
        {
           this.readonly = false;
           if(response.data.status == "success"){
               this.readonly = false;
               this.$router.push({ name: 'buynsell' });
           }
           else {
             this.error     = true; 
             this.errorText  = response.data.message;
           }
        });
      }
    },
    confirmsell(){
      var noerror = 1; 
      if(this.inputunit == 0)
      {
         this.error     = true; 
         this.errorText = "Please select unit";
         noerror = 0;
      }
      
      if(this.sellprice == '' || this.sellprice <= 0)
      {
         
         this.error     = true; 
         this.errorText = "Please enter sell unit price";
         noerror = 0;
      }
     
      if(noerror == 1)
      {
        this.error =false;
        this.readonly = true;
        
        var coin     = localStorage.getItem('coin') || 0;
        var playerid = this.selectedPlayer.user_player_id;
        var unit     = this.inputunit;
        var currencyvalue = localStorage.getItem('coinvalue') || 1;
        var price    = 0;
        if(coin == 1)
         {
           price    = this.sellprice/currencyvalue;
           price    = Math.round(price);
         } 
        else
         {
           price    = parseFloat(this.sellprice).toFixed(2);
         } 
        
          this.$store.dispatch("sellPlayer",{"playerid":playerid,"unit":unit,"price":price}).then((response) => 
        {
           this.readonly = false;
           if(response.data.status == "success"){
               this.readonly = false;
               this.$router.push({ name: 'buynsell' });
           }
           else {
             this.error     = true; 
             this.errorText  = response.data.message;
           }
        });
      }
    },  
    checkresult(){
      if(this.inputunit > 0 ||this.inputunit < this.selectedPlayer.buy_units)
       {
         var total = this.inputunit*this.sellprice;
         var percent = 0;
         if(total > 0)
         {
           var tax = 0;
           if(this.$store.getters.getcurrencySetting.paypal_fee > 0)
           {
              tax = this.$store.getters.getcurrencySetting.paypal_fee;
           }
           if(tax > 0)
           {
            if(this.sellprice < 1)
              percent =  0.02 
            else
              percent = (tax/100)*total;
            
             total = total-percent;
           } 
           this.total = total.toFixed(2);
         }
         else
         {
           this.total = total;
         } 
       }
       
    },
    quantity(action){
       if(action == 'plus') 
       {
         if(typeof this.inputunit == "undefined" || this.inputunit == '')
         {
           this.inputunit = 0;
         }
         
         if(this.inputunit < this.selectedPlayer.buy_units)
         {
           this.inputunit = parseInt(this.inputunit)+1;
           this.error =false;
         } 
       }
       else{
          if(this.inputunit > 0)
          {
             this.inputunit = parseInt(this.inputunit)-1;
          }
          else{
            this.inputunit = 0;
          }
       }
       
       var total = this.inputunit*this.sellprice;
       var percent = 0;
       var tax = 0;
        if(this.$store.getters.getcurrencySetting.paypal_fee > 0)
        {
          tax = this.$store.getters.getcurrencySetting.paypal_fee;
        }
        if(tax > 0)
        {
          if(this.sellprice < 1)
            percent =  0.02 
          else
            percent = (tax/100)*total;
           
          total = total-percent;
        } 
        
        if(total > 0)
          this.total = total.toFixed(2);
        else
          this.total = total;   
    },
     sellplayer(index){
         this.error = false;
         this.errorText = '';
         this.inputunit = '';
         this.sellprice = '';
         this.total     = 0;
         //this.selectedPlayer = this.itemsWithSno[index];
         this.selectedPlayer = index;  
         this.dialog = true; 
     },
     sellteam(index)
     {
         this.error = false;
         this.errorText = '';
         this.inputunit = '';
         this.sellprice = '';
         this.total     = 0;
         //this.selectedPlayer = this.itemsWithSno[index];
         this.selectedPlayer = index;
         this.dialogteam = true; 
     }
  }
};
</script>
<style scoped>
.displaycurr.otherimg img,img.otherimg{width:21% !important;}
.graphimg img{ width: 80%;}
.graphimgother img{width:30%}
.playername{font-family:Lexend;font-weight:500; size:12px; margin-left:8px;}
.comfont{font-family:lexend; font-size:12px;}
.themebtn{color:#fff;text-transform:capitalize;}
.smallfont{font-size:14px;}
.smallfonts{font-size: 12px;}
.country {display: inline-block;vertical-align: top;}
.redfont{color:red;}
.greenfont{color:green;}
.percentdata{margin-left:5px;}
.v-input.customwhite{width: 80%;margin: auto;font-size: 13px;}
.v-input.customwhite::v-deep input{ color:white !important; }
.action button{width:90%} 
.unitshow {margin-left: 7px;margin-right: 2px;}
.cursorhand{cursor:pointer;}
.bold{color:black; font-size:13px;}
.displaycurr.myothercurr{align-items:center;margin-right: 8px;}
.newtable table td:nth-child(1){width:5%;}
.newtable table td:nth-child(2){width:25%;}
.newtable table td:nth-child(3){width:20%;}
.newtable table td:nth-child(4){width:10%;}
.newtable table td:nth-child(5){width:30%;}
.newtable table td:last-child{width:10%;}
.newtable .displaycurr.myothercurr{width:19%;}
.newtable .graphimgother img{width:100%}
.newtable .displaycurr.othercase{width:100%;}
.newtable .othercase .bold{flex: 0 0 23%;}
.newtable .othercase .unitshow{flex: 0 0 23%; margin-left:0;margin-right:0;}
.newtable .othercase .inner1{flex: 0 0 15%; color:#000; font-weight:500;}
.newtable .othercase .equals{flex: 0 0 5%; text-align:center;}

.newtable .othercase .inner2{flex: 0 0 15%; color:#000; font-weight:500;}
.newtable .othercase .inner3{flex: 0 0 10%; color:#000; font-weight:500;}
@media(max-width:768px){
  .newtable .playername{margin-left:0;}
  .newtable .othercase .bold{flex: 0 0 32%;}
  .newtable .othercase .unitshow{flex: 0 0 28%;}
  .newtable .othercase .inner1{flex: 0 0 24%;}
  .newtable .displaycurr.myothercurr{ width: 26%;}
  .listing-result img.photo {max-width: 40%;max-height: 47px;}
  .listing-result img.grp {max-width: 65%; margin: auto; max-height: 45px;}
  .player-graph { margin-top: 12px;}
  .portfolio-right{padding-left: 15px;}
  .sellportdiv { text-align: right;}
} 
@media(max-width:414px){
 .newtable table td:nth-child(5) {    width: 30%;    padding-right: 35px;}
 .newtable .graphimgother img { width: 40px; }
 .newtable .v-data-table-header{display:none;}
 .newtable .othercase .bold{flex: 0 0 35%;}
 .newtable .othercase .equals{flex:0 0 6%;}
}
.unitprice .customwhite{color: #fff;  border-color: white;} 
.v-input--is-focused .v-input__slot {
  border: 2px solid #005fcc !important;
  border-bottom-color: rgba(0, 0, 0, 0.38) !important;
}
</style>
<style>
.displaycurr.myothercurr .material-icons{font-size:17px;}
.displaycurr .greenvalue,.displaycurr .redvalue,.displaycurr .orangevalue{display:flex}
.totaltopbody.diffmarket {font-size: 11px;}
.totalvaldiv {display: flex;justify-content: center;align-items: center;padding: 15px 10px;}
.sameline {background: linear-gradient(87.42deg, #247CDD 4.23%, #ff2457 98.92%);min-height: 133px;flex: 0 0 16%;border-radius: 10px;margin: 0 0.3%;}
.totaltophead {padding-top: 5px;text-align: center;color: white;font-size: 14px;font-weight: 600;font-family: Lexend;}
.totaltopbody {padding-top: 0px;text-align: center;color: white;font-size: 14px;font-family: Lexend;padding-bottom: 6px;}
.sameline.single-data{padding-top:25px;}
.justifycontent{justify-content:center}

.portfolio-left{flex:0 0 32%;}
.portfolio-right{flex:0 0 68%;}
.sellportdiv { margin: auto; text-align: center;}
.makediffcircle .v-avatar{border: 2px solid red;}
@media(max-width:1023px){
.totalvaldiv {flex-wrap: wrap;justify-content: center;}
.sameline {flex: 0 0 32%;border-radius: 10px;margin-bottom: 0;margin-top: 5px;}
}
@media(max-width:767px){
.sameline {flex: 0 0 48%;border-radius: 10px;margin-bottom: 0px;}
}
@media(max-width:420px){
.totalvaldiv{padding: 0;}  
.sameline {flex: 0 0 48%;margin-bottom: 0px;margin-right: 5px}
}
@media(max-width:320px){
  .sameline {-webkit-box-flex: 0;-ms-flex: 0 0 48%;flex: 0 0 98%;margin-bottom: 0px;margin-right: 5px;margin-top: 5px;}
}


</style>