<template>
    <v-dialog transition="dialog-bottom-transition" max-width="400" v-model="teamdialog" content-class="vdialognew" @click:outside="closeteamDialog">
      <v-card class="rounded-xl" >
        <v-card-text v-if="selectedPlayer">
          <div class="popupbody">
            <v-row  no-gutters>
            <v-col cols="10" md="10" sm="10"  class="heading">Buy Units</v-col>
            <v-col cols="2" md="2" sm="2"  class="closebtn text-center">
              <span class="material-icons closeicon"  @click="closeteamDialog">close</span>
            </v-col>
        </v-row>
          <div class="row"><div class="playerphoto"><img :src="selectedPlayer.thumbnail_image"></div></div>
          <div class="row mt-5"><div class="playername">{{ selectedPlayer.name }}</div></div>   
          <v-row class="mt-3">
          <v-col cols="6" md="6" sm="6" class="text-center">
            <div class="themebox">
            <div class="unitprice"><span class="displaycurr" v-html="currency(selectedPlayer.price)"></span></div>
            <div class="unitvalue">Price Per Unit</div>
            </div>
          </v-col>
          <v-col cols="6" md="6" sm="6" class="text-center">
            <div class="themebox">
            <div class="totalunit">{{ selectedPlayer.availability}}</div>
            <div class="unitvalue">Units Available to Buy</div>
            </div>
          </v-col>
        </v-row>    
        </div>
        <v-row>
        <v-col cols="6" md="6" sm="6">
            <div class="labels">Input Units</div>
            <div class="input">
                <input type="text" v-model="inputunit" @keyup="checkresult" class="custom" />
                <span class="material-icons controls" @click="quantity('minus')">remove</span>
                <span class="material-icons controls" @click="quantity('plus')">add</span>
            </div>
        </v-col>
        <v-col cols="6" md="6" sm="6">
            <div class="labels">Total</div>
            <div class="total"><span class="displaycurr" v-html="currency(total)"></span></div>
        </v-col>
    </v-row>
       <div v-if="error" class="red--text smallfonts">{{ errorText }}</div>
      </v-card-text>
      <v-card-text v-else>
        <div class="bold pt-8 pb-8">{{language}}</div>
      </v-card-text>
      <v-card-actions class="justify-center action pb-5" v-if="selectedPlayer">
        <v-btn x-large class="primary" :disabled=readonly @click="confirmbuyteam">Confirm</v-btn>
      </v-card-actions>
    </v-card>
   </v-dialog>
</template>
<script>
export default {
    name: 'Buyteam',
    props: {
        teamdialog: {
            type: Boolean
        },
        selectedPlayer:{
                type: Object
        },
        language:{
            required: false
        }
    },
    data(){
        return {
            readonly:false,
            error: false,
            errorText:'',
            inputunit: 0,
            total:0,
            
        }
    },
    watch: {
      teamdialog(visible) {
      if (visible) {
         this.error          = '';
         this.errorText      = '';
         this.inputunit      = '';
         this.total          = 0;  
      } else {
         this.error          = '';
         this.errorText      = '';
         this.inputunit      = '';
         this.total          = 0;  
      }
    }
    },
    methods: 
    {
      closeteamDialog() 
      {
        this.$emit('update:teamdialog', false);
      },
      confirmbuyteam()
      {
         var puchasetype = "";
        if(this.$route.name == "dividend")
         {
           puchasetype = "Dividend";
         } 
        if(this.inputunit == 0)
        {
            this.error     = true; 
            this.errorText = "Please select unit";
        }
        else
        {
            this.error    = false;
            this.readonly = true;
            var teamid    = this.selectedPlayer.id;
            var unit      = this.inputunit;
            this.$store.dispatch("buyTeam",{"teamid":teamid,"unit":unit,"purchasetype":puchasetype}).then((response) => 
            {
                this.readonly = false;
                if(response.data.status == "success")
                {
                    this.readonly = false;
                    this.closeteamDialog();
                    this.$store.dispatch("retrieveBalance");
                    //this.$router.push("/portfolio?choose=team");
                    this.$router.push({ name: 'portfolio' });
                }
                else 
                {
                    this.error     = true; 
                    this.errorText  = response.data.message;
                }
            });
         }
       },
       checkresult()
       {
           var coin     = localStorage.getItem('coin') || 0;
           if(this.inputunit > 0 ||this.inputunit <= this.selectedPlayer.availability)
           {
            //var coin     = localStorage.getItem('coin') || 0;
            //var multiply = localStorage.getItem('coinvalue') || 1;
            var total   = 0;
                total   = this.selectedPlayer.price*this.inputunit;

            if(coin == 1)         
            this.total = Math.round(total);  
            else
            this.total = total.toFixed(2); 
           }
       }, 
       quantity(action)
       {
           if(action == 'plus') 
           {
                if(this.inputunit == '')
                {
                    this.inputunit = 0;
                }
                this.inputunit = parseInt(this.inputunit)+1;
                this.error =false;
           }
           else
           {
               if(this.inputunit > 0)
               {
                 this.inputunit = parseInt(this.inputunit)-1;
               }
               else
               {
                 this.inputunit = 0;
               }
           }
           var total = this.inputunit*this.selectedPlayer.price;
           this.total = total.toFixed(2);
       },            
     },
   };
</script>