<template>
  <div class="fill-height">
    <v-app-bar app class="header" color="white" v-if="loggedIn" >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" /> 
      <span class="headertitle">
        {{ pagetitle }}
      </span>
      <span v-if="$route.name === 'dividend'" class="diffbalance">
        <div>Balance </div>
        <div>${{ dividendBalance }}</div>
      </span> 
      <span v-if="checkshowhelp($route.name)" title="Help" class="helpcls material-icons" @click="gethelp($route.name)">help</span>
      <v-spacer></v-spacer>
      <div class="topbalance marginright30 notformobile">
        <span class="toptitle">Bonus Bank</span>
        <span class="balance" ><span class="displaycurr black--text" v-html="currency(getbonusbank)"></span></span>
      </div>
      <div class="topbalance marginright30 notformobile">
         <span class="toptitle" @click="opendeposit">DamnN Bank</span>
         <span class="balance" @click="opendeposit"><span class="displaycurr red--text" v-html="currency(getinbankbalance)"></span></span>
       </div>
       <div class="topbalance marginright30 notformobile">
         <span class="toptitle">PP Bank</span>
         <span class="balance"><span class="displaycurr" v-html="currency(getbalance)"></span></span>
       </div>
        <div class="topbalance marginright10 notformobile">
         <div class="toptitle">Kels</div>
         <span class="balance"><span class="displaycurr" v-html="getkels"></span></span>
       </div>
       <v-avatar class="topavatar">
         <img :src="getavatar" >
       </v-avatar>
       <span class="topname">{{ name }}</span>
        <v-menu
        bottom
        offset-y
        min-width="200px">
        <template v-slot:activator="{ on, attrs }">
         <span class="material-icons" v-bind="attrs" v-on="on" >keyboard_arrow_down</span>
        </template>
        <v-list class="topbarmenu">
          <v-list-item><router-link :to="{name: 'personal'}">Personal Information</router-link></v-list-item> 
          <v-list-item><router-link :to="{name: 'changepassword'}">Change Password</router-link></v-list-item> 
          <v-list-item><a @click="opendeposit">Deposit</a></v-list-item> 
          <v-list-item><a @click="openwithdraw">Withdraw</a></v-list-item> 
          <v-list-item style="display:none"><router-link :to="{name: 'invitefriend'}">Invite Friend</router-link></v-list-item> 
          <v-list-item><router-link :to="{name: 'logout'}">Logout</router-link></v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer  light v-model="drawer" app class="sidebarcss" v-if="loggedIn">
    <div class="sidebarlogo">
        <img class="vimg v-imageback" :src="logo"  />
    </div>
    <v-list nav dense>
      <v-list-item class="displayonmob">
        <div class="topbalance  marginright7">
          <span class="toptitle" @click="opendeposit">Bonus Bank</span>
          <span class="balance" @click="opendeposit"><span class="displaycurr black--text" v-html="currency(getbonusbank)"></span></span>
        </div>
          <div class="topbalance marginright7 ">
             <span class="toptitle" @click="opendeposit">DamnN Bank</span>
             <span class="balance" @click="opendeposit"><span class="displaycurr " v-html="currency(getinbankbalance)"></span></span>
           </div>
           <div class="topbalance marginright7">
             <span class="toptitle">PP Bank</span>
             <span class="balance"><span class="displaycurr " v-html="currency(getbalance)"></span></span>
           </div>
            <div class="topbalance marginright7">
             <div class="toptitle">Kels</div>
             <span class="balance"><span class="displaycurr " v-html="getkels"></span></span>
           </div>
         </v-list-item>
      <v-list>
        <v-list-item link :to="{ name: 'home' }"  :class="'/' === $route.path ? 'highlighted' : ''">
          <v-list-item-icon><span class="material-icons">home</span></v-list-item-icon>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list  expand dense>
          <v-list-group  :value="expand_locations_true"   class="otherhigh" no-action>
            <template v-slot:activator>
               <v-list-item-title>Live Score</v-list-item-title>
            </template>
            <template #prependIcon><span class="material-icons">trending_up</span></template>
            <template>
              <v-list-item link :to="{ name: 'livescore' }"  :class="'/live-score' === $route.path ? 'highlighted' : ''">
                <v-list-item-title>World Sports Live</v-list-item-title>
              </v-list-item>
              <v-list-item link :to="{ name: 'fantasylivescore' }"  :class="'/fantasy-live-score' === $route.path ? 'highlighted' : ''">
                <v-list-item-title>Real Fantasy Live</v-list-item-title>
              </v-list-item>
              </template>
              
            </v-list-group>
         </v-list>
        <!--<v-list-item link :to="{ name: 'livescore' }"  :class="'/live-score' === $route.path ? 'highlighted' : ''">
          <v-list-item-icon><span class="material-icons">trending_up</span></v-list-item-icon>
          <v-list-item-title>Live Score</v-list-item-title>
        </v-list-item>-->
        <v-list-item link :to="{ name: 'hotornot' }"  :class="'/hot-or-not' === $route.path ? 'highlighted' : ''">
          <v-list-item-icon><span class="material-icons">search</span></v-list-item-icon>
          <v-list-item-title>Hot or Not</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'bonus' }"  :class="'/bonus' === $route.path ? 'highlighted' : ''">
          <v-list-item-icon><span class="material-icons">monetization_on</span></v-list-item-icon>
          <v-list-item-title>Hat Trick</v-list-item-title>
        </v-list-item>
     
        <v-list v-if="gameList.length > 0" expand dense>
          <v-list-group prepend-icon="mdi-run" :value="expand_locations_true"   class="otherhigh" no-action>
            <template v-slot:activator>
               <v-list-item-title>World Sport UX</v-list-item-title>
            </template>
            <template>
              <v-list-group  no-action :value="expand_locations_players" class="innergrouplist" prepend-icon="$expand">
                <template v-slot:activator>
                  <v-list-item-content><v-list-item-title>Players</v-list-item-title></v-list-item-content>             
                </template>
                <template v-for="child in gameList" >
                  <template> 
                    <v-list-group class="innergames" no-action sub-group="player" :key="child.id" v-if="child.leagues.length > 0 && child.disable == 'N'">
                      <template  v-slot:activator>
                        <img :src="child.web_icon" class="sideicon"> <span class="v-list-item" v-html="child.name"></span>
                      </template>   
                      <template v-for="league in child.leagues">
                        <v-list-item :key="league.id" :to="{ name: 'damnnpx', params:{ sport: league.name }}" v-if="child.id == 28">
                          <v-list-item-title class=" d-flex"><img :src="league.leaguelogo" class="sideiconnew"><span v-html="league.name"></span></v-list-item-title>
                        </v-list-item>     
                        <v-list-item  :key="league.id"  :to="{ name: 'damnnpx', params:{ sport: child.name, league: league.id }}" v-else>
                          <v-list-item-title class=" d-flex"><img :src="league.leaguelogo" class="sideiconnew"><span v-html="league.name"></span></v-list-item-title>
                        </v-list-item>     
                      </template>
                    </v-list-group> 
                    <v-list-item v-if="child.leagues.length == 0 && child.disable == 'N' && child.leagueid != ''" :key="child.id" link :to="{ name: 'damnnpx', params:{ sport: child.name, league: child.leagueid }}">
                      <img :src="child.web_icon" class="sideicon"><v-list-item-title class="nothightlight" v-text="child.name"></v-list-item-title>
                    </v-list-item>  
                    <v-list-item v-if="child.leagues.length == 0 && child.disable == 'N' && child.leagueid == ''" :key="child.id" link :to="{ name: 'damnnpx', params:{ sport: child.name  }}">
                      <img :src="child.web_icon" class="sideicon"><v-list-item-title class="nothightlight" v-text="child.name"></v-list-item-title>
                    </v-list-item>  
                   </template> 
                  </template>
                </v-list-group>
              </template>
              <v-list-group no-action group="teams" :value="false" class="innergrouplist" prepend-icon="$expand">
                <template v-slot:activator>
                  <v-list-item-content><v-list-item-title>Teams</v-list-item-title></v-list-item-content>
                </template>
                <template v-for="child in teamList" >
                  <template> 
                    <v-list-group class="innergames" no-action sub-group="teams" :value="false" :key="child.id" v-if="child.leagues.length > 0">
                     <template v-slot:activator>
                       <img :src="child.web_icon" class="sideicon"> <span class="v-list-item" v-html="child.name"></span>
                     </template> 
                     <template v-for="league in child.leagues" >
                     <v-list-item v-if="league.name != 'Australia'"  :key="league.id"  :to="{ name: 'teams', params:{ sport: child.name, country: league.id }}">
                       <v-list-item-title class=" d-flex">
                          <img v-if="league.leaguelogo" :src="league.leaguelogo" class="sideiconnew"> <span v-html="league.name"></span>
                       </v-list-item-title>
                     </v-list-item>   
                     <v-list-item v-else :key="league.id">
                       <template>
                        <v-list-group no-action group="teams" :value="false" class="innergrouplistlat" >
                          <template v-slot:activator>
                            <v-list-item-content><v-list-item-title>{{league.name}}</v-list-item-title></v-list-item-content>
                          </template>
                          <template v-for="child in league.leagues" >
                              <v-list-group :key="child.id" no-action  class="innergrouplist" >
                                <template v-slot:activator>
                                  <v-list-item  link :to="{ name: 'teams', params:{ sport: league.id, country:'australia', fantasyid:child.id }}">
                                    <img :src="child.leaguelogo" class="sideicon"><v-list-item-title class="nothightlight" v-text="child.name"></v-list-item-title>
                                  </v-list-item>  
                                            
                              </template>
                             </v-list-group>
                             
                           </template>
                         </v-list-group>
                       </template> 
                     </v-list-item> 
                    </template>   
                    </v-list-group> 
                    <v-list-item v-else :key="child.id" link :to="{ name: 'teams', params:{ sport: child.name }}">
                      <img :src="child.web_icon" class="sideicon"><v-list-item-title class="nothightlight" v-text="child.name"></v-list-item-title>
                    </v-list-item>  
                   </template> 
                  </template>
                </v-list-group>
            </v-list-group>
         </v-list>
         <v-list v-if="getFantasyGameList" expand dense>
          <v-list-group  :value="expand_locations_true"   class="otherhigh" no-action>
            <template v-slot:activator>
               <v-list-item-title>Real Fantasy UX</v-list-item-title>
            </template>
            <template #prependIcon><img class="fantasy-logo" src="../../assets/fantasy-logo.png" /></template>
            <template>
              <v-list-group  no-action :value="expand_locations_players" class="innergrouplist" prepend-icon="$expand">
                <template v-slot:activator>
                  <v-list-item-content><v-list-item-title>Players</v-list-item-title></v-list-item-content>             
                </template>
                <template v-for="child in getFantasyGameList" >
                  <template> 
                    <v-list-item  :key="child.id" link :to="{ name: 'damnnpx', params:{ sport: 'fantasy-'+child.small, league: child.id }}">
                      <img :src="child.leaguelogo" class="sideicon"><v-list-item-title class="nothightlight" v-text="child.name"></v-list-item-title>
                    </v-list-item>  
                   </template> 
                  </template>
                </v-list-group>
              </template>
              <v-list-group no-action group="teams" :value="false" class="innergrouplist" prepend-icon="$expand">
                <template v-slot:activator>
                  <v-list-item-content><v-list-item-title>Teams</v-list-item-title></v-list-item-content>
                </template>
                <template v-for="child in getFantasyGameList" >
                  <template> 
                   <!--
                     <v-list-item :key="child.id" link :to="{ name: 'teams', params:{ sport: child.name, country:'fantasy', fantasyid:child.id }}">
                      <img :src="child.leaguelogo" class="sideicon"><v-list-item-title class="nothightlight" v-text="child.name"></v-list-item-title>
                    </v-list-item> 
                   -->
                   <v-list-group :key="child.id" no-action :value="expand_locations_players" class="innergrouplist" prepend-icon="$expand">
                    <template v-slot:activator>
                      <v-list-item-content>
                        <v-list-item  link :to="{ name: 'teams', params:{ sport: child.name, country:'fantasy', fantasyid:child.id }}">
                         <img :src="child.leaguelogo" class="sideicon"><v-list-item-title class="nothightlight" v-text="child.name"></v-list-item-title>
                       </v-list-item>  
                     </v-list-item-content>             
                   </template>
                <template  >
                  <template> 
                    <v-list-item  class="litdiff" link :to="{ name: 'fantasyladder', params:{ sport: child.name, leagueid:child.id }}">
                      <img src="../../assets/ladder.png" class="sideicon"> Ladder
                    </v-list-item>  
                    <v-list-item  class="litdiff" link :to="{ name: 'fantasyfixtures', params:{ sport: child.name, leagueid:child.id }}">
                      <img src="../../assets/fixture.png" class="sideicon"> Fixture
                    </v-list-item>  
                   </template> 
                  </template>
                </v-list-group>
                   </template> 
                  </template>
                </v-list-group>
            </v-list-group>
         </v-list>  
        <v-list-item link :to="{ name: 'portfolio' }" :class="'portfolio' === $route.name ? 'highlighted' : ''">
          <v-list-item-icon><span class="material-icons">local_mall</span></v-list-item-icon>
          <v-list-item-title>Portfolio</v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'dividend' }" :class="'dividend' === $route.name ? 'highlighted' : ''">
          <v-list-item-icon><span class="material-icons">price_change</span></v-list-item-icon>
          <v-list-item-title>Dividend</v-list-item-title>
        </v-list-item>
        
        <v-list-item link :to="{ name: 'buynsell' }"  :class="'/buy-n-sell' === $route.path ? 'highlighted' : ''">
          <v-list-item-icon><span class="icon-Group-1286 cusfonts"></span></v-list-item-icon>
          <v-list-item-title>Marketplace </v-list-item-title>
        </v-list-item>
        <v-list-item link :to="{ name: 'transactionhistory' }"  :class="'/transaction-history' === $route.path ? 'highlighted' : ''">
          <v-list-item-icon><span class="icon-Vector2x cusfonts"></span></v-list-item-icon>
          <v-list-item-title>Transaction History</v-list-item-title>
        </v-list-item>
        <v-list-item target="_blank" link href="https://ads.damnnint.com/">
          <v-list-item-icon>
            <v-icon>mdi-alpha-a-box-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Advertise on Sportstrade</v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon><span class="material-icons">quiz</span></v-list-item-icon>
          <v-list-item-title><a style="color:black" href="https://sportstrade.online/faq.html" target="_blank">FAQ's</a></v-list-item-title>
        </v-list-item>
      
        <v-list-item style="display:none" link :to="{ name: 'invitefriend' }"  :class="'/invite-friend' === $route.path ? 'highlighted' : ''">
          <v-list-item-icon><span class="material-icons">settings_accessibility</span></v-list-item-icon>
          <v-list-item-title>Invite Friend</v-list-item-title>
        </v-list-item>
        <v-list-item class="sportlogTab">   
           <span class="logochoose">Choose Sport to Trade </span>       <br>
           <div class="sportlogos">
            <div v-for="item in gameList" :key="item.id">
            <router-link  style="margin-left:10px"  :to="{ name: 'teams', params:{ sport: item.name }}" v-if="item.id == 26 || item.id == 27">
              <img :src="item.web_avatar" style="max-width:70px">
            </router-link>
            <span @click="openncaa" style="margin-left:10px;" v-else-if="item.id == 29">
              <img :src="item.web_avatar" style="max-width:61px;cursor:pointer">
               <v-dialog transition="dialog-bottom-transition" max-width="400" v-model="ncaadialog">
                <v-card>
                  <v-card-text class="d-flex showleagues">
                       <template v-for="league in item.leagues">
                        <div class="myblock" :key="league.id" >
                          <a @click="gotoncaa(league.id)">
                            <img :src="league.leaguelogo" class="sideiconnew">
                            <div v-html="league.name"></div>
                          </a>
                        </div> 
                      </template>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </span>
            <router-link  style="margin-left:10px"  :to="{ name: 'damnnpx', params:{ sport: item.name }}" v-else>
              <img :src="item.web_avatar" style="max-width:70px">
            </router-link>
           </div>  
           </div>
        </v-list-item>
      </v-list>
     </v-list> 
    </v-navigation-drawer>
    <v-dialog transition="dialog-bottom-transition" max-width="400"  v-model="dialogwithdraw" content-class="depositmodal">
      <v-card class="rounded-xl">
        <v-card-text>
           <div class="popupbody">
             <div class="row">
               <div class="heading col-md-10 col-xs-10">Withdraw</div>
               <div class="closebtn col-md-2 col-xs-2">
                  <span class="material-icons closeicon" @click="closewithdraw">close</span>
               </div>
             </div> 
             
             <div v-if="hideallthing">
               <div class="row paybtnsdiv" v-if="paymodeselected == 0">
                 <v-btn color="themebtn paybtns" @click="setpaymethod('paypal')" x-large dark>Paypal Withdraw</v-btn>
                 <!--<v-btn color="primary paybtns" @click="setpaymethod('gift')" x-large dark>Gift Pay</v-btn>-->
               </div>
               <div v-else> 
                 <div class="text-left mb-5" ><a @click="paymentback"><span class="material-icons">arrow_back</span></a></div>
                 <div v-if="paymentmethod == 'gift'">
                    
                    <div class="text-center" v-if="ratesloader">
                      <v-progress-circular  :size="50" color="primary" indeterminate></v-progress-circular>
                    </div>  
                    <div v-else>
                    <v-select
                      v-model="senddcvalue"
                      :items="giftcarddcvalues"
                      item-text="values"
                      item-value="keys"
                      label="Select DC"
                      return-object
                      outlined></v-select>
                    <v-select
                      v-model="sendregion"
                      :items="giftcardvalue"
                      item-text="regionname"
                      item-value="regioncode"
                      label="Select Region"
                      return-object
                      outlined></v-select>
                    <div class="text-center red--text" v-if="error">{{ errorText }}</div>
                    <v-btn color="primary paybtns" @click="giftwithdraw" x-large dark>Withdraw</v-btn>
                   </div>
                 </div>
                 <div v-else>
                  <div v-if="paypalid != ''">
                    <v-row align="center" justify="center">
                      <v-col col="8" sm="8" md="8">Available Balance</v-col> 
                      <v-col col="4" sm="4" md="4" class="removepad">
                        <span class="displaycurr" v-html="currency(validamount)"></span>
                      </v-col> 
                    </v-row>
                    <v-row align="center" justify="center">
                      <v-col col="8" sm="8" md="8">Withdrawl Amount</v-col> 
                      <v-col col="4" sm="4" md="4" class="removepad d-flex withdrawl">
                          <div class="displaycurrency" v-html="currencysymbol()"></div>  
                          <v-text-field
                              hide-details="auto"
                              class="withdrawlinput"
                              placeholder="Amount"
                              v-model="withdrawamount"></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row v-if="checkDamncoin == 1">
                      <v-col class="text-center">${{ withdrawamount/100 }}</v-col>
                    </v-row>
                    <div v-if="error" class="text-center red--text smallfonts">{{ errorText }}</div>
                      <v-row align="center" justify="center" class="mt-3">
                        <v-col col="12" sm="12" md="12" class="text-center">
                            <v-btn 
                              depressed 
                              color="primary"
                              :disabled="makedisabled"
                              @click="withdraw">
                              Withdraw
                            </v-btn>
                        </v-col> 
                      </v-row>
                    </div>
                    <div class="text-center action pb-5" v-if="paypalid == ''">
                      <v-btn :href="paypalhref" x-large class="paypalbtn">Connect with Paypal</v-btn>
                    </div>  

                 </div>
               </div>
             </div>
           </div> 
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog transition="dialog-bottom-transition" max-width="400"  v-model="dialogdeposit" content-class="depositmodal">
      <v-card class="rounded-xl">
        <v-card-text >
           <div class="popupbody">
            <div class="row">
              <div class="heading col-md-10 col-xs-10">Deposit</div>
              <div class="closebtn col-md-2 col-xs-2">
              <span class="material-icons closeicon"  @click="closedeposit">close</span>
            </div>
           </div> 
           <div v-if="depositscreenfirst">
              <div class="totalamnt text-center">
                <div class="headfirst">Total Balance</div>
                 <div class="amntdiv mt-5 myflex">
                     <div>${{depositprice}}</div>  
                     <div class="equalsto" v-if="checkDamncoin == 1"> = </div>  
                     <div class="d-flex" v-if="checkDamncoin == 1">
                       <div><img src="@/assets/transaction.png"></div><div>{{depositprice*100}}</div>
                     </div>
                 </div>
              </div>
              <div class="pricerange mt-5 pb-5">
                 <v-slider step="1" min="1" max="10000" disabled track-color="white" tick-size=30 color="themebtn" v-model="depositprice"></v-slider>
              </div> 
              <div class="pricebtns">
                <div class="prices" @click="changeprice(1)" :class="{'active':depositprice == 1  }">
                  <div class="pricesymbol">$1</div>
                  <div class="pricevalue" v-if="checkDamncoin == 1">
                    <div>=</div>
                    <div>
                       <div><img src="@/assets/transaction.png"></div> 
                       <div>100</div>
                     </div>
                  </div>
                </div>
                <div class="prices" @click="changeprice(10)" :class="{'active':depositprice == 10  }">
                  <div class="pricesymbol">$10</div>
                  <div class="pricevalue" v-if="checkDamncoin == 1">
                    <div>=</div>
                    <div>
                       <div><img src="@/assets/transaction.png"></div> 
                       <div>100</div>
                     </div>
                  </div>
                </div>
                <div class="prices" @click="changeprice(50)" :class="{'active':depositprice == 50  }">
                  <div class="pricesymbol">$50</div>
                  <div class="pricevalue" v-if="checkDamncoin == 1">
                     <div>=</div>
                     <div>
                       <div><img src="@/assets/transaction.png"></div> 
                       <div>500</div>
                     </div>
                  </div>
                </div>
                <div class="prices" @click="changeprice(100)" :class="{'active':depositprice == 100  }">
                  <div class="pricesymbol">$100</div>
                  <div class="pricevalue" v-if="checkDamncoin == 1">
                    <div>=</div>
                    <div>
                       <div><img src="@/assets/transaction.png"></div> 
                       <div>1000</div>
                     </div>
                  </div>
                </div>
                <div class="prices" @click="changeprice(500)" :class="{'active':depositprice == 500  }">
                  <div class="pricesymbol">$500</div>
                  <div class="pricevalue" v-if="checkDamncoin == 1">
                     <div>=</div>
                     <div>
                       <div><img src="@/assets/transaction.png"></div> 
                       <div>50000</div>
                     </div>
                  </div>
                </div>
                <div class="prices" @click="changeprice(1000)" :class="{'active':depositprice == 1000  }">
                  <div class="pricesymbol">$1000</div>
                  <div class="pricevalue" v-if="checkDamncoin == 1">
                     <div>=</div>
                     <div>
                       <div><img src="@/assets/transaction.png"></div> 
                       <div>100000</div>
                     </div>
                  </div>
                </div>
               </div>
                <div class="text-center mt-10">
                <v-text-field
            label="Add Balance"
            v-model="depositprice"
            type="number"
            outlined
          ></v-text-field>
             </div>
             </div> 
            
           </div>
           <div v-if="!depositscreenfirst">
             <div class="text-left"><a @click="depoback"><span class="material-icons">arrow_back</span></a></div>
             <div class="totalbalance" v-bind:style="{ 'background-image': 'url(' + depostieback + ')' }">
                <div class="inneramt">
                  <div class="leftside">Pack</div>
                  <div class="rightside myflex">
                     <div>${{depositprice}}</div>  
                     <div class="equalsto"  v-if="checkDamncoin == 1"> = </div>  
                     <div class="d-flex"  v-if="checkDamncoin == 1">
                       <div><img class="smallimgs" src="@/assets/transaction.png"></div><div>{{depositprice*100}}</div>
                     </div>
                  </div>
                </div>
             </div>
             <div class="totalbalance  mt-3" v-bind:style="{ 'background-image': 'url(' + depostieback + ')' }">
                <div class="inneramt">
                  <div class="leftside">Paypal Fee</div>
                  <div class="rightside myflex">
                     <div>${{ getprentage(depositprice) }}</div>  
                      <div class="equalsto minwidth" v-if="checkDamncoin == 1"></div>  
                     <div class="d-flex lastwith minwidth" v-if="checkDamncoin == 1">
                       
                     </div>
                  </div>
                </div>
             </div>
             <div class="totalbalance mt-3" v-bind:style="{ 'background-image': 'url(' + depostieback + ')' }">
                <div class="inneramt">
                  <div class="leftside">Total</div>
                  <div class="rightside myflex">
                     <div>${{gettotalvalue(depositprice)}}</div>  
                     <div class="equalsto minwidth" v-if="checkDamncoin == 1"></div>  
                     <div class="d-flex lastwith minwidth" v-if="checkDamncoin == 1">
                       
                     </div>
                  </div>
                </div>
             </div>
             <div class="payelem mt-5" style="display:none">
                <div class="d-flex">
                  <div class="babuflexfull exptime">
                     <div class="label-expriry">
                        <div>Card No.</div>
                     </div>
                     <div class="input-group-custom-full">
                      <div class="input-gup-cus-inner ">
                        <input type="tel" v-cardformat:formatCardNumber ref="cardNumInput" :data-error="(cardErrors.cardNumber)?true:false" v-model="cardno" />
                      </div>
                     </div> 
                  </div> 
                </div>  
                <div v-if="cardErrors.cardNumber" class="error mb-5">
                    <small>{{ cardErrors.cardNumber }}</small>
                </div>  
                <div class="d-flex mt-5">
                  <div class="babuflex exptime">
                     <div class="label-expriry">
                        <div>Exp. Date</div>
                     </div>
                     <div class="input-group-custom">
                      <div class="input-gup-cus-inner input-gup-cus-1">
                       <input v-model="expdate" ref="cardExpInput" id="card-exp" :data-error="(cardErrors.cardExpiry)?true:false" maxlength="10" v-cardformat:formatCardExpiry>
                      </div>
                     </div>
                  </div>
                  
                  <div class="babuflex exptime cvv">
                     <div class="label-expriry">
                        <div>CVC</div>
                     </div>  
                     <div class="input-group-custom-new">
                      <div class="input-gup-cus-inner input-gup-cus">
                        <input type="text" ref="cardCvcInput" :data-error="(cardErrors.cardCvc)?true:false" v-cardformat:formatCardCVC  v-model="cvv" />
                      </div>
                     </div>
                  </div>
                </div>
                 <div v-if="cardErrors.cardExpiry || cardErrors.cardCvc" class="mb-5 validate">
                     <div v-if="cardErrors.cardExpiry" class="firstblock error"><small>{{ cardErrors.cardExpiry }}</small></div>
                     <div v-if="cardErrors.cardCvc" class="secondblock error"><small>{{ cardErrors.cardCvc }}</small></div>
                 </div>
                 <div class="d-flex mt-5">
                  
                  <div class="babuflexfull exptime">
                     <div class="label-expriry">
                        <div>Card Holder Name</div>
                     </div>
                     <div class="input-group-custom-full">
                      <div class="input-gup-cus-inner ">
                        <input type="text" placeholder="" v-model="cardholder" />
                      </div>
                     </div> 
                  </div>   
                </div> 
             </div>
           </div> 
        <div v-if="error" class="red--text smallfonts">{{ errorText }}</div>
      </v-card-text>
      <v-card-actions class="justify-center action pb-5">
        <v-btn x-large class="primary" :disabled="makedisable" v-if="depositscreenfirst" @click="nextdepositscreen">Next</v-btn>
        <!--<v-btn x-large class="primary" :disabled="makedisable" v-else @click="confirmdeposit">Confirm</v-btn>-->
        <div v-else id="paypal-button"></div>
      </v-card-actions>
    </v-card>
   </v-dialog>
    <v-content class="fill-height background" >
      <router-view />
    </v-content>
    
    <v-dialog
      v-model="showhelpdialog"
      width="650px">
      <v-card>
        <v-card-text>
          <div class="pt-5 helpcontent" v-html="showhelphtml"></div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="showhelpdialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
     </v-dialog>
     <v-dialog
      @click="openInGame"
      v-model="ingamepop"
      width="350px">
      <v-card>
        <v-card-text>
          <div class="pt-15 informtext">Unable to purchase while game is in progress.</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        
          <v-btn
            color="green darken-1"
            text
            @click="ingamepop = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
     </v-dialog>
  </div>
</template>

<script>  
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vue from 'vue'
import LoadScript from 'vue-plugin-load-script';

Vue.use(LoadScript);
Vue.use(VueAxios, axios)
/*
import {
  Card,
  createToken,
  createPaymentMethod
} from "vue-stripe-elements-plus";*/

axios.defaults.baseURL = process.env.VUE_APP_BASEAPI;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
Vue.mixin({
  methods: {
    numberWithCommas(x){
       return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }, 
    currencysymbol: function(){
       var coin = localStorage.getItem('coin') || 0;
       var symbol   = "$";
       if(coin == 1)  
             {
                symbol = "<img src='"+require("@/assets/transaction.png")+"'>";
             } 

        return symbol;      
    },
    currencyvalue: function(){
      var multiply = localStorage.getItem('coinvalue') || 1;

      return multiply; 
    },
    simplecurrenc(currency = ""){
      var newprice = "";
      if(this.$store.getters.loggedIn) 
      {
        // var coin = localStorage.getItem('coin') || 0;
         var multiply = localStorage.getItem('coinvalue') || 1;
         if(currency >= 0)
         {
             var dispaycurrency = Math.round(multiply*currency);
             newprice = "<div class='currval'>"+dispaycurrency+"</div>";
         }
         else
         {
           if(typeof currency != 'undefined')
           {
              
             var splitcurrency = currency.split(" - ");
            
            if(splitcurrency.length == 2)
            {
               var firstcurrval = splitcurrency[0].split("$");
               var firstcurr    = Math.round(parseInt(firstcurrval[1])*multiply);
               
               var secondcurrval = splitcurrency[1].split("$");
               var secondcurr    = Math.round(secondcurrval[1]*multiply);
              
               newprice = "<div class='currval'>"+firstcurr+"</div>&nbsp; -  &nbsp;"+
                          "<div class='currval'>"+secondcurr+"</div>";
            } 
            else
            {
                var anotherval = splitcurrency[0].split("$");
                var anothervalcurr    = Math.round(parseInt(anotherval[1])*multiply);

                newprice = "<div class='currval'>"+anothervalcurr+"</div>";
            } 
           } 
         } 
          
       } 
       return newprice; 
    },
    currency: function (currency="") {
      var newprice = "";
      if(this.$store.getters.loggedIn) 
      {
         var coin     = localStorage.getItem('coin') || 0;
         var multiply = localStorage.getItem('coinvalue') || 1;
         
         var symbol   = "$";
         if(currency >= 0)
         {
             if(coin == 1)  
             {
                symbol = "<img src='"+require("@/assets/transaction.png")+"'>";
             } 

             var dispaycurrency = currency;  
             if(coin == 1)
             {
               dispaycurrency = multiply*currency;
               dispaycurrency = Math.round(dispaycurrency); 
             }
             else
             {
               dispaycurrency = parseFloat(currency).toFixed(2);
             }
             
             newprice = "<div class='cursym'>"+symbol+"</div><div class='currval'>"+dispaycurrency+"</div>";
         }
         else if(currency < 0)
         {
           if(coin == 1)  
             {
                symbol = "<img src='"+require("@/assets/transaction.png")+"'>";
             } 

              if(coin == 1)
             {
               dispaycurrency = multiply*currency;
               dispaycurrency = Math.round(dispaycurrency); 
             }
             else
             {
               dispaycurrency = parseFloat(currency).toFixed(2);
             }
             newprice = "<div class='cursym'>"+symbol+"</div><div class='currval'>"+dispaycurrency+"</div>";
         }   
         else
         {
            if(typeof currency != 'undefined')
            {
               
              var splitcurrency = currency.split(" - ");
           // console.log(splitcurrency);
            if(coin == 1)  
            {
                symbol = "<img src='"+require("@/assets/transaction.png")+"'>";
            } 

            if(splitcurrency.length == 2)
            {
               var firstcurrval = splitcurrency[0].split("$");
               var firstcurr    = 0;
               if(coin == 1)
                {
                  dispaycurrency = firstcurrval[1]*multiply;
                  firstcurr      = Math.round(dispaycurrency); 
                }
                else
                {
                  firstcurr = parseFloat(firstcurrval[1]).toFixed(2);
                }

               var secondcurrval = splitcurrency[1].split("$");
               var secondcurr    = 0;
               if(coin == 1)
                {
                  dispaycurrency = secondcurrval[1]*multiply;
                  secondcurr      = Math.round(dispaycurrency); 
                }
                else
                {
                  secondcurr = parseFloat(secondcurrval[1]).toFixed(2);
                }
              
               newprice = "<div class='cursym'>"+symbol+"</div><div class='currval'>"+firstcurr+"</div>&nbsp; -  &nbsp;"+
                          "<div class='cursym'>"+symbol+"</div><div class='currval'>"+secondcurr+"</div>";
            } 
            else
            {
                var anotherval = splitcurrency[0].split("$");
                var anothervalcurr  = 0;
                 if(coin == 1)
                {
                  dispaycurrency  = anotherval[1]*multiply;
                  anothervalcurr  = Math.round(dispaycurrency); 
                }
                else
                {
                  anothervalcurr  = parseFloat(anotherval[1]).toFixed(2);
                }
                
                
                newprice = "<div class='cursym'>"+symbol+"</div><div class='currval'>"+anothervalcurr+"</div>";
            } 
            }
         } 
          
       } 
       return newprice; 
    },
  }
})
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      ingamepop:false,
      expand_locations_true:false,
      expand_locations_players: false,
      expand_locations_teams: false,
      depositmsg:'',
      referenceno:'',
      makedisable:false,
      cardno:'',
      ncaadialog:false,
      expdate:'',
      expmonth:'', 
      expyear:'',
      cvv:'',
      cardholder:'',
      error :'',
      errorText:'',
      drawer: null,
      depositprice:50,
      withdrawprice:50,
      dialogdeposit:false,
      depositscreenfirst:true,
      dialogwithdraw:false,
      depostieback:require("@/assets/depositeback.png"),
      logo: require("@/assets/logo.png"),
      custom1: require("@/assets/buy-sell.png"),
      custom2: require("@/assets/history.png"),
      nfllogo:require("@/assets/nfl.png"),
      afllogo:require("@/assets/afl.png"),
      f1logo:require("@/assets/f1.png"),
      epllogo:require("@/assets/epl.jpg"),
      sportlogo1: require("@/assets/example-left-hand-side_1.png"),
      sportlogo2: require("@/assets/example-left-hand-side_2.png"),
      stripeOptions: {
        hidePostalCode: true,
        style: {
          base: {
            color: "#000",
            fontSize: "16px",
            fontFamily: '"Lexend'
          }
        }
      },
      paymentAmounts: [],
      paymentAmount: "",
      balance: 0,
      apiKey: process.env.VUE_APP_STRIPE_API_KEY,
      cardErrors: {},
      $cardFormat: Vue.prototype.$cardFormat,
      referenceid:'',
      finaldeposite:0,
      withdrawamount:0,
      makedisabled:false,
      validamount:0,
      paypalid:'',
      hideallthing:false,
      paymodeselected:'',
      paymentmethod:'',
      ratesloader:'',
      giftcarddata:'',
      giftcarddcvalues: '',
      giftcardvalue: '',
      senddcvalue:'',
      sendregion:'',
      //gameList:[]
      specialcase:false,
      specialcaseOther:false,
      todaylimitcheck:0,
      showhelphtml:'',
      showhelpdialog:false
    };
  },
  /*components: { Card },*/
  watch: {
     $route() {
        if(this.$route.name == "transactionhistory")
       {
         this.drawer = false;
       } 
        if(this.$route.name == "damnnpx")
       {
          if(typeof this.$route.params.sport != 'undefined')
          {
            this.expand_locations_true = true;
            this.expand_locations_players = true;
          } 
          else
         {
            this.expand_locations_true = false;
            this.expand_locations_players = false;
         }
       }
       else
       {
         this.expand_locations_true = false;
       }
       
       if(this.$route.name == "teams")
       {
         this.expand_locations_true = true;
         this.expand_locations_teams = true;
       }
       else
       {
         this.expand_locations_teams = false;
       }
      /*
      expand_locations_players: false,
      expand_locations_teams: false,
      */
       this.specialcase = false;
       this.specialcaseOther=false;
        if(this.$route.params.sport != '' && this.$route.name == 'damnnpx')
         {
            this.specialcase = true;
            this.specialcaseOther=false;
         }
         if(typeof this.$route.params.sport == 'undefined' && this.$route.name == 'damnnpx')
         {
            this.specialcaseOther = true;
            this.specialcase = false;
         } 
     },    
      cardno: function(val){
        if(this.$cardFormat.validateCardNumber(val)){
          this.$refs.cardExpInput.focus();
        }
      },
      expdate: function (val) {
        if (this.$cardFormat.validateCardExpiry(val)) {
          this.$refs.cardCvcInput.focus();
        }
      }
    },
  created() {
     if(this.$route.name == "transactionhistory")
       {
         this.drawer = false;
       } 
       this.specialcase = false;
       this.specialcaseOther=false;
        if(this.$route.params.sport != '' && this.$route.name == 'damnnpx')
         {
            this.specialcase = true;
            this.specialcaseOther=false;
         }
         if(typeof this.$route.params.sport == 'undefined' && this.$route.name == 'damnnpx')
         {
            this.specialcaseOther = true;
            this.specialcase = false;
         } 
    this.$root.$refs.Master = this;
    this.$store.dispatch("retrieveUserDetails").then(() => {
       if(this.$route.fullPath == "/?success")
       {
         this.openwithdraw();
       }
    });
   // this.getGames();
  },
  methods: {
    openncaa(){
       this.ncaadialog = true;
    },
    gotoncaa(id){
      this.ncaadialog = false;
      this.$router.push("/teams/NCAA/"+id);
    },
    /* getGames() {
      this.$store.dispatch("getGameList").then(() => {
         this.gameList = this.$store.getters.getGameList;
      })
    },*/
    checkshowhelp(routename){
        const routearray = ["bonus", "dividend"];
        var returnvalue = false;
        if(routearray.includes(routename))
        {
           returnvalue = true;  
        } 
        return returnvalue;
    },
    gethelp(routename){
      
      var htmlval = "";
       if(routename == "bonus")
       {
          htmlval  = "A player or team has just gone up once or twice in a row on Sportstrade.<br><br>";
          htmlval += "If your player or team price goes up for a third time in a row your player or team will increase by 10% thus increasing your portfolio amount.<br><br>";
          htmlval += "You can look here at all the players and teams that have gone up once or twice in a row and see if there is someone who you think might get that ‘hat trick’ next time out.<br><br>";
          //htmlval += "This bonus hattrick payment must be at least 1 cent to be credited to your DamnN Bank. If your bonus is less than 1 cent you will recieve nothing.";
       } 
       else if(routename == "dividend")
       {
          htmlval = "This amount is a dividend that has been paid to you this month. This money can only be used here. The players or teams below are the only ones that are available this month that you can buy. This dividend is for this month only and any funds in there at the end of the month will be lost.";
       }
       this.showhelphtml = htmlval;
       this.showhelpdialog = true;
    },
    openInGame(){
      this.ingamepop = true;
    },
    giftwithdraw(){
       var amount = this.senddcvalue.values/100;
       var region = this.sendregion.regioncode;

       this.$store.dispatch("rewardPurchase",{"amount":amount,"region":region}).then((response) => {
            this.error     = true;
            this.errorText = response.data.message;
       })
    },
    closewithdraw(){
      this.dialogwithdraw = false
      this.paymentback();
    },
    paymentback(){
      this.paymentmethod = '';
      this.paymodeselected = 0;
      localStorage.removeItem('paymentmethod');
    },
    setpaymethod(mode)
    {
       this.paymentmethod   = mode; 
       this.paymodeselected = 1;
       localStorage.setItem('paymentmethod',mode);
       if(mode == 'gift')
       {
          this.giftpaydata();
       } 
       else
       {
          this.paypaldata();  
       }
    },
    openwithdraw()
    {
       var paymentmethod = localStorage.getItem('paymentmethod') || ''
       this.paymentmethod = paymentmethod; 
      
      if(paymentmethod == '')
      { 
        this.paymodeselected = 0;
      }
      else
      { 
        this.paymodeselected = 1;
      }
      this.hideallthing = true;
      if(paymentmethod == 'gift')
       {
          this.giftpaydata();
       } 
       else
       {
          this.paypaldata();  
       }
      this.dialogwithdraw = true;
    },
    paypaldata()
    {
      if(this.$store.getters.getUserdetails.paypal_payer_id != null) 
      {
        this.$store.dispatch("validamount").then((response) => 
        {
            if(typeof response.data[0] != 'undefined')
            {
              this.validamount     = response.data[0].value;
              this.todaylimitcheck = response.data[0].todayWithdrwal;
              this.paypalid = this.$store.getters.getUserdetails.paypal_payer_id;
            } 
        });
      }
    },
    giftpaydata(){
      this.ratesloader = true;
         var giftdata = [];
         var giftdcval = [];
         this.$store.dispatch("getgiftRegions").then((response) =>
         {
            if(response.status)
            {
              var regions = response.data.regions;
              this.$store.dispatch("getgiftRates").then((responsedata) => 
              {
                 var regionsval = responsedata.data;
                 var keyval = "";
                 for(var i = 0; i < regions.length; i++)
                 {
                   if(regions[i] == "America")
                     keyval = "us";
                   else if(regions[i] == "Australia")
                     keyval = "au";
                   else if(regions[i] == "United Kingdom")
                     keyval = "uk";   
                   else if(regions[i] == "New Zealand")
                     keyval = "nz";     

                   var dataval = {regionname : regions[i] +" ("+regionsval[keyval]+") ", regionvalue : regionsval[keyval], regioncode : keyval};
                   giftdata.push(dataval);
                 }
                 
               for(var j = 1; j <= 10; j++)
               {
                   var dcval = { values : j*500, keys : j*500};
                   giftdcval.push(dcval);
               }
                 this.giftcarddcvalues = giftdcval;
                 this.giftcardvalue = giftdata;
                 this.ratesloader = false;
              });    
            }
         });
    },
    gettotalvalue(price){
         var mainval   = price;
         var percent   = 0;
         var paypalfee = this.$store.getters.getcurrencySetting.paypal_fee;
         
         if(paypalfee > 0)
         {
           percent = (price/100)*paypalfee;
         }
         mainval = (parseFloat(percent)+parseFloat(mainval)).toFixed(2);
         
         this.finaldeposite = mainval;

         return mainval;
    },
    getprentage(price)
    { 
         var mainval   = 0;
         var paypalfee = this.$store.getters.getcurrencySetting.paypal_fee;
         
         if(paypalfee > 0)
         {
           mainval = (price/100)*paypalfee;
         }

         return mainval.toFixed(2);
    },
    depoback(){
      this.depositscreenfirst = true;
    },
    reset: function(){
          this.cardErrors = {};
           
    },
    withdraw(){ 
        if(this.withdrawamount == 0)
        {
          this.error = true;
          this.errorText = 'Please enter valid amount';
        }
        else if(this.todaylimitcheck > 1000)
        {
          this.error = true;
          this.errorText = 'You can withdraw $1000 in a day';
        } 
        else
        {
          //var amt = this.withdrawamount/100;
          var amt = this.withdrawamount;

          if(amt > this.validamount)
          {
            this.error = true;
            this.errorText = 'Enter valid amount';
          }
          else
          {
            this.error  = false;
            this.errorText = '';
            this.makedisabled = true;
            this.$store.dispatch("paypalWithdrawl",{"amount":amt}).then((response) => {
              if(response.data.status == 'error')
              {
                this.error = true;
                this.errorText = response.data.message;
              }
              else
              {
                this.error = true;
                this.errorText = response.data.message;
                this.$store.dispatch('retrieveBalance');
                this.dialogwithdraw = true;
                var v = this;
                setTimeout(function () {
                 v.makedisabled = false;
                 v.error = true;
                 v.errorText = '';
                 v.dialogwithdraw = false; 
                 v.withdrawamount = '';
                 v.paymentmethod = '';
                 v.paymodeselected = 0;
                 v.hideallthing = true;
               }, 3000);
              }
            });
          }
        }
    },
    changewithprice(price){
      this.withdrawprice = price;
    },
    opendeposit(){
      localStorage.setItem('price', this.depositprice);
      this.dialogdeposit = true;
      this.error = false;
      this.errorText = '';
    },
    confirmdeposit()
    {
         this.cardErrors = {};
    
          if(!this.$cardFormat.validateCardNumber(this.cardno)){
            this.cardErrors.cardNumber = "Invalid Credit Card Number.";
          }

          if (!this.$cardFormat.validateCardExpiry(this.expdate)) {
            this.cardErrors.cardExpiry = "Invalid Expiration Date.";
          }

          if (!this.$cardFormat.validateCardCVC(this.cvv)) {
            this.cardErrors.cardCvc = "Invalid CVC.";
          }
          
          if(Object.keys(this.cardErrors).length == 0)
          {
            var expdate = this.expdate.split("/");
            this.makedisable = true;
            this.$store.dispatch("paymentConfirmed",{"reference":this.referenceno,"cardnumber":this.cardno,
                                                    "expmonth":expdate[0],"expyear":expdate[1],"cvv":this.cvv,
                                                    "cardholder":this.cardholder}).then((response) => 
            {
                             if(response.data.status == "success")
                             {
                               this.makedisable = false;
                               this.error = true;
                               this.errorText = response.data.message;
                               this.referenceno = '';
                               this.cardno = '';
                               this.expmonth = '';
                               this.expyear = '';
                               this.cvv = '';
                               this.cardholder = '';
                               this.$store.dispatch('retrieveBalance');
                               
                             }       
           })
            this.dialogdeposit = false;
          } 
    },
    changeprice(price){
      this.depositprice = price;
      localStorage.setItem('price', price);
    },
    nextdepositscreen(){
      this.depositscreenfirst = false;
      this.makedisable = false;
      /*this.$store.dispatch("getpaymentToken",{"amount":this.depositprice}).then((response) => {
         this.referenceno = response.data.data.reference;
      });*/
       var mythis = this;
       this.$loadScript("https://www.paypalobjects.com/api/checkout.js").then(() => 
     {
       window.paypal.Button.render({
         //env: 'sandbox',
         client: {
          //sandbox: 'AdrGbr9KAd_Zexm_Ore20_fTxP0_lF_y4SwSaMV8KreN3PYbi71W2cAVQkeKlErjtdObHDzYEL-Q_hU9'
          production: 'AUxJGrr53OY24x1KlUwTHy4rkPvhW_Srkli1rmnRjfHyDTa00ZEgphHVJ0wkdVnWxA43KeMrPCVS39re'
         },
         locale: 'en_US',
         style: {
                  size: 'small',
                  color: 'gold',
                  shape: 'pill',
                  tagline: 'false'
                },
         commit: true,

         payment: function(resolve, reject) {
           console.log(resolve, reject);
          let returnUrl = "https://admin.sporttrade.online/faq";
          let amount = mythis.finaldeposite;

           return new Promise((resolve, reject) => {
           axios.post('create-payment', {
            return_url: returnUrl,
            amount:amount
        }, {
                headers: { 'Authorization': 'Bearer ' + localStorage.getItem('access_token') }
            })
            .then(res => {
              //console.log(res.data);
                localStorage.setItem('reference_id', res.data.paypal_intent_id.reference_id);
                resolve(res.data.paypal_intent_id.paypal_intent_id)
            })
            .catch(error => {
                reject(error)
            })
    })
    },
    
    onAuthorize: function(data) {
      
          return new Promise((resolve, reject) => {
        axios.post('execute-payment',  {
            payer_id: data.payerID,
            payment_id: data.paymentID,
            reference_id:localStorage.getItem('reference_id')
        }, {
                headers: { 'Authorization': 'Bearer ' +  localStorage.getItem('access_token') }
            })
            .then(res => {
              localStorage.removeItem('reference_id');
              localStorage.removeItem('price');
              mythis.closedeposit();
              mythis.$store.dispatch('retrieveBalance');
                resolve(res)
            })
            .catch(error => {
                reject(error)
            })
    })
    }
  }, '#paypal-button');
     });
    },
    closedeposit(){
      this.dialogdeposit = false
      this.depositscreenfirst = true;
    }
  },
  computed: {
     ...mapGetters({
      gameList: 'getGameList',
      teamList: 'getTeamList',
      getFantasyGameList: 'getFantasyGameList',
      dividendBalance: 'getdividendBalance'
    }),
    
    paypalhref(){
      var hrefval = "https://www.paypal.com/connect/?flowEntry=static&client_id=AUxJGrr53OY24x1KlUwTHy4rkPvhW_Srkli1rmnRjfHyDTa00ZEgphHVJ0wkdVnWxA43KeMrPCVS39re&scope=openid%20https://uri.paypal.com/services/paypalattributes&redirect_uri=https%3A%2F%2Fadmin.sportstrade.online%2Fapi%2Fpaypalreturn&state="+this.$store.getters.getUserid;
      return hrefval;
    },
    checkDamncoin(){
      var coin = localStorage.getItem('coin') || 0;

      return coin;
    },
    pagetitle(){
      var pagetitleval = "Home";
      
      if(this.$route.meta.title != '')
      {
         pagetitleval = this.$route.meta.title;
         
         if(typeof this.$route.params.sport == 'undefined' && this.$route.name == 'damnnpx')
         {
            pagetitleval = 'Hot or Not';   
         }
         if(this.$route.params.country == "fantasy")
         {
          pagetitleval = 'Real Fantasy UX';   
         } 
         if(this.$route.params.sport == "fantasy-basketball")
         {
          pagetitleval = 'Real Fantasy UX';   
         } 
      }

      return pagetitleval;
    },
    getavatar(){
      var avatar = require("@/assets/avatar.png");
         if(this.$store.getters.getAvatar != '') 
        {
            avatar = this.$store.getters.getAvatar;
        } 
         return avatar;
    },
    getbonusbank(){
      return this.$store.getters.getBonusBank;
    },
    getinbankbalance(){
      return this.$store.getters.inbank;
    },
    getbalance() {
      return this.$store.getters.balance;
    },
    getkels(){
      return this.$store.getters.kels;
     },
    loggedIn() {
      let loggedInvar = "";
      if(typeof this.$route.query.preview != "undefined")
      {
         loggedInvar = 1;  
      }
      else{
         loggedInvar = this.$store.getters.loggedIn;
      }
      return loggedInvar;
    },
    name() {
      return this.$store.getters.name;
    },
    email() {
      return this.$store.getters.email;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    }
  },
};
</script>

<style scoped>
.totalbalance {background-size: 100% 100%;background-repeat: no-repeat;}
.inneramt{display: flex;justify-content: space-between;padding: 15px 15px;color: white;font-size: 15px;font-weight: 400;font-family: Lexend;}
.name-title {text-transform: capitalize;}
.sidebarlogo{text-align:center;}
.background{background:#E5E5E5}
.headfirst {color: #767676;font-size:16px;font-weight:400;}
.pricerange::v-deep .v-application .primary.lighten-3{background-color:none;}
.pricerange::v-deep .v-slider--horizontal .v-slider__track-container{height:6px;}
.pricerange::v-deep .v-slider__thumb{height:20px; width:20px;background: white !important;background-color: white;border: 4px solid #247cdd;}
.pricebtns {display: flex;cursor:pointer;justify-content: space-between;}
.prices {background: white;border-radius: 10px;flex: 0 23%;margin-left: 1%;padding: 15px 0;}
.prices.active {background: #247CDD;border-radius: 10px;flex: 0 23%;margin-left: 1%;padding: 15px 0;}
.pricesymbol{font-size: 18px;background: white;margin: auto;padding: 10px 20px;border-radius: 10px;width:65%;color: #767676;}
.pricevalue {color: white;text-align: center;padding: 10px 0 0;font-size: 16px;color: #767676;}
.active .pricesymbol {color:#247cdd;}
.active .pricevalue {color: white;}
</style>
<style>
body{font-family:Lexend !important}
.displayonmob{display: none;}
.paypalbtn{background: linear-gradient(90deg, #009CDE 0%, #003087 100%);color:white !important;text-transform:capitalize !important;}
.v-dialog.depositmodal{font-family:Lexend;box-shadow:none;}
.lastwith.minwidth{min-width:55px}
.equalsto.minwidth{min-width:10px}
.amntdiv{font-size: 22px;color: #010101;font-weight: 400;line-height: 27.5px;}
.myflex{display: flex;justify-content: center;align-content: center;}
.equalsto {
    margin-right: 6px;
    margin-left: 6px;
}
.smallimgs {
    width: 18px;
    margin-right: 2px;
    margin-top: 2px;
}
.v-dialog.depositmodal::v-deep .primay{margin-left: 0 !important;}
.v-application a{text-decoration:none;}
a.bluecolor{ color: #247CDD !important;background:none;text-decoration: none;}
.nopadding{padding-right:0; padding-left:0;}
.theme--light.v-navigation-drawer {background-color: #FAFAFA !important;}
.v-content__wrap {background: #FFF;}
.header.v-toolbar{-webkit-box-shadow: 0px 0px 0px -1px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;box-shadow: 0px 0px 0px -1px rgba(0, 0, 0, 0.2), 0px 1px 0px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;}
.v-list-item--link::before {background-color: #fff !important;}
.highlighted{background-color: #4b94c7;}  
.application.theme--dark .v-list, .application.theme--dark .v-navigation-drawer {   background: #570505; color:#010101}
.ElementsApp .InputElement{color:#d6d6d6 !important;}
  @font-face {
    font-family: 'icomoon';
     src:  url('../../assets/icomoon.eot?t5elpl');
     src:  url('../../assets/icomoon.eot?t5elpl#iefix') format('embedded-opentype'),
    url('../../assets/icomoon.ttf?t5elpl') format('truetype'),
    url('../../assets/icomoon.woff?t5elpl') format('woff'),
    url('../../assets/icomoon.svg?t5elpl#icomoon') format('svg');
    font-weight: normal;
  }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]::before, [class*=" icon-"]::before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sportlogo{
  width:35%;
}
.sportlogos{
  padding:2%;
  display:flex
}
.icon-Vector2x:before {
  content: "\e901";
}
.icon-Group-1286:before {
  content: "\e900";
}
.sportlogTab{
  margin-left:-5%;
  display:block !important
}
.logochoose{
  margin-left:20%
}
.cusfonts{font-size:20px;}
.v-navigation-drawer .v-list{padding:0}
.v-list--nav .v-list-item{border-radius:0 !important;}
.highlighted{
  border-left: 2px solid blue;
border-radius: 0;
background: transparent;
}
.v-application .otherhigh..primary--text {
    color: #333 !important;
    caret-color: #333 !important;
}
.sport_icon{color: #808080;
    font-size: 20px;
    margin-top: 3px;}
.highlighted span,.highlighted .v-list-item__title{color:#247CDD;}
.nothightlight{color:#333 !important}
.headertitle{font-family:Lexend;font-weight:500;font-size:24px;}
.topavatar{margin-right:5px; width:30px !important; height:30px !important;}
.topname{font-family:Lexend;font-size:16px; font-weight:400;}
.toptitle, .topbalance{display:block; text-align:right;font-family:Lexend}
.balance{color:#247CDD;font-size:16px;}
.toptitle{font-size:10px;color:#010101;}
.topbarmenu{min-height:40px;}
.topbarmenu a{color:#010101 !important; font-size:14px; font-family:Lexend}
.d-flex .babuflex{flex:0 0 50%}
.d-flex .babuflexfull{flex:0 0 100%}
.babuflex.exptime {
	border: 1px solid rgba(0, 0, 0, 0.38);
	border-radius: 3px;
	position: relative;
	margin-right: 2%;
	height: 57px !important;
}
.babuflexfull.exptime {
	border: 1px solid rgba(0, 0, 0, 0.38);
	border-radius: 3px;
	position: relative;
	margin-right: 2%;
	height: 57px !important;
}
.label-expriry {position: absolute;top: -13px;background: white;left: 12px;	padding: 0px 5px;	font-size: 13px;}
.input-group-custom {display: flex;	flex-wrap: wrap;}
.input-group-custom > div {	flex: 0 0 48%;width: 48%;}
.input-group-custom-full> div {	flex: 0 0 100%;width: 100%;}
.input-group-custom-full .input-gup-cus-inner input{border: none;
width: 95%;
margin: 7px auto 0px;
color: rgba(0, 0, 0, 1);
line-height: 52px;
margin: auto;
display: block;}
.input-gup-cus-inner input {border: none;	max-width: 95%;	margin: 3px auto 0px;		color: rgba(0, 0, 0, 1);line-height:52px;}
.input-gup-cus-inner input:focus{ outline: none !important;}
.input-gup-cus-inner input:focus{}
.input-group-custom > div:first-child input{text-align:right;margin-right:5%;}
.input-group-custom > div:nth-child(2) input{text-align:left;margin-left:8%;max-width:90%;}
.cvv .input-gup-cus-inner input{width:80%;margin:auto;display:block;}
.d-flex .babuflex.cvv{flex:0 0 42%;}
.validate{display:flex}
.validate .error{flex:0 0 48%; margin-right:5px;}
.error{color:white;padding-right:5px}
.firstblock.error {
    flex: 0 0 56%;
    margin-right: 2%;
}

.secondblock.error {
    flex: 0 0 42%;
}
/*
.input-gup-cus-inner.input-gup-cus-1::after {	background: rgba(0, 0, 0, 0.34);width: 2px;	height: 15px;	position: absolute;	content: "";top: 22px;	transform: rotate(15deg);
	color: rgba(0,0,0,.87);}

*/
.displaycurr{display:flex}
.cursym img {
    margin-right: 3px;
}
.displaycurr .greenvalue,.displaycurr .redvalue,.displaycurr .orangevalue{margin-left:3px;}
.displaycurr .currval ,.displaycurr .greenvalue,.displaycurr .redvalue,.displaycurr .orangevalue{
    line-height: 24px;
}
.prices .pricesymbol {	font-size: 15px;background: white;	margin: auto;padding: 0px 2px;border-radius: 4px;width: 95%;color: #767676;	text-align: center;}
.prices .pricevalue > div{font-size:14px;}
.prices .pricevalue{padding-top:0px;}
.theme--light.v-slider .v-slider__track-fill {
    background: #247cdd !important;
}
.d-flex.withdrawl{align-items: center;}
.d-flex.withdrawl .v-text-field{  padding-top: 0;margin-top: 0;}
.withdrawlinput input{  padding: 3px 0 8px;margin-top: 0;}
.marginright40 { margin-right: 40px;}
.marginright30 { margin-right: 30px;}
.marginright10 { margin-right: 10px;}
.paybtnsdiv {
    width: 98%;
    margin: auto !important;
    text-align:center;
}
.paybtns {
    width: 100%;
    margin-top: 10px;
    text-transform:capitalize !important;
}
.sportlogos{display:flex;flex-wrap:wrap;width:100%;padding:5px 10px;}
.sportlogos > div { flex: 0 0 33%; padding: 0; margin: 0;margin-bottom:10px;}
/*.sportlogos  div a{display:block;height:55px;text-align:center;}
.sportlogos  div a img{max-height:100%;width:auto;margin:auto;border-radius:6px;}*/
.sportlogos  div a{display:block;height: inherit/*55px*/;text-align:center;overflow-y:hidden;border-radius:6px;}
.sportlogos  div a img{width:100%;margin:auto;border-radius:6px;}
.v-application .otherhigh.primary--text{color:#333 !important;}
.sideicon{width:20px;margin-right:5px}
.sideiconnew{width:20px;margin-right:5px;line-height:25px;}
.innergrouplist > div:first-child > div:first-child  i{display:none;}
.innergames > div:first-child > div:first-child {margin-right:0 !important;}
 span.v-list-item{font-size: 0.8125rem;  font-weight: 500;    line-height: 1rem; padding-left:0 !important;}
 @media(max-width:767px){
 header .topname{display:none;}
 header .headertitle{font-size: 20px;}
 header .topbalance .toptitle{display:none;}
 .displayonmob{display: flex;border-bottom: 1px solid #007bce;}
 .notformobile{display: none;}
}
.actions.green{color:white !important}
.mobile td{width: 100%;display: block;height: inherit !important;}
.informtext{color: black; font-size: 14px; text-align: center;}
span.diffbalance {
    margin-left: 10px;
    font-size: 13px;
}
.helpcls {
    margin-left: 6px;
    margin-top: 2px;
    color: #3b7cdd;
    cursor: pointer;
}
.helpcontent{color: black; font-size: 14px;}
.myblock {
    /* display: flex; */
    padding-right: 10px;
    padding-left: 10px;
    text-align: center;
}
.myblock img {
    width: 40%;
    margin-bottom: 7px;
    height: auto;
}
.myblock a{color: #000 !important;}
.showleagues{
  padding-top: 15px !important;
  justify-content: center;
}
.fantasy-logo{width: 24px; height: 24px;}
.marginright7{margin-right: 7px;}
.v-application--is-ltr .v-list--dense .v-list-group--sub-group .innergrouplistlat .v-list-group__header{padding-left: 0 !important;}
.v-application--is-ltr .v-list-group--sub-group .innergrouplistlat .v-list-item{padding-left: 0 !important;}
.innergrouplistlat .v-list-group__items .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon{display: none;}
</style>
<style>
.blueanchor a{color: #3b7cdd; margin-left: 5px; font-size:14px;}
.v-toolbar__content, .v-toolbar__extension {
    padding: 4px 4px;
}
.litdiff {
    font-size: 0.8125rem;
    font-weight: 500;
    margin-left: 8px;
}

</style>