<template>
  <div id="damnnpx">
    <gamelist v-if="showgamelist"></gamelist>
    <div v-else>
     <v-tabs class="tabber" color="themebtn" @change="tabchange" v-model="tabing">
       <v-tab>One Up</v-tab>
       <v-tab>Two Up</v-tab>
       <v-tab>Hat Trick</v-tab>
     </v-tabs>
      
    <div v-if="tabing == 0">
      <playertable :headers="headers" :fire="false" :itemsWithSno="itemsWithSno"></playertable> 
      <infinite-loading  :identifier="infiniteId" @infinite="getListing"></infinite-loading>
    </div>
    <div v-if="tabing == 1">
      <playertable :headers="headers" :fire="false" :itemsWithSno="itemsWithSno"></playertable> 
      <infinite-loading  :identifier="infiniteId" @infinite="getListing"></infinite-loading>
    </div>
    <div v-if="tabing == 2">
      <playertable :headers="headers" :fire="false" :itemsWithSno="itemsWithSno"></playertable> 
      <infinite-loading  :identifier="infiniteId" @infinite="getListing"></infinite-loading>
    </div>
   <v-overlay color="white" :value="overlay">
      <v-progress-circular indeterminate color="themebtn" size="80"></v-progress-circular>
   </v-overlay>
  </div>
 </div>
</template>

<script>
import Gamelist from '../components/Gamelist.vue';
import Playertable from '../components/Playertable.vue';

export default {
  name: "Damnnpx",
  components:{ Gamelist,Playertable },
  data() {
    return {
      mobileBreakpoint: 600,
      price:'',
      error: false,
      errorText:'',
      tabing:0,
      overlay: false,
      showgamelist:false,
      desserts:[],
      search:'', 
      showplayerdialog:false,
      dialogteam:false,
      selectedPlayer:null,
      readonly:false,
      team:'',
      teams: [],
      language:'',
      page:1,
      isMobile: false,
      infiniteId: +new Date(),
    };
  },
  computed: {
     headers(){
        let headers = [
              { text:"#", value: "sno", width:"5%"},
              { text:"Name",align: "name",value: "name",sortable: true, width:"35%"},
              { text:"", value: "nationality",sortable: true, width:"22%"},
              { text:"", value: "",sortable: false, width:"8%"},
              { text:"UX Units To Buy", value:"availability",sortable: true, width:"8%"},
              { text:"Current",value:"price",sortable:true, width:"10%"},
              { text:"Buy",value:"#", sortable: false, width:"12%"}
         ]
         return headers
       },
       
       itemsWithSno() {
          return this.desserts.map((d, index) => ({ ...d, sno: index + 1,keyval:index }))
       },
       
  },
  watch: {
    $route() {
      this.page = 1;
      this.tabing = 0;
      this.overlay = true;
      this.desserts = [];
      this.league = '';
      this.team = '';  
      this.hotornot = false;
      this.tabone = 'All';
       
      if(this.$route.params.sport){
        this.game = this.$route.params.sport;
      }
      else{
        this.game = '';
      }
      if(typeof this.$route.params.league != "undefined"){
       this.league = this.$route.params.league; 
      } 
      else{
        this.league = '';
      }
      this.changeType();
   },
    
  },
  mounted() {
      this.overlay = true;
    var param = this.$route.params.sport;
    if(typeof param != "undefined") {
       this.game = param;
    }
    if(typeof this.$route.params.league != "undefined"){
       this.league = this.$route.params.league; 
    } 
    //this.getListing("one");
   
  },
  methods: {
   
    tabchange(){
       this.overlay = true;
       this.page = 1;
      this.desserts = [];
       
      this.infiniteId += 1;
       
      //  if(this.tabing == 0){
      //     this.getListing("one");
      //   }
      //   else if(this.tabing == 1){
      //     this.getListing("two");
      //   }
      //   else{
      //     this.getListing("three");
      //   }

    },
    getListing($state)
    { 
        var param = "?";
        if(this.tabing == 0)
          param += "type=one";
        else if(this.tabing == 1)
          param +="type=two";
        else
          param +="type=three";
        
        param += "&page="+this.page;
       
       this.$store.dispatch("getBonus",{paramters:param}).then((response) => 
       {
         if(response.data.length > 0)
         {
           
           this.page += 1;
           this.overlay = false; 
           this.desserts.push(...response.data);
           $state.loaded();
         } 
         else
         {
           $state.complete();
         }
       });
     },
  }
};
</script>
<style>
td.Inctive span{
   
    border-radius: 50%;
    width: 22px;
    display: block;
    text-align: center;
    font-size: 12px;
}
td.Active span {
    background: green;
    border-radius: 50%;
    width: 22px;
    display: block;
    text-align: center;
    color: white;
    font-size: 12px;
    height: 22px;
    line-height: 22px;
}
.greencolor.v-tab {
    color: green !important;
}
thead.v-data-table-header.v-data-table-header-mobile {   display: none;}
.d-flex.listing-result {display: flex;flex-wrap: wrap; width: 98%;margin:10px auto;box-shadow: 0 0 2px 3px #ddd;padding: 5px 8px; border-radius: 10px; align-items: center;}
  .listing-result-sub-1.player-info { flex: 0 0 40%;}
  .listing-result {display: flex; flex-wrap: wrap;}
  .listing-result-sub-1.player-graph {flex: 0 0 25%;}
  .listing-result-sub-1.player-data { flex: 0 0 35%;}
  .listing-result img {  max-width: 80%; margin: auto;     max-height: 60px;}
  .play-lst-current {margin-bottom: 15px;}
 .listing-result-sub-1.player-data .buy-now { background-color: #247cdd; border-color: #247cdd;padding: 5px 0px;color: white;font-weight: bold;text-align: center;
    border-radius: 3px;}
  .listing-result-sub-1 label{font-size: 14px;color:#646464;}
.listing-result-sub-1 div.value,.listing-result-sub-1 div.value span{font-size: 13px;}
.listing-result .play-lst-graph-image img {
    max-width: 75%;
    max-height: 40px;
}
 @media screen and (max-width: 768px) {
   .playernamemob{color: black !important;}
   span.country {
    font-size: 11px;
}
    .formobile .mobile table tr td{border: none !important;}
      .mobile table.v-table tr {
        max-width: 100%;
        position: relative;
        display: block;
      }

      .mobile table.v-table tr:nth-child(odd) {
        border-left: 6px solid deeppink;
      }

      .mobile table.v-table tr:nth-child(even) {
        border-left: 6px solid cyan;
      }

      .mobile table.v-table tr td {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #f5f5f5;
        height: auto;
        padding: 10px;
      }

      .mobile table.v-table tr td ul li:before {
        content: attr(data-label);
        padding-right: .5em;
        text-align: left;
        display: block;
        color: #999;

      }
      .v-datatable__actions__select
      {
        width: 50%;
        margin: 0px;
        justify-content: flex-start;
      }
      .mobile .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
        background: transparent;
      }

    }
     
</style>
 