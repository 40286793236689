<template>
  <div id="buynsell">
    <v-tabs class="tabber" color="themebtn" @change="tabchange" v-model="tabing">
       <v-tab>All Sales</v-tab>
       <v-tab>My Sales</v-tab>
     </v-tabs>
    
     <div class="d-flex mt-3 pl-2">
       <div class="filterdiv">
        <v-select label="Choose Sport" @change="filteredItems" v-model="choosesport" :items="sportList" item-text="name"
        item-value="name"   hide-details></v-select>
       </div>
       <div class="filterdiv1">
        <v-select label="Looking for" @change="filteredItems" :items="itemtype"  v-model="searchfor" hide-details></v-select>
       </div>
       <div class="filterdiv2">
        <v-text-field hide-details v-model="searching"  label="Search by name"></v-text-field>
       </div> 
       <div class="filterdiv3 mt-2">
         <v-btn color="primary" @click="resetAll">Reset</v-btn>
       </div>
      </div>
     <v-data-table 
       :headers="headers" 
       :items="dataWithIndex" 
       :single-expand="singleExpand" 
       :expanded.sync="expanded" 
       item-key="id" 
       show-expand 
       :search="search" 
       class="elevation-1 buysellmob" hide-default-footer  disable-pagination>
      <template v-slot:item="{ item,expand, isExpanded,index }"  >
        <tr class="bannertr" v-if="((index+1) %25 == 1 && index > 1)">
             <td :colspan="headers.length">
              <div class="adcontainer">
                <iframe  src='https://api.damnnbank.com/api/showbanners?website=sportrade&width=700&height=90' frameborder='0' scrolling='no' width='720' height='90' allow='autoplay'></iframe>
              </div>
              </td>
           </tr>
        <tr>
          <td>{{ item.index }} </td>  
          <td>
            <div class="mt-2 mb-2">
              <router-link class="anchorcust" :to="{name: 'player', params:{ id: item.player_id }}" v-if="item.type == 'player'"> 
                <v-avatar><img :src="item.thumbnail_image"   :alt="item.name"></v-avatar>  
                <span class="playername">{{item.name}}</span>
              </router-link>
              <router-link class="anchorcust" :to="{name: 'team', params:{ id: item.player_id }}" v-else> 
                <v-avatar><img :src="item.thumbnail_image"   :alt="item.name"></v-avatar>  
                <span class="playername">{{item.name}}</span>
              </router-link>
            </div>
          </td>
          <td><span class="displaycurr formob" v-html="currency(item.price_range)"></span></td> 
          <td>
            <div class="chips" v-bind:style="{ 'background-image': 'url(' + transimage + ')' }">
              <div class="chipscontent">{{ item.total_units_to_trade }}</div>            
            </div>
          </td>
          <td>
            <v-btn color="themebtn" @click="expand(!isExpanded); getUnitsData(item,isExpanded)" class="actions">See Units</v-btn>
          </td>
    </tr>
    
      </template>   
      <template v-slot:expanded-item="{ headers,item }">
       <td :colspan="headers.length" class="customtd" v-if="item.total_units_to_trade > 0" >
        <div v-if="showloader" class="text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
        <div class="unitdiv" v-for="(val,key) in item.see_units" :key="key">
         <v-row>
          <v-col col="4" md="4" sm="4" class="pl-5">
            <div class="d-flex centeritem"><span class="pr-1">Price : </span><span class="displaycurr" v-html="currency(val.sell_price)"></span></div>
            <div class="blackfont">
              <span class="contentdata d-flex centeritem">
                 <span class="pr-1">Total Price : </span><span class="displaycurr" v-html="currency(val.sell_price*val.units_to_trade)"></span>
              </span>
            </div>
            <div class="unitstosold blackfont">Units to Trade: {{ val.units_to_trade }}</div> 
         </v-col>
              <v-col col="4" md="4" sm="4">
                 <div class="sellerphoto"><img :src="val.seller_photo" /></div>
                 <div class="sellername">{{val.seller_name}}</div>
              </v-col>  
              <v-col col="4" md="4" sm="4" class="text-right pr-5 pt-7">
                 
                  <template> 
                   <v-btn v-if="val.sold_by_self == 'yes'" color="primary" @click="cancelsell(val.sell_id,val.units_to_trade,item.type)" class="actions">Cancel</v-btn>
                   <v-btn v-else color="themebtn" @click="buythisitem(val.sell_id,val.units_to_trade,val.sell_price,item.type,item.index,key)" class="actions">Buy</v-btn>
                   </template>  
              </v-col>
              </v-row>
           </div>
        </td>
      </template>
    </v-data-table>
    <infinite-loading ref="infiniteLoading" v-if="tabing == 0" :identifier="infiniteId" @infinite="getList"></infinite-loading>  
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>  
     <v-dialog v-model="dialog"  persistent  max-width="400" >
      <v-card>
        <v-card-text class="pt-5 ">
         <div class="displaycurr otherclss"> 
          <div>{{ showbuytext}} </div>
          <div  class="displaycurr" v-html="currency(this.selectedPlayer.price)"></div>
         </div> 
        </v-card-text>
        <v-card-actions class="justify-center" >
           <v-btn color="#247cdd" text :disabled="showconfirmation"  @click="confirmbuyitem" >Yes</v-btn>
           <v-btn color="red darken-1" :disabled="showconfirmation" text @click="dialog = false">No</v-btn>
        </v-card-actions>
         <v-progress-linear indeterminate color="red darken-2" v-if="showconfirmation"></v-progress-linear>
      </v-card>
    </v-dialog>
    <v-overlay color="white" :value="overlay">
      <v-progress-circular indeterminate color="themebtn" size="80"></v-progress-circular>
   </v-overlay>
  </div>
</template>
<script>
export default {
  name: "Buynsell",
  data() {
    return {
      tabing:0,
      expanded: [],
      desserts:[],
      singleExpand: false,
      overlay: false,
      snackbar: false,
      showconfirmation:false,
      timeout: 2000,
      search:'', 
      text:'',
      dialog: false,
      showbuytext:'',
      selectedPlayer : [],
      choosetype:'Players',
      itemtype:['Players','Teams'],
      teams:[],
      sport:'',
      showloader:false,
      page:1,
      infiniteId: +new Date(),
      mainitem:0,
      mainitemkey:0,
      transimage:require("@/assets/chips.png"),
      searchfor:'',
      searching:'',
      choosesport:'',
      comesearch:0,
      sportList:[],
      headers: [
        { text: '#', value: 'sno' },
        { text: 'Name', value: 'name' },
        { text: 'Units to Trade', value: 'total_units_to_trade' },
        { text: 'Price', value: 'price_range' },
        { text: 'Units', value: 'data-table-expand' }
      ],
    };
  },
  watch:{
   searching()
   {
      if(this.searching.length >= 3)
      {
        this.comesearch = 1;
      }
      else{
        this.comesearch = 0;
      }
      this.filteredItems();
   }
  },
  computed: {
    dataWithIndex() 
    {  
      return this.desserts.map((items, index) => ({ ...items, index: index + 1 }));
    },
  },
  created() {
     this.overlay = true;
     this.$store.dispatch("getGameList").then(() => {
      var gameList = this.$store.getters.getGameList;
        
        for(var i=0; i< gameList.length; i++)
        {
          this.sportList.push({name:gameList[i].name});
        } 
      })
     this.$store.dispatch("retrieveUserDetails").then((response) => {
       this.userid = response.data.id;
       //this.getList();
     }); 
     this.$store.dispatch("getGameListMarketPlace").then((response) => {
       this.teams = response.sports;   
     });
     
  },
 methods: {
     resetAll(){
       this.comesearch = 0;
       this.searchfor = '';
       this.searching = '';
       this.choosesport = '';
       this.filteredItems();
     },
     filteredItems(){
       this.overlay  = true;
       this.desserts = [];
       this.page     = 1;
       //this.getList();
       this.desserts = [];
       this.$nextTick(() => {
           this.$refs.infiniteLoading.attemptLoad();
       });
       this.infiniteId += 1;
     },  
     tabchange(){
       this.desserts = [];
       this.overlay  = true;
       this.page = 1;
       
      this.infiniteId += 1;
       if(this.tabing == 1)
       {
         this.getList();
       }
     },
     getList($state){
         var extradata = "";
         if(this.choosesport != '')
         {
           extradata += "&sport="+this.choosesport;
         }
         if(this.searchfor != "" && this.choosesport != '' && this.comesearch  == 0) 
         {
          extradata += "&sport="+this.choosesport+"&searchfor="+this.searchfor;
         }
         if(this.comesearch  == 1){
            if(this.searchfor != ""){
              extradata += "&searchfor="+this.searchfor;
            }
            if(this.searching != '')
            {
              extradata += "&name="+this.searching;
            } 
         }
       
         var mytype = 0; 
         if(this.tabing == 0){
            mytype = "";
         }else{
            mytype = "1"
         }
         this.$store.dispatch("getBuyandSell",{"type":mytype,"extradata":extradata,"page":this.page}).then((response) => {
            this.overlay = false;
             if(response.data.length > 0)
             {
               if(this.tabing == 0)
               {
                 this.page += 1;
                 this.desserts.push(...response.data);
                 $state.loaded();
               } 
               else
               {
                 this.desserts = response.data;
               }
             }  
             else
             {
               //this.desserts = [];
               $state.complete();
             } 
         });
     },
     getUnitsData(item,isExpanded){
      if(!isExpanded)
      {
        this.showloader = true;
        this.$store.dispatch("getUnitsteamandplayer",{type:item.type,id:item.player_id}).then((response) => {
          item.see_units = response.data;
          this.showloader = false;
        });  
      }
     },
      getMyList(){
         var sport = "";
         if(this.sport >  0)
         {
           sport = this.sport;
         } 
         this.$store.dispatch("getBuyandSell",{"type":'1',"sport":sport}).then((response) => {
             this.desserts = response.data;
             this.overlay = false;
         })
     },
     buythisitem(sellid,units,price,type,item,key){
       this.mainitem = item-1;
       this.mainitemkey = key; 
       this.dialog = true;
       this.showbuytext = "Are your sure you want to buy "+units+" units for ";
       this.selectedPlayer.sellid = sellid;
       this.selectedPlayer.units = units;
       this.selectedPlayer.price = price*units;
       this.selectedPlayer.type  = type;
       this.selectedPlayer.place = 'marketplace';
     },
     confirmbuyitem()
     {
       this.showconfirmation = true;
       if(this.selectedPlayer.type == 'player')
       {
           this.$store.dispatch("buyPlayerbuynsell",{"data":this.selectedPlayer}).then((response) => 
           {
             this.snackbar = true;
             this.dialog = false;
             if(response.data.status == 'success')
            {
              this.text = response.data.message;
              this.$store.dispatch('retrieveBalance');
              this.desserts[this.mainitem].see_units.splice(this.mainitemkey,1);
              if(this.desserts[this.mainitem].see_units.length == 0)
              {
                this.desserts.splice(this.mainitem,1)
              } 
              //this.getList();
            }
           else
           {
              this.text = response.data.message;
           }
           this.showconfirmation = false;
        }) 
       } 
       else
       {
         this.$store.dispatch("buyTeambuynsell",{"data":this.selectedPlayer}).then((response) => 
         {
           this.snackbar = true;
           this.dialog = false;
           if(response.data.status == 'success')
           {
              this.text = response.data.message;
              this.$store.dispatch('retrieveBalance');
              this.desserts[this.mainitem].see_units.splice(this.mainitemkey,1);
              if(this.desserts[this.mainitem].see_units.length == 0)
              {
                this.desserts.splice(this.mainitem,1)
              } 
              //this.getList();
           }
           else
           {
              this.text = response.data.message;
           }
           this.showconfirmation = false;
          })  
       } 
     },
     cancelsell(playerid,units,type)
     {
       if(type == 'player')
       {
         this.$store.dispatch("cancelSellPlayer",{"playerid":playerid,"units":units}).then((response) => 
         {
           this.snackbar = true;
           if(response.data.status == "success")
           {
             this.text = response.data.message;
             if(this.tabing == 0)
               this.getList();
             else
               this.getMyList();  
           }
           else
           {
             this.text = response.data.message;
           }
           this.tabing = 1;
       });
      }
      else
      {
        this.$store.dispatch("cancelSellTeam",{"playerid":playerid,"units":units}).then((response) => 
        {
          this.snackbar = true;
          if(response.data.status == "success")
          {
             this.text = response.data.message;
             if(this.tabing == 0)
               this.getList();
             else
               this.getMyList();  
          }
          else
          {
              this.text = response.data.message;
          }
       });
      }
    },
  }
};
</script>
<style scopped>
.chips{width:56px; height:32px;  color:#247CDD}
.chipscontent {display: block;width: 100%;text-align: center;padding-top: 6px;}
.customtd{box-shadow: inset 0px 4px 4px -5px rgba(50, 50, 50, 0.75), inset 0px -4px 4px -5px rgba(50, 50, 50, 0.75);}
.unitdiv {font-family:Lexend;border: 1px solid #ccc;padding: 5px;border-radius: 10px;width: 50%;margin: auto;margin-top: auto;margin-bottom: auto;margin-top: 10px;margin-bottom: 10px;}
.blackfont{color:#010101;font-weight:500;}
.centeritem{align-items: center;}
</style>
<style>
.anchorcust .v-avatar{overflow:hidden;}
.anchorcust .v-avatar img{ max-width: 70px;height: auto;	margin: auto;}
.v-avatar img{ max-width: 70px;height: auto;	margin: auto;}
.otherclss .displaycurr {margin-left: 4px;}
.sellerphoto {width: 40px;height: 40px;overflow: hidden;border-radius: 50%;margin: auto;}
.sellerphoto img{width: 100%;}
.sellername {font-size: 13px;font-weight: normal;text-align: center;}
@media(max-width:768px)
{
.unitdiv{width: 68%;}
}
@media(max-width:767px)
{
  .buysellmob .v-data-table__mobile-row__header{display: none;}
  .buysellmob .v-data-table__mobile-row__cell{flex: 0 0 100% !important;text-align: center;}
  .buysellmob .displaycurr.formob {width: 100%;align-items: center;justify-content: center;}
  .buysellmob .chips{margin: auto;}  
  .unitdiv{width: 90%;}
}  
.filterdiv1{flex: 0 0 15%;}
.filterdiv1{flex: 0 0 20%;}
.filterdiv2{flex: 0 0 45%; margin-left: 10px;}
.filterdiv3{flex: 0 0 10%; margin-left: 10px;}
</style>