import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App';
import store from './store';
import vuetify from './plugins/vuetify';
import routes from './router/index';
import '@mdi/font/css/materialdesignicons.css'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import InfiniteLoading from 'vue-infinite-loading';
import VueCardFormat from 'vue-credit-card-validation';
import VueSocialSharing from 'vue-social-sharing';
import vueVimeoPlayer from 'vue-vimeo-player';

import BuyplayerModal from '@/components/BuyplayerModal'
import BuyteamModal from '@/components/BuyteamModal'

Vue.use(vueVimeoPlayer);
Vue.use(VueSocialSharing);
Vue.use(VueCardFormat);
Vue.use(InfiniteLoading); 

Vue.use(VueMoment, {
    moment,
})
Vue.use(VueRouter);
Vue.component('buy-player-modal', BuyplayerModal)
Vue.component('buy-team-modal', BuyteamModal)
const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    //this route requires auth, check if logged in, redirect to login if not
    if(!store.getters.loggedIn) {
      next({
        name: 'login'
      });
    } else {
      next();
    }
  } else if(to.matched.some(record => record.meta.requiresVisitor)) {
    //this route requires auth, check if logged in, redirect to login if not
    if(store.getters.loggedIn) {
      next({
        name: 'home'
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

new Vue({
  el: '#app',
  router: router,
  store: store,
  iconfont: 'mdi',
  vuetify,
  render: app => app(App)
});