<template>
    <div class="topcont">
       <div v-for="(val,key) in matches" :key="key" >
         <div v-if="val.semifinalsep == 1" class="seperator">Semi-Finals</div>
         <div v-if="val.finalseperator == 1" class="seperator">Finals</div>
         <div class="matchblock" :class="{'diffclass' : val.showback == 1, 'finaldiffclass': val.finalshowback == 1}">
          <div class="logo">
            <div v-if="val.homeresult != ''" class="ribbon-wrapper left">
                <img class="winlose" v-if="val.homeresult == 'win'" src="../assets/win.png" />
                <img class="winlose" v-if="val.homeresult == 'lose'" src="../assets/lose.png" />
            </div>
            <div><img :src="val.homelogo" /></div>
            <div class="teamtitle">{{ val.hometeam }}</div>
          </div>
          <div class="versus">
             <div class="roundme">VS</div>
             <div class="timing">
                <div class="mt-2">{{ val.matchdate }}</div>
                <div>{{ val.matchtime }}</div>
                <div>{{ val.special }}</div>
             </div>
          </div>
          <div class="logo">
           <div v-if="val.awayresult != ''" class="ribbon-wrapper right">
                <img class="winlose" v-if="val.awayresult == 'win'" src="../assets/win.png" />
                <img class="winlose" v-if="val.awayresult == 'lose'" src="../assets/lose.png" />
           </div>   
            <div><img :src="val.awaylogo" /></div>
            <div class="teamtitle">{{ val.awayteam }}</div>
          </div>
        </div>  
       </div>
       <v-overlay color="white" :value="overlay">
         <v-progress-circular indeterminate color="themebtn" size="80"></v-progress-circular>
       </v-overlay>
    </div>
    
</template>
<script>
export default{
   data(){
    return{
      matches:[],
      sportid:0,
      leagueid:0,
      overlay:false
    }
   },
   created(){
     this.sportid  = this.$route.params.sport;
     this.leagueid = this.$route.params.leagueid;
     this.overlay = true;
     this.getFixtures();
   },
   methods:{
    getFixtures(){
      this.$store.dispatch("getFixtures",{sport:this.sportid,leagueid:this.leagueid}).then((response) => {
        this.matches = response.data;
        this.overlay = false;
      }); 
    },
    getimage(type){
      console.log(type);
       var path="";
       if(type == "win"){
        path == require("@/assets/win.png")
       }
       else{
        path == require("@/assets/lose.png")
       }
       return path;
    }  
   } 
}
</script>
<style scoped>
.seperator{
  position: relative;
  max-width: 500px;
  margin: 25px auto;
  text-align: center;
  color: black;
  font-family: Agbalumo;
  
}
.seperator:before{
  content:" ";
  height: 2px;
  width: 160px;
  background: red;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
}

.seperator:after{
  content:" ";
  height: 2px;
  width: 160px;
  background: red;
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
}
.topcont{width: 60%; margin: auto; margin-top: 15px;}
.matchblock { display: flex; box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; margin-top: 15px; padding: 15px 15px 15px; border-radius: 10px; align-items: center;}
.logo { width: 40%; text-align: center; position: relative;}
.versus {width: 20%; text-align: center;}
.logo img { width: 80px; height: 80px;}
.roundme {width: 50px;height: 50px;margin: auto;border: 2px solid red;border-radius: 50%;line-height: 50px;}
.teamtitle {font-weight: bold; font-size: 14px;}
.timing{font-size: 13px;}
.ribbon-wrapper.left{
    position: absolute;
  
}
.matchblock.diffclass {background-color: #dbd534a6;}
.matchblock.finaldiffclass{background-color: rgb(253 244 7);}
.ribbon-wrapper.right{
    position: absolute;
    right: 1%;
}
/* top right*/
  
img.winlose {
    width: 35px !important;
    height: 35px !important;
}
@media(max-width:480px){  
  .topcont{width: 96%;}  
}
</style>