<template>
  <div id="Gamelist">
    <v-col cols="12" sm="12" md="12">
        <h3 class="chooseheading">Choose Sport to Trade</h3>
    </v-col>
   <v-row class="customrow">  
      <v-col class="text-center sportblock "  sm="6"  md="4" v-for="list in gameList" :key="list.id"> 
        <router-link :to="{name: 'damnnpx', params:{ sport: list.name }}" >
         <div v-if="!list.web_avatar" class="sporttitle" :class="{'noimage' : list.web_avatar == ''}">{{list.name}}</div>
         <img v-if="list.web_avatar" class="sportsimg" :src="list.web_avatar" /> 
         <img v-else class="sportsimg" src="@/assets/no-image.png" /> 
       </router-link>       
  </v-col>
   </v-row>
  </div>           
</template>

<script>

export default {
  name: "Gamelist",
  
  data() {
    return {
      gameList:[],
    };
  },
  computed:{
    checklocalpath(){
       
       if(process.env.VUE_APP_BASEAPI == "http://127.0.0.1/sporttrade/api/")
         return true;
       else
         return false;
    }
  },
  mounted() {
    this.getGames();
    
  },
   methods: {
    getGames() {
      this.$store.dispatch("getGameList").then(() => {
         this.gameList = this.$store.getters.getGameList;
      })
    }
  }
}  
</script>  
<style>
.chooseheading{
  font-family: Lexend;
font-weight: 500;
font-size: 22px;
}
</style>
<style scoped>

.customrow{margin-right: 0;margin-left: 0;}
.sportsimg{width:100%}
.sportblock{position:relative}
.sporttitle {
    position: absolute;
    top: 43%;
    left: inherit;
    width: 93%;
    color: white;
    font-size: 22px;
    font-family: Lexend;
    font-weight: 500;
    font-style: normal;
    line-height: 37px;
}
.sporttitle.noimage{color:black;}
@media(max-width:767px){
  .row.customrow {
    display: block;
}
}
</style>
