<template>
  <div id="playerpage">
    <v-container class="fill-height" fluid>
    <v-row>
     <v-col  col="12" md="12" sm="12">
       <v-btn class="custbtn" @click="gotoback" elevation="1" plain tile ><span class="material-icons">chevron_left</span>Back</v-btn>
     </v-col>
    </v-row>
    <v-row>
     <v-col col="12" md="12" sm="12" v-if="playerinfo">
        <v-row>
          <v-col col="12" md="4" sm="4" class="playerinfo">
             <div class="block">
                <div class="avatar">
                  <img :src="playerinfo.profile_image" />  
                </div>
                <div class="playerdata">
                   <div class="name">
                      <span>{{ playerinfo.name }}</span> 
                      <span v-if="playerinfo.player_status == 'Active'" title="Playing Now" class="alagshow" :class="playerinfo.player_status"></span>
                   </div>
                   <div class="balance" v-if="playerinfo.stats">
                      <div class="title"><!--{{ getcurrencyname }}--></div>
                      <div class="displaycurr">
                        <span class="displaycurr" v-html="currency(playerinfo.price)"></span>
                        <span class="greenvalue" v-if="playerinfo.stats.twnty_four_Hrs.flag == 'positive'">
                         ( + $<span v-html="playerinfo.stats.twnty_four_Hrs.percentage_difference"></span> )
                        </span>
                        <span class="redvalue" v-else>
                         ( - $<span v-html="playerinfo.stats.twnty_four_Hrs.percentage_difference"></span> )
                        </span>
                      </div>
                      <div class="trophycontainer d-flex" v-if="playerinfo.trophy > 0" :title="counttrophy(playerinfo.trophy)">
                         <div class="trophy" v-for="n in playerinfo.trophy" :key="n">
                            <img :src="fireimage"/>
                         </div>
                      </div>
                   </div>
                </div>
             </div> 
          </v-col>  
          <v-col col="12" md="4" sm="4">
            <div class="block optimize-block">
               <div class="avatar">
                  <img src="@/assets/nationality.png" />
               </div>
               <div class="playerdata">
                <div class="country">
                   <div class="countrytitle" v-if="playerinfo.teamname == ''">Country</div>
                   <div class="countrytitle" v-else>Team</div>
                   <div class="countryname" v-if="playerinfo.teamname == ''">
                     <span v-if="playerinfo.nationality || playerinfo.nationality != null">{{ playerinfo.nationality }}</span>
                     <span v-else>N/A</span>
                   </div>
                   <div class="countryname" v-else>{{ playerinfo.teamname }}</div>
                 </div>
               </div>  
             </div> 
          </v-col>  
          <v-col col="12" md="4" sm="4">
            <div class="block optimize-block">
               <div class="avatar">
                  <img src="@/assets/position.png" />
               </div>
               <div class="playerdata">
                <div class="country">
                   <div class="countrytitle">Position</div>
                   <div class="countryname">
                     <span v-if="playerinfo.position || playerinfo.position != null">{{ playerinfo.position }}</span>
                     <span v-else>N/A</span>
                  </div>
                 </div>
               </div>  
             </div> 
          </v-col>  
        </v-row> 
     </v-col>
    </v-row> 
    <v-row>
     <v-col col="12" md="12" sm="12">
       <div class="showdivshere">
         <div class="buyinfo balance" @click="buyplayer">
            {{playerinfo.damann_units}} To Buy World Sport UX
         </div>
         <div class="sellBtn" @click="sellplayer" v-if="playerinfo.is_selected == 1"> Sell </div>
         <div class="sellinfo" @click="showlist">
            {{playerinfo.sell_units}} For Sale in Marketplace 
         </div>
       </div>   
     </v-col>
    </v-row>
    <v-row> 
     <v-col col="12" md="12" sm="12">
       <v-row> 
         <v-col col="12" md="6" sm="6">
            <div class="chartdiv">
              <div class="legendata">
                <div class="row d-inline-flex">
                  <div class="col-sm-4 col-md-4 pt-5 titles">{{getcurrencyname}} Chart</div>
                  <div class="col-sm-8 col-md-8 d-inline-flex justifyright">
                    <div class="pxblock">
                      <div class="colorbox"></div><div class="legendname">UX</div> 
                    </div>
                    <div class="tmblock">
                      <div class="colorbox"></div><div class="legendname">Average</div> 
                    </div>
                    <div class="selector otherselect">
                      <v-select class="ma-0 pa-0 selectbox" v-model="pricedays" :items="daysitem" item-text="name" item-value="abbr" @change="changedata('pricechart')" outlined hide-details></v-select>
                    </div>
                  </div>
                </div>
              </div>
              <canvas id="pricechart"  ref="pricechart" style="display:none"></canvas>
              <line-chart chart-id="pricechartnew" :width="480" :height="240"  v-if="loaded" :chart-data="chartdata"  :options="chartoptions"></line-chart>
            </div>
         </v-col>
         <v-col col="12" md="6" sm="6" >
           <div class="chartdiv">
             <div class="legendata">
               <div class="row d-inline-flex">
                  <div class="col-sm-4 col-md-4 pt-5 titles">Point Chart</div>
                  <div class="col-sm-8 col-md-8 d-inline-flex justifyright">
                    <div class="pxblock">
                      <div class="colorbox"></div><div class="legendname">UX</div> 
                    </div>
                    <div class="tmblock">
                      <div class="colorbox"></div><div class="legendname">Average</div> 
                    </div>
                    <div class="selector otherselect">
                         <v-select
                         class="ma-0 pa-0 selectbox"
                          v-model="pointchartdays"
                         :items="daysitem"
                         item-text="name"
                         item-value="abbr"
                         @change="changedata('pointschart')"
                         outlined
                         hide-details
                         ></v-select>
                    </div>
                  </div>
                </div>
              </div>
             <line-chart chart-id="pointschartnew" :width="480" :height="240"  v-if="pointloaded" :chart-data="pointchartdata"  :options="pointchartoptions"></line-chart>
             <!--<area-chart chart-id="pointschartnew"   :legend="false" width="480px" height="300px"    v-if="pointloaded" :data="pointchartdata"></area-chart>-->
          </div>
            
         </v-col>
         <v-col col="12" md="6" sm="6">
           <div class="chartdiv">
           <div class="legendata">
                <div class="row d-inline-flex mb-2">
                  <div class="col-sm-2 col-md-2 pr-0 pl-1 pt-5 titles">
                   Unit Chart
                  </div>
                  <div class="col-sm-10 col-md-10 d-inline-flex pl-5  justifyright">
                    <div class="pxblock">
                      <div class="colorbox"></div><div class="legendname">Sold Units</div> 
                    </div>
                   
                    <div class="tmblock">
                      <div class="colorboxgold"></div><div class="legendname">Available Units</div> 
                    </div>
                   
                    <div class="selector units">
                         <v-select
                         class="ma-0 pa-0 selectbox"
                          v-model="unitsdays"
                         :items="daysitem"
                         item-text="name"
                         item-value="abbr"
                         @change="changedata('unitschart')"
                         outlined
                         hide-details
                         ></v-select>
                    </div>
                  </div>
                </div>
              </div>
             <!--<canvas id="unitschart" ref="unitschart"></canvas>-->
             <line-chart ref="unitschart" chart-id="unitscharts" :width="480" :height="240"  v-if="unitloaded" :chart-data="unitschartdata"  :options="unitsoption"></line-chart>
          </div>
         </v-col>
         <v-col col="12" md="6" sm="6">
            <div class="chartdiv">
              <div class="legendata" style="margin-bottom:13px">
                <div class="row ">
                  <div class="col-sm-4 col-md-4 pt-5 titles">Dividend Chart</div>
                  <div class="col-sm-8 col-md-8 ">
                    <div class="pxblock">
                      <div class="colorbox"></div><div class="legendname">Total paid out</div> 
                    </div>
                    <div class="tmblock">
                      <div class="colorboxgold"></div><div class="legendname">Monthly Div rate</div> 
                    </div>
                  </div>
                </div>
              </div>
              <!--<area-chart :legend="false" width="480px" height="300px" :min="0" :max="10" :data="dividendCharddata"></area-chart>-->
              <line-chart ref="dividendchart" chart-id="dividendchart" :width="480" :height="240"  v-if="dividendloaded" :chart-data="dividendchartdata"  :options="dividendoption"></line-chart>
            </div>
          </v-col>
        </v-row>
      </v-col>  
    </v-row> 
    <v-row v-if="playerinfo.last_stats != ''">
      <v-col class="text-center"><!-- {{getcurrencyname}}-->Points History</v-col>
    </v-row>
    <v-row v-if="playerinfo.last_stats" class="stats">
     <v-col>
        <v-list v-for="(item, key) in playerinfo.last_stats" :key="key" class="pricelist">
          <v-list-group :class="playerinfo.sport == 'nfl' ? 'nflclass-old' : '' ">
             <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title >
                  <div class="d-flex innerflex">
                     <div>
                       <div class="dateshow">{{item.date}}</div>
                       <div class="mt-2 displaycurr">{{item.match_point}} </div>
                     </div> 
                     <span class="teamlogo golf" v-if="playerinfo.sport == 'golf'">
                      <img :title="item.againstteam.name" :src="item.againstteam.logo"/> {{item.againstteam.name}}</span>
                     <span class="teamlogo" v-else><img :title="item.againstteam.name" :src="item.againstteam.logo"/> </span>
                  </div>
                </v-list-item-title>
              </v-list-item-content>
             </template>
            <template v-if="playerinfo.sport == 'nfl'">
               <div v-for="(items, keys) in item.unit" :key="keys" class="flex">
               <div>{{keys}}</div>
               <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-for="(innerItem, innerKey) in items" :key="innerKey">
                        {{innerItem.key}}
                      </th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td  v-for="(innerItem, innerKey) in items" :key="innerKey">
                        <span >{{ innerItem.value }}</span>
                      </td>
                    </tr>
                  </tbody>
                 </template>
              </v-simple-table>
                </div>
            </template>   
            <template v-else-if="playerinfo.sport == 'baseball'">
              <div v-for="(items, keys) in item.unit" :key="keys">
               <div class="">{{keys}}</div>
               <v-simple-table >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-for="(initems, inkeys) in items.head" :key="inkeys">
                        {{initems}}
                      </th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td v-for="(initems, inkeys) in items.value" :key="inkeys">
                        <span>{{ initems }}</span>
                      </td>
                    </tr>
                  </tbody>
                 </template>
              </v-simple-table>
              </div>
            </template> 
            <template v-else-if="playerinfo.sport == 'tennis'">
              <div >
               <div class="">{{keys}}</div>
               <v-simple-table >
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Player Name</th>
                      <th v-if="item.tennisSetPlayed >= 1">S1</th>
                      <th v-if="item.tennisSetPlayed >= 2">S2</th>
                      <th v-if="item.tennisSetPlayed >= 3">S3</th>
                      <th v-if="item.tennisSetPlayed >= 4">S4</th>
                      <th v-if="item.tennisSetPlayed >= 5">S5</th>
                      <th>Set Won</th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><span>{{ item.unit[key].playeraname }}</span></td>
                      <td v-for="(inval,inkey) in item.unit[key].teamascore.stats" :key="inkey">
                          <span v-html="scoredisplaying(inval.val)"></span>
                      </td>
                      <td><span>{{ item.unit[key].teamascore.set_won }}</span></td>
                    </tr>
                    <tr>
                      <td><span>{{ item.unit[key].playerbname }}</span></td>
                      <td v-for="(inval,inkey) in item.unit[key].teambscore.stats" :key="inkey">
                         <span v-html="scoredisplaying(inval.val)"></span>
                      </td>
                      <td><span>{{ item.unit[key].teambscore.set_won }}</span></td>
                    </tr>
                  </tbody>
                 </template>
              </v-simple-table>
              </div>
            </template> 
            <template v-else-if="playerinfo.sport == 'golf'">
               <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-for="(itemhead,key) in item.heading" :key="key">
                         {{itemhead}}
                      </th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr >
                      <td v-for="(initems, inkeys) in item.unit" :key="inkeys">
                        <span >{{ initems }}</span>
                      </td>
                    </tr>
                  </tbody>
                 </template>
              </v-simple-table>
            </template>  
            <template v-else>
               <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-for="(itemhead,key) in item.heading" :key="key">
                        {{itemhead}}
                      </th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td v-for="(initems, inkeys) in item.unit" :key="inkeys">
                        <span class="inner" v-if="inkeys == 'sub_total' && item.match_point == initems" :class="item.color">{{ initems }}</span>
                        <span v-else>{{ initems.value }}</span>
                      </td>
                    </tr>
                  </tbody>
                 </template>
              </v-simple-table>
            </template>  
         </v-list-group>
       </v-list>
     </v-col> 
    </v-row>
    <v-dialog v-model="buyplayerdialog"  persistent  max-width="400" >
      <v-card>
        <v-card-text class="pt-5 ">
         <div class="displaycurr otherclss"> 
          <div>{{ showbuytext}} </div>
          <div  class="displaycurr" v-html="currency(this.selectedPlayerBuy.price)"></div>
         </div> 
        </v-card-text>
        <v-card-actions class="justify-center" >
           <v-btn color="#247cdd" text :disabled="showconfirmation"  @click="confirmbuyitem" >Yes</v-btn>
           <v-btn color="red darken-1" :disabled="showconfirmation" text @click="buyplayerdialog = false">No</v-btn>
        </v-card-actions>
         <v-progress-linear indeterminate color="red darken-2" v-if="showconfirmation"></v-progress-linear>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>  
    <v-dialog transition="dialog-bottom-transition" max-width="570" scrollable v-model="showsellList" content-class="market">
      <v-card class="rounded-xl">
        <v-card-text>
          <div class="popupbody">
            <div class="row">
              <div class="heading col-md-10 col-xs-10">Marketplace</div>
              <div class="closebtn col-md-2 col-xs-2 text-right justify-right">
                 <span class="material-icons closeicon"  @click="showsellList = false">close</span>
              </div>
            </div>
          </div>  
        </v-card-text>  
        <v-divider></v-divider>
        <v-card-text style="height: 470px;">
          <div v-if="showloader" class="text-center pt-5">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
          <div class="unitdivnew" v-for="(val,key) in sellList" :key="key">
         <v-row>
          <v-col col="4" md="4" sm="4" class="pl-5">
            <div class="d-flex centeritem"><span class="pr-1">Price : </span><span class="displaycurr" v-html="currency(val.sell_price)"></span></div>
            <div class="blackfont">
              <span class="contentdata d-flex centeritem">
                 <span class="pr-1">Total Price : </span><span class="displaycurr" v-html="currency(val.sell_price*val.units_to_trade)"></span>
              </span>
            </div>
            <div class="unitstosold blackfont">Units to Trade: {{ val.units_to_trade }}</div> 
         </v-col>
              <v-col col="4" md="4" sm="4">
                 <div class="sellerphoto"><img :src="val.seller_photo" /></div>
                 <div class="sellername">{{val.seller_name}}</div>
              </v-col>  
              <v-col col="4" md="4" sm="4" class="text-right pr-5 pt-7">
                 
                  <template> 
                   <v-btn v-if="val.sold_by_self == 'yes'" color="primary" @click="cancelsell(val.sell_id,val.units_to_trade,key)" class="actions">Cancel</v-btn>
                   <v-btn v-else color="themebtn" @click="buythisitem(val.sell_id,val.units_to_trade,val.sell_price,key)" class="actions">Buy</v-btn>
                   </template>  
              </v-col>
              </v-row>
           </div>
       
      </v-card-text>
      </v-card>
    </v-dialog>
   <buy-player-modal :dialog.sync="dialog" :selectedPlayer="selectedPlayer" :language="language" />       
   <v-dialog transition="dialog-bottom-transition" max-width="400" v-model="selldialog" content-class="vdialognew">
      <v-card class="rounded-xl">
       <v-card-text v-if="selectedPlayersell">
        <div class="popupbody">
         <div class="row">
          <div class="heading col-md-10 col-xs-10">Sell Units</div>
           <div class="closebtn col-md-2 col-xs-2">
             <span class="material-icons closeicon"  @click="selldialog = false">close</span>
           </div>
         </div>
         <div class="row"><div class="playerphoto"><img :src="selectedPlayersell.profile_image"></div></div>
         <div class="row mt-5"><div class="playername">{{ selectedPlayersell.name }}</div></div>   
         <div class="row mt-4">
          <div class="col-md-6 col-xs-6 text-center">
           <div class="themebox">
            <div class="unitprice">
              <v-text-field placeholder="Enter price per unit" color="white" v-model="sellprice" @change="checkresultsell" @keyup="checkresultsell" class="customwhite"></v-text-field>     
            </div>
           </div>
          </div>
          <div class="col-md-6 col-xs-6 text-center">
            <div class="themebox">
              <div class="totalunit">{{ selectedPlayersell.total_units}}</div>
              <div class="unitvalue">Units Available to Sell</div>
            </div>
          </div>
         </div>   
        </div>
        <div class="row ">
            <div class="col-md-6 col-xs-6">
              <div class="labels">Input Units</div>
              <div class="input">
                  <input type="text" v-model="inputunitsell" class="custom"  @keyup="checkresultsell"/>
                  <span class="material-icons controls" @click="quantitysell('minus')">remove</span>
                  <span class="material-icons controls" @click="quantitysell('plus')">add</span>
              </div>
              
            </div>
            <div class="col-md-6 col-xs-6">
              <div class="labels">Total</div>
              <div class="total displaycurr"><span v-html="currencysymbol()"></span> {{ totalsell }} </div> 
              <div v-if="totalsell > 0" class="taxclass">( Include's Transaction fee )</div>
            </div>
        </div>
        <div v-if="error" class="red--text smallfonts mt-1 mb-1 text-center">{{ errorText }}</div>
            </v-card-text>
            <v-card-actions class="justify-center action pb-5">
              
                <v-btn
              x-large
               class="primary"
               :disabled=readonly
               @click="confirmsell"
              >Confirm</v-btn>
            </v-card-actions>
          </v-card>
        
      </v-dialog>
  </v-container>
 </div>
 
</template>
<script>
import Chart from 'chart.js'
import LineChart from './LineChart.js'

export default {
  name: 'PlanetChart',
    components: {
      LineChart
    },
  data() {
    return {
      showcharts:false,
      fireimage:require("@/assets/trophy.png"),
      daysitem:[
          { name: '1 Day', abbr: '1' },
          { name: '7 Days', abbr: '7' },
          { name: '30 Days', abbr: '30' },
          { name: '90 Days', abbr: '90' },
          { name: '180 Days', abbr: '180' },
          { name: '365 Days', abbr: '365' },
      ],
      chartDatamy:{},
      language:'',
      prevRoute: null,
      gradient: null,
      gradient2: null,
      gradients: null,
      gradients2: null,
      gradientss: null,
      gradientss2: null,
      playerinfo:[],
      priceChartData:'',
      dividendCharddata:'',
      unitschartdata:'',
      pointschartdata:'',
      type:"line",
      pricedays:30,
      dividenddays:30,
      unitsdays:30,
      pointchartdays:30,
      dialog:false,
      selldialog:false,
      selectedPlayer:null,
      selectedPlayersell:null,
      inputunit: 0,
      inputunitsell:0,
      total:0,
      totalsell:0,
      readonly:false,
      chartoptions:'',
      chartdata:'',
      loaded:false,    
      unitloaded:false,
      pointchartoptions:'',
      pointchartdata:'',
      pointloaded:false,   
      dividendloaded:false,
      dividendoption:'',
      dividendchartdata:'', 
      unitsoption:'',
      showsellList:false,
      showloader:false,
      snackbar: false,
      timeout: 2000,
      text:'',
      sellList:[],
      buyplayerdialog:false,
      showbuytext:'',
      mainitemkey:0,
      selectedPlayerBuy:[],
      showconfirmation:false,
      commonoption : {
                       responsive: true,
                       tooltips: {
                       enabled: true,
                       mode: 'single',
                       callbacks: {
                          label: function(tooltipItems) { 
                            var getvalue = localStorage.getItem('coin') || 0;
                            if(getvalue == 1)
                              return 'DC'+tooltipItems.yLabel;
                            else
                              return '$'+tooltipItems.yLabel.toFixed(2);
                          }
                       }
                     },
                     styles: {
                        width: '100%',
                        height: '240px',
                        position: 'relative'
                     },
                     bezierCurve: false,
                     legend: { display: false},
                     pointDotRadius: 10,
                     pointDotStrokeWidth: 8,
                     pointHitDetectionRadius: 20,
                     hover: {
                              mode: 'nearest',
                              intersect: true
                            },          
                            scales: {
                                      xAxes: [{
                                                gridLines: {
                                                  display: false,
                                                },
                                                
                                              }],
                                      yAxes: [{
                                                gridLines: {
                                                      borderDash: [8, 8],
                                                      color: "rgba(255, 255, 255, 0.1)",
                                                      zeroLineColor: '#fff'
                                                  },
                                                    
                                                    ticks: {
                                                      beginAtZero: true,
                                                      padding: 25,
                                                      callback: function(value) {
                                                         var getvalue = localStorage.getItem('coin') || 0;
                                                         //var multiply = localStorage.getItem('coinvalue') || 1;
                                                          if(getvalue == 1)
                                                            return 'DC ' + value;
                                                          else
                                                            return '$' + value.toFixed(2);  
                                                      }
                                                    },
                                               }]
                                     },
                       },
    };
  },
  computed:{
   getcurrencyname(){
     var currencyname = 'Price';
      if(this.$store.getters.getcurrencySetting.dammn_coin == 1)
      {
         currencyname = 'DC';
      }
     return currencyname;
   }
  },
  beforeRouteEnter(to, from, next) {
  next(vm => {
    vm.prevRoute = from;
     localStorage.setItem('comefrom', vm.prevRoute.path);
  })
},
  mounted() {
      this.pricedays      = { name:'30 Days', abbr: '30'};
      this.dividenddays   = { name:'7 Days',  abbr: '7' };
      this.unitsdays      = { name:'7 Days',  abbr: '7' };
      this.pointchartdays = { name:'30 Days', abbr: '30'};
      
      this.gradient  = this.$refs.pricechart.getContext("2d").createLinearGradient(0, 0, 0, 450);
      this.gradient2 = this.$refs.pricechart.getContext("2d").createLinearGradient(0, 0, 0, 450);

      this.gradient.addColorStop(0, "rgba(255, 0,0, 0)");
      this.gradient.addColorStop(0.5, "rgba(255, 0, 0, 0)");
      this.gradient.addColorStop(1, "rgba(255, 0, 0, 0)");

      this.gradient2.addColorStop(0, "rgba(36, 124, 221, 0.8)");
      this.gradient2.addColorStop(0.5, "rgba(36, 124, 221, 0.5)");
      this.gradient2.addColorStop(1, "rgba(36, 124, 221, 0)");
      
      this.getplayerpricechart();
      this.getplayerdividendchart();
      this.getplayerunitschart();
      this.getplayerpointschart();    
      this.getPlayerProfile();
  },    
  methods:{
    confirmbuyitem()
     {
       this.showconfirmation = true;
       this.$store.dispatch("buyPlayerbuynsell",{"data":this.selectedPlayerBuy}).then((response) => {
           this.snackbar = true;
           this.buyplayerdialog = false;
           if(response.data.status == 'success')
           {
              this.text = response.data.message;
              delete this.sellList[this.mainitemkey];
              this.$store.dispatch('retrieveBalance');
              
           }
           else
           {
              this.text = response.data.message;
           }
           this.showconfirmation = false;
        }); 
    },
    cancelsell(playerid,units)
    {
       this.$store.dispatch("cancelSellPlayer",{"playerid":playerid,"units":units}).then((response) => 
       {
          this.snackbar = true;
          if(response.data.status == "success")
          {
            this.text = response.data.message;
            this.getList();
          }
          else
          {
            this.text = response.data.message;
          }
          
      });
     },
    showlist(){
       this.showsellList = true;
       this.getList();
    },
    getList(){
      var playerid = this.$route.params.id;
       this.showloader = true;
       this.sellList = [];
       this.$store.dispatch("getUnitsteamandplayer",{type:"player",id:playerid}).then((response) => {
          this.sellList = response.data;
          this.showloader = false;
        });  
    },
    buythisitem(sellid,units,price,key){
       this.mainitemkey = key; 
       this.buyplayerdialog = true;
       this.showbuytext = "Are your sure you want to buy "+units+" units for ";
       this.selectedPlayerBuy.sellid = sellid;
       this.selectedPlayerBuy.units = units;
       this.selectedPlayerBuy.price = price*units;
     },
    counttrophy(counts){
     return counts+ " time bonus";
   },
    scoredisplaying(score)
      {
         if(typeof score != "undefined")
        {
          var splitval = score.split(".");

         if(splitval.length > 0)
         {
           if(splitval.length > 1)
           {
             score = splitval[0]+"<sup>"+splitval[1]+"</sup>"; 
           } 
           else
           {
             score = splitval[0];
           }
         } 
        }
         
         return score;
      }, 
    changename(item){
        var newname = item.replace("_"," ");
        return newname;
    },
    gotoback(){
     var back = localStorage.getItem('comefrom') || 'damnnpx'; 
     this.$router.push(back);
    },
    getPlayerProfile(){
       var playerid = this.$route.params.id;
        this.$store.dispatch("playerProfile",{"playerid":playerid}).then((response) => {
        this.playerinfo = response.player;
       });
    },
    changedata(chartname) 
    {
      this.priceChartData  = '';
       if(chartname == 'pricechart')
         this.getplayerpricechart();
       else if(chartname == 'unitschart')
          this.getplayerunitschart();
       else if(chartname == 'pointschart')
          this.getplayerpointschart();
    },
   
    async getplayerpricechart(){
      var playerid = this.$route.params.id;
      var pricedays = "";

      if(typeof this.pricedays.abbr == "undefined")
      {
        pricedays = this.pricedays;
      }
      else
      {
        pricedays = this.pricedays.abbr;
      }
      this.loaded = false;

     await this.$store.dispatch("playerChart",{"playerid":playerid,"days":pricedays,"type":"price"}).then((response) => 
      {
           this.chartdata = {
                  labels: response.data.x,
                  datasets: [
                              {
                                label: "Trade Market",
                                data: response.data.avg,
                                backgroundColor: this.gradient,
                                borderColor: "rgba(255, 64, 64, 1)",
                                borderWidth: 0.8,
                                radius: 2,
                              },
                              {
                                label: "PX",
                                data: response.data.y,
                                backgroundColor: this.gradient2,
                               borderColor: response.data.color,
                                borderWidth: 0.8,
                                borderCapStyle:'Yes',
                                pointBackgroundColor:response.data.color,
                                radius: 2,
                                
                              }
                            ]
                };
              
         this.loaded = true;
         this.chartoptions = this.commonoption;       
         /*
          this.priceChartData = {
               type: this.type,
               
               data: {
                  labels: response.data.x,
                  datasets: [
                              {
                                label: "Trade Market",
                                data: response.data.avg,
                                backgroundColor: this.gradient,
                                borderColor: "rgba(255, 64, 64, 1)",
                                borderWidth: 1,
                                borderDash: [8, 8],
                                radius: 0,
                              },
                              {
                                label: "Px",
                                data: response.data.y,
                                backgroundColor: this.gradient2,
                                borderColor: "#247CDD",
                                borderWidth: 0.8,
                                borderCapStyle:'Yes',
                                radius: 0,
                              }
                            ]
                },
                options: this.commonoption,
           };     
         */
      const ctx = document.getElementById('pricechart');
      new Chart(ctx, this.priceChartData);
         this.chartDatamy = [
                              {name:'PX',data:response.data.newaxis},
                              {name:'AVG',data:response.data.newavg}
                            ];
         this.loaded = true;
      });
    },
    async getplayerpointschart(){
       var playerid = this.$route.params.id; 
       var pointchartdays = "";
       
       if(typeof this.pointchartdays.abbr == "undefined")
          pointchartdays = this.pointchartdays;
       else
          pointchartdays = this.pointchartdays.abbr;
        
       this.pointloaded = false;
       this.pointsoption = {
                            responsive: true,
                            bezierCurve: false,
                            pointDotRadius: 10,
                            pointDotStrokeWidth: 8,
                            pointHitDetectionRadius: 20,
                            legend: { display: false},
                            scales: {
                                      xAxes: [{
                                                gridLines: {
                                                  display: false,
                                                },
                                              }],
                                      yAxes: [{
                                                gridLines: {
                                                      borderDash: [8, 8],
                                                      color: "rgba(255, 255, 255, 0.1)",
                                                      zeroLineColor: '#fff'
                                                  },
                                                    
                                                    ticks: {
                                                      beginAtZero: true,
                                                      padding: 25,
                                                      callback: function(value) {
                                                          return  value;
                                                      }
                                                    },
                                               }]
                                     },
                       };
      await this.$store.dispatch("pointsChart",{"playerid":playerid,"days":pointchartdays}).then((response) => 
       {
         this.pointchartdata = {
                  labels: response.data.x,
                  datasets: [
                              {
                                label: "Trade Market",
                                data: response.data.avg,
                                backgroundColor: this.gradient,
                                borderColor: "rgba(255, 64, 64, 1)",
                                borderWidth: 1,
                                radius: 2,
                              },
                              {
                                label: "Px",
                                data: response.data.y,
                                backgroundColor: this.gradient2,
                                borderColor: "#247CDD",
                                borderWidth: 0.8,
                                borderCapStyle:'Yes',
                                radius: 2,
                              }
                            ]
                };
         this.pointloaded = true;
         this.pointchartoptions = this.pointsoption;  

         
         /*this.pointchartdata = [
                                {name:'PX',data:response.data.newaxis},
                                {name:'AVG',data:response.data.newavg}
                               ];   */
       
       });
    },
    getplayerdividendchart(){
     
     var playerid = this.$route.params.id;
     this.dividendloaded = false;
     this.dividendoption = {
       responsive : true,
       bezierCurve: true,
       lineTension: 10,
       legend     : { display: false},
       interaction: { mode: 'index', intersect: false,},
        tooltips: {
                      enabled: true,
                      mode: 'single',
                      callbacks: {
                         label: function(tooltipItems) { 
                           var getvalue = localStorage.getItem('coin') || 0;
                           if(getvalue == 1)
                             return 'DC'+tooltipItems.yLabel;
                           else
                             return '$'+tooltipItems.yLabel;
                         }
                      }
                    },
       scales: 
       {
          yAxes: [
            
                   {
                      id: 'y-axis',
                      type: "linear",
                      "position": 'left',
                      gridLines: {display: false,},  
                       ticks: {
                                                     beginAtZero: true,
                                                     padding: 25,
                                                     fontColor:'#1E5490',
                                                     callback: function(value) {
                                                        var getvalue = localStorage.getItem('coin') || 0;
                                                        //var multiply = localStorage.getItem('coinvalue') || 1;
                                                         if(getvalue == 1)
                                                           return 'DC ' + value;
                                                         else
                                                           return '$' + value.toFixed(2);  
                                                     },
                                                     
                                                   },      
                   }, 
                   {
                      id: 'y1-axis',
                      type: "linear",
                      "position": "right",
                      gridLines: {  display: false,},      
                       ticks: {
                                                     beginAtZero: true,
                                                     padding: 25,
                                                     fontColor:'#d9a23d',
                                                     callback: function(value) {
                                                        var getvalue = localStorage.getItem('coin') || 0;
                                                        //var multiply = localStorage.getItem('coinvalue') || 1;
                                                         if(getvalue == 1)
                                                           return 'DC ' + value;
                                                         else
                                                           return '$' + value.toFixed(2);  
                                                     }
                                                   },  
                   }
                ],
           xAxes: [{
                   gridLines: {
                     display: false,
                     
                   },
                   
                 }],  
                     
      },
     };
     this.$store.dispatch("dividendChart",{"playerid":playerid}).then((response) => 
     {
        this.dividendchartdata = {
          labels: response.data.x,
                 datasets: [
                             {
                               data: response.data.y,
                               borderColor: "#247CDD",
                               borderWidth: 0.8,
                               yAxisID: 'y-axis',
                               fill: false,
                             },
                             {
                               data: response.data.newaxis,
                               fill: false,
                               borderColor: "#d9a23d",
                               borderWidth: 0.8,
                               yAxisID: 'y1-axis',
                             },
                           ]
        };
        this.dividendloaded = true;
     }); 
   },
    getplayerunitschart(){
      var playerid = this.$route.params.id;
      var unitsdays = "";
      if(typeof this.unitsdays.abbr == "undefined")
          unitsdays = this.unitsdays;
      else
          unitsdays = this.unitsdays.abbr;
      
      this.unitloaded = false;
      this.unitsoption = {
        responsive : true,
       bezierCurve: true,
       lineTension: 10,
       legend     : { display: false},
       interaction: { mode: 'index', intersect: false,},
        tooltips: {
                      enabled: true,
                      mode: 'single',
                      callbacks: {
                         label: function(tooltipItems) { 
                           
                             return tooltipItems.yLabel.toLocaleString();
                         }
                      }
                    },
       scales: 
       {
          yAxes: [
            
                   {
                      id: 'y-axis',
                      type: "linear",
                      "position": 'left',
                      gridLines: {display: false,},  
                       ticks: {
                                                     beginAtZero: true,
                                                     padding: 25,
                                                     fontColor:'#1E5490',
                                                     callback: function(value) {
                                                         
                                                           return   value;  
                                                     },
                                                     
                                                   },      
                   }, 
                   {
                      id: 'y1-axis',
                      type: "linear",
                      "position": "right",
                      gridLines: {  display: false,},      
                       ticks: {
                                                     beginAtZero: true,
                                                     padding: 25,
                                                     fontColor:'#d9a23d',
                                                     callback: function(value) {
                                                        return    value.toLocaleString();
                                                         
                                                     }
                                                   },  
                   }
                ],
           xAxes: [{
                   gridLines: {
                     display: false,
                     
                   },
                   
                 }],  
                     
      },
                       };
                    
      this.$store.dispatch("unitsChart",{"playerid":playerid,"days":unitsdays}).then((response) => 
      {
           //this.unitschartdata = response.data.newaxis;     
          this.unitschartdata = {
            labels: response.data.x,
                 datasets: [
                             {
                               data: response.data.y,
                               borderColor: "#247CDD",
                               borderWidth: 0.8,
                               yAxisID: 'y-axis',
                               fill: false,
                             },
                             {
                               data: response.data.units,
                               fill: false,
                               borderColor: "#d9a23d",
                               borderWidth: 0.8,
                               yAxisID: 'y1-axis',
                             },
                           ]
       };            
           this.unitloaded = true;
           
      }); 
    },
    
    
    quantitysell(action){
       var coin     = localStorage.getItem('coin') || 0;

       if(action == 'plus'){
         if(typeof this.inputunitsell == "undefined" || this.inputunitsell == ''){
           this.inputunitsell = 0;
         }
         if(this.inputunitsell < this.selectedPlayersell.total_units){
           this.inputunitsell = parseInt(this.inputunitsell)+1;
           this.error =false;
         } 
       }
       else{
          if(this.inputunitsell > 0) {
             this.inputunitsell = parseInt(this.inputunitsell)-1;
          }else{
            this.inputunitsell = 0;
          }
       }
       
       var total = this.inputunitsell*this.sellprice;
       var percent = 0;
       var tax = 0;
       if(this.$store.getters.getcurrencySetting.paypal_fee > 0){
          tax = this.$store.getters.getcurrencySetting.paypal_fee;
       }
        
       if(tax > 0)
       {
          if(this.sellprice < 1)
            percent =  0.02 
          else
            percent = (tax/100)*total;

          total = total-percent;
       } 
       if(coin == 1) {
         this.totalsell = Math.round(total);
       }
       else{
         this.totalsell = parseFloat(total).toFixed(2);
       }
    },
    checkresult(){
       var coin     = localStorage.getItem('coin') || 0;
        if(this.inputunit > 0 ||this.inputunit <= this.selectedPlayer.availability)
       {
          //var coin     = localStorage.getItem('coin') || 0;
          //var multiply = localStorage.getItem('coinvalue') || 1;
          var total    = 0;

          total   = this.selectedPlayer.price*this.inputunit;

          if(coin == 1)         
           this.total = Math.round(total);  
          else
           this.total = total.toFixed(2); 
         
       }
    },
     sellplayer(){
         this.error = false;
         this.errorText = '';
         this.inputunitsell = '';
         this.sellprice = '';
         this.totalsell = '';
         this.selectedPlayersell = this.playerinfo;

         this.selldialog = true; 
     },
      /*Buy Functionality*/
    buyplayer(){
         this.error = '';
         this.errorText = '';
         if(this.playerinfo.player_status == "Active")
         {
          this.$root.$refs.Master.openInGame();
         }
         else
         {
           this.selectedPlayer = this.playerinfo;
           this.dialog = true; 
         }
    },
    quantity(action){
       if(action == 'plus') {
          this.inputunit = parseInt(this.inputunit)+1;
          this.error =false;
       }
       else{
          if(this.inputunit > 0)
          {
             this.inputunit = parseInt(this.inputunit)-1;
          }
          else{
            this.inputunit = 0;
          }
       }
       var total = this.inputunit*this.selectedPlayer.price;

        var percent = 0;
       var tax = 0;
        if(this.$store.getters.getcurrencySetting.paypal_fee > 0)
        {
          tax = this.$store.getters.getcurrencySetting.paypal_fee;
        }
        if(tax > 0)
        {
          if(this.sellprice < 1)
            percent =  0.02 
          else
            percent = (tax/100)*total;
           
          total = total-percent;
        } 

       var coin     = localStorage.getItem('coin') || 0;
       if(coin == 1)
         this.total = Math.round(total);
       else
         this.total = parseFloat(total).toFixed(2);  
    },
    confirmbuy(){
      if(this.inputunit == 0)
      {
         this.error     = true; 
         this.errorText = "Please select unit";
      }
      else{
        this.error =false;
        this.readonly = true;
        var playerid = this.selectedPlayer.id;
        var unit = this.inputunit;
        this.$store.dispatch("buyPlayerDamnn",{"playerid":playerid,"unit":unit}).then((response) => {
           this.readonly = false;
           if(response.data.status == "success"){
             this.readonly = false;
               this.$router.push({ name: 'portfolio' });
           }
            else {
             this.error     = true; 
             this.errorText  = response.data.message;
           }
        });
      }
    },
    /*Sell Functionality*/
    confirmsell(){
      var noerror = 1; 
      if(this.inputunitsell == 0)
      {
         this.error     = true; 
         this.errorText = "Please select unit";
         noerror = 0;
      }
      
      if(this.sellprice == '' || this.sellprice <= 0)
      {
         
         this.error     = true; 
         this.errorText = "Please enter sell unit price";
         noerror = 0;
      }
     
      if(noerror == 1)
      {
        this.error =false;
        this.readonly = true;
        var coin     = localStorage.getItem('coin') || 0;
        var playerid = this.selectedPlayersell.user_player_id;
        var unit = this.inputunitsell;
        var currencyvalue = localStorage.getItem('coinvalue') || 1;
        
        var price    = 0;
        if(coin == 1)
         {
           price    = this.sellprice/currencyvalue;
           price    = Math.round(price);
         } 
        else
         {
           price    = parseFloat(this.sellprice).toFixed(2);
         } 
        
        // var price    = this.totalsell/currencyvalue;
        //price = price.toFixed(2);                
        this.$store.dispatch("sellPlayer",{"playerid":playerid,"unit":unit,"price":price}).then((response) => {
          this.readonly = false;
          
           if(response.data.status == "success"){
             this.readonly = false;
               this.$router.push({ name: 'buynsell' });
           }
           else {
            this.error     = true; 
             this.errorText  = response.data.message;
           }
        });
      }
    },  
    checkresultsell(){
      var coin     = localStorage.getItem('coin') || 0; 
      if(this.inputunitsell > 0 ||this.inputunitsell < this.selectedPlayersell.total_units){
         var total = this.inputunitsell*this.sellprice;
         var percent = 0;
         if(total > 0)
         {
           var tax = 0;
           if(this.$store.getters.getcurrencySetting.paypal_fee > 0){
              tax = this.$store.getters.getcurrencySetting.paypal_fee;
           }
           if(tax > 0)
           {
              if(this.sellprice < 1)
                percent =  0.02 
              else
                percent = (tax/100)*total;

             total = total-percent;
           } 
           if(coin == 1)
             this.totalsell = Math.round(total);
           else
             this.totalsell = parseFloat(total).toFixed(2); 
         }
         else
         {
           if(coin == 1)
             this.totalsell = Math.round(total);
           else
             this.totalsell = parseFloat(total).toFixed(2); 
         } 
       }
    },
   },   
};
</script>
<style scopped>
  .justifyright{justify-content: right;}
  .selector.units{width: 30%;}
  .selector.otherselect{width: 35%;}
.popupbody .playerphoto {
    width: 62px;
    height: 62px;
    display: block;
    margin: auto;
    text-align: center;
}
.popupbody .playerphoto img {
    width: auto;
    border-radius: 5px;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}
.selectbox.v-text-field--outlined > .v-input__control > .v-input__slot{min-height:40px;}
.v-text-field--enclosed .v-input__append-inner{margin-top: 12px;}
.custbtn{padding-left:4px !important;}
.block {
    width: 100%;
    margin: auto;
    height: 120px;
    border: 1px solid #f1f1f1;
    border-radius: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 15px;
        padding-top: 15px;
    padding-top: 20px;
}

.avatar {
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 10px;
}

.avatar img {
    width: auto;
    height: inherit;
    border-radius: 10px;
    max-width: 100px;
    margin: auto;
    display: block;
}
#playerpage{font-family:Lexend;}
.playerdata {
    margin-left: 8%;
}
.playerdata .name{color: #010101;
font-size: 16px;
font-weight: 500;}

.balance .title{ font-size:12px !important; font-weight:400; color:#010101}
span.greenvalue {
    font-size: 12px;
    color: #0C9400;
}
span.orangevalue {
    font-size: 12px;
    color: orange;
}
span.redvalue{font-size:12px;color:red}
.countrytitle {
    margin-top: 5px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
.countryname {
    color: #010101;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.5px;
}
.chartdiv {
    padding: 15px;
    border: 1px solid #f1f1f1;
    margin-bottom: 10px;
    border-radius: 10px;
}
.pxblock .colorbox {
    background: #1E5490;
    width: 14px;
    height: 14px;
    display: inline-block;
}
.legendname {
    display: inline-block;
    margin-left: 5px;
}
.tmblock .colorboxgold {
    width: 14px;
    height: 14px;
    background: #d9a23d;
    display: inline-block;
}
.tmblock .colorbox {
    width: 14px;
    height: 14px;
    background: #EC2829;
    display: inline-block;
}
.selector,.selectorcustom {
    width: 42%;
    display: inline-block;
}
.pxblock,.tmblock {
    margin-right: 8px;
    padding-top: 10px;
    display:inline-block;
}
.titles {
    color: #010101;
    font-size: 16px;
    font-weight: 400;
}
.showdivshere{display:flex;justify-content:center;}
.showdivshere div {
    margin: 0 15px;
    box-shadow: 0 0 1px 2px #cacaca;
    padding: 10px 15px;
    border-radius: 10px;
    cursor:pointer;
}
.sellinfo{font-size:16px; color:black;}
.v-input.customwhite{
    width: 80%;
    margin: auto;
    font-size: 13px;
    
}
.v-input.customwhite::v-deep input{ color: rgba(255,255,255,1); }
.pricelist .v-list-item__content{padding:0}
.pricelist{border-bottom:1px solid #cccccc !important}
.pricelist .displaycurr{ align-items: center;}
.pricelist .v-list-group__items {
    display: block;
    width:100%;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
}

.pricelist .nflclass .v-list-group__items {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
}

.pricelist .v-list-item {
    flex: 0 0 50%;
    padding:0 !important
}
.sellBtn{
  color:white !important;
  background:green !important;
}
.d-flex.customflex div:first-child {
    width: 40%;
    text-transform:capitalize;
}
.taxclass {
    color: black;
    font-size: 9px;
}
.result-list > div {
    flex: 0 0 75%;
    color: black;
}
.result-list {
    display: flex;
    justify-content: start;
    align-items: center;
}
.result-list > div:nth-child(2){flex: 0 0 25%;font-size:14px;}
.nflclass .v-list-group__items > div {flex:0 0 33%}
.nflclass .v-list-group__items {padding-left: 15px;}
span.teamlogo {
    height: 50px;
    overflow: hidden;
    width: /*50px*/auto;
}
.teamlogo img {
    max-width: 50px;
    /* max-height: 100%; */
}
.d-flex.innerflex > div {
    margin-right: 20px;
}
.trophycontainer {
    margin-top: 2px;
}
.trophy img {
    width: 85%;
}
@media(max-width:480px)
{
  .block.optimize-block{height:70px;padding:10px 15px;}
.block.optimize-block .avatar{width: 50px;height: 50px;}
.showdivshere div{padding:10px 8px;}
}
</style>
<style>
.total.displaycurr img {
    width: 20px;
}
.stats .v-data-table table{width:auto}
span.alagshow.Active {
    width: 12px;
    background: green;
    display: inline-block;
    height: 12px;
    border-radius: 50%;
    margin-left: 8px;
}
.teamlogo.golf{    min-width: 250px;}
.buyinfo.balance{background: #247CDD; color: white;box-shadow: 0 0 1px 2px #f9f9f9;}
.unitdivnew {font-family:Lexend;border: 1px solid #ccc;padding: 5px;border-radius: 10px;width: 100%;margin: auto;margin-top: auto;margin-bottom: auto;margin-top: 10px;margin-bottom: 10px;}
</style>
<style>
.v-dialog.market > .v-card > .v-card__text {
    padding: 0 24px 5px;
}
</style>