<script>
import { Doughnut } from 'vue-chartjs'
//import Chart from "chart.js"; 

export default {
  extends: Doughnut,
  props:['chartDataval','optionsval','midPointcolor'],
  methods: {
    update() {
      this.$data._chart.update()
    },
  },
  mounted () {
    
   /*  Chart.pluginService.register({
          beforeDraw: function (chart) {
              var width = chart.chart.width,
                  height = chart.chart.height,
                  ctx = chart.chart.ctx;
              ctx.restore();
              var fontSize = (height / 74).toFixed(2);
              ctx.font = fontSize + "em sans-serif";
              ctx.textBaseline = "middle";
             // ctx.fillStyle = midcolor;
              var text = chart.config.options.elements.center.text,
                  textX = Math.round((width - ctx.measureText(text).width) / 2),
                  textY = height / 1.5;
              ctx.fillText(text, textX, textY);
              ctx.save();
          }

          
    });*/
    
    this.options = 
         {
           borderWidth:1,
           cutoutPercentage:80,
           legend  : { display: false },
           tooltips: {enabled: false},
           hover   : {mode: null},
           rotation: 1 * Math.PI,
           circumference: 1 * Math.PI,
           responsive: false, 
           maintainAspectRatio: false, 
           /*  title: {
            display: true,
            text: this.midPoint,
            fontSize: 10,
            fontColor:'#000',
            
        },*/
       
           animation: { 
              animateRotate: false,
             },
            elements: {
           /* center: {
                text: this.optionsval  //set as you wish
            },*/
            
        },
      };  

    this.chartData = 
         {
            datasets: [
                        {
                          backgroundColor:['#fff','#fff','#fff','#fff','#fff','#fff'],
                          data: [1,1,1,1,1,1]
                        }
                    ],
                     
         }
   
    this.chartData.datasets[0].backgroundColor = this.chartDataval;   
    this.renderChart(this.chartData, this.options)
  }
};
</script>