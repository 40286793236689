<template>
  <div id="playerpage">
    <v-container class="fill-height" fluid>
    <v-row>
     <v-col  col="12" md="12" sm="12">
       <v-btn class="custbtn" @click="gotoback" elevation="1" plain tile ><span class="material-icons">chevron_left</span>Back</v-btn>
     </v-col>
     <v-col col="12" md="12" sm="12" v-if="playerinfo">
        <div class="top-box-verces-block" >
          <div class="top-box-verces heading-paert-verce" v-if="playerinfo.team_id_vs">
              <div class="next-match"><div class="next-matchlable">Next Match</div></div>
              <div class="line">Line</div>
          </div>
          <div class="top-box-verces">
            <div class="next-match">
              <div class="team-block-set">
                <div class="block">
                      <div class="avatar">
                        <img :src="playerinfo.profile_image" />  
                      </div>
                      <div class="playerdata">
                        <div class="name">
                            <span>{{ playerinfo.name }}</span>
                            <span v-if="playerinfo.team_status == 'Active'" title="Playing Now" class="alagshow" :class="playerinfo.team_status"></span>
                        </div>
                        <div class="balance" v-if="playerinfo.stats">
                            <div class="title"><!--{{ getcurrencyname }}--></div>
                            <div class="displaycurr">
                              <span class="displaycurr" v-html="currency(playerinfo.price)"></span>
                              <span class="greenvalue" v-if="playerinfo.stats.twnty_four_Hrs.flag == 'positive'">
                              ( + $<span v-html="playerinfo.stats.twnty_four_Hrs.percentage_difference"></span> )
                              </span>
                              <span class="orangevalue" v-else-if="playerinfo.stats.twnty_four_Hrs.flag == 'orange'">
                              ( <span v-html="playerinfo.stats.twnty_four_Hrs.percentage_difference"></span> )
                              </span>
                              <span class="redvalue" v-else>
                              ( - $<span v-html="playerinfo.stats.twnty_four_Hrs.percentage_difference"></span> )
                              </span>
                            </div>
                            <div class="trophycontainer d-flex" v-if="playerinfo.trophy > 0" :title="counttrophy(playerinfo.trophy)">
                              <div class="trophy" v-for="n in playerinfo.trophy" :key="n">
                                 <img :src="fireimage"/>
                              </div>
                           </div>
                        </div>
                      </div>
                  </div> 
              </div>
              <div class="team-block-vs"><span v-if="playerinfo.team_id_vs">VS</span></div>
              <div class="team-block-set no-border">
                <div class="block" v-if="playerinfo.team_id_vs">
                      <div class="avatar smallavatar">
                        <img :src="playerinfo.teamvslogo" />  
                      </div>
                      <div class="playerdata smallname">
                        <div class="name">
                            {{ playerinfo.teamvsname }}
                        </div>
                        <div class="balance" v-if="playerinfo.stats">
                            <div class="title"><!--{{ getcurrencyname }}--></div>
                            <div class="displaycurr">
                             
                            </div>
                        </div>
                      </div>
                  </div> 
              </div>
            </div>
            <div class="line" >
              <div class="line-value" v-if="playerinfo.team_id_vs">
                <span v-if="playerinfo.ext_line" :class="playerinfo.extcolor">+</span>
                <span v-else :class="playerinfo.extcolor">-</span><span :class="playerinfo.extcolor">{{ playerinfo.line }}</span>
              </div>
              <div class="line-description">*{{playerinfo.comment}}</div>
            </div>
          </div>  
        </div>
        
        <v-row>
          
          <v-col col="12" md="4" sm="4">
            <div class="block"  style="display:none">
               <div class="avatar">
                  <img src="@/assets/nationality.png" />
               </div>
               <div class="playerdata">
                <div class="country">
                   <div class="countrytitle" v-if="playerinfo.teamname == ''">Country</div>
                   <div class="countrytitle" v-else>Team</div>
                   <div class="countryname" v-if="playerinfo.teamname == ''">
                     <span v-if="playerinfo.nationality || playerinfo.nationality != null">{{ playerinfo.nationality }}</span>
                     <span v-else>N/A</span>
                   </div>
                   <div class="countryname" v-else>{{ playerinfo.teamname }}</div>
                 </div>
               </div>  
             </div> 
          </v-col>  
          <v-col col="12" md="4" sm="4">
            <div class="block" style="display:none">
               <div class="avatar">
                  <img src="@/assets/position.png" />
               </div>
               <div class="playerdata">
                <div class="country">
                   <div class="countrytitle">Position</div>
                   <div class="countryname">
                     <span v-if="playerinfo.position || playerinfo.position != null">{{ playerinfo.position }}</span>
                     <span v-else>N/A</span>
                  </div>
                 </div>
               </div>  
             </div> 
          </v-col>  
        </v-row> 
     </v-col>
     <v-col col="12" md="12" sm="12">
       <div class="showdivshere">
         <div class="buyinfo balance" @click="buyplayer">
            {{playerinfo.damann_units}} To World Sport UX
         </div>
          <!--<div class="sellBtn"  @click="sellplayer" v-if="playerinfo.is_selected == 1"> Sell </div>-->
          <div class="sellBtn"  @click="sellplayer" v-if="playerinfo.total_units > 0"> Sell </div>
         <div class="sellinfo" @click="showlist">
            {{playerinfo.sell_units}} For Sale in Marketplace
         </div>
       </div>   
     </v-col>
     <v-col col="12" md="12" sm="12">
       <v-row> 
         <v-col col="12" md="6" sm="6">
            <div class="chartdiv">
              <div class="legendata">
                <div class="row d-inline-flex">
                  <div class="col-sm-4 col-md-4 pt-5 titles">{{getcurrencyname}} Chart</div>
                  <div class="col-sm-8 col-md-8 d-inline-flex">
                    <div class="pxblock">
                      <div class="colorbox"></div><div class="legendname">UX</div> 
                    </div>
                    <div class="tmblock">
                      <div class="colorbox"></div><div class="legendname">Average</div> 
                    </div>
                    <div class="selector">
                      <v-select class="ma-0 pa-0 selectbox" v-model="pricedays" :items="daysitem" item-text="name" item-value="abbr" @change="changedata('pricechart')" outlined hide-details></v-select>
                    </div>
                  </div>
                </div>
              </div>
              <!--<line-chart chart-id="pricechartnew" :width="480" :height="240"  v-if="loaded" :chartdata="chartdata"  :options="chartoptions"></line-chart>-->
              <canvas id="pricechart"  ref="pricechart" style="display:none"></canvas>
              <line-chart chart-id="pricechartnew" :width="480" :height="240"  v-if="loaded" :chart-data="chartdata"  :options="chartoptions"></line-chart>
               
            </div>
         </v-col>
         <v-col col="12" md="6" sm="6" >
            <div class="chartdiv">
           <div class="legendata">
                <div class="row d-inline-flex">
                  <div class="col-sm-4 col-md-4 pt-5 titles">
                   Point Chart
                  </div>
                  <div class="col-sm-8 col-md-8 d-inline-flex">
                    <div class="pxblock">
                      <div class="colorbox"></div><div class="legendname">UX</div> 
                    </div>
                   
                    <div class="tmblock">
                      <div class="colorbox"></div><div class="legendname">Average</div> 
                    </div>
                    
                    <div class="selector">
                         <v-select
                         class="ma-0 pa-0 selectbox"
                          v-model="pointchartdays"
                         :items="daysitem"
                         item-text="name"
                         item-value="abbr"
                         @change="changedata('pointschart')"
                         outlined
                         hide-details
                         ></v-select>
                    </div>
                  </div>
                </div>
              </div>
             <canvas id="pointschart"  ref="pointschart" style="display:none"></canvas>
             <line-chart chart-id="pointschartnew" :width="480" :height="240"  v-if="pointloaded" :chart-data="pointchartdata"  :options="pointsoption"></line-chart>
          </div>
            
         </v-col>
         <v-col col="12" md="6" sm="6" >
           <div class="chartdiv">
           <div class="legendata">
                <div class="row d-inline-flex">
                  <div class="col-sm-4 col-md-4 pt-5 titles">
                   Unit Chart
                  </div>
                  <div class="col-sm-8 col-md-8 d-inline-flex">
                    <div class="pxblock">
                      <div class="colorbox"></div><div class="legendname">UX</div> 
                    </div>
                    <!--
                    <div class="tmblock">
                      <div class="colorbox"></div><div class="legendname">Average</div> 
                    </div>
                   -->
                    <div class="selector">
                         <v-select
                         class="ma-0 pa-0 selectbox"
                          v-model="unitsdays"
                         :items="daysitem"
                         item-text="name"
                         item-value="abbr"
                         @change="changedata('unitschart')"
                         outlined
                         hide-details
                         ></v-select>
                    </div>
                  </div>
                </div>
              </div>
             <line-chart ref="unitschart" chart-id="unitscharts" :width="480" :height="240" :chart-data="unitschartdata"  :options="unitsoption"></line-chart>
              
          </div>
         </v-col>
         <v-col col="12" md="6" sm="6" style="display:none">
            <div class="chartdiv">
              <div class="legendata">
                <div class="row ">
                  <div class="col-sm-6 col-md-6 pt-5 titles">Dividend Chart</div>
                  <div class="col-sm-6 col-md-6 ">
                    <div class="pxblock">
                      <div class="colorbox"></div><div class="legendname">UX</div> 
                    </div>
                    <!--
                    <div class="tmblock">
                      <div class="colorbox"></div><div class="legendname">Average</div> 
                    </div>
                   -->
                    <div class="selectorcustom"></div>
                  </div>
                </div>
              </div>
              <!--<line-chart :width="480" :height="240"  :chartdata="unitchartdata"></line-chart>-->
              
            </div>
          </v-col>
        </v-row>
      </v-col>  
    </v-row> 
    <v-row v-if="playerinfo.last_stats != ''">
      <v-col class="text-center">{{getcurrencyname}} History</v-col>
    </v-row>
     
    <v-row v-if="playerinfo.last_stats">
     <v-col>
        <v-list v-for="(item, key) in playerinfo.last_stats"       
          :key="key"
          class="pricelist"
        >
          <v-list-group :class="playerinfo.sport == 'nfl' ? 'nflclass' : '' ">
             <template v-slot:activator >
              <v-list-item-content>
                <v-list-item-title >
                  <div class="d-flex innerflex">
                     <div>
                       <div class="dateshow">{{item.date}}</div>
                       <div class="mt-2 displaycurr" :class="item.color">{{item.match_point}} </div>
                     </div> 
                     <span class="teamlogo"><img @click="gototeam(item.againstteam.id)" :title="item.againstteam.name" :src="item.againstteam.logo"/> </span>
                  </div>
                </v-list-item-title>
              </v-list-item-content>
             </template>
           
             <template v-if="playerinfo.sport == 'nfl'">
               <div v-for="(items, keys) in item.unit" :key="keys" class="flex">
                   <div v-for="(innerItem, innerKey) in items" :key="innerKey" class="d-flex result-list">
                      <div>{{ innerItem }}</div>
                   </div>
               </div>
             </template>
             
             <template v-else-if="playerinfo.sport == 'football' || playerinfo.sport == 'hockey' || playerinfo.sport == 'npl'">
               <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-for="(itemhead,key) in item.header" :key="key">
                        {{itemhead}}
                      </th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(items, keys) in item.unit" :key="keys">
                      <td v-for="(initems, inkeys) in items" :key="inkeys">
                        <span class="inner" v-if="inkeys == 'sub_total' && item.match_point == initems" :class="item.color">
                          <span v-if="playerinfo.sport == 'football'">{{ initems }}</span>
                          <span v-else>{{ initems }}</span>
                        </span>
                        <span v-else>{{ initems }}</span>
                      </td>
                    </tr>
                  </tbody>
                 </template>
              </v-simple-table>
             
             </template>
             <template v-else>
                <v-list-item v-for="(items, keys) in item.unit" :key="keys">
                <v-list-item-content>
                  <v-list-item-title >
                    <div class="d-flex customflex">
                      <div v-html="changename(items.key)"></div>
                      <div>{{items.value}}</div>
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
             </v-list-item>
             </template>
         </v-list-group>
       </v-list>
     </v-col> 
    </v-row>
    <v-dialog transition="dialog-bottom-transition" max-width="400" v-model="dialog" content-class="vdialognew">
      <v-card class="rounded-xl">
        <v-card-text v-if="selectedPlayer">
          <div class="popupbody">
            <div class="row">
              <div class="heading col-md-10 col-xs-10">Buy Units</div>
              <div class="closebtn col-md-2 col-xs-2">
              <span class="material-icons closeicon"  @click="dialog = false">close</span>
            </div>
          </div>
          <div class="row"><div class="playerphoto"><img :src="selectedPlayer.profile_image"></div></div>
          <div class="row mt-5"><div class="playername">{{ selectedPlayer.name }}</div></div>   
          <div class="row mt-3">
            <div class="col-md-6 col-xs-6 text-center">
              <div class="themebox">
                <div class="unitprice"><span class="displaycurr" v-html="currency(selectedPlayer.price)"></span></div>
                <div class="unitvalue">Price Per Unit</div>
              </div>
            </div>
            <div class="col-md-6 col-xs-6 text-center">
              <div class="themebox">
                <div class="totalunit">{{ selectedPlayer.availability}}</div>
                <div class="unitvalue">Units Available to Buy</div>
              </div>
            </div>
          </div>   
        </div>
        <div class="row ">
            <div class="col-md-6 col-xs-6">
              <div class="labels">Input Units</div>
              <div class="input">
                  <input type="text" v-model="inputunit" class="custom"  @keyup="checkresult" />
                  <span class="material-icons controls" @click="quantity('minus')">remove</span>
                  <span class="material-icons controls" @click="quantity('plus')">add</span>
              </div>
            </div>
            <div class="col-md-6 col-xs-6">
              <div class="labels">Total</div>
              <div class="total"><span class="displaycurr" v-html="currency(total)"></span></div>
            </div>
       </div>
       <div v-if="error" class="red--text smallfonts">{{ errorText }}</div>
      </v-card-text>
      <v-card-actions class="justify-center action pb-5">
        <v-btn x-large class="primary" :disabled=readonly @click="confirmbuy">Confirm</v-btn>
      </v-card-actions>
    </v-card>
   </v-dialog>
   <v-dialog transition="dialog-bottom-transition" max-width="400" v-model="selldialog" content-class="vdialognew">
      <v-card class="rounded-xl">
       <v-card-text v-if="selectedPlayersell">
        <div class="popupbody">
         <div class="row">
          <div class="heading col-md-10 col-xs-10">Sell Units</div>
           <div class="closebtn col-md-2 col-xs-2">
             <span class="material-icons closeicon"  @click="selldialog = false">close</span>
           </div>
         </div>
         <div class="row"><div class="playerphoto"><img :src="selectedPlayersell.profile_image"></div></div>
         <div class="row mt-5"><div class="playername">{{ selectedPlayersell.name }}</div></div>   
         <div class="row mt-4">
          <div class="col-md-6 col-xs-6 text-center">
           <div class="themebox">
            <div class="unitprice">
              <v-text-field placeholder="Enter price per unit" color="white" v-model="sellprice" @change="checkresultsell" @keyup="checkresultsell" class="customwhite"></v-text-field>     
            </div>
           </div>
          </div>
          <div class="col-md-6 col-xs-6 text-center">
            <div class="themebox">
              <div class="totalunit">{{ selectedPlayersell.total_units}}</div>
              <div class="unitvalue">Units Available to Sell</div>
            </div>
          </div>
         </div>   
        </div>
        <div class="row ">
            <div class="col-md-6 col-xs-6">
              <div class="labels">Input Units</div>
              <div class="input">
                  <input type="text" v-model="inputunitsell" class="custom"  @keyup="checkresultsell"/>
                  <span class="material-icons controls" @click="quantitysell('minus')">remove</span>
                  <span class="material-icons controls" @click="quantitysell('plus')">add</span>
              </div>
              
            </div>
            <div class="col-md-6 col-xs-6">
              <div class="labels">Total</div>
              <div class="total displaycurr"><span v-html="currencysymbol()"></span> {{ totalsell }} </div> 
              <div v-if="totalsell > 0" class="taxclass">( Include's Transaction fee )</div>
            </div>
        </div>
        <div v-if="error" class="red--text smallfonts mt-1 mb-1 text-center">{{ errorText }}</div>
            </v-card-text>
            <v-card-actions class="justify-center action pb-5">
              
                <v-btn
              x-large
               class="primary"
               :disabled=readonly
               @click="confirmsell"
              >Confirm</v-btn>
            </v-card-actions>
          </v-card>
        
      </v-dialog>

      <v-dialog v-model="buyplayerdialog"  persistent  max-width="400" >
      <v-card>
        <v-card-text class="pt-5 ">
         <div class="displaycurr otherclss"> 
          <div>{{ showbuytext}} </div>
          <div  class="displaycurr" v-html="currency(this.selectedPlayerBuy.price)"></div>
         </div> 
        </v-card-text>
        <v-card-actions class="justify-center" >
           <v-btn color="#247cdd" text :disabled="showconfirmation"  @click="confirmbuyitem" >Yes</v-btn>
           <v-btn color="red darken-1" :disabled="showconfirmation" text @click="buyplayerdialog = false">No</v-btn>
        </v-card-actions>
         <v-progress-linear indeterminate color="red darken-2" v-if="showconfirmation"></v-progress-linear>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>  
    <v-dialog transition="dialog-bottom-transition" max-width="570" scrollable v-model="showsellList" content-class="market">
      <v-card class="rounded-xl">
        <v-card-text>
          <div class="popupbody">
            <div class="row">
              <div class="heading col-md-10 col-xs-10">Marketplace</div>
              <div class="closebtn col-md-2 col-xs-2 text-right justify-right">
                 <span class="material-icons closeicon"  @click="showsellList = false">close</span>
              </div>
            </div>
          </div>  
        </v-card-text>  
        <v-divider></v-divider>
        <v-card-text style="height: 470px;">
          <div v-if="showloader" class="text-center pt-5">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>
          <div class="unitdivnew" v-for="(val,key) in sellList" :key="key">
         <v-row>
          <v-col col="4" md="4" sm="4" class="pl-5">
            <div class="d-flex centeritem"><span class="pr-1">Price : </span><span class="displaycurr" v-html="currency(val.sell_price)"></span></div>
            <div class="blackfont">
              <span class="contentdata d-flex centeritem">
                 <span class="pr-1">Total Price : </span><span class="displaycurr" v-html="currency(val.sell_price*val.units_to_trade)"></span>
              </span>
            </div>
            <div class="unitstosold blackfont">Units to Trade: {{ val.units_to_trade }}</div> 
         </v-col>
              <v-col col="4" md="4" sm="4">
                 <div class="sellerphoto"><img :src="val.seller_photo" /></div>
                 <div class="sellername">{{val.seller_name}}</div>
              </v-col>  
              <v-col col="4" md="4" sm="4" class="text-right pr-5 pt-7">
                 
                  <template> 
                   <v-btn v-if="val.sold_by_self == 'yes'" color="primary" @click="cancelsell(val.sell_id,val.units_to_trade,key)" class="actions">Cancel</v-btn>
                   <v-btn v-else color="themebtn" @click="buythisitem(val.sell_id,val.units_to_trade,val.sell_price,key)" class="actions">Buy</v-btn>
                   </template>  
              </v-col>
              </v-row>
           </div>
       
      </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
 </div>
 
</template>
<script>
import Chart from 'chart.js'
import LineChart from './LineChart.js'

export default {
  name: 'PlanetChart',
    components: {
      LineChart
    },
  data() {
    return {
      showcharts:false,
      fireimage:require("@/assets/trophy.png"),
      daysitem:[
          { name: '1 Day', abbr: '1' },
          { name: '7 Days', abbr: '7' },
          { name: '30 Days', abbr: '30' },
          { name: '90 Days', abbr: '90' },
          { name: '180 Days', abbr: '180' },
          { name: '365 Days', abbr: '365' },
          
      ],
       items: [
        {
          action: 'mdi-ticket',
          items: [{ title: 'List Item' }],
          title: 'Attractions',
        },
        {
          action: 'mdi-silverware-fork-knife',
          active: true,
          items: [
            { title: 'Breakfast & brunch' },
            { title: 'New American' },
            { title: 'Sushi' },
          ],
          title: 'Dining',
        },
        {
          action: 'mdi-school',
          items: [{ title: 'List Item' }],
          title: 'Education',
        },
        {
          action: 'mdi-run',
          items: [{ title: 'List Item' }],
          title: 'Family',
        },
        {
          action: 'mdi-bottle-tonic-plus',
          items: [{ title: 'List Item' }],
          title: 'Health',
        },
        {
          action: 'mdi-content-cut',
          items: [{ title: 'List Item' }],
          title: 'Office',
        },
        {
          action: 'mdi-tag',
          items: [{ title: 'List Item' }],
          title: 'Promotions',
        },
      ],
      prevRoute: null,
      gradient: null,
      gradient2: null,
      gradients: null,
      gradients2: null,
      gradientss: null,
      gradientss2: null,
      playerinfo:[],
      priceChartData:'',
      dividendCharddata:'',
      pointschartdata:'',
      type:"line",
      pricedays:30,
      dividenddays:30,
      unitsdays:30,
      pointchartdays:30,
      unitsoption:'',
      unitschartdata:'',
      unitloaded:false,
      pointsoption:'',
      dialog:false,
      selldialog:false,
      selectedPlayer:null,
      selectedPlayersell:null,
      inputunit: 0,
      inputunitsell:0,
      total:0,
      totalsell:0,
      readonly:false,
      chartoptions:'',
      chartdata:'',
      loaded:false,    
      pointchartoptions:'',
      pointchartdata:'',
      pointloaded:false,    
      chartDatamy:{},
      showsellList:false,
      showloader:false,
      snackbar: false,
      timeout: 2000,
      text:'',
      sellList:[],
      buyplayerdialog:false,
      showbuytext:'',
      mainitemkey:0,
      selectedPlayerBuy:[],
      showconfirmation:false,
      commonoption : {
                       responsive: true,
                       tooltips: {
                       enabled: true,
                       mode: 'single',
                       callbacks: {
                          label: function(tooltipItems) { 
                            var getvalue = localStorage.getItem('coin') || 0;
                            if(getvalue == 1)
                              return 'DC'+tooltipItems.yLabel;
                            else
                              return '$'+tooltipItems.yLabel.toFixed(2);
                          }
                       }
                     },
                     styles: {
                        width: '100%',
                        height: '240px',
                        position: 'relative'
                     },
                     bezierCurve: false,
                     legend: { display: false},
                     pointDotRadius: 10,
                     pointDotStrokeWidth: 8,
                     pointHitDetectionRadius: 20,
                     hover: {
                              mode: 'nearest',
                              intersect: true
                            },          
                            scales: {
                                      xAxes: [{
                                                gridLines: {
                                                  display: false,
                                                },
                                              }],
                                      yAxes: [{
                                                gridLines: {
                                                      borderDash: [8, 8],
                                                      color: "rgba(255, 255, 255, 0.1)",
                                                      zeroLineColor: '#fff'
                                                  },
                                                    
                                                    ticks: {
                                                      beginAtZero: true,
                                                      padding: 25,
                                                      callback: function(value) {
                                                         var getvalue = localStorage.getItem('coin') || 0;
                                                         //var multiply = localStorage.getItem('coinvalue') || 1;
                                                          if(getvalue == 1)
                                                            return 'DC ' + value;
                                                          else
                                                            return '$' + value.toFixed(2);  
                                                      }
                                                    },
                                               }]
                                     },
                       },
      };
  },
  computed:{
   getcurrencyname(){
     var currencyname = 'Price';
      if(this.$store.getters.getcurrencySetting.dammn_coin == 1)
      {
         currencyname = 'DC';
      }
     return currencyname;
   }
  },
  beforeRouteEnter(to, from, next) {
  next(vm => {
    vm.prevRoute = from;
     localStorage.setItem('comefrom', vm.prevRoute.path);
  })
},
  mounted() {
      this.pricedays =  { name: '30 Days', abbr: '30' };
      this.dividenddays = { name: '30 Days', abbr: '30' };
      this.unitsdays = { name: '30 Days', abbr: '30' };
      this.pointchartdays = { name: '30 Days', abbr: '30' };
      
      this.gradient  = this.$refs.pricechart.getContext("2d").createLinearGradient(0, 0, 0, 450);
      this.gradient2 = this.$refs.pricechart.getContext("2d").createLinearGradient(0, 0, 0, 450);

      this.gradient.addColorStop(0, "rgba(255, 0,0, 0)");
      this.gradient.addColorStop(0.5, "rgba(255, 0, 0, 0)");
      this.gradient.addColorStop(1, "rgba(255, 0, 0, 0)");

      this.gradient2.addColorStop(0, "rgba(36, 124, 221, 0.8)");
      this.gradient2.addColorStop(0.5, "rgba(36, 124, 221, 0.5)");
      this.gradient2.addColorStop(1, "rgba(36, 124, 221, 0)");
     
      this.getplayerpricechart();
      //this.getplayerdividendchart();
      this.getplayerunitschart();
      this.getplayerpointschart();    
      this.getPlayerProfile();
  },    
  methods:{
    confirmbuyitem()
     {
       this.showconfirmation = true;
       this.$store.dispatch("buyTeambuynsell",{"data":this.selectedPlayerBuy}).then((response) => 
       {
           this.snackbar = true;
           this.buyplayerdialog = false;
           if(response.data.status == 'success')
           {
              this.text = response.data.message;
              delete this.sellList[this.mainitemkey];
              this.getList();
              this.$store.dispatch('retrieveBalance');
           }
           else
           {
              this.text = response.data.message;
           }
           this.showconfirmation = false;
       })  
      
     },
    cancelsell(playerid,units)
    {
          this.$store.dispatch("cancelSellTeam",{"playerid":playerid,"units":units}).then((response) => {
               this.snackbar = true;
              if(response.data.status == "success")
              {
                 this.text = response.data.message;
                 this.getList();
              }
              else
              {
                 this.text = response.data.message;
              }
       });
    },
    buythisitem(sellid,units,price,key){
       this.mainitemkey = key; 
       this.buyplayerdialog = true;
       this.showbuytext = "Are your sure you want to buy "+units+" units for ";
       this.selectedPlayerBuy.sellid = sellid;
       this.selectedPlayerBuy.units = units;
       this.selectedPlayerBuy.price = price*units;
     },
    showlist(){
        this.showsellList = true;
        this.getList();
    },
    getList(){
      var playerid = this.$route.params.id;
       this.showloader = true;
       this.$store.dispatch("getUnitsteamandplayer",{type:"team",id:playerid}).then((response) => {
          this.sellList = response.data;
          this.showloader = false;
        });  
    },
    counttrophy(counts){
     return counts+ " time bonus";
   },
    checkresult(){
       var coin     = localStorage.getItem('coin') || 0;
        if(this.inputunit > 0 ||this.inputunit <= this.selectedPlayer.availability)
       {
          //var coin     = localStorage.getItem('coin') || 0;
          //var multiply = localStorage.getItem('coinvalue') || 1;
          var total    = 0;

          total   = this.selectedPlayer.price*this.inputunit;

          if(coin == 1)         
           this.total = Math.round(total);  
          else
           this.total = total.toFixed(2); 
         
       }
    },
    changename(item){
        var newname = item.replace("_"," ");
        return newname;
    },
    gototeam(teamid){
      //this.$router.push({ name: 'team', params:{id: teamid} });
      window.location = '/team/'+teamid;
    },
    gotoback(){
     var back = localStorage.getItem('comefrom') || 'damnnpx'; 
     this.$router.push(back);
    },
    getPlayerProfile(){
       var teamid = this.$route.params.id;
        this.$store.dispatch("teamProfile",{"teamid":teamid}).then((response) => {
        this.playerinfo = response.player;
       });
    },
    changedata(chartname) 
    {
      this.priceChartData  = '';
       if(chartname == 'pricechart')
         this.getplayerpricechart();
       else if(chartname == 'unitschart')
          this.getplayerunitschart();
       else if(chartname == 'pointschart')
          this.getplayerpointschart();
    },
    async getplayerpricechart(){
      var teamid = this.$route.params.id;
      var pricedays = "";

      if(typeof this.pricedays.abbr == "undefined")
        pricedays = this.pricedays;
      else
        pricedays = this.pricedays.abbr;
     
     this.loaded = false;

     await this.$store.dispatch("teamChart",{"teamid":teamid,"days":pricedays,"type":"price"}).then((response) => 
      {
         this.loaded = true;
        /* this.chartDatamy = [
                              {name:'PX',data:response.data.newaxis},
                              {name:'AVG',data:response.data.newavg}
                            ];*/
         this.chartdata = {
                  labels: response.data.x,
                  datasets: [
                              {
                                label: "Trade Market",
                                data: response.data.avg,
                                backgroundColor: this.gradient,
                                borderColor: "rgba(255, 64, 64, 1)",
                                borderWidth: 0.8,
                                radius: 2,
                              },
                              {
                                label: "PX",
                                data: response.data.y,
                                backgroundColor: this.gradient2,
                                borderColor: "#247CDD",
                                borderWidth: 0.8,
                                borderCapStyle:'Yes',
                                radius: 2,
                                
                              }
                            ]
                };
         this.loaded = true;
         this.chartoptions = this.commonoption;   
         const ctx = document.getElementById('pricechart');
      new Chart(ctx, this.priceChartData);                   
      });
    },
    async getplayerpointschart(){
       var teamid = this.$route.params.id; 
       var pointchartdays = "";
       
       if(typeof this.pointchartdays.abbr == "undefined")
          pointchartdays = this.pointchartdays;
       else
          pointchartdays = this.pointchartdays.abbr;
        
        this.pointloaded = false;
         this.pointsoption = {
                            responsive: true,
                            bezierCurve: false,
                            pointDotRadius: 10,
                            pointDotStrokeWidth: 8,
                            pointHitDetectionRadius: 20,
                            legend: { display: false},
                            scales: {
                                      xAxes: [{
                                                gridLines: {
                                                  display: false,
                                                },
                                              }],
                                      yAxes: [{
                                                gridLines: {
                                                      borderDash: [8, 8],
                                                      color: "rgba(255, 255, 255, 0.1)",
                                                      zeroLineColor: '#fff'
                                                  },
                                                    
                                                    ticks: {
                                                      beginAtZero: true,
                                                      padding: 25,
                                                      callback: function(value) {
                                                          return  value;
                                                      }
                                                    },
                                               }]
                                     },
                       };
       /**/
        await this.$store.dispatch("teampointsChart",{"teamid":teamid,"days":pointchartdays}).then((response) => 
        {
          this.pointloaded = true;
          this.pointchartdata = {
                  labels: response.data.x,
                  datasets: [
                              {
                                label: "Trade Market",
                                data: response.data.avg,
                                backgroundColor: this.gradient,
                                borderColor: "rgba(255, 64, 64, 1)",
                                borderWidth: 1,
                                radius: 2,
                              },
                              {
                                label: "Px",
                                data: response.data.y,
                                backgroundColor: this.gradient2,
                                borderColor: "#247CDD",
                                borderWidth: 0.8,
                                borderCapStyle:'Yes',
                                radius: 2,
                              }
                            ]
                };
          /*this.pointchartdata = [
                                {name:'PX',data:response.data.newaxis},
                                {name:'AVG',data:response.data.newavg}
                               ];   */
        });
    },
    
    getplayerunitschart(){
      var teamid = this.$route.params.id;
      var unitsdays = "";
      if(typeof this.unitsdays.abbr == "undefined")
          unitsdays = this.unitsdays;
      else
          unitsdays = this.unitsdays.abbr;
      
       this.unitloaded = false;
      this.unitsoption = {
                            responsive: true,
                            bezierCurve: false,
                            lineTension: 10,
                            legend: { display: false},
                            scales: {
                                      xAxes: [{
                                                gridLines: {
                                                  display: false,
                                                  
                                                },
                                              }],
                                      yAxes: [{
                                                gridLines: {
                                                      borderDash: [8, 8],
                                                      color: "rgba(255, 255, 255, 0.1)",
                                                      zeroLineColor: '#fff'
                                                  },
                                                    
                                                    ticks: {
                                                      beginAtZero: true,
                                                      stepSize:1,
                                                      padding: 25,
                                                      callback: function(value) {
                                                          return  value;
                                                      }
                                                    },
                                               }]
                                     },
                       };
      this.$store.dispatch("teamunitsChart",{"teamid":teamid,"days":unitsdays}).then((response) => 
      {
          this.unitschartdata = {
                labels: response.data.x,
                  datasets: [
                              {
                                label: "Px",
                                data: response.data.y,
                                backgroundColor: this.gradient2,
                                borderColor: "#247CDD",
                                borderWidth: 0.8,
                                borderCapStyle:'Yes',
                                radius: 2,
                              }
                            ]
          };            
          this.unitloaded = true;  
      }); 
    },
    /*getplayerdividendchart(){
     
      var playerid = this.$route.params.id;
      this.$store.dispatch("dividendChart",{"playerid":playerid}).then((response) => 
      {
          // console.log(response);
      }); 
    },*/
    
    quantitysell(action){
       if(action == 'plus'){
         if(typeof this.inputunitsell == "undefined" || this.inputunitsell == ''){
           this.inputunitsell = 0;
         }
         if(this.inputunitsell < this.selectedPlayersell.total_units){
           this.inputunitsell = parseInt(this.inputunitsell)+1;
           this.error =false;
         } 
       }
       else{
          if(this.inputunitsell > 0) {
             this.inputunitsell = parseInt(this.inputunitsell)-1;
          }else{
            this.inputunitsell = 0;
          }
       }
       
       var total = this.inputunitsell*this.sellprice;
       var percent = 0;
       var tax = 0;
       if(this.$store.getters.getcurrencySetting.paypal_fee > 0){
          tax = this.$store.getters.getcurrencySetting.paypal_fee;
       }
       if(tax > 0){
          percent = (tax/100)*total;
          //total = percent+total;
          total = total-percent;
       } 
       if(total > 0) {
         this.totalsell = Math.round(total);
       }
       else{
         this.totalsell = total;
       }
    },
     sellplayer(){
         this.error = false;
         this.errorText = '';
         this.inputunitsell = '';
         this.sellprice = '';
         this.selectedPlayersell = this.playerinfo;

         this.selldialog = true; 
     },
      /*Buy Functionality*/
    buyplayer(){
         this.error = '';
         this.errorText = '';
         this.selectedPlayer = this.playerinfo;
         this.dialog = true; 
    },
    quantity(action){
       if(action == 'plus') {
          this.inputunit = parseInt(this.inputunit)+1;
          this.error =false;
       }
       else{
          if(this.inputunit > 0)
          {
             this.inputunit = parseInt(this.inputunit)-1;
          }
          else{
            this.inputunit = 0;
          }
       }
       var total = this.inputunit*this.selectedPlayer.price;
       this.total = Math.round(total);
    },
    confirmbuy(){
       if(this.inputunit == 0)
      {
         this.error     = true; 
         this.errorText = "Please select unit";
      }
      else{
        this.error    = false;
        this.readonly = true;
        var teamid    = this.selectedPlayer.id;
        var unit      = this.inputunit;
        this.$store.dispatch("buyTeam",{"teamid":teamid,"unit":unit}).then((response) => 
        {
           this.readonly = false;
           if(response.data.status == "success")
           {
             this.readonly = false;
             this.$store.dispatch("retrieveBalance");
             
             this.$router.push("/portfolio?choose=team");
           }
           else 
           {
             this.error     = true; 
             this.errorText  = response.data.message;
           }
        });
      }
    },
    /*Sell Functionality*/
    confirmsell(){
      var noerror = 1; 
      if(this.inputunitsell == 0)
      {
         this.error     = true; 
         this.errorText = "Please select unit";
         noerror = 0;
      }
      
      if(this.sellprice == '' || this.sellprice <= 0)
      {
         
         this.error     = true; 
         this.errorText = "Please enter sell unit price";
         noerror = 0;
      }
     
      if(noerror == 1)
      {
        this.error =false;
        this.readonly = true;
        
        var teamid = this.selectedPlayersell.user_team_id;
        var unit = this.inputunitsell;
        var currencyvalue = localStorage.getItem('coinvalue') || 1;
        var price    = this.sellprice/currencyvalue;
       // var price    = this.totalsell/currencyvalue;
       price = price.toFixed(2);                
        this.$store.dispatch("sellTeam",{"user_team_id":teamid,"unit":unit,"price":price}).then((response) => {
          this.readonly = false;
          
           if(response.data.status == "success"){
             this.readonly = false;
               this.$router.push({ name: 'buynsell' });
           }
           else {
            this.error     = true; 
             this.errorText  = response.data.message;
           }
        });
      }
    },  
    checkresultsell(){
      if(this.inputunitsell > 0 ||this.inputunitsell < this.selectedPlayersell.total_units){
         var total = this.inputunitsell*this.sellprice;
         var percent = 0;
         if(total > 0){
           var tax = 0;
           if(this.$store.getters.getcurrencySetting.paypal_fee > 0){
              tax = this.$store.getters.getcurrencySetting.paypal_fee;
           }
           if(tax > 0){
             percent = (tax/100)*total;
             total = total-percent;
           } 
           this.totalsell = Math.round(total);
         }
         else{
           this.totalsell = total;
         } 
       }
    },
   },   
};
</script>
<style scopped>
.popupbody .playerphoto {
    width: 62px;
    height: 62px;
    display: block;
    margin: auto;
    text-align: center;
}
.popupbody .playerphoto img {
    width: auto;
    border-radius: 5px;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}
.selectbox.v-text-field--outlined > .v-input__control > .v-input__slot{min-height:40px;}
.v-text-field--enclosed .v-input__append-inner{margin-top: 12px;}
.custbtn{padding-left:4px !important;}
.block {
    width: 100%;
    margin: auto;
    height: 120px;
    border: 1px solid #f1f1f1;
    border-radius: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 15px;
        padding-top: 15px;
    padding-top: 20px;
}
.avatar {
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 10px;
}
.avatar img {
    width: auto;
    height: inherit;
    border-radius: 10px;
    max-width: 100px;
    margin: auto;
    display: block;
}
.top-box-verces .avatar img{width:100%;max-width:100%}
#playerpage{font-family:Lexend;}
.playerdata {
    margin-left: 8%;
}
.playerdata .name{color: #010101;
font-size: 16px;
font-weight: 500;}

.balance .title{ font-size:12px !important; font-weight:400; color:#010101}
span.greenvalue {
    font-size: 12px;
    color: #0C9400;
}
span.orangevalue {
    font-size: 12px;
    color: orange;
}
span.redvalue{font-size:12px;color:red}
.countrytitle {
    margin-top: 5px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
.countryname {
    color: #010101;
    font-size: 16px;
    font-weight: 600;
    line-height: 22.5px;
}
.chartdiv {
    padding: 15px;
    border: 1px solid #f1f1f1;
    margin-bottom: 10px;
    border-radius: 10px;
}
.pxblock .colorbox {
    background: #1E5490;
    width: 14px;
    height: 14px;
    display: inline-block;
}
.legendname {
    display: inline-block;
    margin-left: 5px;
}
.tmblock .colorbox {
    width: 14px;
    height: 14px;
    background: #EC2829;
    display: inline-block;
}
.selector,.selectorcustom {
    width: 42%;
    display: inline-block;
}
.pxblock,.tmblock {
    margin-right: 8px;
    padding-top: 10px;
    display:inline-block;
}
.titles {
    color: #010101;
    font-size: 18px;
    font-weight: 400;
}
.showdivshere{display:flex;justify-content:center;}
.showdivshere div {
    margin: 0 15px;
    box-shadow: 0 0 1px 2px #cacaca;
    padding: 10px 15px;
    border-radius: 10px;
    cursor:pointer;
}
.sellinfo{font-size:16px; color:black;}
.v-input.customwhite{
    width: 80%;
    margin: auto;
    font-size: 13px;
    
}
.v-input.customwhite::v-deep input{ color: rgba(255,255,255,1); }
.pricelist .v-list-item__content{padding:0}
.pricelist{border-bottom:1px solid #cccccc !important}
.pricelist .displaycurr{ align-items: center;}
.pricelist .v-list-group__items {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
}

.pricelist .v-list-item {
    flex: 0 0 50%;
}
.sellBtn{
  color:white !important;
  background:green !important;
}
.d-flex.customflex div:first-child {
    width: 40%;
    text-transform:capitalize;
}
.taxclass {
    color: black;
    font-size: 9px;
}
.result-list > div {
    flex: 0 0 75%;
    color: black;
}
.result-list {
    display: flex;
    justify-content: start;
    align-items: center;
}
.result-list > div:nth-child(2){flex: 0 0 25%;font-size:14px;}
.nflclass .v-list-group__items > div {flex:0 0 33%}
.nflclass .v-list-group__items {padding-left: 15px;}
span.teamlogo {
    height: 50px;
    overflow: hidden;
    /* width: 50px; */
    /* box-shadow: 0 0 2px 1px #000; */
    /* margin: auto; */
    text-align: center;
}

.teamlogo img {
    /* max-width: 50px; */
    max-height: 100%;
}
.d-flex.innerflex > div {
    margin-right: 20px;
}
.displaycurr.red{background: none !important; color:red;}
</style>
<style>
.total.displaycurr img {
    width: 20px;
}
.displaycurr.greencolor,.inner.greencolor{color:green !important;background:none !important}
.displaycurr.redcolor,.inner.redcolor{color:red !important;background:none !important}
.teampage .avatar img{max-width:100%;}
.dateshow{color:#000 !important;}
.top-box-verces .next-match {display: flex; flex-wrap: nowrap; align-items: center;flex:0 0 65%;}
.team-block-set { flex: 0 0 41%;}
.next-match .team-block-set.no-border .block { border: none;}
.next-match .team-block-vs{
    text-align: center;
    padding: 0px 15px;
}
.top-box-verces {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}
.top-box-verces .line{display: flex; flex-wrap: nowrap; align-items: center;flex:0 0 35%;}
.line-value {
   
    margin-right: 25px;
}
.line-value span.green{color:green;  background:none !important;}
.line-value span.red{color:red;  background:none !important;}
.next-matchlable{width:100%;text-align:center;padding-right:20px;}
.line-description {
    font-size: 13px;
}
.avatar.smallavatar {
    width: 40px;
    height: 40px;
    margin-top: 12px;
}
.playerdata.smallname {
    margin-top: 15px;
}

</style>