<template>
  <v-container  fluid>
     <v-dialog v-model="showmefirst" persistent max-width="320px">
        <v-card>
           <v-col cols="12" sm="12" md="12" class="text-center">
              <img class="coupanimg" :src="coupanimg" />
              <div class="text-center infotext">You have just won <span class="specialfnt">25 units</span> in the team that is on this card</div>
            </v-col>
        </v-card>
      </v-dialog>
    <v-row align="center" justify="center">
       <v-col cols="12" sm="12" md="12" class="embed-container text-center" v-if="dialog">
         <vimeo-player class="vimeo" video-url="https://vimeo.com/576572843" ref="player" :video-id="videoID" @ended="ended" @ready="onReady" :autoplay="auto" :player-height="height" ></vimeo-player>
      </v-col>    
      <v-col cols="12" sm="6" md="6" v-show="showfirstvideo">
        <v-card class="customround">
          <v-row align="center" justify="center">
            <v-col cols="12" sm="10" md="10" class="text-center pt-5 pb-5">
              <img :src="logo" >
              <v-toolbar light flat  class="d-flex justify-space-around">
            <v-toolbar-title>Register</v-toolbar-title>
          </v-toolbar>
          <v-form @submit.prevent="validate" ref="form" lazy-validation>
            <div class="red--text text-center">{{errorgoeshere}}</div>
            <v-card-text>
              <v-row>
                <v-col col="12" md="6" sm="6" class="">
                  <v-text-field v-model="firstname" class="pa-0" hide-details  outlined label="First Name"></v-text-field>
                </v-col>
                <v-col col="12" md="6" sm="6">
                  <v-text-field v-model="lastname" hide-details outlined label="Last Name"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col col="12" md="6" sm="6">
                  <v-text-field v-model="username" :rules="usernameRule" hide-details outlined label="Username"></v-text-field>
                </v-col>
                <v-col col="12" md="6" sm="6">
                  <v-text-field v-model="email" :rules="emailRules" hide-details  outlined label="E-mail" required></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                 <v-col col="12" md="6" sm="6">
                       <date-pick class="dobpicker" :format="'DD/MM/YYYY'" v-model="dob">
                        <template v-slot:default="{toggle}">
          <v-text-field  hide-details  outlined label="DOB" @click="toggle" v-on:keyup="formatdate($event)" placeholder="DD/MM/YYYY" v-model="dob"></v-text-field>
        </template>
                      </date-pick>
                </v-col>
                <v-col col="12" md="6" sm="6">
                  <v-select v-model="gender"  hide-details  outlined :items="genderarr" label="Gender" required></v-select>
                </v-col> 
              </v-row>  
              <v-row>
                <v-col col="12" md="6" sm="6">
                  <v-text-field v-model="password" :rules="passwordRule" outlined hide-details label="Password" @click:append="show1 = !show1" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'" required></v-text-field>
                </v-col>
                <v-col col="12" md="6" sm="6">
                  <v-text-field outlined hide-details label="Confirm Password" v-model="confirmPassword" :rules="confirmPasswordRules" @click:append="show1 = !show1" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'" required ></v-text-field>
                </v-col>
              </v-row>
               <v-row>
                <v-col cols="6">
                  <v-select v-model="region" hide-details  outlined :rules="regionRule" @change="stateslist($event)" :items="regions" label="Country" required></v-select>
                </v-col>
             
                <v-col cols="6">
                   <v-autocomplete id="stateid" hide-details @change="selectcities($event)" outlined :rules="stateidRule" v-model="stateid" :items="statesarr" item-text="state_name" item-value="state_id"   
                       filled label="State"  class="p-0" ></v-autocomplete>
                </v-col>
              </v-row>
               <v-row>
                <v-col cols="6">
                   <v-autocomplete id="cityid" hide-details  outlined  v-model="cityname" :items="citiesarr" item-text="city_name" item-value="city_id"   
                       filled label="City"  class="p-0" ></v-autocomplete>
                </v-col>
             
                <v-col cols="6">
                  <v-text-field  hide-details  outlined v-model="suburb" label="Suburb" required></v-text-field>
                </v-col>
              </v-row>
             
            </v-card-text>
            <v-card-actions>
              <v-row dense>
                 <v-col col="12" md="12" sm="12">  
                   <v-btn type="submit" :disabled="makedisable" class="button__full x-large" color="primary">Create Account</v-btn>
                   <div class="mt-5">
                    Already have an account? <router-link :to="{ name: 'login'}" class="bluecolor">Login</router-link>

                   </div>
                 </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
            </v-col> 
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';

export default {
  name: "Register",
  components: {DatePick},
  data() {
    return {
      showmefirst:false,
      videoID: '123',
      auto:true,
			height: 520,
      options:{ muted: true,autoplay: true,responsive: true},
      playerReady: false,
      logo: require("@/assets/logo.png"),
      coupanimg:require("@/assets/unnamed.png"),
      region: "",
      username:"",
      regions: ["America", "Australia", "United Kingdom","New Zeland"],
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      confirmPassword:"",
      gender:"",
      city:"", 
      stateid:"",
      cityname:"",
      suburb:"",
      statesarr:[],
       dob:"",
      show1:false,
      show2:false,
      errorgoeshere:"",
      makedisable:false,
      referral:"",
      showfirstvideo:false,
      dialog:false,
      qrcodescan:'',
      citiesarr:'',
      genderarr:["Male","Female","Other"],
      from_date: "",
      dollarref:0,
      usernameRule: [
        v => !!v || "Please enter username"
      ],
      emailRules: [
        v => !!v || "Please enter your email address",
        v =>
          /.+@.+\..+/.test(v) ||
          "This doesn't appear to be a valid email address"
      ],
      passwordRule: [
        v => v.length >= 6 || "Password must be at least 6 characters"
      ],
      confirmPasswordRules: [
        (value) => !!value || 'type confirm password',
        (value) =>
          value === this.password || 'The password confirmation does not match.',
      ],
      regionRule: [
        v => !!v || "Select the most accurate locale so we can provide you with more relevant questions"
      ],
       cityRule: [
        v => !!v || "Please Select the city."
      ],
      stateidRule: [
        v => !!v || "Please Select the state."
      ],
    };
  },
  mounted(){
    if(typeof this.$route.query.offer != "undefined")
    {
      
      this.showfirstvideo = false; 
      this.dialog = false;
      this.showmefirst = true;
      
        var self = this;
          setTimeout(function() {
               self.showmefirst = false;
               //self.showfirstvideo = false; 
               //self.dialog = true;
               self.showfirstvideo = true; 
         }, 5000);
    }
    else
    {
      this.showfirstvideo = true;
      this.dialog = false;
      this.showmefirst = false;
    }
    
  },
  methods: {
    formatdate(event){
      var delimeter = "/";
       if(event.code != "Backspace")
       {
         if(this.dob.length == 2 || this.dob.length == 5)
         {
           this.dob = this.dob + delimeter;
         }
       }
       else
       {
          if(event.code == "Backspace")   
          {
             if(this.dob.length == 2)
            {
              this.dob = '';
            }
          } 
       }
    },
    onReady() {
			this.playerReady = true
    },
    play () {
			this.$refs.player.play()
		},
    ended(){
       this.dialog = false;
       this.showfirstvideo = true;
    },
		pause () {
			this.$refs.player.pause()
		},
    validate() {
      if (this.$refs.form.validate()) {
        this.register();
      }
    },
    register() 
    {
      if(typeof this.$route.query.invite != "undefined")
      {
         this.referral = this.$route.query.invite;    
      }
      if(typeof this.$route.query.offer != "undefined")
      {
         if(this.$route.query.offer == 'scanmest')
         {
             this.qrcodescan = this.$route.query.sp; 
         }
      }
      if(typeof this.$route.query.dollor != "undefined")
      {
         if(this.$route.query.dollor == 100)
         {
             this.dollarref = this.$route.query.dollor; 
         }
      }

      this.makedisable = true;
      this.$store.dispatch("register", {
          username  : this.username,
          firstname : this.firstname,
          lastname  : this.lastname,
          email     : this.email,
          password  : this.password,
          region    : this.region,
          referral  : this.referral,
          qrcodescan: this.qrcodescan,
          dob       : this.dob,
          gender    : this.gender,
          cityname  : this.cityname,
          suburb    : this.suburb,
          stateid   : this.stateid,
          dollarref : this.dollarref
        })
        .then(response => {
          if(!response.data.status)
          {
             this.errorgoeshere = response.data.message;
             this.makedisable = false;
          } 
          else
          {
            // this.$router.push({ name: "login" });
             this.$store.dispatch("retrieveTokenRegister", 
                {username: this.email,password: this.password,damnnid:response.data.result.dammNId}).then(() => {
                this.makedisable = false;
                this.$router.push({ name: "home" });
            }).catch((error) => {
             this.error = true;
             this.errorText = error.response.data.message;
            });
          }
        })
        .catch(error => {
          // TODO: handle incorrect login
          console.log("logging error from login");
          console.log(error);
        }) 
    },
    selectcities(getstate)
    {
    
       this.$store
        .dispatch("citieslist", {
          stateid    : getstate,
          countryname: this.region,
        })
        .then(response => {
          this.citiesarr = response.data;
        })
        .catch(error => {
          // TODO: handle incorrect login
          console.log(error);
        });
    },
      stateslist(getcountry)
    {
       this.$store
        .dispatch("stateslist", {
          countryname: getcountry,
        })
        .then(response => {
          this.statesarr = response.data;
         })
        .catch(error => {
          // TODO: handle incorrect login
          console.log("cities error from rigister");
          console.log(error);
        });
    }
  }
};
</script>
<style scoped>
.nopadding .v-text-field__details {
    display:none !important;
}
.button__full{width:96%;height:45px !important;}
.coupanimg {
  width: 100%;
  margin: auto;
}
.specialfnt {
  color: black;
  font-weight: bold;
  font-size: 15px;
}
</style>
<style>
.vimeo {
   height: 100%;
   width: 100% !important;
}
.vimeo > iframe {
    width: 100% !important;
}
.dobpicker input {
    font-size: 16px;
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    padding-right: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 4px;
    background: transparent;
    /*border: 1px solid #9e9e9e;
    box-shadow: 0 0.1em 0.3em rgb(0 0 0 / 5%);*/
    outline: 0;
}
.vdpHeadCellContent{font-size: 12px;}
legend.myleg {
    text-align: left;
    margin-left: 16px;
    font-size: 13px;
    color: #707070;
}
</style>