import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    light: true,
    themes: {
      light: {
        primary: '#EC2829',
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3,
        themebtn:'#247CDD',
        white:'#fff',
        green:'#228B22'
      },
      dark: {
        primary: '#6cbcf4',
        secondary: '#ff0000',
      },
    },
  },
  icons: {iconfont: 'md',},
});

